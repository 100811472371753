import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import './CheckWindowWidth.scss';

@inject('checkWindowWidth')
@observer
class CheckWindowWidth extends Component {
  render() {
    const { isNarrow } = this.props.checkWindowWidth;

    return (
      <div className={`CheckWindowWidth ${isNarrow ? 'display-none' : ''}`}>
        <p>
          해당 기능을 이용하시려면
          <br />
          화면 크기를 키워주세요
        </p>
      </div>
    );
  }
}

export default CheckWindowWidth;
