import { observable, action, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import moment from 'moment';
import { numberToString, nullableNumber } from 'libs/Utils';
import { Listener } from 'ag-grid-community';

export default class CerviBoxAddForResultStore {
  @observable open = false;
  @observable list = [];

  worklistIdx = null;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
  }

  @action
  show = async (worklistIdx) => {
    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.get(`/api/rq/cervico/worklist/${worklistIdx}`);
      const { a, age, chartNum, comment, g, isPreg, idx, imgCount, p, patName, socialNum, takeDate, docId } = response.data;
      if (this.root.auth.doctor.id === docId) {
        //본인 검사의 경우에만
        runInAction('CerviBoxAddForResultStore : async loadWListData -> success', () => {
          this.root.cervicoRequest.changePatinfo('patName', patName);
          this.root.cervicoRequest.changePatinfo('chartNum', chartNum);
          this.root.cervicoRequest.changePatinfo('socialNum1', socialNum.length >= 6 ? socialNum.substring(0, 6) : '');
          this.root.cervicoRequest.changePatinfo('socialNum2', socialNum.length > 6 ? socialNum.substring(6, 7) : '');
          this.root.cervicoRequest.changePatinfo('age', age);
          this.root.cervicoRequest.changePatinfo('isPreg', isPreg ? 'true' : 'false');
          this.root.cervicoRequest.changePatinfo('p', p);
          this.root.cervicoRequest.changePatinfo('g', g);
          this.root.cervicoRequest.changePatinfo('a', a);
          this.root.cervicoRequest.changePatinfo('memo', comment);

          for (let i = 1; i <= imgCount; i++) {
            this.list.push({
              idx: i,
              wIdx: idx,
              checked: true,
              takeDate: takeDate,
              url: `/api/rq/cervico/worklist/${idx}/picture/${i}`
            });
          }

          this.worklistIdx = worklistIdx;
          this.open = true;
        });
      } else {
        toast.warn(this.locale.fm('store.cervibox.worklist.otherpic'));
      }
    } catch (error) {
      runInAction('CerviBoxAddForResultStore : async loadWListData -> catch', () => {
        ajaxErrorHandler(error);
      });
    }
    this.root.screen.setProcessLayer(false);
  };

  @action
  close = () => {
    this.root.cerviBoxForSearch.init();

    this.open = false;
    this.worklistIdx = null;
    this.list = [];
  };

  @action
  save = async (pictureList, targetCerviboxList) => {
    let cervibox = null;
    if (pictureList.length === 0) {
      toast.warn(this.locale.fm('comp.cervibox.vali.sel1pic'));
      return;
    }
    const p = this.root.cervicoRequest.patinfo;
    const boxIdx = targetCerviboxList[0];
    const worklist = {
      idx: this.worklistIdx,
      checkedList: this.list.map((el) => {
        return el.checked === true;
      })
    };

    if (targetCerviboxList.length === 0) {
      //patinfo 검증
      if (p.patName.trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.patname'));
        return;
      }
      if (p.chartNum.trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.chartnum'));
        return;
      }
      if ((p.socialNum1 + p.socialNum2).trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate'));
        return;
      }
      //생년월일 검사
      if (!moment(p.socialNum1.trim(), 'YYMMDD').isValid()) {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate2'));
        return;
      }

      //주민번호 padEnd
      const socialNum = (p.socialNum1 + p.socialNum2).trim().length > 0 ? p.socialNum1.trim().padEnd(6, '0') + p.socialNum2.trim().padEnd(7, '0') : '';

      //cervibox 생성
      cervibox = {
        patName: p.patName.trim(),
        chartNum: p.chartNum.trim(),
        socialNum: socialNum,
        age: numberToString(p.age),
        isPreg: p.isPreg === '' ? null : p.isPreg === 'true',
        g: nullableNumber(p.g),
        p: nullableNumber(p.p),
        a: nullableNumber(p.a),
        memo: p.memo.trim(),
        ...worklist
      };
      this.root.screen.setProcessLayer(true);
      try {
        const response = await axios.put(`/api/rq/cervibox/add-res`, cervibox);
        if (response.data.success) {
          toast.success(this.locale.fm('store.cervibox.save'));
          this.close();
          this.root.cervicoRequest.init();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        ajaxErrorHandler(error);
      }
      this.root.screen.setProcessLayer(false);
    }
    // 선택한 박스가 여러개일때
    else if (targetCerviboxList.length > 1) {
      toast.warn(this.locale.fm('comp.cervibox.vali.sel1box'));
      return;
    }
    // 1개의 박스를 선택했을 때
    else if (targetCerviboxList.length === 1) {
      this.root.screen.setProcessLayer(true);
      try {
        const response = await axios.post(`/api/rq/cervibox/${boxIdx}/append-res-img`, worklist);
        if (response.data.success) {
          toast.success(this.locale.fm('store.cervibox.save'));
          this.close();
          this.root.cervicoRequest.init();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        ajaxErrorHandler(error);
      }
      this.root.screen.setProcessLayer(false);
    }
  };

  @action
  checkImg = (idx, checked) => {
    this.list.find((p) => p.idx === idx).checked = checked;
  };
}
