import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button } from 'reactstrap';
import { PictureBox } from 'components/common';
import { FormattedMessage } from 'react-intl';
import cookies from 'browser-cookies';
import cx from 'classnames';

import { FaArrowCircleLeft, FaArrowCircleRight, FaQuestionCircle, FaAngleLeft, FaAngleRight, FaExpand, FaCompress } from 'react-icons/fa';

import { toggleFullscreen } from 'libs/Screen';

import './ZoomPopup.scss';

@inject('screen')
class ZoomPopup extends Component {
  state = {
    loaded: false,
    pictureList: [],
    current: 0,
    isFirst: false,
    isLast: false,
    position: '',
    title: ''
  };

  el = React.createRef();

  handleFullscreen = () => {
    toggleFullscreen(this.el.current);
  };

  handleContentMenu = (e) => {
    e.preventDefault();
    this.handleClose();
  };

  handleCallbackSwipe = (i, el) => {
    this.props.zoomWindow.setCurrent(i);
  };

  handleClose = (e) => {
    window.close();
  };

  handleNextClick = (e) => {
    this.setState(this.makeState(this.state.current + 1, this.state.pictureList));
  };

  handlePrevClick = (e) => {
    this.setState(this.makeState(this.state.current - 1, this.state.pictureList));
  };

  componentDidMount() {
    const popup = JSON.parse(cookies.get('zoomPopup'));
    const state = this.makeState(popup.current, popup.pictureList);
    state.loaded = true;
    this.setState(state);
  }

  makeState(current, pictureList) {
    const imgCount = pictureList.length;
    return {
      current,
      pictureList,
      isFirst: current === 0,
      isLast: imgCount === current + 1,
      position: `${current + 1} / ${imgCount}`,
      title: pictureList[current].title
    };
  }

  componentWillUnmount() {
    //TODO keyboard 이벤트 unbind
  }

  render() {
    const { screen } = this.props;

    return this.state.loaded === true ? (
      <div className="ZoomPopup" ref={this.el}>
        <div className="area-picture">
          {this.state.pictureList.map((p, i) => (
            <div className={cx('zoom-item', { 'd-none': this.state.current !== i })} key={i}>
              <PictureBox contain src={p.url} onContextMenu={this.handleContentMenu} />
            </div>
          ))}
          <div className="area-title">{this.state.title}</div>
          <div className="area-top-button button-box">
            <span className="d-none d-md-block">
              <FaQuestionCircle /> <FormattedMessage id="comp.dialog.closeInfo" />
            </span>
            <Button size="sm" onClick={this.handleFullscreen}>
              {screen.fullscreen ? <FaCompress /> : <FaExpand />}
            </Button>
            {/* <Button size="sm" onClick={this.handleClose}>
                <FaTimesCircle />
              </Button> */}
          </div>
          <Button size="sm" disabled={this.state.isFirst} onClick={this.handlePrevClick} className="btn-large-left">
            <FaAngleLeft />
          </Button>
          <Button size="sm" disabled={this.state.isLast} onClick={this.handleNextClick} className="btn-large-right">
            <FaAngleRight />
          </Button>
          <div className="area-bottom-button button-box">
            <Button size="sm" disabled={this.state.isFirst} onClick={this.handlePrevClick}>
              <FaArrowCircleLeft />
            </Button>
            <div>{this.state.position}</div>
            <Button size="sm" disabled={this.state.isLast} onClick={this.handleNextClick}>
              <FaArrowCircleRight />
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <>Loading...</>
    );
  }
}

export default ZoomPopup;
