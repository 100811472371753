import { observable, action, computed, set, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { searchSerialize, confirm } from 'libs/Utils';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import moment from 'moment';

const defaultSearch = {
  startDate: null,
  endDate: null,
  curStateStr: '',
  patName: '',
  chartNum: '',
  socialNum: ''
};

const defaultDataGrid = {
  rows: [],
  page: 1,
  totalPage: 1,
  total: 0
};

export default class SmsResultStore {
  @observable search = { ...defaultSearch };
  @observable dataGrid = { ...defaultDataGrid };
  @observable mask = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.initSearch();
    this.dataGrid = { ...defaultDataGrid };
    this.mask = false;
  };

  @action
  initSearch = () => {
    this.search = Object.assign(
      { ...defaultSearch },
      {
        startDate: moment().startOf('day').add(-12, 'month').toDate(),
        endDate: moment().startOf('day').toDate()
      }
    );
  };

  @action
  triggerSearch = async () => {
    await this.loadDataGrid();
  };

  @action
  triggerReset = async () => {
    this.initSearch();
    await this.loadDataGrid();
  };

  @action
  triggerRefresh = async () => {
    await this.loadDataGrid(this.dataGrid.page);
  };

  @action
  changeSearch = (key, value) => {
    this.search[key] = value;
  };

  @action
  loadDataGrid = async (page = 1) => {
    this.mask = true;
    try {
      const response = await axios.get('/api/rq/sms', {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('SmsResultStore : async loadDataGrid -> success', () => {
        this.dataGrid = response.data;
        this.mask = false;
      });
    } catch (error) {
      runInAction('SmsResultStore : async loadDataGrid -> catch', () => {
        ajaxErrorHandler(error);
        this.mask = false;
      });
    }
  };

  @action
  remove = async (idxs) => {
    if (!(await confirm(idxs.length + this.locale.fm('store.smsResult.confirm.delete')))) return;

    this.root.screen.setProcessLayer(true);
    this.normalizeSearch();
    try {
      const response = await axios.delete('/api/rq/sms', { data: idxs });
      if (response.data.success) {
        toast.success(this.locale.fm('store.smsResult.deleteSuccess'));
        this.loadDataGrid(this.dataGrid.page);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };

  @action
  normalizeSearch = () => {
    //param 검증
  };
}
