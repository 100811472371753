import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import DaumPostcode from 'react-daum-postcode';

@inject('cervitemAddr')
@observer
class DaumPostModal extends Component {
  render() {
    const { open, close, onCompletePost } = this.props.cervitemAddr;

    return (
      <Modal style={{ zIndex: 1000 }} className="DaumPostModal" isOpen={open} toggle={close}>
        <ModalHeader toggle={close}>주소 검색</ModalHeader>
        <ModalBody>
          <DaumPostcode onComplete={onCompletePost} />
        </ModalBody>
      </Modal>
    );
  }
}

export default DaumPostModal;
