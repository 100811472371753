import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import { observer, inject } from 'mobx-react';

import './ProgressLayer.scss';

@inject('screen')
@observer
class ProgressLayer extends Component {
  render() {
    const { progressLayer } = this.props.screen;

    return progressLayer !== null ? (
      <div className="ProgressLayer">
        <div className="p-wrapper">
          <div className="text-center mb-1">
            {progressLayer.title} {progressLayer.cur}/{progressLayer.tot}
          </div>
          <Progress animated color="warning" value={(progressLayer.cur / progressLayer.tot) * 100} />
        </div>
      </div>
    ) : (
      <></>
    );
  }
}

export default ProgressLayer;
