import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, ButtonGroup } from 'reactstrap';
import { DataGrid, CardMask, CustomPopover } from 'components/common';
import CervicoResultEditWindow from './CervicoResultEditWindow';
import CervicoResultDeleteWindow from './CervicoResultDeleteWindow';
import CervicoResultSmsWindow from './CervicoResultSmsWindow';
import { CerviBoxAddForResultWindow } from 'components/cervibox';

import { observer, inject } from 'mobx-react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { FaSearch, FaTimes, FaPrint, FaFileUpload, FaBan, FaUserAstronaut, FaBox } from 'react-icons/fa';
import { IoIosChatboxes } from 'react-icons/io';
import { MdCompare, MdDesktopMac, MdFlipToBack } from 'react-icons/md';

import moment from 'moment';
import { DateTimePicker } from 'components/common';
import SmsArea from './SmsArea';

import {
  receivedDateCellRenderer,
  statusCodeCellRenderer,
  testTypeCellRenderer,
  statusCodeCellClassRules,
  socialNumRenderer,
  smsReqTypeMap,
  rqDspResultCellRenderer
} from 'libs/Utils';

function completeFilter(r) {
  return r.statusCode > 6;
}

@inject('cervicoResult')
@inject('smsSend')
@inject('smsTemplate')
@inject('locale')
@observer
class CervicoResult extends Component {
  smsGridApi = null;
  resultGridApi = null;

  handleResultGridReady = (params) => {
    this.resultGridApi = params.api;
    this.resultGridApi.refreshCells({ force: true });
  };

  handleSmsGridReady = (params) => {
    this.smsGridApi = params.api;
  };

  handleResetGridScrollbar = () => {
    //스크롤바
    this.resultGridApi.ensureIndexVisible(0, 'top');
  };

  componentDidMount() {
    this.props.smsSend.setShow(false);
    this.props.smsTemplate.getList();
  }

  componentWillUnmount() {
    this.props.cervicoResult.init();
  }

  render() {
    return (
      <>
        <div className="CardResult container-fluid h-flex">
          <SearchFormCard onAfterSearch={this.handleResetGridScrollbar} />
          <ResultGridCard onGridReady={this.handleResultGridReady} onAfterMove={this.handleResetGridScrollbar} />
          <div className="mb-0" />
          {this.props.locale._locale === 'ko' ? <SmsArea onGridReady={this.handleSmsGridReady} /> : ''}
        </div>
        <CervicoResultEditWindow />
        <CervicoResultDeleteWindow />
        <CervicoResultSmsWindow />
      </>
    );
  }
}

@inject('locale')
@inject('auth')
@inject('cervicoResult')
@inject('cervicoResultWindow')
@inject('cervicoResultEditWindow')
@inject('cervicoResultCompareWindow')
@inject('cervicoResultDeleteWindow')
@inject('cervicoResultSmsWindow')
@inject('cerviBoxAddForResult')
@inject('cerviAi')
@inject('smsSend')
@observer
class ResultGridCard extends Component {
  gridApi = null;
  selected = [];

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
    this.props.cervicoResult.loadDataGrid();
  };

  handleGridSelectionChanged = (l) => {
    this.selected = l;
  };

  handleGridRowClick = (data) => {
    /*if (data.statusCode < 7) {
      toast.warn("판독중입니다.");
    } else {
    }*/
    this.props.cervicoResultWindow.setList([{ idx: data.idx, statusCode: data.statusCode }]);
  };

  handleMovePage = async (page) => {
    await this.props.cervicoResult.loadDataGrid(page);
    this.props.onAfterMove();
  };

  handleViewResultButtonClick = () => {
    //판독 완료 필터
    //const l = this.selected.filter(completeFilter);

    const l = this.selected;
    if (l.length === 0) {
      //toast.warn("판독 완료된 검사를 1개 이상 선택해주세요.");
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
    } else {
      this.props.cervicoResultWindow.setList(
        l.map((r) => {
          return { idx: r.idx, statusCode: r.statusCode };
        })
      );
    }
  };

  handleViewResultCompareButtonClick = () => {
    const l = this.selected.filter(completeFilter);
    if (l.length < 2) {
      toast.warn(this.props.locale.fm('comp.res.vali.compare.more2'));
    } else if (l.length > 5) {
      toast.warn(this.props.locale.fm('comp.res.vali.compare.less5'));
    } else {
      this.props.cervicoResultCompareWindow.setList(l.map((r) => r.idx));
    }
  };

  handlePrintClick = () => {
    const l = this.selected.filter(completeFilter);
    if (l.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.print.more1'));
    } else {
      this.props.cervicoResult.print(
        l.map((w) => w.idx),
        'def'
      );
    }
  };

  handleSmsClick = () => {
    //판독 완료 필터
    //const l = this.selected.filter(completeFilter).map(w => Object.assign({ phone: '' }, w));

    //모두 대상
    const l = this.selected.map((w) => Object.assign({ phone: '' }, w));

    if (l.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
    } else {
      this.props.smsSend.setShow(true);
      this.props.smsSend.addPhoneList(l);
    }
  };

  handleSmsResultClick = (idx, smsType) => {
    this.props.cervicoResultSmsWindow.setRequestInfo(idx, smsType);
  };

  handleRequestClick = async () => {
    const l = this.selected.filter((r) => r.statusCode === 0);
    if (l.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.req.more1'));
    } else {
      await this.props.cervicoResult.request(l.map((w) => w.idx));
    }
  };

  handleDeleteClick = async () => {
    const l = this.selected.filter((r) => r.statusCode <= 1);
    if (l.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.req.more1'));
    } else {
      await this.props.cervicoResultDeleteWindow.setDeleteCause(l.map((w) => w.idx));
    }
  };

  handleDupClick = async () => {
    await this.props.cervicoResult.loadDupDataGrid();
  };

  handleEditBtnClick = (idx) => {
    this.props.cervicoResultEditWindow.setPatinfo(this.gridApi.getRowNode(idx).data);
  };

  handleReportBtnClick = (idx) => {
    this.props.cervicoResult.print([idx], 'def');
  };

  handleCerviAiClick = () => {
    const list = this.selected.map((s) => s.idx);
    if (list.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
    } else {
      this.props.cerviAi.analy(list, 'res');
    }
  };

  handleCerviboxAddClick = () => {
    const list = this.selected.map((s) => {
      return {
        idx: s.idx
      };
    });
    if (list.length === 0) {
      toast.warn(this.props.locale.fm('comp.cervibox.vali.selBox'));
    } else if (list.length > 1) {
      toast.warn(this.props.locale.fm('comp.cervibox.vali.sel1box'));
    } else {
      this.props.cerviBoxAddForResult.show(list[0].idx);
    }
  };
  render() {
    const { locale, auth } = this.props;
    return (
      <Card className="basis-300">
        <CardHeader className="card-toolbar">
          <Button size="sm" onClick={this.handleViewResultButtonClick}>
            <MdDesktopMac /> <FormattedMessage id="button.res.show" />
          </Button>
          <Button size="sm" onClick={this.handleViewResultCompareButtonClick}>
            <MdCompare /> <FormattedMessage id="button.res.compare" />
          </Button>
          <Button size="sm" onClick={this.handlePrintClick}>
            <FaPrint /> <FormattedMessage id="button.res.print" />
          </Button>
          {this.props.locale._locale === 'ko' ? (
            <Button size="sm" onClick={this.handleSmsClick}>
              <IoIosChatboxes /> <FormattedMessage id="button.res.sms" />
            </Button>
          ) : (
            ''
          )}
          {auth.doctor.useRequest ? (
            <Button size="sm" onClick={this.handleRequestClick} color="primary">
              <FaFileUpload /> <FormattedMessage id="button.res.req" />
            </Button>
          ) : null}
          <Button size="sm" onClick={this.handleDeleteClick} color="danger">
            <FaBan /> <FormattedMessage id="button.res.del" />
          </Button>
          <Button size="sm" onClick={this.handleDupClick} color="warning" id="dupPopover">
            <MdFlipToBack /> <FormattedMessage id="button.res.dup" />
          </Button>
          {/* <Button size="sm" color="primary" onClick={this.handleCerviAiClick}>
            <FaUserAstronaut /> <FormattedMessage id="button.ai.analy" />
          </Button> */}
          {auth.doctor.useCerviboxYN ? (
            process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
              this.props.locale._locale === 'ko' ? (
                <Button size="sm" onClick={this.handleCerviboxAddClick} color="purple">
                  <FaBox /> <FormattedMessage id="button.cervibox" />
                </Button>
              ) : (
                ''
              )
            ) : (
              ''
            )
          ) : (
            ''
          )}
          <CustomPopover key="dup" placement="top" target="dupPopover" title={locale.fm('popover.common.title')} contents={locale.fm('popover.res.dup')} />
        </CardHeader>
        <CardBody className="np h-flex">
          <DataGrid
            columnDefs={[
              { width: 30, checkboxSelection: true, headerCheckboxSelection: true },
              { headerName: locale.fm('field.fcode'), field: 'idx', width: 80 },
              { headerName: locale.fm('field.receiveddate'), field: 'receivedDate', width: 130, cellRenderer: receivedDateCellRenderer },
              { headerName: locale.fm('field.docname'), field: 'docName', width: 130 },
              { headerName: locale.fm('field.patname'), field: 'patName', width: 130 },
              { headerName: locale.fm('field.birthday'), field: 'socialNum', width: 80, cellRenderer: socialNumRenderer },
              { headerName: locale.fm('field.age'), field: 'age', width: 70 },
              { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 130 },
              { headerName: locale.fm('field.testtype'), field: 'testType', width: 100, cellRenderer: testTypeCellRenderer },
              { headerName: locale.fm('field.teststatus'), field: 'statusCode', width: 80, cellRenderer: statusCodeCellRenderer, cellClassRules: statusCodeCellClassRules },
              { headerName: locale.fm('field.txtresult'), field: 'txtResult', width: 80 },
              { headerName: locale.fm('field.AIResult'), field: 'classResult', width: 80, cellRenderer: rqDspResultCellRenderer, hide: !auth.doctor.useAI },
              { headerName: locale.fm('button.report'), width: 90, cellRenderer: 'reportClickRenderer', cellClass: 'button' },
              { headerName: locale.fm('field.patinfo'), width: 90, cellRenderer: 'editClickRenderer', cellClass: 'button' },
              {
                headerName: locale.fm('field.rsltdate'),
                field: 'smsRsltDate',
                valueGetter: ({ data }) => {
                  return { data, code: smsReqTypeMap.써비코_검사결과 };
                },
                cellRenderer: 'smsClickRenderer',
                width: 140,
                cellClass: 'button'
              },
              {
                headerName: locale.fm('field.retestdate'),
                field: 'smsRetestDate',
                valueGetter: ({ data }) => {
                  return { data, code: smsReqTypeMap.써비코_재검진 };
                },
                cellRenderer: 'smsClickRenderer',
                width: 140,
                cellClass: 'button'
              }
            ]}
            rowSelection="multiple"
            //모두 선택 가능하게
            //isRowSelectable={({ data }) => data.statusCode > 6}

            onGridReady={this.handleGridReady}
            onRowDoubleClicked={this.handleGridRowClick}
            onSelectionChanged={this.handleGridSelectionChanged}
            onMovePage={this.handleMovePage}
            dataGrid={this.props.cervicoResult.dataGrid}
            redraw={this.props.cervicoResult.t}
            context={{
              componentParent: this
            }}
            frameworkComponents={{
              editClickRenderer: EditClickRenderer,
              reportClickRenderer: ReportClickRenderer,
              smsClickRenderer: SmsClickRenderer
            }}
          />
        </CardBody>
        <CardMask show={this.props.cervicoResult.mask} />
        <CerviBoxAddForResultWindow />
      </Card>
    );
  }
}

class EditClickRenderer extends Component {
  invokeParentMethod = (d) => {
    //this.props.data 는 render 시에 결정되어 수정된 값 반영 안됨.
    this.props.context.componentParent.handleEditBtnClick(this.props.data.idx);
  };

  render() {
    return (
      <button onClick={this.invokeParentMethod} className="btn btn-outline-primary">
        <FormattedMessage id="button.modify" />
      </button>
    );
  }
}

class ReportClickRenderer extends Component {
  invokeParentMethod = (d) => {
    //this.props.data 는 render 시에 결정되어 수정된 값 반영 안됨.
    this.props.context.componentParent.handleReportBtnClick(this.props.data.idx);
  };

  render() {
    return this.props.data.statusCode > 6 ? (
      <button onClick={this.invokeParentMethod} className="btn btn-outline-primary">
        <FormattedMessage id="button.report" />
      </button>
    ) : (
      ''
    );
  }
}

/*그리드 렌더러*/
const SmsClickRenderer = ({ value: { data, code }, context }) => {
  const invokeParentMethod = () => {
    context.componentParent.handleSmsResultClick(data.idx, code);
  };

  const isValueEmpty = code == null || data[code.field] == null;

  return !isValueEmpty ? (
    // dateCellRenderer로 하면 동일한 날짜가 출력됨, moment로 직접함
    <button onClick={invokeParentMethod} className={`btn ${isValueEmpty ? 'btn-outline-secondary' : 'btn-outline-primary'}`}>
      {moment(data[code.field]).format('YYYY-MM-DD HH:mm')}
    </button>
  ) : (
    <div />
  );
};

@inject('locale')
@inject('cervicoResult')
@observer
class SearchFormCard extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.cervicoResult.changeSearch(i.name, i.value);
  };

  handleResetClick = async (e) => {
    await this.props.cervicoResult.triggerReset();
    this.props.onAfterSearch();
  };

  handleSearchClick = async (e) => {
    await this.props.cervicoResult.triggerSearch();
    this.props.onAfterSearch();
  };

  handleKeyUpEnter = (e) => {
    if (e.key === 'Enter') this.handleSearchClick();
  };

  handleLast1wClick = () => {
    this.props.cervicoResult.changeSearch('startDate', moment().startOf('day').add(-1, 'week').toDate());
    this.props.cervicoResult.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1mClick = () => {
    this.props.cervicoResult.changeSearch('startDate', moment().startOf('day').add(-1, 'month').toDate());
    this.props.cervicoResult.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast3mClick = () => {
    this.props.cervicoResult.changeSearch('startDate', moment().startOf('day').add(-3, 'month').toDate());
    this.props.cervicoResult.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleRetest = async () => {
    this.props.cervicoResult.changeSearch('startDate', moment().startOf('day').add(-7, 'month').toDate());
    this.props.cervicoResult.changeSearch('endDate', moment().startOf('day').add(-6, 'month').toDate());
    this.handleSearchClick();
  };

  handleLast1yClick = () => {
    this.props.cervicoResult.changeSearch('startDate', moment().startOf('day').add(-1, 'year').toDate());
    this.props.cervicoResult.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  render() {
    const {
      locale,
      cervicoResult: { search: s }
    } = this.props;
    const hc = this.handleSearchChange;

    return (
      <Card>
        <CardHeader>
          <FormattedMessage id="comp.res.title.searchcondition" />
        </CardHeader>
        <CardBody>
          <Form className="search">
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.requestdate" />
                </Label>
                <DateTimePicker name="startDate" value={s.startDate} onChange={hc} />
                ~
                <DateTimePicker name="endDate" value={s.endDate} onChange={hc} />
              </FormGroup>
              <FormGroup>
                <ButtonGroup className="date-btns" size="sm">
                  <Button type="button" onClick={this.handleLast1wClick} outline>
                    <FormattedMessage id="button.last1weeks" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1mClick} outline>
                    <FormattedMessage id="button.last1months" />
                  </Button>
                  <Button type="button" onClick={this.handleLast3mClick} outline>
                    <FormattedMessage id="button.last3months" />
                  </Button>
                  <Button type="button" onClick={this.handleRetest} id="reTest" outline>
                    <FormattedMessage id="button.reTest" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1yClick} outline>
                    <FormattedMessage id="button.last1years" />
                  </Button>
                  <CustomPopover key="reTest" placement="top" target="reTest" title={locale.fm('popover.common.title')} contents={locale.fm('popover.reTest')} />
                </ButtonGroup>
              </FormGroup>
            </div>
            <div className="form-row fix-width">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.patname" />
                </Label>
                <Input className="ime" bsSize="sm" name="patName" value={s.patName} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.chartnum" />
                </Label>
                <Input bsSize="sm" name="chartNum" value={s.chartNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.birthday" />
                </Label>
                <Input bsSize="sm" name="socialNum" size="8" value={s.socialNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
            </div>
            <div className="form-row fix-width">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.teststatus" />
                </Label>
                <Input type="select" bsSize="sm" name="status" value={s.status} onChange={hc}>
                  <option value="">{locale.fm('value.all')}</option>
                  <option value="save">{locale.fm('value.status.0')}</option>
                  <option value="wait">{locale.fm('value.status.a')}</option>
                  <option value="ready">{locale.fm('value.status.b')}</option>
                  <option value="comp">{locale.fm('value.status.7')}</option>
                  <option value="view">{locale.fm('value.status.8')}</option>
                  <option value="print">{locale.fm('value.status.9')}</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.target" />
                </Label>
                <Input type="select" bsSize="sm" name="scope" value={s.scope} onChange={hc}>
                  <option value="my">{locale.fm('value.mypat')}</option>
                  <option value="">{locale.fm('value.all')}</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.txtresult" />
                </Label>
                <Input type="select" bsSize="sm" name="result" value={s.result} onChange={hc}>
                  <option value="">{locale.fm('value.all')}</option>
                  <option value="neg">{locale.fm('value.result.negative')}</option>
                  <option value="aty">{locale.fm('value.result.atypical')}</option>
                  <option value="pos">{locale.fm('value.result.positive')}</option>
                </Input>
              </FormGroup>
            </div>
            <div className="form-row flex-row-reverse">
              <FormGroup>
                <Button size="sm" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch /> <FormattedMessage id="button.search" />
                </Button>
                <span>&nbsp;</span>
                <Button size="sm" color="light" type="reset" style={{ width: '100px' }} onClick={this.handleResetClick}>
                  <FaTimes /> <FormattedMessage id="button.reset" />
                </Button>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default CervicoResult;
