import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

const defaultCsGuideData = {
  cs: 0,
  ai: '',
  aiWeight: 0,
  csaicnt: 0,
  n: 0,
  a: 0,
  p1: 0,
  p2: 0,
  npct: 0,
  apct: 0,
  p1pct: 0,
  p2pct: 0
};

const defaultSelectData = {
  ai: '',
  prob: 0
};

function buildGroupReducer(acc, row, i) {
  const p = Object.assign({ ...row }, { checked: false });
  acc.push(p);
  return acc;
}

const convertDataModel = (d) => {
  if (d.length === 0) return { ...defaultCsGuideData };
};

export default class CerviAiCsGuideStore {
  @observable mask = false;
  @observable csGuideData = { ...defaultCsGuideData };
  @observable viewCsGuide = false;
  @observable selectData = { ...defaultSelectData };

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  //초기화
  @action
  init = () => {
    this.setMask(false);
    this.csGuideData = { ...defaultCsGuideData };
    this.viewCsGuide = false;
    this.selectData = { ...defaultSelectData };
  };

  @action
  setMask = (m) => {
    this.mask = m;
  };

  @action
  setCsGuideData = (data, ai) => {
    this.csGuideData = data.filter((el) => el.ai === ai);
  };

  @action
  openScGuide = async (c, p) => {
    try {
      const response = await axios.get(`/api/rq/cerviai/getData`);
      if (response.data.success) {
        runInAction('CerviAiStore : async openScGuide -> success', () => {
          const data = response.data.data.map((row) => {
            return {
              cs: row.cs,
              ai: row.ai,
              cnt: row.csaicnt,
              Normal: row.n,
              Atypical: row.a,
              LowGrade: row.p1,
              HighGrade: row.p2,
              npct: row.npct,
              apct: row.apct,
              p1pct: row.p1pct,
              p2pct: row.p2pct
            };
          });
          this.setCsGuideData(data, c);
          this.viewCsGuide = true;
          this.selectData = { ai: c, prob: p };
        });
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
  };

  @action
  closeCsGuide = () => {
    this.viewCsGuide = false;
  };
}
