import { observable, action, computed, set, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import { confirm } from 'libs/Utils';
import { FormattedMessage } from 'react-intl';

export default class SmsTemplateStore {
  @observable templateList = [];
  @observable mask = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  setMask = (mask) => {
    this.mask = mask;
  };

  @action
  getTemplateList = () => {
    return this.templateList;
  };

  @action
  init = () => {
    this.templateList = [];
    this.setMask(false);
  };

  @action
  getList = async () => {
    this.setMask(true);
    try {
      const response = await axios.get('/api/rq/sms/template');
      runInAction('SmsTemplateStore : async getList -> success', () => {
        this.templateList = response.data;
        this.setMask(false);
      });
    } catch (error) {
      runInAction('SmsTemplateStore : async getList -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };
  @action
  saveList = async (gridData) => {
    this.setMask(true);
    try {
      await axios.post('/api/rq/sms/template/save', gridData);
      runInAction('SmsTemplateStore : async saveList -> success', () => {
        this.getList();
      });
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.setMask(false);
  };

  @action
  add = async (msgTxt) => {
    this.setMask(true);
    try {
      const response = await axios.put('/api/rq/sms/template', {
        msgTxt: msgTxt
      });
      if (response.data.success) {
        toast.success(this.locale.fm('store.smsTemplate.saveSuccess'));
        this.getList();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.setMask(false);
  };

  @action
  remove = async (idxs) => {
    if (!(await confirm(this.locale.fm('store.smsTemplate.confirm.delete')))) return;

    this.setMask(true);
    try {
      const response = await axios.delete('/api/rq/sms/template', { data: idxs });
      if (response.data.success) {
        toast.success(this.locale.fm('store.smsTemplate.deleteSuccess'));
        this.getList();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.setMask(false);
  };

  @action
  edit = async (row) => {
    if (!row.msgTxt) {
      toast.warn(this.locale.fm('store.smsTemplate.msgEmpty'));
      this.getList();
      return;
    }

    this.setMask(true);
    try {
      const response = await axios.post('/api/rq/sms/template', row);
      if (response.data.success) {
        toast.success(this.locale.fm('store.smsTemplate.editSuccess'));
        this.getList();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.setMask(false);
  };
}
