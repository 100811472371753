import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { Container, Card, CardHeader, CardBody, Button, Label, Table } from 'reactstrap';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { PictureBox, CardMask } from 'components/common';
import { toJS } from 'mobx';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { toast } from 'react-toastify';
import { CervitemNavigation, CervitemOrderConfirmModal } from './components';

import './CervitemCart.scss';

const MAX_QUANTITY = 999;
const MIN_QUANTITY = 1;
const MIN_WIDTH = 650;

@inject('locale')
@inject('checkWindowWidth')
@inject('productWindow')
@inject('cervitemProduct')
@inject('cervitem')
@inject('cervitemCart')
@inject('cervitemOrder')
@observer
class CervitemCart extends Component {
  handleCheckboxChange = (e, idx) => {
    e.stopPropagation();
    const { checked } = e.target;
    this.props.cervitemCart.selectedItem(idx, checked);
  };

  handleSelectAll = (e) => {
    e.stopPropagation();
    const { checked } = e.target;
    this.props.cervitemCart.selectedAllItems(checked);
  };
  handleDeleteCartItem = (e, idx) => {
    e.stopPropagation();
    this.props.cervitemCart.deleteCartItem(idx);
  };

  handle = (idx) => {
    this.props.cervitemProduct.setProductIdx(idx);
    this.props.productWindow.setOpen(true);
  };

  handleOpenModal = () => {
    this.openModal();
  };

  openModal = debounce(() => {
    const { selectList } = this.props.cervitemCart;

    if (selectList.length <= 0) {
      toast.warn('선택한 물품이 없습니다.');
      return;
    }
    this.props.cervitemOrder.setOpen();
  }, 500);

  handleResize = () => {
    this.props.checkWindowWidth.setWindowWidth(window.innerWidth);
  };
  componentDidMount = () => {
    this.props.cervitemOrder.history = this.props.history;
    this.props.checkWindowWidth.init(MIN_WIDTH, window.innerWidth);
    window.addEventListener('resize', this.handleResize);
  };
  componentWillUnmount = () => {
    this.props.cervitemCart.init();
    window.removeEventListener('resize', this.handleResize);
  };
  render() {
    const { cartList, selectAll, selectList, mask } = this.props.cervitemCart;
    const totalPrice = toJS(selectList)
      .reduce((acc, cur) => acc + cur.price * cur.quantity, 0)
      .toLocaleString();
    return (
      <Container fluid className="CervitemCart">
        <CervitemNavigation categoryIndex={null} fromCartOrder />
        <CervitemOrderConfirmModal fromComponent={'cart'} />
        <CardMask show={mask} />
        <Card className="align-items-center">
          <CardHeader style={{ width: '100%' }}>
            <div>장바구니</div>
          </CardHeader>
          <CardBody className="d-flex flex-column align-items-center justify-content-start Cart-Container">
            {cartList.length > 0 ? (
              <Fragment>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" onChange={this.handleSelectAll} checked={selectAll} />
                      </th>
                      <th>상품정보</th>
                      <th>상품금액</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {cartList.map((item, index) => (
                      <CartItem {...item} key={item.idx} hcc={this.handleCheckboxChange} hd={this.handleDeleteCartItem} h={this.handle} />
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end align-items-center p-2 cart-orderbtn-container">
                  <h2 className="mr-5 mb-0">총 주문 금액: {totalPrice + ' 원'}</h2>
                  <Button color="primary" size="lg" onClick={this.handleOpenModal}>
                    주문하기
                  </Button>
                </div>
              </Fragment>
            ) : (
              <div className="empty-list">장바구니가 비었습니다.</div>
            )}
          </CardBody>
        </Card>
      </Container>
    );
  }
}

@withRouter
@inject('cervitemCart')
@observer
class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: props.idx,
      quantity: props.quantity
    };
  }

  handleSetQuantity = (idx, quantity) => {
    if (quantity > MAX_QUANTITY) quantity = MAX_QUANTITY;
    else if (quantity < MIN_QUANTITY) quantity = MIN_QUANTITY;
    this.setState({ quantity: quantity });
  };

  handleQuantityChange = debounce((idx, quantity) => {
    this.props.cervitemCart.setQuantity(idx, quantity);
  }, 500);

  componentDidUpdate(_, prevState) {
    const { idx, quantity } = this.state;

    if (quantity !== prevState.quantity) {
      this.handleQuantityChange(idx, quantity);
    } else if (quantity !== this.props.quantity) {
      this.setState({ quantity: this.props.quantity });
    }
  }

  handleDecrement = (idx, quantity) => {
    if (quantity - 1 < MIN_QUANTITY) {
      quantity = MIN_QUANTITY;
      return;
    }
    this.setState({ quantity: quantity - 1 });
    // this.props.cervitemCart.quantityDecrement(idx);
  };

  handleIncrement = (idx, quantity) => {
    if (quantity + 1 > MAX_QUANTITY) {
      quantity = MAX_QUANTITY;
      return;
    }
    this.setState({ quantity: quantity + 1 });
    // this.props.cervitemCart.quantityIncrement(idx);
  };

  render() {
    const { selectYn, idx, categoryName, name, price, quantity, fileDir, productIdx: pdIdx, hcc, hd, h, optionName, option } = this.props;
    return (
      <tr>
        <td>
          <div>
            <input type="checkbox" checked={selectYn} onChange={(e) => hcc(e, idx)} />
          </div>
        </td>
        <td style={{ cursor: 'pointer' }} onClick={() => h(pdIdx)}>
          <div className="d-flex align-items-center cart-item">
            <div className="item-picture">
              <PictureBox small src={fileDir} />
            </div>
            <div className="item-info-box">
              <p>{`[${categoryName}]`}</p>
              <h3>{`${name} ${optionName && option ? ' - ' + option.name : ''}`}</h3>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center justify-content-between">
            <div className="item-quantity-container">
              <input type="text" value={this.state.quantity} onChange={(e) => this.handleSetQuantity(idx, Number(e.target.value))} />
              <div className="item-quantity-btn-container">
                <button className="item-quantity-plus" onClick={() => this.handleIncrement(idx, this.state.quantity)}>
                  <FaAngleUp />
                </button>
                <button className="item-quantity-minus" onClick={() => this.handleDecrement(idx, this.state.quantity)}>
                  <FaAngleDown />
                </button>
              </div>
            </div>
            <div className="item-price">
              <div>{(price * quantity).toLocaleString() + ' 원'}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="item-delete-btn">
            <Button onClick={(e) => hd(e, idx)}>삭제</Button>
          </div>
        </td>
      </tr>
    );
  }
}

export default CervitemCart;
