import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import './ConfirmModal.scss';
export default class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true
    };
  }

  toggle = (result = true) => {
    this.setState({
      modalOpen: !this.state.modalOpen
    });

    if (typeof this.props.onClose === 'function') {
      this.props.onClose(result);
    }
  };

  confirm = () => {
    this.toggle(true);
  };

  cancel = () => {
    this.toggle(false);
  };

  render() {
    const locale = window._locale;

    //confirm modal 은 최상위 z-index 1050 기본값..
    return (
      <Modal isOpen={this.state.modalOpen} className="ConfirmModal" fade={false}>
        <ModalHeader
          toggle={() => {
            this.toggle(false);
          }}
        >
          {locale.fm('comp.confirmModal.title')}
        </ModalHeader>
        <ModalBody>{this.props.message}</ModalBody>
        <ModalFooter>
          <Button color="secondary order-2 mr-0 btn-sm pl-3 pr-3" onClick={this.cancel}>
            {locale.fm('button.cancel')}
          </Button>{' '}
          <Button color="primary order-1 mr-1 btn-sm pl-3 pr-3" onClick={this.confirm}>
            {locale.fm('button.ok')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
