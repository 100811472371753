import { observable, action, computed, runInAction } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

export default class CervicoResultCompareWindowStore {
  @observable resultList = [];
  @observable result = [];

  constructor(root) {
    this.root = root;
    this.init();
  }

  //TODO init 로 변경
  @action
  init = () => {
    this.result.replace([]);
    this.resultList.replace([]);
  };

  @action
  setList = (resultList) => {
    this.result.replace(
      [...Array(resultList.length)].map(() => {
        return { show: false };
      })
    );
    this.resultList.replace(resultList);

    this.resultList.forEach((r, i) => this.setResult(i));
  };

  @action
  setResult = async (index) => {
    //this.root.screen.setProcessLayer(true);
    try {
      const res = await axios.get(`/api/rq/cervico/worklist/${this.resultList[index]}`);
      runInAction('CervicoResultCompareWindowStore : async setResult -> success', () => {
        this.result[index] = Object.assign(res.data, { show: true });
      });
    } catch (error) {
      ajaxErrorHandler(error);
    }
    //this.root.screen.setProcessLayer(false);
  };

  @computed
  get show() {
    return this.resultList.length > 0;
  }

  //zoom 화면
  @action
  zoom = (index) => {
    if (this.result.every((r) => r.show)) {
      const pictureList = [...Array(parseInt(this.result.length))].map((_, i) => {
        return {
          url: `/api/rq/cervico/worklist/${this.result[i].idx}/picture/${this.result[i].selectedImg}`,
          title: `환자명 : ${this.result[i].patName}`
        };
      });
      this.root.zoomWindow.setPictureList(pictureList, index);
    }
  };
}
