import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

import moment from 'moment';

const defaultSearch = {
  startDate: null,
  endDate: null,
  reportType: 'cervico'
};

const defaultDataGrid = {
  rows: [],
  page: 1,
  totalPage: 1,
  total: 0
};

export default class ReportStore {
  @observable search = { ...defaultSearch };
  @observable mask = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.initSearch();
    this.dataGrid = { ...defaultDataGrid };
    this.mask = false;
  };

  @action
  initSearch = () => {
    this.search = Object.assign(
      { ...defaultSearch },
      {
        startDate: moment().startOf('day').add(-1, 'month').toDate(),
        endDate: moment().startOf('day').toDate()
      }
    );
  };

  @action
  changeSearch = (key, value) => {
    this.search[key] = value;
  };

  @action
  loadDataGrid = async (page = 1) => {
    this.mask = true;
    try {
      const response = await axios.get('/api/rq/cervico/settlement');
      runInAction('ReportStore : async loadDataGridload -> success', () => {
        this.dataGrid = response.data;
        this.mask = false;
      });
    } catch (error) {
      runInAction('ReportStore : async loadDataGridload -> catch', () => {
        this.mask = false;
        ajaxErrorHandler(error);
      });
    }
  };

  @action
  print = (d, from = null) => {
    let url = '';

    if (!d) url = `/api/rq/report/${this.search.reportType}?search.startDate=${this.search.startDate.toJSON()}&search.endDate=${this.search.endDate.toJSON()}`;
    else {
      if (from === null) {
        url = `/api/rq/report/settlement?idx=${d}`;
      } else {
        url = `/api/rq/report/cervitem?idx=${d}`;
      }
    }

    this.root.pdfWindow.print(url);
  };
}
