import { observable, action, runInAction } from 'mobx';
import moment from 'moment';
import { searchSerialize, calcSocialNum, numberToString, nullableNumber, dateToString } from 'libs/Utils';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler, confirm } from 'libs/Utils';

const defaultPatinfo = {
  patName: '',
  chartNum: '',
  socialNum1: '',
  socialNum2: '',
  age: '',
  isPreg: 'false',
  g: null,
  p: null,
  a: null,
  lastPapDate: null,
  lastPapComment: '',
  lastPapComment_d: '',
  lastCervicoDate: null,
  lastCervicoComment: '',
  hpvDate: null,
  hpvComment: '',
  hpvComment_d: '',
  bxDate: null,
  bxComment: '',
  bxComment_d: '',
  therapyDate: null,
  therapyComment: '',
  comment: '',
  therapyList: [],
  refTestCase: null,

  // CerviBox 전용필드
  memo: '',

  // EMR 에서 검색한 환자일 경우
  idxHpAiReqInfo: null,
  startDate: null,
  endDate: null
};

const defaultRefPatinfo = {
  patName: '',
  receivedDate: null
};

const defaultDataGrid = {
  rows: [],
  page: 1,
  totalPage: 1,
  total: 0
};

export default class CervicoRquestStore {
  @observable patinfo = { ...defaultPatinfo };
  @observable dataGrid = { ...defaultDataGrid };
  @observable refPatinfo = { ...defaultRefPatinfo };
  @observable mask = false;
  @observable dupWorklist = null;
  @observable isTenMinOtherPic = false;
  @observable confirmDialogOpen = false;

  search = {}; //관리될 필요 없음.

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.cerviAi = root.cerviAi;
    this.init();
  }

  @action
  init = () => {
    this.initPatInfo();
    this.dataGrid = { ...defaultDataGrid };
    this.refPatinfo = { ...defaultRefPatinfo };
    this.setMask(false);
  };

  @action
  initPatInfo = () => {
    this.patinfo = Object.assign(
      { ...defaultPatinfo },
      { startDate: moment().startOf('day').toDate(), endDate: moment().startOf('day').add(+1, 'day').add(-1, 'minute').toDate() }
    );
  };

  @action
  changePatinfo = (key, value) => {
    this.patinfo[key] = value;
  };

  @action
  changeRefPatinfo = (key, value) => {
    this.refPatinfo[key] = value;
  };

  @action
  changePreg = (key, value) => {
    if (key === 'p') {
      //출산
      if (value !== null && this.patinfo.a !== null) this.patinfo.g = value + this.patinfo.a;
    } else if (key === 'a') {
      //유산
      if (value !== null && this.patinfo.p !== null) this.patinfo.g = value + this.patinfo.p;
    }
    this.patinfo[key] = value;
  };

  @action
  changeSocialNum = (index) => {
    const p = this.patinfo;
    const ret = calcSocialNum(index, p.socialNum1, p.socialNum2, p.age);
    p.socialNum1 = ret[0];
    p.socialNum2 = ret[1];
    p.age = ret[2];
  };

  //테라피 채크박스 선택
  @action
  changeTherapy = (checked, value) => {
    if (checked) {
      this.patinfo.therapyList.push(value);
    } else {
      this.patinfo.therapyList.remove(value);
    }
  };

  // cervibox용 box정보 => 환자정보 변환
  @action
  setInfo = (info, from = null) => {
    if (from === 'AI') {
      info.socialNum = info.birthDay;
      info.memo = info.comment;
    }
    this.changePatinfo('patName', info.patName);
    this.changePatinfo('chartNum', info.chartNum);
    this.changePatinfo('socialNum1', info.socialNum ? (info.socialNum.length >= 6 ? info.socialNum.substring(0, 6) : '') : '');
    this.changePatinfo('socialNum2', info.socialNum ? (info.socialNum.length > 6 ? info.socialNum.substring(6, 7) : '') : '');
    this.changePatinfo('age', info.age);
    this.changePatinfo('isPreg', info.isPreg ? 'true' : 'false');
    this.changePatinfo('p', info.p);
    this.changePatinfo('g', info.g);
    this.changePatinfo('a', info.a);
    this.changePatinfo('memo', info.memo);
  };

  @action
  setMask = (bool) => {
    this.mask = bool;
  };

  @action
  triggerSearch = async () => {
    const p = this.patinfo;

    //주민번호 padEnd
    const socialNum = (p.socialNum1.trim() + p.socialNum2.trim()).length > 0 ? p.socialNum1.trim().padEnd(6, '0') + p.socialNum2.trim().padEnd(7, '0') : '';

    if (p.patName.trim() === '' && p.chartNum.trim() === '' && socialNum.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.more1'));
      return;
    }

    this.search = {
      patName: p.patName,
      chartNum: p.chartNum,
      socialNum: socialNum,
      status: 'history'
    };
    await this.loadDataGrid();
  };

  @action
  loadDataGrid = async (page = 1) => {
    this.setMask(true);
    //this.normalizeSearch()
    try {
      const response = await axios.get('/api/rq/cervico/worklist', {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('CervicoRequestStore : async loadDataGrid -> success', () => {
        this.dataGrid = response.data;
        this.setMask(false);
      });
    } catch (error) {
      runInAction('CervicoRequestStore : async loadDataGrid -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  triggerSearchByEMR = async (didMount = false) => {
    const p = this.patinfo;

    //주민번호 padEnd
    const socialNum = (p.socialNum1.trim() + p.socialNum2.trim()).length > 0 ? p.socialNum1.trim().padEnd(6, '0') + p.socialNum2.trim().padEnd(7, '0') : '';

    if (!didMount) {
      if (p.patName.trim() === '' && p.chartNum.trim() === '' && socialNum.trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.more1'));
        return;
      }
    }

    this.search = {
      startDate: p.startDate,
      endDate: p.endDate,
      patName: p.patName,
      chartNum: p.chartNum,
      socialNum: socialNum,
      status: 'EMR'
    };

    await this.loadDataGridByEMR();
  };

  @action
  loadDataGridByEMR = async (page = 1) => {
    this.setMask(true);
    //this.normalizeSearch()
    try {
      const response = await axios.get('/api/rq/cervico/worklist/emr', {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('CervicoRequestStore : async loadDataGridByEMR -> success', () => {
        this.dataGrid = response.data;
        this.setMask(false);
      });
    } catch (error) {
      runInAction('CervicoRequestStore : async loadDataGridByEMR -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  request = async (req, isCerviBox = false, isCerviAi = false, checkReqValid = false, reqConfirmYN = false, isDetail = false) => {
    const p = this.patinfo;
    const refP = this.refPatinfo;

    let pList = [];
    if (isCerviBox) pList = this.root.cerviBox.selectedRqUploadIdxList();
    else if (isCerviAi && !isDetail) pList = this.root.cerviAi.selectedRqUploadIdxList();
    else pList = this.root.pictureList.selectedIdxList();

    const socialNum = this.checkPatInfoThenGetSocialNum(pList, p);
    if (socialNum === false) return;

    //보류일 경우 확인
    if (req !== true) {
      if (!(await confirm(this.locale.fm('store.cervicoRequest.confirm.save')))) return;
    }

    let idxPatientAiInfo;
    try {
      idxPatientAiInfo = this.root.cerviAi.preview.idx;
    } catch {
      idxPatientAiInfo = null;
    }

    //worklist 생성
    const worklist = {
      idxHpAiReqInfo: p.idxHpAiReqInfo,
      idxPatientAiInfo: idxPatientAiInfo,
      patName: p.patName.trim(),
      chartNum: p.chartNum.trim(),
      socialNum: socialNum,
      age: numberToString(p.age),
      isPreg: p.isPreg === '' ? null : p.isPreg === 'true',
      g: nullableNumber(p.g),
      p: nullableNumber(p.p),
      a: nullableNumber(p.a),
      lastCervicoDate: dateToString(p.lastCervicoDate),
      lastCervicoComment: p.lastCervicoComment.trim(),
      lastPapDate: dateToString(p.lastPapDate),
      lastPapComment: `${p.lastPapComment.trim()} ${p.lastPapComment_d.trim()}`.trim(),
      hpvDate: dateToString(p.hpvDate),
      hpvComment: `${p.hpvComment.trim()} ${p.hpvComment_d.trim()}`.trim(),
      bxDate: dateToString(p.bxDate),
      bxComment: `${p.bxComment.trim()} ${p.bxComment_d.trim()}`.trim(),
      therapyDate: dateToString(p.therapyDate),
      therapy: p.therapyList.join(','),
      comment: p.comment.trim(),
      pictureIdxs: pList,
      refTestCase: p.refTestCase
    };

    const run = async () => {
      //this.setMask(true);
      try {
        const response = await axios.put('/api/rq/cervico/worklist', worklist, {
          params: {
            req,
            isCerviBox,
            isCerviAi,
            checkReqValid,
            reqConfirmYN,
            isDetail
          }
        });
        if (response.data.success) {
          this.init();
          toast.success(this.locale.fm('store.cervicoRequest.success'));
          if (isCerviBox) {
            this.root.cerviBox.reqDialog.setOpen(false);
            this.root.cerviBox.triggerRefresh(this.root.cerviBox.preview.box.idx);
          } else if (isCerviAi) {
            this.initConfirmModal();
            this.root.cerviAi.reqDialog.close();
            this.root.cerviAi.triggerRefresh(this.root.cerviAi.preview.idx);
          } else {
            this.initConfirmModal();
            this.root.pictureList.getList();
            this.triggerSearchByEMR(true);
          }
        } else {
          //this.setMask(false);
          if (response.data.message === 'NO-PIC') {
            this.root.pictureList.getList();
            toast.error(this.locale.fm('store.cervicoRequest.fail.aleadyRequested'));
          } else if (response.data.message === 'DUP-INFO' || response.data.message === 'CONFIRM-OPEN') {
            if (response.data.data) {
              const { isChecked10MinInterval, checkedDupList } = response.data.data;
              if (isChecked10MinInterval) this.setIsTenMinOtherPic(isChecked10MinInterval);
              if (checkedDupList) this.setDupWorklist(checkedDupList);
            }
            this.setConfrimDialogOpen(true);
            return;
          } else {
            toast.error(response.data.message);
          }
        }
      } catch (error) {
        ajaxErrorHandler(error);
        //this.setMask(false);
      }
    };

    this.root.screen.setProcessLayer(true);

    if (worklist.refTestCase !== null) {
      if (await confirm(moment(refP.receivedDate).format('YYYY-MM-DD') + ' ' + refP.patName + '\n' + this.locale.fm('store.cervicoRequest.confirm.refReq'))) {
        // detailRun(isDetail);
        await run();
      }
    } else {
      // detailRun(isDetail);
      await run();
    }

    this.root.screen.setProcessLayer(false);
  };

  @action
  requestCerviAi = async (checkReqValid = false, reqConfirmYN = false) => {
    const p = this.patinfo;

    let pList = [];
    pList = this.root.pictureList.selectedIdxList();

    const socialNum = this.checkPatInfoThenGetSocialNum(pList, p);
    if (socialNum === false) return;

    //patient_ai_info 생성
    const patInfo = {
      idxHpAiReqInfo: p.idxHpAiReqInfo,
      patName: p.patName.trim(),
      chartNum: p.chartNum.trim(),
      socialNum: socialNum,
      age: numberToString(p.age),
      isPreg: p.isPreg === '' ? null : p.isPreg === 'true',
      g: nullableNumber(p.g),
      p: nullableNumber(p.p),
      a: nullableNumber(p.a),
      comment: p.comment.trim(),
      pictureIdxs: pList
    };

    this.setMask(true);
    try {
      const response = await axios.put('/api/rq/cerviai/add', patInfo, {
        params: {
          checkReqValid,
          reqConfirmYN
        }
      });
      if (response.data.success) {
        this.init();
        toast.success(this.locale.fm('store.cervicoRequest.aisuccess'));
        this.initConfirmModal();
        this.root.pictureList.getList();
        this.triggerSearchByEMR(true);
        // this.setMask(false);
      } else {
        this.setMask(false);
        if (response.data.message === 'NO-PIC') {
          this.root.pictureList.getList();
          toast.error(this.locale.fm('store.cervicoRequest.fail.aleadyRequested'));
        } else if (response.data.message === 'DUP-INFO' || response.data.message === 'CONFIRM-OPEN') {
          if (response.data.data) {
            const { isChecked10MinInterval, checkedDupList } = response.data.data;
            if (isChecked10MinInterval) this.setIsTenMinOtherPic(isChecked10MinInterval);
            if (checkedDupList) this.setDupWorklist(checkedDupList);
          }
          this.setConfrimDialogOpen(true);
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      ajaxErrorHandler(error);
      // this.setMask(false);
    }
  };

  checkPatInfoThenGetSocialNum = (pList, p) => {
    //picture 검증
    if (pList.length === 0 || pList.length > 4) {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.picture'));
      return false;
    }

    //patinfo 검증
    if (p.patName.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.patname'));
      return false;
    }
    if (p.chartNum.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.chartnum'));
      return false;
    }
    if ((p.socialNum1 + p.socialNum2).trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate'));
      return false;
    }
    //생년월일 검사
    if (!moment(p.socialNum1.trim(), 'YYMMDD').isValid()) {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate2'));
      return false;
    }

    const socialNum = (p.socialNum1 + p.socialNum2).trim().length > 0 ? p.socialNum1.trim().padEnd(6, '0') + p.socialNum2.trim().padEnd(7, '0') : '';
    return socialNum;
  };

  @action
  setConfrimDialogOpen = (bool) => {
    this.confirmDialogOpen = bool;
  };

  @action
  initConfirmModal = () => {
    this.setMask(false);
    this.dupWorklist = null;
    this.confirmDialogOpen = false;
    this.isTenMinOtherPic = false;
  };

  @action
  setDupWorklist = (wl) => {
    this.dupWorklist = wl;
  };

  @action
  setIsTenMinOtherPic = (el) => {
    this.isTenMinOtherPic = el;
  };
}
