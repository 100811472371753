import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import './CerviAiDeleteWindow.scss';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { FaTimes, FaTrash } from 'react-icons/fa';

@inject('locale')
@inject('cerviAi')
@inject('cerviAiDeleteWindow')
@observer
class CerviAiDeleteWindow extends Component {
  handleDelete = () => {
    this.props.cerviAiDeleteWindow.delete();
  };

  handleClose = () => {
    this.props.cerviAiDeleteWindow.close();
  };

  render() {
    const { locale } = this.props;
    return (
      <Modal isOpen={this.props.cerviAiDeleteWindow.open} toggle={this.handleClose} zIndex="940">
        <ModalHeader toggle={this.handleClose}>{locale.fm('comp.cerviai.title.remove')}</ModalHeader>
        <ModalBody>
          {locale.fm('field.AI.remove')}
          <FormattedMessage id="store.res.confirm.remove" />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.handleDelete} size="sm">
            <FaTrash /> {locale.fm('button.remove')}
          </Button>{' '}
          <Button color="secondary" onClick={this.handleClose} size="sm">
            <FaTimes /> {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CerviAiDeleteWindow;
