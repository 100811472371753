import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';

import './CervicoResultEditWindow.scss';
import { observer, inject } from 'mobx-react';

import { FaCheck, FaTimes } from 'react-icons/fa';

@inject('locale')
@inject('cervicoResultEditWindow')
@observer
class CervicoResultEditWindow extends Component {
  handleSave = () => {
    this.props.cervicoResultEditWindow.save();
  };

  handleClose = () => {
    this.props.cervicoResultEditWindow.init();
  };

  handleFocus = (e) => {
    e.target.select();
  };

  handleNumberChange = (e) => {
    const i = e.target;
    this.handleChange({
      target: {
        name: i.name,
        value: `${i.value}`.replace(/[^0-9]+/g, '')
      }
    });
  };

  handleChange = (e) => {
    const i = e.target;
    this.props.cervicoResultEditWindow.changePatinfo(i.name, i.value);
  };

  render() {
    const { locale, cervicoResultEditWindow } = this.props;
    const { patinfo: p } = cervicoResultEditWindow;
    const hc = this.handleChange;
    const hnc = this.handleNumberChange;

    return (
      <Modal isOpen={this.props.cervicoResultEditWindow.open} toggle={this.handleClose} zIndex="940">
        <ModalHeader toggle={this.handleClose}>{locale.fm('comp.res.title.modifyPatInfo')}</ModalHeader>
        <ModalBody>
          <Form className="search">
            <div className="form-row">
              <FormGroup>
                <Label>
                  {locale.fm('field.patname')} <span className="req">*</span>
                </Label>
                <Input type="text" bsSize="sm" name="patName" value={p.patName} onChange={hc} size="15" />
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  {locale.fm('field.chartnum')} <span className="req">*</span>
                </Label>
                <Input type="text" bsSize="sm" name="chartNum" value={p.chartNum} onChange={hc} size="15" />
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  {locale.fm('field.birthday')} <span className="req">*</span>
                </Label>
                <Input
                  bsSize="sm"
                  name="socialNum1"
                  value={p.socialNum1}
                  onChange={hnc}
                  size="6"
                  maxLength="6"
                  onFocus={this.handleFocus}
                  onBlur={() => cervicoResultEditWindow.changeSocialNum(1)}
                />
                -
                <Input
                  bsSize="sm"
                  name="socialNum2"
                  value={p.socialNum2}
                  onChange={hnc}
                  size="7"
                  maxLength="1"
                  onFocus={this.handleFocus}
                  onBlur={() => cervicoResultEditWindow.changeSocialNum(2)}
                  style={{ width: '20px' }}
                />
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>{locale.fm('field.age')}</Label>
                <Input bsSize="sm" name="age" value={p.age} onChange={hnc} size="3" onFocus={this.handleFocus} />
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="w-100">
                <Label>{locale.fm('field.comment')}</Label>
                <Input type="textarea" name="comment" value={p.comment} onChange={hc} readOnly={p.statusCode >= 7} />
              </FormGroup>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSave} size="sm">
            <FaCheck /> {locale.fm('button.save')}
          </Button>{' '}
          <Button color="secondary" onClick={this.handleClose} size="sm">
            <FaTimes /> {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CervicoResultEditWindow;
