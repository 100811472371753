import React, { Component } from 'react';
import cx from 'classnames';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './DataGrid.scss';

import { FormattedMessage } from 'react-intl';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

import { localeTextFunc } from 'libs/Utils';

class DataGrid extends Component {
  static defaultProps = {
    //모양
    border: false,
    style: {},

    showFooter: true,
    showSelectCount: true,
    showPagination: true,
    suppressNoRowsOverlay: true,

    //grid 전달
    columnDefs: {},
    defaultColDef: {
      resizable: true,
      suppressMovable: true
    },
    rowSelection: 'multiple',
    isRowSelectable: () => true,
    getRowNodeId: (data) => data.idx,
    dataGrid: {},
    frameworkComponents: {},
    context: { componentParent: this },
    enableBrowserTooltips: false,

    //이벤트
    onGridReady: () => {},
    onRowDoubleClicked: () => {},
    onSelectionChanged: () => {},
    onMovePage: () => {},
    onCellClicked: () => {}
  };

  state = {
    selectedCount: 0
  };

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
  };

  handleRowDoubleClicked = (e) => {
    this.props.onRowDoubleClicked(e.data);
  };

  handleSelectionChanged = (e) => {
    this.setState({
      selectedCount: this.gridApi.getSelectedRows().length
    });
    this.props.onSelectionChanged(this.gridApi.getSelectedRows());
  };

  handleMovePage = (page) => {
    this.props.onMovePage(page);
  };

  handleCellClicked = (e) => {
    this.props.onCellClicked(e);
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    const {
      border,
      style,
      suppressRowClickSelection = true,
      suppressNoRowsOverlay,
      columnDefs,
      defaultColDef,
      rowSelection,
      getRowNodeId,
      isRowSelectable,
      showSelectCount,
      showPagination,
      dataGrid,
      frameworkComponents,
      context,
      showFooter
    } = this.props;
    return (
      <div className={cx('DataGrid', { 'border-grid': border })} style={style}>
        <div className="ag-theme-balham">
          <AgGridReact
            reactNext={true}
            localeTextFunc={localeTextFunc}
            suppressRowClickSelection={suppressRowClickSelection}
            deltaRowDataMode={true}
            //props
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection={rowSelection}
            suppressNoRowsOverlay={suppressNoRowsOverlay}
            getRowNodeId={getRowNodeId}
            isRowSelectable={isRowSelectable}
            rowData={dataGrid.rows}
            frameworkComponents={frameworkComponents}
            context={context}
            enableBrowserTooltips={true}
            //자체
            onRowDoubleClicked={this.handleRowDoubleClicked}
            onSelectionChanged={this.handleSelectionChanged}
            onGridReady={this.handleGridReady}
            onCellClicked={this.handleCellClicked}
          />
        </div>
        {showFooter ? (
          <div className="area-paging">
            <div>
              <span>
                <FormattedMessage id="field.total" /> : {dataGrid.total.toLocaleString()}
              </span>
              {showSelectCount ? (
                <span>
                  {' '}
                  / <FormattedMessage id="field.selected" /> :{' '}
                  {this.state.selectedCount === 0 ? 0 : <span className="badge badge-primary">{this.state.selectedCount.toLocaleString()}</span>}
                </span>
              ) : (
                ''
              )}
            </div>
            {showPagination ? (
              <div className="ml-auto">
                <button type="button" onClick={() => this.handleMovePage(1)} disabled={dataGrid.page === 1}>
                  <FaAngleDoubleLeft />
                </button>
                <button type="button" onClick={() => this.handleMovePage(dataGrid.page - 1)} disabled={dataGrid.page === 1}>
                  <FaAngleLeft />
                </button>
                <span>
                  <FormattedMessage id="grid.page" /> {dataGrid.page} / {dataGrid.totalPage}
                </span>
                <button type="button" onClick={() => this.handleMovePage(dataGrid.page + 1)} disabled={dataGrid.totalPage === dataGrid.page}>
                  <FaAngleRight />
                </button>
                <button type="button" onClick={() => this.handleMovePage(dataGrid.totalPage)} disabled={dataGrid.totalPage === dataGrid.page}>
                  <FaAngleDoubleRight />
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default DataGrid;
