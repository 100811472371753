import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import pdfobject from 'pdfobject';

import { toggleFullscreen } from 'libs/Screen';

import { FaTimesCircle, FaArrowLeft } from 'react-icons/fa';

import './PdfWindow.scss';

@inject('locale')
@inject('screen')
@inject('pdfWindow')
@withRouter
@observer
class PdfWindow extends Component {
  el = React.createRef();

  handleFullscreen = () => {
    toggleFullscreen(this.el.current);
  };

  componentDidMount() {
    //TODO keyboard 이벤트 bind
  }

  componentWillUnmount() {
    //TODO keyboard 이벤트 unbind
  }

  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.pdfWindow.init();
    }
  }

  render() {
    const { pdfWindow } = this.props;

    if (pdfWindow.show) {
      return (
        <div className="PdfWindow layer-window" ref={this.el}>
          <div className="area-back-button">
            <Button size="sm" onClick={pdfWindow.init}>
              <FaArrowLeft />
            </Button>
          </div>
          <div className="area-button">
            <Button size="sm" onClick={pdfWindow.init}>
              <FaTimesCircle />
            </Button>
          </div>
          <div className="area-pdf" id="area-pdf">
            <PdfEmbed src={pdfWindow.pdf}></PdfEmbed>
          </div>
        </div>
      );
    } else {
      return '';
    }
  }
}

@inject('pdfWindow')
class PdfEmbed extends Component {
  componentDidMount() {
    pdfobject.embed(this.props.src, '#area-pdf', {
      pdfOpenParams: {
        navpanes: 1,
        toolbar: 1,
        statusbar: 1,
        view: 'FitV',
        pagemode: 'thumbs',
        page: 1
      },
      PDFJS_URL: '/pdfjs/web/viewer.html'
      //,forcePDFJS: (window.rq!==undefined)
    });
  }

  render() {
    return null;
  }
}

export default PdfWindow;
