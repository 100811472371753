import React, { Component } from 'react';
import { IoIosShareAlt } from 'react-icons/io';
import { withRouter } from 'react-router-dom';

import './CervitemMoveIcon.scss';

@withRouter
class CervitemMoveIcon extends Component {
  handleMoveCart = () => {
    this.props.history.push('/cart');
  };
  handleMoveOrder = () => {
    this.props.history.push('/order');
  };

  render() {
    return (
      <div className="CervitemMoveIcon">
        <Btn btnName="장바구니" handleClick={this.handleMoveCart} />
        <Btn btnName="구매내역" handleClick={this.handleMoveOrder} />
      </div>
    );
  }
}

class Btn extends Component {
  render() {
    const { btnName, handleClick } = this.props;
    return (
      <div className={`${btnName === '장바구니' ? 'cart-btn' : 'order-btn'}`}>
        <div className="Btn" onClick={handleClick}>
          <div className="btn">{btnName}</div>
          <div className="icon-btn">
            <IoIosShareAlt className="icon" />
          </div>
        </div>
      </div>
    );
  }
}

export default CervitemMoveIcon;
