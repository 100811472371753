import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { PictureBox, DaumPostModal } from 'components/common';
import { Modal, ModalHeader, ModalBody, Table, ModalFooter, Button, Form, FormGroup, Label, CustomInput, Input, FormText } from 'reactstrap';

import './CervitemOrderConfirmModal.scss';

@withRouter
@inject('cervitemCart')
@inject('cervitemProduct')
@inject('cervitemOrder')
@inject('cervitemAddr')
@observer
class CervitemOrderConfirmModal extends Component {
  handleClose = () => {
    this.props.cervitemAddr.init();
    this.props.cervitemOrder.close();
  };
  handleAddToOrder = (fromComponent, item) => {
    const addressInfo = item.addressInfo;
    if (item.shippingType && (addressInfo.address === '' || addressInfo.addressDetail === '' || addressInfo.contact === '')) {
      alert('배송지 정보를 입력해주세요.');
      return;
    }

    this.handleClose();
    this.props.cervitemOrder.addToOrder(fromComponent, item);
  };

  handleShippingType = () => {
    this.props.cervitemAddr.changeShippingType();
  };

  handleShippingAddrType = (e) => {
    const i = e.target;
    this.props.cervitemAddr.changeShippingAddrType(i.value);
  };

  handleDefaultAddr = () => {
    this.props.cervitemAddr.changeDefaultAddr();
  };

  handleChange = (e) => {
    const i = e.target;
    this.props.cervitemAddr.changeAddr(i.name, i.value);
  };

  handleChangeRequirement = (e) => {
    const i = e.target;
    this.props.cervitemOrder.changeRequirement(i.name, i.value);
  };

  render() {
    const { open, requirement } = this.props.cervitemOrder;
    const { selectList } = this.props.cervitemCart;
    const { product, selectedOptions } = this.props.cervitemProduct;
    const { setOpen, shippingType, shippingAddrType, defaultAddr, addressInfo: a } = this.props.cervitemAddr;
    const { fromComponent } = this.props;
    const hc = this.handleChange;

    const totalPrice =
      fromComponent === 'product'
        ? Object.keys(selectedOptions).reduce((acc, cur) => {
            return acc + selectedOptions[cur] * product.price;
          }, 0)
        : selectList.reduce((acc, cur) => {
            return acc + cur.price * cur.quantity;
          }, 0);
    const quantity = Object.values(selectedOptions).reduce((acc, cur) => acc + cur, 0);
    const priceInfo = {};
    selectList.forEach((item) => {
      priceInfo[item.idx] = item.price;
    });

    const item = {
      itemIdx: product.idx,
      price: fromComponent === 'product' ? product.price : priceInfo,
      quantity,
      itemOptionMap: selectedOptions,
      shippingType,
      defaultAddr,
      addressInfo: a,
      requirement: ''
    };

    return (
      <>
        <Modal className="CervitemOrderConfirmModal" isOpen={open} toggle={this.handleClose} size="lg" zIndex="940">
          <ModalHeader toggle={this.handleClose}></ModalHeader>
          <ModalBody>
            <Form className="search">
              <div className="form-row mb-2" hidden={true}>
                <FormGroup>
                  <Label>배송 방식</Label>
                  <CustomInput id="shipping-false" type="radio" name="shippingType" value={false} onChange={this.handleShippingType} checked={!shippingType} label="직납" />
                  <CustomInput id="shipping-true" type="radio" name="shippingType" value={true} onChange={this.handleShippingType} checked={shippingType} label="택배" />
                </FormGroup>
              </div>
              {shippingType && (
                <>
                  <div className="form-row">
                    <FormGroup>
                      <Label>배송지</Label>
                      <CustomInput
                        id="shippingAddrType-1"
                        type="radio"
                        name="shippingAddrType"
                        value="default"
                        onChange={this.handleShippingAddrType}
                        label="기본"
                        checked={shippingAddrType === 'default'}
                      />
                      <CustomInput
                        id="shippingAddrType-2"
                        type="radio"
                        name="shippingAddrType"
                        value="hospital"
                        onChange={this.handleShippingAddrType}
                        label="병원"
                        checked={shippingAddrType === 'hospital'}
                      />
                      <CustomInput
                        id="shippingAddrType-3"
                        type="radio"
                        name="shippingAddrType"
                        value="new"
                        onChange={this.handleShippingAddrType}
                        label="신규"
                        checked={shippingAddrType === 'new'}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup>
                      <Label>연락처</Label>
                      <Input className="contact" type="text" bsSize="sm" name="contact" value={a.contact} onChange={hc} />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup>
                      <Label>주소</Label>
                      <div className="d-flex align-items-center address-container">
                        <Input
                          className="address"
                          type="text"
                          bsSize="sm"
                          name="address"
                          value={a.address !== '' ? a.address + ' (' + a.zonecode + ')' : ''}
                          onClick={setOpen}
                          readOnly
                        />
                        <Button size="sm" color="primary" onClick={setOpen}>
                          주소검색
                        </Button>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="form-row mb-2">
                    <FormGroup>
                      <Label>상세주소</Label>
                      <Input className="address-detail" type="text" bsSize="sm" name="addressDetail" value={a.addressDetail} onChange={hc} />
                      <CustomInput type="checkbox" id="default-addr" label="기본 배송지" onChange={this.handleDefaultAddr} />
                    </FormGroup>
                  </div>
                </>
              )}
            </Form>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>제품명</th>
                  <th>제조사/수입사</th>
                  <th>옵션</th>
                  <th>수량</th>
                  <th>가격</th>
                </tr>
              </thead>
              <tbody>
                {fromComponent === 'cart'
                  ? selectList.map((item, index) => {
                      return <SelectItem {...item} key={item.idx} />;
                    })
                  : fromComponent === 'product'
                  ? Object.keys(selectedOptions).map((key, index) => {
                      return <SelectItem {...product} key={key} optionIdx={key} quantity={selectedOptions[key]} />;
                    })
                  : null}
              </tbody>
            </Table>
            <FormText style={{ display: 'none' }}>
              <Input type="textarea" name="text" id="exampleText" placeholder="요청사항을 입력해주세요." value={requirement} onChange={this.handleChangeRequirement} />
            </FormText>
            <h2 className="mr-5 mb-0" style={{ textAlign: 'end' }}>
              총 주문 금액: {totalPrice ? totalPrice.toLocaleString() + ' 원' : '0 원'}
            </h2>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" size="sm" style={{ width: '140px' }} onClick={() => this.handleAddToOrder(fromComponent, item)}>
              주문하기
            </Button>
            <Button color="secondary" onClick={this.handleClose} size="sm" style={{ width: '100px' }}>
              닫기
            </Button>
          </ModalFooter>
        </Modal>
        <DaumPostModal />
      </>
    );
  }
}

@inject('cervitemProduct')
@observer
class SelectItem extends Component {
  render() {
    const { fileDir, manufacturer, name, price, quantity, optionIdx, thumbImgList, option } = this.props;
    const { product } = this.props.cervitemProduct;
    const imgSrc = fileDir ? fileDir : thumbImgList ? thumbImgList[0].fileDir : '';
    const optionName = optionIdx > 0 ? product.options.find((item) => item.idx === parseInt(optionIdx)).name : '';

    return (
      <tr>
        <td>
          <div style={{ width: '100px', height: '100px' }}>
            <PictureBox src={imgSrc} />
          </div>
        </td>
        <td>
          <h3>{name}</h3>
        </td>
        <td>
          <div>{manufacturer}</div>
        </td>
        {optionIdx > 0 ? (
          <td>
            <div>{optionName}</div>
          </td>
        ) : option ? (
          <td>
            <div>{option.name}</div>
          </td>
        ) : (
          <td>
            <div>default</div>
          </td>
        )}
        <td>
          <div>{quantity}</div>
        </td>
        <td>
          <div>{(price * quantity).toLocaleString()}</div>
        </td>
      </tr>
    );
  }
}

export default CervitemOrderConfirmModal;
