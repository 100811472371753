import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import './DoctorEditWindow.scss';

class MedicalDeviceWindow extends Component {
  render() {
    const { isOpen, setIsOpen } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={setIsOpen} zIndex="940">
        <ModalHeader toggle={setIsOpen}>의료기기 표시 기재 사항</ModalHeader>
        <ModalBody style={{ fontSize: 13 }}>
          <p>
            (1) 제조업자의 상호 및 주소
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;1) 상호 : ㈜엔티엘헬스케어
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2) 주소 : 경기도 성남시 수정구 창업로 42 경기기업성장센터 712호
            <br />
            <br />
            (2) 제조번호 : 소프트웨어 버전 : 1.10
            <br />
            <br />
            (3) 제품명, 형명(모델명), 품목인증번호, 제조업 허가번호
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;1) 제품명 : 산부인과학 진료용 소프트웨어
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2) 형명(모델명) : CerviCare AI
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3) 품목허가번호 : 제허 22-886호
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;4) 제조업허가번호 : 제1861호
            <br />
            <br />
            (4) 사용방법 및 사용 시 주의사항 : 사용자 매뉴얼 참조
            <br />
            <br />
            (5) “의료기기”라는 표시 : 웹 내에 표시
            <br />
            <br />
            (6) 사용목적 : 체외형 자궁경부확대 촬영 영상 내에서 자궁경부를 자동으로 표시하고 자궁경부암의 정상/이형성증을 구분하여 의료인의 자궁경부암 진단결정을 보조하는
            소프트웨어
            <br />
            <br />
            (7) 성능 : 사용자 매뉴얼 참조
            <br />
            <br />
            (8) 의료기기 표준코드 : 로그인 화면에 표시
            <br />
            <br />※ 본 제품은 의료기기임.
          </p>
        </ModalBody>
      </Modal>
    );
  }
}

export default MedicalDeviceWindow;
