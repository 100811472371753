import React, { Component } from 'react';
import { Row } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import { withRouter } from 'react-router-dom';
import { CervitemMoveIcon, CheckWindowWidth } from './components';
import { inject } from 'mobx-react';
import './CervitemMain.scss';

const MIN_WIDTH = 1080;

@inject('checkWindowWidth')
@withRouter
class CervitemMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // wideScreen: window.innerWidth > 767,
      activeIndex: 0
    };
  }

  setActiveIndex = (index) => {
    this.setState({
      activeIndex: index
    });
  };

  handleAccordionClick = (index) => {
    this.props.history.push({
      pathname: '/cervitem',
      state: { categoryIndex: index }
    });
  };

  handleResize = () => {
    this.props.checkWindowWidth.setWindowWidth(window.innerWidth);
  };
  componentDidMount() {
    this.props.checkWindowWidth.init(MIN_WIDTH, window.innerWidth);
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { activeIndex } = this.state;
    const { isNarrow } = this.props.checkWindowWidth;

    return (
      <Container className="container-fluid m-0">
        <CheckWindowWidth isNarrow={isNarrow} />
        <CervitemMoveIcon />
        <Row style={{ width: '100vw' }}>
          <ul className="accordion-group" id="accordion">
            <li className={`first ${activeIndex === 1 ? 'out' : ''}`} onClick={() => this.handleAccordionClick(1)} onMouseEnter={() => this.setActiveIndex(1)}>
              <div className="accordion-overlay first" />
              <h2>
                <p>치료재료</p>
              </h2>
            </li>
            <li className={`second ${activeIndex === 2 ? 'out' : ''}`} onClick={() => this.handleAccordionClick(2)} onMouseEnter={() => this.setActiveIndex(2)}>
              <div className="accordion-overlay second" />
              <h2>
                <p>의료기기</p>
              </h2>
            </li>
            <li className={`third ${activeIndex === 3 ? 'out' : ''}`} onClick={() => this.handleAccordionClick(3)} onMouseEnter={() => this.setActiveIndex(3)}>
              <div className="accordion-overlay third" />
              <h2>화장품</h2>
            </li>
            <li className={`fourth ${activeIndex === 0 ? 'out' : ''}`} onClick={() => this.handleAccordionClick(0)} onMouseEnter={() => this.setActiveIndex(0)}>
              <div className="accordion-overlay fourth" />
              <h2>ALL</h2>
            </li>
          </ul>
        </Row>
      </Container>
    );
  }
}

export default CervitemMain;
