import React, { Component } from 'react';
import { DataGrid, CardMask } from 'components/common';
import { Card, CardBody } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { socialNumRenderer, dateCellRenderer } from 'libs/Utils';
import './CerviBox.scss';

@inject('locale')
@inject('cerviBoxForSearch')
@observer
class CerviBoxResultGridCardForSearch extends Component {
  gridApi = null;

  handleGridReady = (params) => {
    this.props.cerviBoxForSearch.init();
    this.gridApi = params.api;
    this.props.onGridReady(params);
  };

  handleMovePage = async (page) => {
    await this.props.cerviBoxForSearch.loadDataGrid(page);
    this.props.onAfterMove();
  };

  handleCellClicked = ({ data }) => {
    this.props.cerviBoxForSearch.setPreviewBox(data.idx);
  };

  render() {
    const { locale } = this.props;

    return (
      <div className="ResultGridCard flex-grow-1 h-flex">
        <Card className="basis-300">
          <CardBody className="np h-flex">
            <DataGrid
              columnDefs={[
                { width: 30, checkboxSelection: true, headerCheckboxSelection: true },
                { headerName: locale.fm('field.regdate'), field: 'regDate', width: 130, cellRenderer: dateCellRenderer },
                { headerName: locale.fm('field.patname'), field: 'patName', width: 130 },
                { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 130 },
                { headerName: locale.fm('field.birthday'), field: 'socialNum', width: 80, cellRenderer: socialNumRenderer },
                { headerName: locale.fm('field.age'), field: 'age', width: 70 },
                { headerName: locale.fm('field.cnt'), field: 'cnt', width: 70 },
                {
                  headerName: locale.fm('field.docname'),
                  field: 'docName',
                  width: 70,
                  cellRenderer: (p) => {
                    return `${p.data.docName}(${p.data.docId})`;
                  }
                }
              ]}
              rowSelection={'multiple'}
              suppressRowClickSelection={false}
              onGridReady={this.handleGridReady}
              onRowDoubleClicked={this.handleGridRowClick}
              onSelectionChanged={this.handleGridSelectionChanged}
              onCellClicked={this.handleCellClicked}
              onMovePage={this.handleMovePage}
              dataGrid={this.props.cerviBoxForSearch.dataGrid}
              redraw={this.props.cerviBoxForSearch.t}
              context={{
                componentParent: this
              }}
            />
          </CardBody>
          <CardMask show={this.props.cerviBoxForSearch.mask} />
        </Card>
      </div>
    );
  }
}

export default CerviBoxResultGridCardForSearch;
