import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, ButtonGroup } from 'reactstrap';
import { DataGrid, CardMask } from 'components/common';

import { observer, inject } from 'mobx-react';
import { FaSearch, FaTimes, FaTrash } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { CustomPopover, DateTimePicker } from 'components/common';
import { toast } from 'react-toastify';

import moment from 'moment';
import { dateCellRenderer, socialNumRenderer } from 'libs/Utils';

@inject('smsResult')
@observer
class SmsResult extends Component {
  gridApi = null;

  handleGridReady = (params) => {
    this.gridApi = params.api;
  };

  handleResetGridScrollbar = () => {
    //스크롤바
    this.gridApi.ensureIndexVisible(0, 'top');
  };

  componentDidMount() {}

  componentWillUnmount() {
    this.props.smsResult.init();
  }

  render() {
    return (
      <div className="container-fluid h-flex">
        <SearchFormCard onAfterSearch={this.handleResetGridScrollbar} />
        <SmsResultGridCard onGridReady={this.handleGridReady} onAfterMove={this.handleResetGridScrollbar} />
        <div className="mb-0" />
      </div>
    );
  }
}

@inject('locale')
@inject('smsResult')
@observer
class SmsResultGridCard extends Component {
  gridApi = null;
  selected = [];

  handleGridSelectionChanged = (l) => {
    this.selected = l;
  };

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
    this.props.smsResult.loadDataGrid();
  };

  handleMovePage = async (page) => {
    await this.props.smsResult.loadDataGrid(page);
    this.props.onAfterMove();
  };

  handleRemoveClick = () => {
    const l = this.selected.filter((r) => r.curState < 3);
    if (l.length === 0) {
      toast.warn(this.props.locale.fm('comp.smsResult.vali.selectMore1'));
      return;
    }
    this.props.smsResult.remove(this.selected.map((w) => w.idx));
  };

  render() {
    const { locale } = this.props;

    return (
      <Card className="basis-300">
        <CardHeader className="card-toolbar">
          <Button size="sm" onClick={this.handleRemoveClick}>
            <FaTrash /> <FormattedMessage id="button.remove" />
          </Button>
        </CardHeader>
        <CardBody className="np h-flex">
          <DataGrid
            columnDefs={[
              { width: 30, checkboxSelection: true, headerCheckboxSelection: true },
              { headerName: locale.fm('field.patname'), field: 'patName', width: 130 },
              { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 130 },
              { headerName: locale.fm('field.birthday'), field: 'socialNum', width: 130, cellRenderer: socialNumRenderer },
              { headerName: locale.fm('field.sms.receiverList.mobile'), field: 'callTo', width: 100 },
              { headerName: locale.fm('field.sms.receiverList.smsType'), field: 'strReqType', width: 120 },
              { headerName: locale.fm('field.sms.list.sendDate'), field: 'sendDate', width: 130, cellRenderer: dateCellRenderer },
              {
                headerName: locale.fm('field.sms.status'),
                field: 'curState',
                width: 80,
                cellRenderer: ({ value: curState, data }) => {
                  if (curState === 3) {
                    if (data.rsltCode === 100) return locale.fm('value.success');
                    else return `${locale.fm('value.failure')}:${data.rsltDesc}`;
                  } else {
                    if (data.reportDate === null) return locale.fm('value.sms.status.sending');
                    else return locale.fm('value.sms.status.scheduling');
                  }
                },
                cellClass: ({ value: curState, data }) => {
                  if (curState === 3) {
                    if (data.rsltCode === 100) return 'text-success';
                    else return 'text-danger';
                  } else {
                    if (data.rsvDate === null) return '';
                    else return 'text-primary';
                  }
                },
                tooltipField: 'rsltDesc'
              },
              { headerName: locale.fm('field.sms.contents'), field: 'msgText', width: 600 }
            ]}
            rowSelection="multiple"
            isRowSelectable={({ data }) => data.curState === null || data.curState < 3}
            onGridReady={this.handleGridReady}
            onSelectionChanged={this.handleGridSelectionChanged}
            onMovePage={this.handleMovePage}
            dataGrid={this.props.smsResult.dataGrid}
            enableBrowserTooltips={true}
          />
        </CardBody>
        <CardMask show={this.props.smsResult.mask} />
      </Card>
    );
  }
}

@inject('locale')
@inject('smsResult')
@observer
class SearchFormCard extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.smsResult.changeSearch(i.name, i.value);
  };

  handleResetClick = async (e) => {
    await this.props.smsResult.triggerReset();
    this.props.onAfterSearch();
  };

  handleSearchClick = async (e) => {
    await this.props.smsResult.triggerSearch();
    this.props.onAfterSearch();
  };

  handleKeyUpEnter = (e) => {
    if (e.key === 'Enter') this.handleSearchClick();
  };

  handleTodayClick = () => {
    this.props.smsResult.changeSearch('startDate', moment().startOf('day').toDate());
    this.props.smsResult.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1wClick = () => {
    this.props.smsResult.changeSearch('startDate', moment().startOf('day').add(-1, 'week').toDate());
    this.props.smsResult.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1mClick = () => {
    this.props.smsResult.changeSearch('startDate', moment().startOf('day').add(-1, 'month').toDate());
    this.props.smsResult.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  render() {
    const {
      locale,
      smsResult: { search: s }
    } = this.props;
    const hc = this.handleSearchChange;

    return (
      <Card>
        <CardHeader>
          {' '}
          <FormattedMessage id="comp.sms.title.searchcondition" />{' '}
        </CardHeader>
        <CardBody>
          <Form className="search">
            <div className="form-row">
              <FormGroup>
                <Label>
                  {' '}
                  <FormattedMessage id="field.sms.list.sendDate" />{' '}
                </Label>
                <DateTimePicker name="startDate" value={s.startDate} onChange={hc} />
                ~
                <DateTimePicker name="endDate" value={s.endDate} onChange={hc} />
              </FormGroup>
              <FormGroup>
                <ButtonGroup className="date-btns" size="sm">
                  <Button type="button" onClick={this.handleTodayClick} outline>
                    <FormattedMessage id="button.today" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1wClick} outline>
                    <FormattedMessage id="button.last1weeks" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1mClick} outline>
                    <FormattedMessage id="button.last1months" />
                  </Button>
                </ButtonGroup>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.patname" />
                </Label>
                <Input className="ime" bsSize="sm" name="patName" value={s.patName} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.chartnum" />
                </Label>
                <Input bsSize="sm" name="chartNum" value={s.chartNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.birthday" />
                </Label>
                <Input bsSize="sm" name="socialNum" size="8" value={s.socialNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.sms.status" />
                </Label>
                <Input type="select" bsSize="sm" name="curStateStr" value={s.curStateStr} onChange={hc}>
                  <option value="">{locale.fm('value.all')}</option>
                  <option value="r">{locale.fm('value.sms.status.scheduling')}</option>
                  <option value="s">{locale.fm('value.sms.status.success')}</option>
                  <option value="f">{locale.fm('value.sms.status.failed')}</option>
                </Input>
              </FormGroup>
            </div>
            <div className="form-row flex-row-reverse">
              <FormGroup>
                <Button size="sm" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch /> <FormattedMessage id="button.search" />
                </Button>
                <span>&nbsp;</span>
                <Button size="sm" color="light" type="reset" style={{ width: '100px' }} onClick={this.handleResetClick}>
                  <FaTimes /> <FormattedMessage id="button.reset" />
                </Button>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default SmsResult;
