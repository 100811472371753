import { observable, action, computed, set, runInAction } from 'mobx';
import { calcSocialNum } from 'libs/Utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

const defaultPatinfo = {
  idx: null,
  patName: '',
  chartNum: '',
  birthDay: '',
  age: '',
  comment: ''
};

function numberToString(data) {
  if (data === null) return null;
  else return `${data}`;
}

export default class CerviAiResultEditWindowStore {
  @observable patinfo = { ...defaultPatinfo };
  @observable open = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.patinfo = { ...defaultPatinfo };
    this.open = false;
  };

  @action
  setPatinfo = (p) => {
    this.patinfo = {
      idx: p.idx,
      patName: p.patName,
      chartNum: p.chartNum,
      birthDay: p.birthDay,
      age: p.age,
      comment: p.comment
    };
    this.open = true;
  };

  @action
  changePatinfo = (key, value) => {
    this.patinfo[key] = value;
  };

  @action
  changeSocialNum = (index) => {
    const p = this.patinfo;
    const ret = calcSocialNum(index, p.birthDay, '', p.age);
    p.birthDay = ret[0];
    p.age = ret[2];
  };

  @action
  save = async () => {
    const p = this.patinfo;

    //patinfo 검증
    if (p.patName.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.patname'));
      return;
    }
    if (p.chartNum.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.chartnum'));
      return;
    }
    if (p.birthDay.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate'));
      return;
    }
    //생년월일 검사
    if (!moment(p.birthDay.trim(), 'YYMMDD').isValid()) {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate2'));
      return;
    }

    //worklist 생성
    const patInfo = {
      patName: p.patName.trim(),
      chartNum: p.chartNum.trim(),
      birthDay: p.birthDay.trim(),
      age: numberToString(p.age),
      comment: p.comment
    };

    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.post(`/api/rq/cerviai/patinfo/${p.idx}`, patInfo);
      if (response.data.success) {
        this.init();
        this.root.cerviAi.triggerRefresh(this.root.cerviAi.preview.idx);
        toast.success(this.locale.fm('store.res.modify.PatInfo'));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };
}
