import { observable, action, runInAction, toJS } from 'mobx';
import moment from 'moment';
import axios from 'axios';
import { ajaxErrorHandler, searchSerialize, confirm } from 'libs/Utils';
import { toast } from 'react-toastify';
import cookies from 'browser-cookies';

const WORKLIST_PRINTED = 9;

const defaultSearch = {
  patName: '',
  chartNum: '',
  socialNum: '',
  startDate: null,
  endDate: null,
  status: '',
  scope: 'my',
  result: ''
};

const defaultDataGrid = {
  rows: [],
  page: 1,
  totalPage: 1,
  total: 0
};

export default class CervicoResultStore {
  @observable search = { ...defaultSearch };
  @observable dataGrid = { ...defaultDataGrid };
  @observable mask = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.initSearch();
    this.dataGrid = { ...defaultDataGrid };
    this.mask = false;
  };

  @action
  initSearch = () => {
    const scope = localStorage.getItem('resultSearchScope');
    const cookie = JSON.parse(cookies.get('resultSearch'));

    this.search = Object.assign(
      { ...defaultSearch },
      {
        startDate: cookie !== null ? moment(cookie.startDate).toDate() : moment().startOf('day').add(-1, 'month').toDate(),
        endDate: cookie !== null ? moment(cookie.endDate).toDate() : moment().startOf('day').toDate(),
        scope: scope !== null && scope === 'all' ? scope : 'my'
      }
    );
  };

  @action
  triggerSearch = async () => {
    await this.loadDataGrid();
  };

  @action
  triggerReset = async () => {
    this.initSearch();
    await this.loadDataGrid();
  };

  @action
  triggerRefresh = async () => {
    await this.loadDataGrid(this.dataGrid.page);
  };

  @action
  changeSearch = (key, value) => {
    this.search[key] = value;
  };

  @action
  loadDataGrid = async (page = 1) => {
    this.mask = true;
    this.normalizeSearch();
    localStorage.setItem('resultSearchScope', this.search.scope === 'my' ? 'my' : 'all');
    cookies.set('resultSearch', JSON.stringify({ startDate: this.search.startDate, endDate: this.search.endDate }), { expires: 0 });
    try {
      const response = await axios.get('/api/rq/cervico/worklist', {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('CervicoResultStore : async loadDataGridload -> success', () => {
        this.dataGrid = response.data;
        this.mask = false;
      });
    } catch (error) {
      runInAction('CervicoResultStore : async loadDataGridload -> catch', () => {
        this.mask = false;
        ajaxErrorHandler(error);
      });
    }
  };

  @action
  loadDupDataGrid = async (page = 1) => {
    this.mask = true;
    try {
      const response = await axios.get('/api/rq/cervico/worklist/dup', {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('CervicoResultStore : async loadDupDataGridload -> success', () => {
        this.dataGrid = response.data;
        this.mask = false;
      });
    } catch (error) {
      runInAction('CervicoResultStore : async loadDupDataGridload -> catch', () => {
        this.mask = false;
        ajaxErrorHandler(error);
      });
    }
  };

  @action
  changeStatusCodeByIdx = (idx, statusCode) => {
    //clone (dataGrid 를 통째로 변경해야 observer 반응하더라..)
    var c = toJS(this.dataGrid);
    var r = c.rows.find((row) => row.idx === idx);
    if (r && r.statusCode !== statusCode) {
      r.statusCode = statusCode;
      this.dataGrid = c;
    }
  };

  @action
  request = async (idxs) => {
    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.post('/api/rq/cervico/worklist/req', idxs);
      if (response.data.success) {
        this.triggerRefresh();
        toast.success(this.locale.fm('store.cervicoRequest.success'));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };

  @action
  normalizeSearch = () => {
    //param 검증
    const s = this.search;
    if (!moment(s.startDate).isValid()) s.startDate = null;
    if (!moment(s.endDate).isValid()) s.endDate = null;
    if (s.startDate !== null && s.endDate === null) s.endDate = s.startDate;
    if (s.startDate === null && s.endDate !== null) s.startDate = s.endDate;
    if (s.startDate > s.endDate) s.endDate = s.startDate;
  };

  print = (idxList, type) => {
    //출력코드 변경
    idxList.forEach((idx) => {
      this.changeStatusCodeByIdx(idx, WORKLIST_PRINTED);
    });

    //리포트 20장 까지
    const idxParam = idxList
      .slice(0, 20)
      .map((i) => `idx=${i}`)
      .join('&');
    const url = `/api/rq/cervico/report/pdf?type=${type}&${idxParam}`;

    this.root.pdfWindow.print(url);
  };
}
