import { observable, action, computed } from 'mobx';

export default class ZoomWindowStore {
  @observable pictureList = [];
  @observable current = 0;

  // **** 추가됨
  constructor(root) {
    this.root = root;
    this.init();
  }

  @action
  init = () => {
    this.pictureList = [];
    this.current = 0;
  };

  @action
  setPictureList = (a = [], current = 0) => {
    this.pictureList = a;
    this.current = current;
  };

  @action
  setCurrent = (current) => {
    this.current = current;
  };

  @computed
  get show() {
    return this.pictureList.length > 0;
  }

  @computed
  get isLast() {
    return this.pictureList.length === this.current + 1;
  }

  @computed
  get isFirst() {
    return this.current === 0;
  }

  @computed
  get position() {
    return `${this.current + 1} / ${this.pictureList.length}`;
  }

  @action
  next = () => {
    this.current++;
  };

  @action
  prev = () => {
    this.current--;
  };
}
