import { observable, action, computed, set, runInAction } from 'mobx';
import { calcSocialNum, smsReqTypeMap } from 'libs/Utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import { MdSignalCellularNull } from 'react-icons/md';

export default class OtherTestResultSmsWindow {
  @observable open = false;
  @observable row = null;
  @observable smsType = null;
  @observable resultList = [];

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.open = false;
    this.row = null;
    this.smsType = null;
    this.resultList = [];
  };

  @action
  setRequestInfo = (row, smsType) => {
    this.row = row;
    this.smsType = smsType;
    this.loadData();
    this.open = true;
  };

  @action
  loadData = async () => {
    if (this.row && this.smsType) {
      let url =
        this.smsType.code === smsReqTypeMap.써비코_검사결과.code || this.smsType.code === smsReqTypeMap.써비코_재검진.code
          ? `/api/rq/sms/${this.smsType.code}/${this.row.idx}`
          : `/api/rq/other-test/${this.smsType.code}/${this.row.reportId}`;

      try {
        const response = await axios.get(url);
        runInAction('OtherTestResultSmsWindow : async loadData -> success', () => {
          this.resultList = response.data.data;
        });
      } catch (error) {
        ajaxErrorHandler(error);
      }
    }
  };
}
