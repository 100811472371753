import { observable, action, computed, set, runInAction } from 'mobx';
import { calcSocialNum } from 'libs/Utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

const defaultPatinfo = {
  idx: null,
  patName: '',
  chartNum: '',
  socialNum1: '',
  socialNum2: '',
  age: '',
  comment: ''
};

function numberToString(data) {
  if (data === null) return null;
  else return `${data}`;
}

export default class CervicoResultEditWindowStore {
  @observable patinfo = { ...defaultPatinfo };
  @observable open = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.patinfo = { ...defaultPatinfo };
    this.open = false;
  };

  @action
  setPatinfo = (p, from = null) => {
    this.patinfo = {
      idx: p.idx,
      patName: p.patName,
      chartNum: p.chartNum,
      socialNum1: from === 'ai' ? p.birthDay : p.socialNum.substring(0, 6),
      socialNum2: from === 'ai' ? null : p.socialNum.substring(6),
      age: p.age,
      statusCode: p.statusCode,
      comment: p.comment
    };
    this.open = true;
  };

  @action
  changePatinfo = (key, value) => {
    this.patinfo[key] = value;
  };

  @action
  changeSocialNum = (index) => {
    const p = this.patinfo;
    const ret = calcSocialNum(index, p.socialNum1, p.socialNum2, p.age);
    p.socialNum1 = ret[0];
    p.socialNum2 = ret[1];
    p.age = ret[2];
  };

  @action
  save = async () => {
    const p = this.patinfo;

    //patinfo 검증
    if (p.patName.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.patname'));
      return;
    }
    if (p.chartNum.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.chartnum'));
      return;
    }
    if ((p.socialNum1 + p.socialNum2).trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate'));
      return;
    }
    //생년월일 검사
    if (!moment(p.socialNum1.trim(), 'YYMMDD').isValid()) {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate2'));
      return;
    }

    //주민번호 padEnd
    const socialNum = (p.socialNum1 + p.socialNum2).trim().length > 0 ? p.socialNum1.trim().padEnd(6, '0') + p.socialNum2.trim().padEnd(7, '0') : '';

    //worklist 생성
    const worklist = {
      patName: p.patName.trim(),
      chartNum: p.chartNum.trim(),
      socialNum: socialNum,
      age: numberToString(p.age),
      comment: p.comment
    };

    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.post(`/api/rq/cervico/worklist/${p.idx}`, worklist);
      if (response.data.success) {
        this.init();
        this.root.cervicoResult.triggerRefresh();
        toast.success(this.locale.fm('store.res.modify.PatInfo'));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };
}
