import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody } from 'reactstrap';
import { PictureBox } from 'components/common';
import { FormattedMessage } from 'react-intl';
import { FaFileUpload } from 'react-icons/fa';

import moment from 'moment';
import './RequestConfirm.scss';

@inject('locale')
@inject('cervicoRequest')
@inject('cerviAi')
@inject('pictureList')
@observer
class RequestConfirm extends Component {
  handleClose = () => {
    this.props.cervicoRequest.initConfirmModal();
  };

  handleRequest = (e) => {
    e.preventDefault();
    if (this.props.reqAIAnaly) {
      this.props.cervicoRequest.requestCerviAi();
    } else if (this.props.reqDetail) {
      this.props.cervicoRequest.request(true, false, false, false, false, true);
    } else if (this.props.reqFromAi) {
      this.props.cervicoRequest.request(true, false, true);
    } else this.props.cervicoRequest.request(true, false, false, false);
  };

  render() {
    const { locale, cervicoRequest, cerviAi, pictureList, reqAIAnaly, reqFromAi } = this.props;
    const p = cervicoRequest.patinfo;
    const isTenMinOtherPic = cervicoRequest.isTenMinOtherPic;
    let checkedPicList = [];
    if (reqFromAi) {
      checkedPicList = cerviAi.preview.aiResultList.filter((e) => e.checked);
    } else {
      checkedPicList = pictureList.group.reduce((a, c) => [...a, ...c.list], []).filter((e) => e.checked);
    }
    return (
      <Modal isOpen={cervicoRequest.confirmDialogOpen} toggle={this.handleClose} className="RequestConfirm" zIndex="940">
        <ModalHeader toggle={this.handleClose}>
          <FaFileUpload /> {locale.fm('store.cervicoRequest.confirm.title')}
        </ModalHeader>
        <ModalBody>
          <div className="patinfo">
            <ul>
              <li>
                {locale.fm('field.patname')} : {p.patName}{' '}
              </li>
              <li>
                {locale.fm('field.chartnum')} : {p.chartNum}{' '}
              </li>
              <li>
                {locale.fm('field.birthday')} : {p.socialNum1}
              </li>
            </ul>
          </div>
          <Card style={{ marginBottom: '10px' }}>
            <CardBody className="thumb-list">
              <ul>
                {checkedPicList.map((el) => {
                  const idx = reqFromAi ? el.rqUploadIdx : el.idx;
                  const takedate = reqFromAi ? cerviAi.preview.regDate : el.takedate;
                  return (
                    <li key={idx}>
                      <div className="picture">
                        <PictureBox small src={`/api/rq/cervico/picture/${idx}?thumb=true`} />
                        <div>{moment(takedate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
          {cervicoRequest.dupWorklist ? (
            <Alert color="warning">
              <p>{locale.fm('field.equalresultmsg')}</p>
              <p>
                {locale.fm('field.lastrequestdate')}: {moment(cervicoRequest.dupWorklist.receivedDate || cervicoRequest.dupWorklist.regDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD')}
              </p>
              {reqAIAnaly ? null : (
                <p>
                  {locale.fm('field.fcode')}: {cervicoRequest.dupWorklist.idx}
                </p>
              )}
            </Alert>
          ) : null}
          {isTenMinOtherPic ? (
            <Alert color="warning">
              <FormattedMessage id="store.cervicoRequest.confirm.otherPat" />
            </Alert>
          ) : null}
          {reqAIAnaly ? (
            <Alert color="primary">
              <FormattedMessage id="field.requestConfirm.AI" />
            </Alert>
          ) : (
            <Alert color="primary">
              <FormattedMessage id="field.requestConfirm" />
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleRequest} size="sm" style={{ width: '100px' }}>
            {locale.fm('button.ok')}
          </Button>
          <Button color="secondary" onClick={this.handleClose} size="sm" style={{ width: '100px' }}>
            {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default RequestConfirm;
