import { observable, action, computed } from 'mobx';

export default class ProductWindowStore {
  @observable isOpen = false;

  @action
  init = () => {
    this.setOpen(false);
  };

  @action
  setOpen = (bool) => {
    this.isOpen = bool;
  };

  @action
  close = () => {
    this.isOpen = false;
  };

  @computed
  get show() {
    return this.isOpen;
  }
}
