import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';

import './PatForm.scss';
import { DateTimePicker, DataGrid } from 'components/common';
import NumericInput from 'react-numeric-input';
import { FormattedMessage } from 'react-intl';
import { FaSearch, FaUndoAlt } from 'react-icons/fa';
import cx from 'classnames';
import moment from 'moment';
import debounce from 'lodash.debounce';

import { dateCellRenderer, statusCodeCellRenderer, statusCodeCellClassRules, socialNumRenderer } from 'libs/Utils';
import CervicoPatinfoGridCardForSearch from './CervicoPatinfoGridCardForSearch';

const papOption = ['Negative', 'Inflammation', 'ASC-US', 'AGUS', 'ASC-H', 'LSIL', 'HSIL', 'SCC', 'Adenocarcinoma', 'Other'];
const cervicoOption = ['N1', 'N2', 'A1', 'A2', 'P0', 'P1A', 'P1B', 'P2', 'P3'];
const hpvOption = ['Negative', 'High risk', 'Low risk', 'High/Low risk'];
const bxOption = ['Negative', 'Polyp', 'Cervicitis', 'Metaplasia', 'Koilocytotic atypia', 'Condyloma', 'LSIL', 'HSIL(CIN1, CIN2, CIN3)', 'SCC', 'Adenocarcinoma', 'Other'];
const therapy = [
  { id: 'HRT', desc: 'HRT' },
  { id: 'AUB', desc: 'AUB' },
  { id: 'Premenopause', desc: 'Premenopause' },
  { id: 'Menopause', desc: 'Menopause' },
  { id: 'Hysterectomy', desc: 'Hysterectomy' },
  { id: 'CCRT', desc: 'CCRT' },
  { id: 'Colpo-Bx', desc: 'Colpo Bx.' },
  { id: 'Cone-Bx', desc: 'Cone Bx.' }
];
const regexp = /[ㄱ-ㅎ]|[ㅏ-ㅣ]/gi;

@inject('locale')
@inject('cervicoRequest')
@inject('cerviAi')
@inject('cervicoResultWindow')
@observer
class PatForm extends Component {
  gridApi = null;
  emrLinkSearchApi = null;
  constructor(props) {
    super(props);

    this.state = {
      typoErr: false
    };
  }

  handleGridReady = (params) => {
    this.gridApi = params.api;
  };

  handleResultGridReady = (params) => {
    this.emrLinkSearchApi = params.api;
  };

  handleResetGridScrollbar = () => {
    //스크롤바
    this.emrLinkSearchApi.ensureIndexVisible(0, 'top');
  };

  handleGridSelectionChanged = (l) => {
    if (l && l.length > 0) {
      this.props.cervicoRequest.changePatinfo('refTestCase', l[0].idx);
      this.props.cervicoRequest.changePatinfo('patName', l[0].patName);
      this.props.cervicoRequest.changePatinfo('chartNum', l[0].chartNum);
      this.props.cervicoRequest.changeRefPatinfo('patName', l[0].patName);
      this.props.cervicoRequest.changeRefPatinfo('receivedDate', l[0].receivedDate);
      if (l[0].socialNum.length > 0) {
        this.props.cervicoRequest.changePatinfo('socialNum1', l[0].socialNum.trim().substring(0, 6));
        this.props.cervicoRequest.changePatinfo('socialNum2', l[0].socialNum.trim().substring(6, 7));
        this.props.cervicoRequest.changeSocialNum(1);
      } else {
        this.props.cervicoRequest.changePatinfo('socialNum1', '');
        this.props.cervicoRequest.changePatinfo('socialNum2', '');
        this.props.cervicoRequest.changePatinfo('age', '');
      }
    } else {
      this.props.cervicoRequest.changePatinfo('refTestCase', null);
    }
  };

  handleFocus = (e) => {
    e.target.select();
  };

  handleNumberChange = (e) => {
    this.props.cervicoRequest.changePatinfo('idxHpAiReqInfo', null);

    const i = e.target;
    this.handleChange({
      target: {
        name: i.name,
        value: `${i.value}`.replace(/[^0-9]+/g, '')
      }
    });
  };

  handleChange = (e) => {
    const i = e.target;
    this.props.cervicoRequest.changePatinfo(i.name, i.value);

    if (i.name === 'patName') {
      this.typoErrFunc(this.props.cervicoRequest.patinfo.patName.trim());
    }
    if (i.name !== 'startDate' && i.name !== 'endDate') {
      this.props.cervicoRequest.changePatinfo('idxHpAiReqInfo', null);
    }
  };

  handleTodayClick = () => {
    this.props.cervicoRequest.changePatinfo('startDate', moment().startOf('day').toDate());
    this.props.cervicoRequest.changePatinfo('endDate', moment().startOf('day').add(+1, 'day').add(-1, 'minute').toDate());
  };

  handlePregChange = (e) => {
    const i = e.target;
    this.props.cervicoRequest.changePreg(i.name, i.value);
  };

  handleCheckboxChange = (checked, name) => {
    this.props.cervicoRequest.changeTherapy(checked, name);
  };

  handleSearchClick = async () => {
    await this.props.cervicoRequest.triggerSearch();
    this.gridApi.ensureIndexVisible(0, 'top');
  };

  handleEmrLinkResetClick = () => {
    this.props.cervicoRequest.init();
    this.handleEmrLinkSearchClick(true);
  };

  handleEmrLinkSearchClick = async (didMount = false) => {
    await this.props.cervicoRequest.triggerSearchByEMR(didMount);
  };

  handleCerviBoxSearchClick = () => {
    const patinfo = this.props.cervicoRequest.patinfo;
    this.props.onSearchClick(patinfo.patName, patinfo.chartNum, patinfo.socialNum1, patinfo.socialNum2);
  };

  handleGridRowClick = (data) => {
    if (this.props.info)
      // cervibox의뢰 폼의 history 조회면 resWindow 사용안함
      return;
    this.props.cervicoResultWindow.setList([{ idx: data.idx, statusCode: data.statusCode }]);
  };

  componentDidMount = () => {
    if (this.props.useHistoryYn && this.props.useAIOnPatType === 2) {
      this.handleEmrLinkSearchClick(true);
    }

    // AI 결과에서 검사의뢰할 때, comment 값 가져오기
    if (this.props.useDetailHistoryYn && this.props.useAiRequestYn) {
      this.props.cervicoRequest.changePatinfo('comment', this.props.cerviAi.preview.comment);
    }

    if (this.props.cervicoRequest.patinfo.socialNum2 === '') {
      this.props.cervicoRequest.changeSocialNum(1);
    }
  };

  typoErrFunc = debounce((str) => {
    const checkStr = str.match(regexp);

    this.setState({
      typoErr: checkStr !== null
    });
  }, 500);

  render() {
    const { locale, cervicoRequest, useDetailHistoryYn, useHistoryYn, useCerviBoxMemoYn, useCerviBoxSearch, useCerviBoxCopy, useAIOnPatType } = this.props;
    const { patinfo: p } = cervicoRequest;
    const hc = this.handleChange;
    const hpc = this.handlePregChange;
    const hnc = this.handleNumberChange;
    const hcc = this.handleCheckboxChange;
    return (
      <Form className="search PatForm">
        <Card style={{ marginBottom: '10px' }}>
          <CardHeader>
            <FormattedMessage id="comp.req.title.pat" />
          </CardHeader>
          <CardBody>
            <div className="form-row">
              <FormGroup className="fix-width">
                <Label>
                  <FormattedMessage id="field.patname" /> <span className="req">*</span>
                </Label>
                <Input className="ime" type="text" bsSize="sm" name="patName" size="10" value={p.patName} onChange={hc} invalid={this.state.typoErr} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.chartnum" /> <span className="req">*</span>
                </Label>
                <Input type="text" bsSize="sm" name="chartNum" value={p.chartNum} onChange={hc} />
              </FormGroup>
              {useCerviBoxCopy ? (
                <FormGroup>
                  <Label>
                    <FormattedMessage id="field.birthday" /> <span className="req">*</span>
                  </Label>
                  <Input
                    bsSize="sm"
                    name="socialNum1"
                    value={p.socialNum1}
                    onChange={hnc}
                    size="6"
                    maxLength="6"
                    onFocus={this.handleFocus}
                    onBlur={() => cervicoRequest.changeSocialNum(1)}
                    placeholder="YYMMDD"
                  />
                </FormGroup>
              ) : (
                <FormGroup className="fix-width">
                  <Label>
                    <FormattedMessage id="field.birthday" /> <span className="req">*</span>
                  </Label>
                  <Input
                    bsSize="sm"
                    name="socialNum1"
                    value={p.socialNum1}
                    onChange={hnc}
                    size="6"
                    maxLength="6"
                    onFocus={this.handleFocus}
                    onBlur={() => cervicoRequest.changeSocialNum(1)}
                    placeholder="YYMMDD"
                  />
                  -
                  <Input
                    bsSize="sm"
                    name="socialNum2"
                    value={p.socialNum2}
                    onChange={hnc}
                    size="7"
                    maxLength="1"
                    onFocus={this.handleFocus}
                    onBlur={() => cervicoRequest.changeSocialNum(2)}
                    style={{ width: '20px' }}
                  />
                </FormGroup>
              )}
              {useCerviBoxCopy ? null : (
                <FormGroup>
                  <Label>
                    <FormattedMessage id="field.age" />
                  </Label>
                  <Input bsSize="sm" name="age" value={p.age} onChange={hnc} size="4" onFocus={this.handleFocus} />
                </FormGroup>
              )}
              {useHistoryYn && useAIOnPatType <= 1 ? (
                <FormGroup className="ml-auto">
                  <Button size="sm" color="success" type="button" onClick={this.handleSearchClick}>
                    <FaSearch /> <FormattedMessage id="button.search.testhistory" />{' '}
                  </Button>
                </FormGroup>
              ) : null}
              {useHistoryYn && useAIOnPatType === 2 ? (
                <div className="form-row">
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="field.visitDate" />
                    </Label>
                    <DateTimePicker name="startDate" value={p.startDate} onChange={hc} />
                    ~
                    <DateTimePicker name="endDate" value={p.endDate} onChange={hc} />
                  </FormGroup>
                  <FormGroup>
                    <Button type="button" onClick={this.handleTodayClick} outline>
                      <FormattedMessage id="button.today" />
                    </Button>
                  </FormGroup>
                </div>
              ) : null}
              {useHistoryYn && useAIOnPatType === 2 ? (
                <FormGroup className="ml-auto">
                  <Button size="sm" color="secondary" type="button" onClick={this.handleEmrLinkResetClick} className="mr-2">
                    <FaUndoAlt /> <FormattedMessage id="button.reset" />{' '}
                  </Button>
                  <Button size="sm" color="success" type="button" onClick={this.handleEmrLinkSearchClick}>
                    <FaSearch /> <FormattedMessage id="button.search" />{' '}
                  </Button>
                </FormGroup>
              ) : null}
              {useCerviBoxSearch ? (
                <FormGroup className="ml-auto">
                  <Button size="sm" color="purple" type="button" onClick={this.handleCerviBoxSearchClick}>
                    <FaSearch /> <FormattedMessage id="comp.cervibox.title.searchcondition" />{' '}
                  </Button>
                </FormGroup>
              ) : null}
            </div>
            {useHistoryYn && useAIOnPatType <= 1 ? (
              <DataGrid
                border="true"
                style={{ height: '100px' }}
                showFooter={false}
                showSelectCount={false}
                showPagination={false}
                rowSelection="single"
                columnDefs={[
                  { width: 30, checkboxSelection: true, headerCheckboxSelection: false },
                  { headerName: locale.fm('field.fcode'), field: 'idx', width: 80 },
                  { headerName: locale.fm('field.receiveddate'), field: 'receivedDate', width: 130, cellRenderer: dateCellRenderer },
                  { headerName: locale.fm('field.patname'), field: 'patName', width: 130 },
                  { headerName: locale.fm('field.birthday'), field: 'socialNum', width: 130, cellRenderer: socialNumRenderer },
                  { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 130 },
                  { headerName: locale.fm('field.age'), field: 'age', width: 70 },
                  { headerName: locale.fm('field.teststatus'), field: 'statusCode', width: 80, cellRenderer: statusCodeCellRenderer, cellClassRules: statusCodeCellClassRules },
                  { headerName: locale.fm('field.txtresult'), field: 'txtResult', width: 80 }
                ]}
                //rowSelection="multiple"
                //isRowSelectable={({data})=>data.statusCode>6}

                onGridReady={this.handleGridReady}
                onRowDoubleClicked={this.handleGridRowClick}
                onSelectionChanged={this.handleGridSelectionChanged}
                //onMovePage={this.handleMovePage}

                dataGrid={cervicoRequest.dataGrid}
              />
            ) : null}
          </CardBody>
        </Card>
        {useCerviBoxCopy ? null : (
          <Card style={{ marginBottom: '10px' }}>
            <CardHeader>
              <FormattedMessage id="comp.req.title.detail" />
            </CardHeader>
            <CardBody>
              <div className="form-row">
                <FormGroup>
                  <Label>
                    <FormattedMessage id="field.pregnancy" /> <span className="req">*</span>
                  </Label>
                  <CustomInput id="request-isPreg-y" type="radio" name="isPreg" value="true" onChange={hc} checked={p.isPreg === 'true'} label={locale.fm('value.yes')} />
                  <CustomInput id="request-isPreg-n" type="radio" name="isPreg" value="false" onChange={hc} checked={p.isPreg === 'false'} label={locale.fm('value.no')} />
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup>
                  <Label>
                    <FormattedMessage id="field.parturition" />
                  </Label>
                  <NumericInput
                    className="form-control form-control-sm field-number"
                    min={0}
                    precision={0}
                    value={p.p}
                    onChange={(v) => hpc({ target: { name: 'p', value: v } })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="field.abortion" />
                  </Label>
                  <NumericInput
                    className="form-control form-control-sm field-number"
                    min={0}
                    precision={0}
                    value={p.a}
                    onChange={(v) => hpc({ target: { name: 'a', value: v } })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="field.gestatio" />
                  </Label>
                  <NumericInput className="form-control form-control-sm field-number" min={0} precision={0} value={p.g} onChange={(v) => hc({ target: { name: 'g', value: v } })} />
                </FormGroup>
              </div>
              {useDetailHistoryYn && useAIOnPatType <= 1 ? (
                <Fragment>
                  <div className="form-row history">
                    <FormGroup>
                      <Label>Last Cervico.</Label>
                      <DateTimePicker name="lastCervicoDate" value={p.lastCervicoDate} onChange={hc} dropUp />
                      <Input type="select" bsSize="sm" name="lastCervicoComment" value={p.lastCervicoComment} onChange={hc} className="ml-1">
                        <option value="">-select-</option>
                        {cervicoOption.map((v, i) => (
                          <option key={i} value={v}>
                            {v}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="form-row history">
                    <FormGroup>
                      <Label>Last PAP</Label>
                      <DateTimePicker name="lastPapDate" value={p.lastPapDate} onChange={hc} dropUp />
                      <Input type="select" bsSize="sm" name="lastPapComment" value={p.lastPapComment} onChange={hc} className="ml-1">
                        <option value="">-select-</option>
                        {papOption.map((v, i) => (
                          <option key={i} value={v}>
                            {v}
                          </option>
                        ))}
                      </Input>
                      <div className="break" />
                      <Label className="descr" />
                      <Input type="text" bsSize="sm" name="lastPapComment_d" value={p.lastPapComment_d} onChange={hc} className="ml-1 descr" placeholder="else" />
                    </FormGroup>
                  </div>
                  <div className="form-row history">
                    <FormGroup>
                      <Label>Last HPV</Label>
                      <DateTimePicker name="hpvDate" value={p.hpvDate} onChange={hc} dropUp />
                      <Input type="select" bsSize="sm" name="hpvComment" value={p.hpvComment} onChange={hc} className="ml-1">
                        <option value="">-select-</option>
                        {hpvOption.map((v, i) => (
                          <option key={i} value={v}>
                            {v}
                          </option>
                        ))}
                      </Input>
                      <div className="break" />
                      <Label className="descr" />
                      <Input type="text" bsSize="sm" name="hpvComment_d" value={p.hpvComment_d} onChange={hc} className="ml-1 descr" placeholder="ex) 16, 18, etc" />
                    </FormGroup>
                  </div>
                  <div className="form-row history">
                    <FormGroup>
                      <Label>Last Bx.</Label>
                      <DateTimePicker name="bxDate" value={p.bxDate} onChange={hc} dropUp />
                      <Input type="select" bsSize="sm" name="bxComment" value={p.bxComment} onChange={hc} className="ml-1">
                        <option value="">-select-</option>
                        {bxOption.map((v, i) => (
                          <option key={i} value={v}>
                            {v}
                          </option>
                        ))}
                      </Input>
                      <div className="break" />
                      <Label className="descr" />
                      <Input type="text" bsSize="sm" name="bxComment_d" value={p.bxComment_d} onChange={hc} className="ml-1 descr" placeholder="ex) Punch, Leep, etc" />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    <FormGroup>
                      <Label>
                        <FormattedMessage id="field.therapy" />
                      </Label>
                      <DateTimePicker name="therapyDate" value={p.therapyDate} onChange={hc} dropUp />
                    </FormGroup>
                  </div>
                  <div className="form-row">
                    {therapy.map((t, i) => (
                      <FormGroup key={i} className="check">
                        <Label className="descr" />
                        <CustomInput type="checkbox" id={t.id} label={t.desc} checked={p.therapyList.includes(t.desc)} onChange={({ target }) => hcc(target.checked, t.desc)} />
                      </FormGroup>
                    ))}
                  </div>
                  <div className="form-row">
                    <FormGroup className="col flex-fill">
                      <label>
                        <FormattedMessage id="field.doctorcomment" />
                      </label>
                      <Input type="textarea" style={{ width: '100%' }} name="comment" value={p.comment} onChange={hc} />
                    </FormGroup>
                  </div>
                </Fragment>
              ) : null}
              {/* useAIonPatType == 2 일때, 써비박스/AI결과에서 검사의뢰할때 */}
              {useDetailHistoryYn && (useAIOnPatType === undefined || useAIOnPatType === 2) && (
                <div className="form-row">
                  <FormGroup className="col flex-fill">
                    <label>
                      <FormattedMessage id="field.doctorcomment" />
                    </label>
                    <Input type="textarea" style={{ width: '100%' }} name="comment" value={p.comment} onChange={hc} />
                  </FormGroup>
                </div>
              )}
              {useCerviBoxMemoYn ? (
                <div className="form-row">
                  <FormGroup className="col flex-fill">
                    <label>
                      <FormattedMessage id="field.memo" />
                    </label>
                    <Input type="textarea" style={{ width: '100%' }} name="memo" value={p.memo} onChange={hc} />
                  </FormGroup>
                </div>
              ) : null}
            </CardBody>
          </Card>
        )}
        {useAIOnPatType === 2 ? (
          <Card>
            <CervicoPatinfoGridCardForSearch onGridReady={this.handleResultGridReady} onAfterMove={this.handleResetGridScrollbar} />
          </Card>
        ) : null}
      </Form>
    );
  }
}

export default PatForm;
