import { observable, action, computed, runInAction } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler, addCheckedKey } from 'libs/Utils';
import { toast } from 'react-toastify';

export default class CerviAiResultWindowStore {
  //resultList 는 {idx, statusCode} 의 배열임!!
  @observable resultList = [];
  @observable currentIndex = 0;
  @observable result = null;
  @observable currentPic = 0;
  @observable preview = null;
  @observable pictureIndex = 0;
  @observable roiList = [];

  constructor(root) {
    this.root = root;
    this.init();
  }

  //TODO init 로 변경
  @action
  init = () => {
    this.resultList.replace([]);
    this.currentIndex = 0;
    this.result = null;
    this.pictureIndex = 0;
    this.currentPic = 0;
    this.preview = null;
    this.roiList = [];
  };

  @action
  setRoiList = (pIdx) => {
    this.roiList = addCheckedKey(this.result.aiResultList[pIdx].roiList);
  };

  @action
  setCurrentPic = (pIdx) => {
    this.setRoiList(pIdx);
    this.currentPic = pIdx;
  };

  @action
  toggleRoiCheck = (idx) => {
    this.roiList.forEach((el) => {
      if (el.idx === idx) {
        el.checked = !el.checked;
      }
    });
  };

  @action
  changeRoiName = (idx, value) => {
    this.roiList.forEach((el) => {
      if (el.idx === idx) {
        el.roiName = value;
      }
    });
  };

  @action
  setList = (data, from = '') => {
    if (from !== 'cervibox' && data.aiResultList.length === 0) {
      toast.warn(this.props.locale.fm('field.ai.noresult.detail'));
      return;
    }

    if (from === 'cervibox') {
      this.setResult(data[0].idx);
    } else {
      this.result = data;
      this.roiList = addCheckedKey(data.aiResultList[this.currentIndex].roiList);
    }
  };

  @action
  setResult = async (idx) => {
    this.root.screen.setProcessLayer(true);

    try {
      const res = await axios.get(`/api/rq/cerviai/${idx}`);
      runInAction('CerviAiResultWindowStore : async setResult -> success', () => {
        res.data.data.aiResultList.forEach((r) => {
          r.roiList = addCheckedKey(r.roiList);
        });
        this.result = res.data.data;
      });
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };

  @action
  nextResult = () => {
    this.currentIndex++;
    this.setResult();
  };

  @action
  prevResult = () => {
    this.currentIndex--;
    this.setResult();
  };

  @action
  setPictureIndex = (pictureIndex) => {
    this.pictureIndex = pictureIndex;
  };
  @action
  setPreviewBox = (idx) => {
    const p = this.dataGrid.rows.find((r) => r.idx === idx);

    this.preview = p;
    this.currentPic = 0;
  };
  //zoom 화면
  @action
  zoom = () => {
    const r = this.result;
    if (r !== null) {
      const pictureList = [...Array(parseInt(r.imgCount))].map((_, i) => {
        return {
          url: `/api/rq/cervico/worklist/${r.idx}/picture/${i + 1}`,
          title: `환자명 : ${r.patName}`
        };
      });
      this.root.zoomWindow.setPictureList(pictureList, this.pictureIndex);
    }
  };

  @action
  print = (pIdx, type = 'reKo') => {
    const url = `/api/rq/cerviai/report/pdf?idx=${pIdx}&type=${type}`;

    this.root.pdfWindow.print(url);
  };

  @action
  download = (pIdx, type = 'reKo') => {
    const url = `/api/rq/cerviai/report/img?idx=${pIdx}&type=${type}`;

    window.location.href = url;
  };

  @computed
  get show() {
    return this.result !== null;
  }

  @computed
  get isLastResult() {
    return this.resultList.length === this.currentIndex + 1;
  }

  @computed
  get isFirstResult() {
    return this.currentIndex === 0;
  }

  @computed
  get position() {
    return `${this.currentIndex + 1} / ${this.resultList.length}`;
  }

  @computed
  get completeCount() {
    return this.resultList.filter((r) => r.statusCode > 6).length;
  }
}
