import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect, withRouter } from 'react-router-dom';

@withRouter
@inject('screen')
@inject('pictureList')
@inject('auth')
@observer
class PrivateRoute extends Component {
  componentDidMount() {
    this.props.screen.history = this.props.history;
    this.props.pictureList.getCount();
  }

  render() {
    const { auth, location, children } = this.props;
    return (
      //랜더링 시점 screen.authChecked 완료 된 이후.. 따라서 이곳에서 auth.doctor 채크 하면 됨
      auth.doctor != null ? ( //
        <>{children}</> //
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )
    );
  }
}

export default PrivateRoute;
