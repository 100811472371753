import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';

@inject('locale')
class UpdateToast extends Component {
  render() {
    return (
      <NavLink exact to="notice/2">
        <p style={{ whiteSpace: 'pre-line', color: 'white', padding: '0 15px', margin: '0' }}>
          <FaInfoCircle /> <FormattedMessage id="field.unreadUpdateRelease" />
        </p>
      </NavLink>
    );
  }
}

export default UpdateToast;
