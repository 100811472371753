import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { ButtonGroup, Button, Card, CardHeader, CardBody, CustomInput, CardFooter, Alert } from 'reactstrap';
import { PictureBox, CardMask } from 'components/common';
import debounce from 'lodash.debounce';
import moment from 'moment';
import cx from 'classnames';

import { FormattedMessage } from 'react-intl';
import { IoMdRefresh } from 'react-icons/io';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import { FaRegCheckSquare, FaRegSquare, FaTrash, FaCloudDownloadAlt, FaCloudUploadAlt, FaUserAstronaut, FaBox, FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';

import checkIcon from 'resources/check.png';
import aiIcon from 'resources/ai_icon.png';
import hourglassIcon from 'resources/hourglass.png';

import './PictureListCardForAndroid.scss';

@inject('pictureList')
@inject('cerviBoxAdd')
@inject('cerviAi')
@inject('auth')
@inject('locale')
@observer
class PictureListCardForAndroid extends Component {
  constructor(props) {
    super(props);

    this.intervalId = null;
    this.isRequestAI = false;
  }

  handleUpload = (e) => {
    this.props.pictureList.upload(e.target.files);
    e.target.value = '';
  };

  handleCerviAiClick = debounce(async () => {
    const list = this.props.pictureList.group.reduce((a, c) => [...a, ...c.list], []).filter((e) => e.checked);

    if (list.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
      return;
    } else if (list.some((g) => g.aiStatus === 3)) {
      toast.warn(this.props.locale.fm('comp.cerviai.vali'));
      return;
    }
    const pl = list.map((el) => {
      return el.idx;
    });

    await this.props.cerviAi.analy(pl, 'req');
    this.props.pictureList.getList();

    this.isRequestAI = true;
  }, 500);

  handleRefresh = () => {
    this.props.pictureList.getList();
  };

  resetInterval = () => {
    clearInterval(this.intervalId);
    this.intervalId = null;
  };

  componentDidMount = () => {
    this.handleRefresh();
  };

  componentDidUpdate = (prevProps) => {
    if (this.isRequestAI) {
      this.intervalId = setInterval(() => {
        this.handleRefresh();
      }, 3000);

      if (prevProps.pictureList.reFreshAiStatus) {
        this.isRequestAI = false;
      }
    }

    if (!prevProps.pictureList.reFreshAiStatus && this.intervalId) {
      this.resetInterval();
    }
  };

  componentWillUnmount = () => {
    this.resetInterval();
  };

  render() {
    const { pictureList, auth } = this.props;
    return (
      <>
        <Card className="PictureListCardForAndroid basis-300">
          <CardHeader className="card-toolbar d-flex flex-wrap align-items-center">
            <Button size="lg" color="secondary" onClick={pictureList.testWindowClose} className="mr-auto">
              <FaArrowLeft />
            </Button>
            <Button size="lg" color="secondary" onClick={pictureList.selectChange}>
              {pictureList.selectAll ? <FaRegCheckSquare /> : <FaRegSquare />} <FormattedMessage id="button.select" />
            </Button>
            <Button size="lg" color="primary" onClick={pictureList.getList}>
              <IoMdRefresh /> <FormattedMessage id="button.reload" />
            </Button>
            <Button size="lg" color="darkgray" onClick={pictureList.sortChange}>
              {pictureList.sortDesc ? <TiArrowSortedDown /> : <TiArrowSortedUp />} <FormattedMessage id="button.sortDate" />
            </Button>
            <Button size="lg" color="danger" onClick={pictureList.removeSelected}>
              <FaTrash /> <FormattedMessage id="button.removeSelected" />
            </Button>
            {auth.doctor.useAI && auth.doctor.useQuickAI ? (
              <Button size="lg" color="sunsetOrange" style={{ color: 'white' }} onClick={this.handleCerviAiClick}>
                <FaUserAstronaut /> <FormattedMessage id="button.quickAi" />
              </Button>
            ) : (
              ''
            )}
          </CardHeader>
          <CardBody className="np h-flex">
            <PictureList />
          </CardBody>
          <CardFooter style={{ fontWeight: 'bold' }}>
            <CheckedCount className="mr-auto" />
          </CardFooter>
          <CardMask show={pictureList.mask} />
        </Card>
      </>
    );
  }
}

@inject('pictureList')
@observer
class CheckedCount extends Component {
  render() {
    const pictureList = this.props.pictureList;
    return (
      <div>
        <FormattedMessage id="field.total" /> : {pictureList.totalCount} / <FormattedMessage id="field.selected" /> :{' '}
        {pictureList.selectedCount === 0 ? 0 : <span className="badge badge-primary">{pictureList.selectedCount}</span>}
      </div>
    );
  }
}

@inject('locale')
@inject('zoomWindow')
@inject('pictureList')
@inject('cerviAiResultWindowWithoutPatInfo')
@observer
class PictureList extends Component {
  handlePictureClick = (gIdx, cIdx, e) => {
    const { locale, zoomWindow, pictureList } = this.props;
    const pl = pictureList.getGroupPicture(gIdx).map((p) => {
      return {
        url: p.url,
        title: `${locale.fm('field.takedate')} : ${moment(p.takedate).format('YYYY-MM-DD HH:mm:SS')}`
      };
    });
    zoomWindow.setPictureList(pl, cIdx);
  };

  handlePictureAiClick = (gIdx, cIdx, e) => {
    e.stopPropagation();
    const { locale, cerviAiResultWindowWithoutPatInfo, pictureList } = this.props;
    const pl = pictureList.getGroupPicture(gIdx);
    cerviAiResultWindowWithoutPatInfo.setList(pl, pl[cIdx].idx);
  };

  handlePictureCheckChange = (gIdx, pIdx, e) => {
    const { pictureList } = this.props;
    const checked = e.target ? e.target.checked : e;
    pictureList.selectPicture(gIdx, pIdx, checked);
  };

  handleGroupCheckChange = (gIdx, e) => {
    const { pictureList } = this.props;
    pictureList.selectGroup(gIdx, e.target.checked);
  };

  handleClickReload = (gIdx, aiIdx, e) => {
    e.stopPropagation();
    const { pictureList } = this.props;
    pictureList.getAiStatus(gIdx, aiIdx);
  };

  componentDidMount() {
    this.props.pictureList.getList();
  }

  componentWillUnmount() {
    //store 정리
    //header 카운트 위해서 정리하지 않는다.
    //this.props.pictureList.init();
  }

  render() {
    const { group } = this.props.pictureList;
    return (
      <div className="picture-list">
        <ul>
          {group.map((g) => (
            <PictureGroup
              key={g.idx}
              pictureGroup={g}
              onPictureClick={this.handlePictureClick}
              onPictureAiClick={this.handlePictureAiClick}
              onGroupCheckChange={this.handleGroupCheckChange}
              onPictureCheckChange={this.handlePictureCheckChange}
              onClickReload={this.handleClickReload}
            />
          ))}
        </ul>
      </div>
    );
  }
}

@inject('locale')
@observer
class PictureGroup extends Component {
  render() {
    const { locale, pictureGroup: g, onPictureClick, onPictureAiClick, onGroupCheckChange, onPictureCheckChange, onClickReload } = this.props;
    return (
      <li>
        <div className="send-title">
          <CustomInput
            type="checkbox"
            id={`g-${g.idx}`}
            label={`${locale.fm('field.startdate')} : ${moment(g.startdate).format('YYYY-MM-DD HH:mm:ss')}`}
            onChange={(e) => onGroupCheckChange(g.idx, e)}
            checked={g.checked}
          />
        </div>
        <ul>
          {g.list.map((p, j) => (
            <Picture
              key={p.idx}
              gIdx={g.idx}
              cIdx={j}
              picture={p}
              onPictureClick={onPictureClick}
              onPictureAiClick={onPictureAiClick}
              onPictureCheckChange={onPictureCheckChange}
              onClickReload={onClickReload}
            />
          ))}
        </ul>
      </li>
    );
  }
}

@inject('pictureList')
@inject('auth')
@observer
class Picture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null
    };
  }

  handleRefresh = (e) => {
    e.stopPropagation();
    this.props.pictureList.getList();
  };

  handleMouseDown = (gIdx, cIdx) => {
    const timeoutId = setTimeout(() => {
      this.props.onPictureClick(gIdx, cIdx);
    }, 500);
    this.setState({ timer: timeoutId });
  };

  handleMouseUp = () => {
    clearTimeout(this.state.timer);
    this.setState({ timer: null });
  };

  handleMouseLeave = () => {
    clearTimeout(this.state.timer);
    this.setState({ timer: null });
  };

  render() {
    const { gIdx, cIdx, picture: p, onPictureClick, onPictureAiClick, onPictureCheckChange, onClickReload, auth } = this.props;
    return (
      <li key={p.fCode} className={cx({ checked: p.checked })}>
        <div
          className="picture"
          onClick={(e) => onPictureCheckChange(gIdx, p.idx, !p.checked)}
          onMouseDown={() => this.handleMouseDown(gIdx, cIdx)}
          onMouseUp={this.handleMouseUp}
          onMouseLeave={this.handleMouseLeave}
        >
          <PictureBox small src={`${p.url}?thumb=true`} />
          {p.aiStatus === 0 || p.aiStatus === 1 ? (
            <>
              <img src={hourglassIcon} alt="analyzing" className="ai-result analyzing" />
              <Button className="refresh btn-success" onClick={(e) => this.handleRefresh(e)}>
                <IoMdRefresh />
              </Button>
            </>
          ) : p.aiStatus === 2 || p.aiStatus === 3 ? (
            <img src={aiIcon} alt="ai-result" className="ai-result" onClick={(e) => onPictureAiClick(gIdx, cIdx, e)} />
          ) : null}
          {p.checked && <img src={checkIcon} alt="check" className={cx({ 'checked-icon': p.checked })} />}
        </div>
        {/* <div className="take-date">
          <CustomInput
            type="checkbox"
            id={`p-${p.idx}`}
            label={moment(p.takedate).format('YYYY-MM-DD HH:mm:ss')}
            onChange={(e) => onPictureCheckChange(gIdx, p.idx, e)}
            checked={p.checked}
          />
        </div> */}
      </li>
    );
  }
}

export default PictureListCardForAndroid;
