import { observable, action, runInAction } from 'mobx';
import { searchSerialize } from 'libs/Utils';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

export default class CervitemStore {
  @observable defaultList = [];
  @observable isOpen = false;
  @observable mask = false;
  @observable page = 1;
  @observable totalPage = 1;
  @observable categoryList = [];

  @action
  init = () => {
    this.setMask(false);
  };

  @action
  setMask = (m) => {
    this.mask = m;
  };

  @action
  handleMovePage = (page) => {
    this.page = page;
  };

  @action
  setList = (rows) => {
    this.defaultList = rows;
  };

  @action
  setPage = (page, idx = 0) => {
    this.page = page;
    this.loadData(page, idx);
  };

  @action
  setSelectCategory = (idx) => {
    this.page = 1;
    this.loadData(this.page, idx);
  };

  @action
  setTotalPage = (page) => {
    this.totalPage = page;
  };

  @action
  setOpen = (bool) => {
    this.isOpen = bool;
  };

  @action
  setCategoryList = (list) => {
    list.unshift({ idx: 0, name: '전체' });
    this.categoryList = list;
  };

  @action
  close = () => {
    this.isOpen = false;
  };

  @action
  loadData = async (page = 1, category, query = null) => {
    this.setMask(true);
    if (category === 0) category = null;
    try {
      const response = await axios.get('/api/rq/cervitem/list', {
        params: searchSerialize(this.search, { page: page, category: category, query: query })
      });
      runInAction('CervitemStore : async loadData -> success', () => {
        const { list, totalPage, categoryList } = response.data.data;
        this.setCategoryList(categoryList);
        this.setList(list);
        this.setTotalPage(totalPage);
        this.setMask(false);
      });
    } catch (error) {
      runInAction('CervitemStore : async loadData -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };
}
