import { observable, action, computed } from 'mobx';
import formatMessage from 'format-message';
import translations from 'resources/locale';

import moment from 'moment';
import 'moment/locale/ko';
import momentLocalizer from 'react-widgets-moment';

formatMessage.setup({
  missingTranslation: 'ignore'
});

const LOCALE = 'locale';
const DEFAULT_LOCALE = 'ko';

export default class LocaleStore {
  @observable _locale = '';

  constructor() {
    let def = DEFAULT_LOCALE;
    //기본 로캘 구하자..
    if (typeof Storage !== 'undefined') {
      def = localStorage.getItem(LOCALE) || DEFAULT_LOCALE;
    }
    this.changeLocale(def);
  }

  @computed
  get value() {
    return this._locale;
  }

  @action
  changeLocale = (value) => {
    if (typeof Storage !== 'undefined') {
      localStorage.setItem(LOCALE, value);
    }
    this._locale = value;
    moment.locale(this._locale);
    momentLocalizer();

    //client 측 locale 설정
    if (window.rq && window.rq.changeLocale) {
      window.rq.changeLocale(value);
    }
  };

  @computed
  get messages() {
    return translations[this._locale];
  }

  fm = (id, values) => {
    return this.formatMessage(id, values);
  };

  formatMessage = (id, values) => {
    if (!(id in this.messages)) {
      console.warn('Id not found in intl list: ' + id);
      return id;
    }

    return formatMessage(this.messages[id], values);
  };
}
