import React, { PureComponent } from 'react';
import moment from 'moment';
import './CervicoResultTable.scss';

import { FormattedMessage } from 'react-intl';
import { testTypeCellRenderer } from 'libs/Utils';

function isNotEmpty(v) {
  if (v) return v.trim().length > 0;
  else return false;
}

class CervicoResultTable extends PureComponent {
  render() {
    const { result } = this.props;
    const testType = { __html: `${result.idx} ${testTypeCellRenderer({ data: result })}` };
    const socialNum = result.socialNum;

    return (
      <div className="CervicoResultTable">
        <table>
          <tbody>
            <tr>
              <th>
                <FormattedMessage id="field.fcode" />
              </th>
              <td dangerouslySetInnerHTML={testType}></td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.patinfo" />
              </th>
              <td>
                {result.patName} {result.age ? `(${result.age}세) ` : ''}
                {socialNum.length > 6 ? `${socialNum.substring(0, 6)}-${socialNum.substring(6, 7)}` : socialNum}
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.chartnum" />
              </th>
              <td>{result.chartNum}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.takedate" />
              </th>
              <td>{moment(result.takeDate).format('YYYY-MM-DD HH:mm:ss')}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.receiveddate" />
              </th>
              <td>{result.statusCode > 0 ? moment(result.receivedDate).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.pregnancy" />
              </th>
              <td>
                {result.isPreg === null ? (
                  <span className="badge badge-light">
                    <FormattedMessage id="value.unknown" />
                  </span>
                ) : result.isPreg ? (
                  <span className="badge badge-primary">
                    <FormattedMessage id="value.yes" />
                  </span>
                ) : (
                  <span className="badge badge-secondary">
                    <FormattedMessage id="value.no" />
                  </span>
                )}{' '}
                G:{result.g === null ? '- ' : result.g + ' '}
                P:{result.p === null ? '- ' : result.p + ' '}
                A:{result.a === null ? '- ' : result.a + ' '}
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.morphology" />
              </th>
              <td>{result.morphology}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.therapy.short" />
              </th>
              <td className="ws-pre-line">
                {isNotEmpty(result.lastPapComment) ? `Last Pap : ${result.lastPapComment} ${result.lastPapDate ? `(${result.lastPapDate})` : ''}\n` : ''}
                {isNotEmpty(result.lastCervicoComment) ? `Last Cervico. : ${result.lastCervicoComment} ${result.lastCervicoDate ? `(${result.lastCervicoDate})` : ''}\n` : ''}
                {isNotEmpty(result.hpvComment) ? `Last Hpv : ${result.hpvComment} ${result.hpvDate ? `(${result.hpvDate})` : ''}\n` : ''}
                {isNotEmpty(result.bxComment) ? `Last Bx : ${result.bxComment} ${result.bxDate ? `(${result.bxDate})` : ''}\n` : ''}
                {isNotEmpty(result.therapy) ? `Last Therapy : ${result.therapy} ${result.therapyDate ? `(${result.therapyDate})` : ''}\n` : ''}
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.doctorcomment" />
              </th>
              <td className="ws-pre-line" dangerouslySetInnerHTML={{ __html: result.comment }}></td>
            </tr>
            {result.statusCode < 7 ? (
              <tr>
                <th>
                  <FormattedMessage id="field.teststatus" />
                </th>
                <td className="text-primary">
                  <FormattedMessage id={result.statusCode === 0 ? 'value.status.0' : result.statusCode === 1 ? 'value.status.a' : 'value.status.b'} />
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <th>
                    <FormattedMessage id="field.evaname" />
                  </th>
                  <td>{result.evaName}</td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage id="field.diagnosisdate" />
                  </th>
                  <td>{moment(result.diagnosisDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage id="field.txtresult" />
                  </th>
                  <td>{result.txtResult}</td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage id="field.typecomments" />
                  </th>
                  <td className="ws-pre-line text-danger">{result.typeComments}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CervicoResultTable;
