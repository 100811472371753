import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import { PictureBox } from 'components/common';
import { FormattedMessage } from 'react-intl';
import cookies from 'browser-cookies';
import cx from 'classnames';

import { FaArrowCircleLeft, FaArrowCircleRight, FaTimesCircle, FaArrowLeft, FaQuestionCircle, FaAngleLeft, FaAngleRight, FaRegWindowRestore } from 'react-icons/fa';

import { toggleFullscreen } from 'libs/Screen';

import './ZoomWindow.scss';

@inject('screen')
@inject('zoomWindow')
@withRouter
@observer
class ZoomWindow extends Component {
  el = React.createRef();

  handleFullscreen = () => {
    toggleFullscreen(this.el.current);
  };

  handlePopup = () => {
    //쿠키 설정
    const { pictureList, current } = this.props.zoomWindow;
    const picture = {
      pictureList,
      current
    };
    cookies.set('zoomPopup', JSON.stringify(picture));

    if (window.rq) {
      window.rq.openPicturePopup();
    } else {
      const w = window.open('/picture', 'zoomPicture', `width=${window.screen.availWidth},height=${window.screen.availHeight}`);
      w.focus();
    }
    this.props.zoomWindow.init();
  };

  handleContentMenu = (e) => {
    e.preventDefault();
    this.props.zoomWindow.init();
  };

  handleCallbackSwipe = (i, el) => {
    this.props.zoomWindow.setCurrent(i);
  };

  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.zoomWindow.init();
    }
  }

  componentDidMount() {
    //TODO keyboard 이벤트 bind
  }

  componentWillUnmount() {
    //TODO keyboard 이벤트 unbind
  }

  render() {
    const { zoomWindow } = this.props;

    if (zoomWindow.show) {
      const { pictureList, current } = zoomWindow;
      const currentPicture = pictureList[current];

      return (
        <div className="ZoomWindow layer-window" ref={this.el} onKeyDown={this.handleKeyDown}>
          <div className="area-picture">
            {pictureList.map((p, i) => (
              <div className={cx('zoom-item', { 'd-none': current !== i })} key={i}>
                <PictureBox contain src={p.url} onContextMenu={this.handleContentMenu} />
              </div>
            ))}
            <div className="area-title">{currentPicture.title}</div>
            <div className="area-top-button left button-box">
              <Button size="sm" onClick={zoomWindow.init}>
                <FaArrowLeft />
              </Button>
            </div>
            <div className="area-top-button button-box">
              <span className="d-none d-md-block">
                <FaQuestionCircle /> <FormattedMessage id="comp.dialog.closeInfo" />
              </span>
              {/* <Button size="sm" onClick={this.handleFullscreen}>
                {screen.fullscreen ? <FaCompress /> : <FaExpand />}
              </Button> */}
              <Button size="sm" onClick={zoomWindow.init}>
                <FaTimesCircle />
              </Button>
            </div>
            <Button size="sm" onClick={this.handlePopup} className="btn-popup">
              <FaRegWindowRestore />
            </Button>
            <Button size="sm" disabled={zoomWindow.isFirst} onClick={zoomWindow.prev} className="btn-large-left">
              <FaAngleLeft />
            </Button>
            <Button size="sm" disabled={zoomWindow.isLast} onClick={zoomWindow.next} className="btn-large-right">
              <FaAngleRight />
            </Button>
            <div className="area-bottom-button button-box">
              <Button size="sm" disabled={zoomWindow.isFirst} onClick={zoomWindow.prev}>
                <FaArrowCircleLeft />
              </Button>
              <div>{zoomWindow.position}</div>
              <Button size="sm" disabled={zoomWindow.isLast} onClick={zoomWindow.next}>
                <FaArrowCircleRight />
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default ZoomWindow;
