import { observable, action } from 'mobx';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

const defaultDeleteCauseInfo = {
  idxs: null,
  deleteCause: ''
};

export default class CervicoResultDeleteWindowStore {
  @observable deleteCauseInfo = { ...defaultDeleteCauseInfo };
  @observable open = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.deleteCauseInfo = { ...defaultDeleteCauseInfo };
    this.open = false;
  };

  @action
  setDeleteCause = (idxs) => {
    this.deleteCauseInfo.idxs = idxs;
    this.open = true;
  };

  @action
  changeDeleteCauseInfo = (key, value) => {
    this.deleteCauseInfo[key] = value;
  };

  @action
  delete = async () => {
    const d = this.deleteCauseInfo;

    //deleteCauseInfo 검증
    if (d.deleteCause.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.deleteCause'));
      return;
    }

    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.delete('/api/rq/cervico/worklist/del', { data: d });
      if (response.data.success) {
        toast.success(this.locale.fm('store.res.modify.PatInfo'));
        this.root.cervicoResult.triggerRefresh();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.init();
    this.root.screen.setProcessLayer(false);
  };
}
