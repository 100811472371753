import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import moment from 'moment';
import './OtherTestResultSmsWindow.scss';
import { IoIosChatboxes } from 'react-icons/io';
import { observer, inject } from 'mobx-react';
import { FaTimes } from 'react-icons/fa';

@inject('locale')
@inject('otherTestResultSmsWindow')
@observer
class OtherTestResultSmsWindow extends Component {
  handleClose = () => {
    this.props.otherTestResultSmsWindow.init();
  };

  render() {
    const { locale } = this.props;
    const { smsType, resultList } = this.props.otherTestResultSmsWindow;
    return (
      <Modal className="OtherTestResultSmsWindow" isOpen={this.props.otherTestResultSmsWindow.open} toggle={this.handleClose} zIndex="940">
        <ModalHeader toggle={this.handleClose}>{smsType ? smsType.title : ''}</ModalHeader>
        <ModalBody className="py-0 px-2">
          {resultList.map((r) => (
            <Card key={r.idx} className="my-2">
              <CardHeader>
                <div className="d-flex flex-direction-row">
                  <div className="flex-grow-1">
                    <IoIosChatboxes /> {locale.fm('field.sms.list.sendDate')} {moment(r.sendDate).format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div className={r.curState === 3 ? (r.rsltCode === 100 ? 'text-success' : 'text-danger') : r.reportDate === null ? '' : 'text-primary'}>
                    {r.curState === 3
                      ? r.rsltCode === 100
                        ? locale.fm('value.success')
                        : `${locale.fm('value.failure')} : ${r.rsltDesc}`
                      : r.reportDate === null
                      ? locale.fm('value.sms.status.sending')
                      : locale.fm('value.sms.status.scheduling')}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <CardText>{r.msgText}</CardText>
              </CardBody>
            </Card>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleClose} size="sm">
            <FaTimes /> {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default OtherTestResultSmsWindow;
