import { observable, action } from 'mobx';
import moment from 'moment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler, numberToString, nullableNumber } from 'libs/Utils';

export default class CerviBoxEditStore {
  @observable open = false;
  @observable editIdx = null;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
  }

  @action
  show = (rows) => {
    if (!rows || rows.length !== 1) {
      toast.warn(this.locale.fm('comp.cervibox.vali.sel1box'));
      return;
    }

    this.editIdx = rows[0].idx;
    this.root.cervicoRequest.setInfo(rows[0]);

    this.open = true;
  };

  @action
  close = () => {
    this.editIdx = null;
    this.root.cervicoRequest.initPatInfo();
    this.open = false;
  };

  @action
  edit = async () => {
    const p = this.root.cervicoRequest.patinfo;

    //patinfo 검증
    if (p.patName.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.patname'));
      return;
    }
    if (p.chartNum.trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.chartnum'));
      return;
    }
    if ((p.socialNum1 + p.socialNum2).trim() === '') {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate'));
      return;
    }
    //생년월일 검사
    if (!moment(p.socialNum1.trim(), 'YYMMDD').isValid()) {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate2'));
      return;
    }

    //주민번호 padEnd
    const socialNum = (p.socialNum1 + p.socialNum2).trim().length > 0 ? p.socialNum1.trim().padEnd(6, '0') + p.socialNum2.trim().padEnd(7, '0') : '';

    //cervibox로 변환
    const cervibox = {
      patName: p.patName.trim(),
      chartNum: p.chartNum.trim(),
      socialNum: socialNum,
      age: numberToString(p.age),
      isPreg: p.isPreg === '' ? null : p.isPreg === 'true',
      g: nullableNumber(p.g),
      p: nullableNumber(p.p),
      a: nullableNumber(p.a),
      memo: p.memo.trim()
    };

    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.post(`/api/rq/cervibox/${this.editIdx}`, cervibox);

      if (response.data.success) {
        toast.success(this.locale.fm('store.cervibox.save'));
        this.root.cerviBox.triggerRefresh();
        this.close();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };
}
