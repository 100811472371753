import React, { Component } from 'react';
import { Input, InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class DropdownInput extends Component {
  inp = React.createRef();

  state = {
    value: this.props.value || '',
    dropdownOpen: false
  };

  handleMenuClick = (v) => {
    this.setState({
      dropdownOpen: false
    });
    this.handleChange({
      target: {
        value: v
      }
    });
  };

  handleToggleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value
    });
    if (this.props.onChange)
      this.props.onChange({
        target: {
          name: this.props.name,
          value: e.target.value
        }
      });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return {
        value: nextProps.value
      };
    } else {
      return null;
    }
  }

  render() {
    return (
      <InputGroup size={this.props.size} className={this.props.className}>
        <Input name={this.props.name} value={this.state.value} onChange={this.handleChange} innerRef={this.inp} />
        <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.handleToggleDropDown}>
          <DropdownToggle caret outline color="gray" />
          <DropdownMenu>
            {this.props.options.map((o, i) => (
              <DropdownItem key={i} onClick={() => this.handleMenuClick(o)}>
                {o}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </InputGroupButtonDropdown>
      </InputGroup>
    );
  }
}

export default DropdownInput;
