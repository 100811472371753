import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { MdFiberNew, MdMarkunreadMailbox, MdPlayCircleOutline } from 'react-icons/md';
import { CardMask } from 'components/common';
import axios from 'axios';
import moment from 'moment';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { inject } from 'mobx-react';
import Youtube from 'react-youtube';

import './Video.scss';
import Button from 'reactstrap/lib/Button';

function getVideoKey(url) {
  if (!url) return false;

  // youtube
  // const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);
  // const vKey = match && match[7].length === 11 ? match[7] : false;

  // vimeo
  const match = url.match(/player.vimeo.com\/video\/?([0-9]+)/i);
  const vKey = match ? match[1] : false;

  return vKey;
}

@inject('locale')
class Video extends Component {
  state = {
    video: [],
    mask: false,
    load: false,
    open: null
  };

  async componentDidMount() {
    this.setState({ mask: true });
    const res = await axios.get(`/api/rq/video`);
    this.setState({ mask: false, video: res.data.data, load: true });
  }

  componentWillUnmount() {}

  handleOpen = (idx) => {
    const video = this.state.video.map((v) => (v.idx === idx ? { ...v, readDate: true } : v));
    const open = video.find((v) => v.idx === idx);
    axios.get(`/api/rq/video/${idx}`); //조회여부 전송
    this.setState({ open: open, video: video });
  };

  handleClose = () => {
    this.setState({ open: null });
  };

  render() {
    const { locale } = this.props;
    return (
      <>
        <div className="container-fluid h-flex">
          <Card className="Video">
            <CardHeader>{locale.fm('menu.video')}</CardHeader>
            <CardBody>
              <ul>
                {this.state.video.map((v, i) => (
                  <Article v={v} key={i} handleOpen={this.handleOpen} />
                ))}
              </ul>
              {this.state.load && this.state.video.length === 0 ? <div className="none">{locale.fm('field.video.none')}</div> : ''}
            </CardBody>
            <CardMask show={this.state.mask} />
          </Card>
        </div>
        <PopupArticle v={this.state.open} handleClose={this.handleClose} />
      </>
    );
  }
}

const Article = ({ v, handleOpen }) => {
  const vKey = getVideoKey(v.url);
  const now = moment();
  const writeDate = moment(v.writeDate);

  const [videoThumb, setVideoThumb] = useState();
  useEffect(() => {
    async function handleThumbImg() {
      if (vKey) {
        const axiosInstance = axios.create({
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_VIMEO_TOKEN}`
          }
        });

        const url = `https://api.vimeo.com/videos/${vKey}`;
        const videoInfo = await axiosInstance.get(url);
        const thumbImgList = videoInfo.data.pictures.sizes;

        const imgSrc = thumbImgList[3].link;
        setVideoThumb(imgSrc);
      }
    }
    handleThumbImg();
  }, [vKey]);

  return vKey ? (
    <li>
      <Container fluid>
        <Row onClick={() => handleOpen(v.idx)}>
          <Col className="thumbnail text-center h-flex" lg="2">
            <div className="video-container">
              {/* youtube */}
              {/* <img src={`https://img.youtube.com/vi/${vKey}/mqdefault.jpg`} alt={v.title} className="thumb" /> */}

              {/* vimeo */}
              {videoThumb !== undefined && <img src={videoThumb} alt={v.title} className="thumb" />}
            </div>
            <Button outline={true} color="primary" size="lg">
              <MdPlayCircleOutline /> <FormattedMessage id="button.video" />
            </Button>
          </Col>
          <Col className="info" lg="10">
            <div className={cx('title', { read: v.readDate !== null })}>
              <MdMarkunreadMailbox className="text-primary unread" style={{ marginRight: '3px' }} />
              {writeDate.isAfter(now.add(-7, 'd')) ? <MdFiberNew className="text-danger" style={{ marginRight: '3px' }} /> : ''}
              {v.title}
            </div>
            <div className="synopsis">
              <span className="summary">{v.synopsis}</span>
            </div>
            <span>{writeDate.format('YYYY년 M월 D일 HH:mm')}</span>
          </Col>
        </Row>
      </Container>
    </li>
  ) : (
    ''
  );
};

// adblock 확장프로그램 설치경우 콘솔오류 발생함, 무시해도 될 듯
// 1. GET https://googleads.g.doubleclick.net/pagead/id net::ERR_BLOCKED_BY_CLIENT
// 2. GET https://static.doubleclick.net/instream/ad_status.js net::ERR_BLOCKED_BY_CLIENT
const PopupArticle = ({ v, handleClose }) => {
  const vKey = v ? getVideoKey(v.url) : false;
  const opts = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1
    }
  };

  return (
    <Modal size="lg" centered={true} isOpen={v !== null} toggle={handleClose} className="PopupModal video" zIndex="940">
      {v !== null ? (
        <>
          <ModalHeader toggle={handleClose}>{v.title}</ModalHeader>
          <ModalBody className="p-1">
            {/* youtube */}
            {/* <div className="video-container">{vKey ? <Youtube videoId={vKey} opts={opts} /> : ''}</div> */}

            {/* vimeo */}
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src={v.url}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title={v.title}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </ModalBody>
        </>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default Video;
