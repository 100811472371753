import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import { confirm } from 'libs/Utils';
import { setMessageListener } from 'libs/Fcm';
import { toast } from 'react-toastify';
import cookies from 'browser-cookies';
import moment from 'moment';

export default class ScreenStore {
  //privateRoute 에서 설정함
  history = null;

  @observable fullscreen = false;
  @observable progressLayer = null;
  @observable processLayer = false;
  @observable authChecked = false;
  @observable popup = [];
  @observable unreadNoticeCount = 0;
  @observable unreadVideoCount = 0;
  @observable unreadUpdateRelease = [];

  //quit confirm 채크용 observable 아님
  quitConfirmOpened = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;

    if (window.rq) window.rq.setScreenStore(this);
    if (!window.rq) setMessageListener(this.root);
  }

  @action
  setFullscreen = (stat) => {
    this.fullscreen = stat;
  };

  @action
  setProcessLayer = (stat) => {
    this.processLayer = stat;
  };

  @action
  startProgressLayer = (title, cur, tot, forUpload = false) => {
    if (forUpload) this.history.push('/request');
    this.progressLayer = { title, cur, tot };
    this.root.pictureList.setUploadCnt(tot);
  };

  confirm = (msg) => {
    return confirm(msg);
  };

  confirmQuit = async () => {
    if (this.quitConfirmOpened === false) {
      this.quitConfirmOpened = true;
      const res = await confirm(this.locale.fm('store.screen.quit'));
      this.quitConfirmOpened = false;
      return res;
    }
  };

  alert = (type, msg) => {
    toast(msg, {
      type: type
    });
  };

  @action
  href = (url) => {
    //현재 url 하고 비교
    if (window.location.pathname.indexOf(url) === 0) {
      if (url === '/request') this.root.pictureList.getList();
    } else {
      this.history.push(url);
    }
  };

  @action
  changeProgressLayer = (cur) => {
    this.progressLayer.cur = cur;
  };

  @action
  endProgressLayer = (msg, success = true, forUpload = false, invalidImg = false) => {
    if (success) {
      if (invalidImg === false) {
        toast.success(msg);
      } else {
        toast.warn(msg, { autoClose: false, bodyClassName: 'ws-pre' });
      }
      if (forUpload) {
        this.root.pictureList.getList();
        this.root.pictureList.getPreprocessingCount();
      }
    } else {
      toast.error(msg);
    }
    this.progressLayer = null;
  };

  @action
  setAuthChecked = (stat) => {
    this.authChecked = stat;
  };

  @action
  checkPopup = async () => {
    try {
      const response = await axios.get('/api/rq/notice/popup');
      runInAction('ScreenStore : async checkPopup -> success', () => {
        this.popup = response.data.popup.filter((p) => !cookies.get(`popup-${p.idx}`));
        this.popup.forEach((p) => cookies.set(`popup-${p.idx}`, 'true', { expires: moment().endOf('day').toDate() }));
        this.unreadNoticeCount = response.data.notice;
        this.unreadVideoCount = response.data.video;
        this.unreadUpdateRelease = response.data.update.filter((u) => !cookies.get(`update-${u}`));
        this.unreadUpdateRelease.forEach((u) => cookies.set(`update-${u}`, 'ture', { expires: moment().add(7, 'day').endOf('day').toDate() }));
      });
    } catch (error) {
      //dO nothing
    }
  };
}
