import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Button, Input } from 'reactstrap';
import { PictureBox } from 'components/common';
import cx from 'classnames';
import CervicoResultTable from './CervicoResultTable';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { toggleFullscreen } from 'libs/Screen';

import { FaArrowCircleLeft, FaArrowCircleRight, FaTimesCircle, FaArrowLeft, FaExpand, FaCompress, FaPrint, FaQuestionCircle, FaCloudDownloadAlt } from 'react-icons/fa';

import './CervicoResultWindow.scss';

function completeFilter(r) {
  return r.statusCode > 6;
}

@inject('locale')
@inject('screen')
@inject('cervicoResult')
@inject('cervicoResultWindow')
@withRouter
@observer
class CervicoResultWindow extends Component {
  el = React.createRef();
  reportType = React.createRef();

  handleFullscreen = () => {
    toggleFullscreen(this.el.current);
  };

  handlePictureClick = () => {
    this.props.cervicoResultWindow.zoom();
  };

  handlePrintClick = () => {
    //필터링
    const l = this.props.cervicoResultWindow.resultList.filter(completeFilter).map((r) => r.idx);
    if (l.length === 0) {
      toast.warn(this.props.locale.fm('store.cervicoRequest.fail.print'));
    } else {
      this.props.cervicoResult.print(l, this.reportType.current.value);
    }
  };

  handleDownloadClick = () => {
    this.props.cervicoResultWindow.download(this.reportType.current.value);
  };

  handlePictureContextMenu = (e) => {
    e.preventDefault();
    this.props.cervicoResultWindow.init();
  };

  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.cervicoResultWindow.init();
    }
  }

  componentDidMount() {
    //TODO keyboard 이벤트 bind
  }

  componentWillUnmount() {
    //TODO keyboard 이벤트 unbind
  }

  render() {
    const { locale, screen, cervicoResultWindow } = this.props;

    if (cervicoResultWindow.show) {
      const { result } = cervicoResultWindow;

      return (
        <div className="CervicoResultWindow layer-window" ref={this.el}>
          <div className="area-back-button">
            <Button size="sm" onClick={cervicoResultWindow.init}>
              <FaArrowLeft />
            </Button>
          </div>
          <div className="area-picture" onClick={this.handlePictureClick}>
            <PictureBox
              contain
              src={`/api/rq/cervico/worklist/${result.idx}/picture/${cervicoResultWindow.pictureIndex + 1}?ts=${result.imgUpdDate ? result.imgUpdDate : ''}`}
              onContextMenu={this.handlePictureContextMenu}
            />
          </div>
          <div className="area-info h-flex">
            <div className="button-box justify-content-end">
              <span className="d-none d-md-block">
                <FaQuestionCircle /> <FormattedMessage id="comp.dialog.closeInfo" />
              </span>
              {/* <Button size="sm" onClick={this.handleFullscreen}>
                {screen.fullscreen ? <FaCompress /> : <FaExpand />}
              </Button> */}
              <Button size="sm" onClick={cervicoResultWindow.init}>
                <FaTimesCircle />
              </Button>
            </div>
            <div className="button-box">
              <Button size="sm" onClick={cervicoResultWindow.prevResult} disabled={cervicoResultWindow.isFirstResult}>
                <FaArrowCircleLeft />
              </Button>
              <div>{cervicoResultWindow.position}</div>
              <Button size="sm" onClick={cervicoResultWindow.nextResult} disabled={cervicoResultWindow.isLastResult}>
                <FaArrowCircleRight />
              </Button>
            </div>
            <CervicoResultTable result={result} />
            <ul className="area-picture-list">
              {[...Array(parseInt(result.imgCount))].map((_, i) => (
                <div key={i} className={cx('picture', { active: i === cervicoResultWindow.pictureIndex })} onClick={() => cervicoResultWindow.setPictureIndex(i)}>
                  <PictureBox small src={`/api/rq/cervico/worklist/${result.idx}/picture/${i + 1}?width=150&ts=${result.imgUpdDate ? result.imgUpdDate : ''}`} />
                </div>
              ))}
            </ul>
            <div className="area-print">
              <Input type="select" bsSize="sm" innerRef={this.reportType}>
                <option value="def">{locale.fm('value.printtype.standard')}</option>
                <option value="reKo">{locale.fm('value.printtype.bigPicLocal')}</option>
                <option value="reEn">{locale.fm('value.printtype.bigPicEng')}</option>
              </Input>
              <Button size="lg" block color="primary" onClick={this.handlePrintClick} disabled={cervicoResultWindow.completeCount === 0}>
                <FaPrint /> <FormattedMessage id="button.res.print" />
              </Button>
              <Button block color="secondary" onClick={this.handleDownloadClick} disabled={cervicoResultWindow.completeCount === 0}>
                <FaCloudDownloadAlt /> <FormattedMessage id="button.res.downloadRptImg" />
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  }
}

export default CervicoResultWindow;
