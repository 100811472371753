import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { MdFolderSpecial } from 'react-icons/md';
import { PatForm } from 'components/cervico';

@inject('locale')
@inject('cerviBoxEdit')
@observer
class CerviBoxEditWindow extends Component {
  handleEdit = () => {
    this.props.cerviBoxEdit.edit();
  };

  handleClose = () => {
    this.props.cerviBoxEdit.close();
  };

  render() {
    const { locale } = this.props;

    return (
      <Modal className="cerviBoxEditWindow" isOpen={this.props.cerviBoxEdit.open} toggle={this.handleClose} size="lg" zIndex="940">
        <ModalHeader toggle={this.handleClose}>
          <MdFolderSpecial /> {locale.fm('comp.cervibox.title')}
        </ModalHeader>
        <ModalBody>
          <PatForm useCerviBoxMemoYn />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleEdit} size="sm" style={{ width: '100px' }}>
            <FaEdit /> {locale.fm('button.modify')}
          </Button>
          <Button color="secondary" onClick={this.handleClose} size="sm" style={{ width: '100px' }}>
            <FaTimes /> {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CerviBoxEditWindow;
