import { observable, action, computed, set, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { phonePattern } from 'libs/Utils';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import moment from 'moment';
//import { FormattedMessage } from 'react-intl';

function chkByte(strValue) {
  const strLen = strValue.length;
  let totalByte = 0;
  let oneChar = '';

  for (var i = 0; i < strLen; i++) {
    oneChar = strValue.charAt(i);
    if (escape(oneChar).length > 4) {
      totalByte += 2;
    } else {
      totalByte++;
    }
  }
  return totalByte;
}

export default class OtherSmsSendStore {
  @observable message = '';
  @observable reserve = false;
  @observable reserveDatetime = null;
  @observable show = false;
  @observable retest = false;

  @observable phoneList = [];

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.message = '';
    this.reserve = false;
    this.reserveDatetime = null;
    this.phoneList = [];
  };

  @action
  toggleShow = () => {
    this.show = !this.show;
  };

  @action
  setShow = (show) => {
    this.show = show;
  };

  @action
  setMessage = (value) => {
    if (chkByte(value) > 1000) return;
    this.message = value;
  };

  @computed
  get messageByte() {
    return chkByte(this.message);
  }

  @action
  setReserve = (value) => {
    this.reserve = value;
    if (value === false) {
      this.reserveDatetime = null;
    }
  };

  @action
  setReserveDatetime = (value) => {
    this.reserveDatetime = value;
  };

  @action
  setRetest = (checked) => {
    this.retest = checked;
  };

  @action
  initPhone = () => {
    this.phoneList = [];
  };

  @action
  addPhoneList = (addedPhoneList) => {
    const newPhoneList = addedPhoneList
      .filter((w) => !this.phoneList.some((r) => r.reportId === w.reportId))
      .map((w) => {
        return { ...w, phoneValid: false };
      });
    this.phoneList = [...this.phoneList, ...newPhoneList];
  };

  @action
  removePhoneList = (removedPhoneList) => {
    if (removedPhoneList.length === 0) {
      toast.warn(this.locale.fm('store.smsSend.vali.delReceiver.selectMore1'));
    }
    this.phoneList = this.phoneList.filter((w) => !removedPhoneList.some((r) => r.idx === w.idx));
  };

  @computed
  get phoneCount() {
    return this.phoneList.length;
  }

  @action
  send = async () => {
    const reportType = this.root.otherTest.search.reportType;

    if (reportType !== 'COVID' && this.message.trim() === '') {
      toast.warn(this.locale.fm('store.smsSend.vali.emptyMsg'));
      return;
    }

    //전화번호 모두 입력했는지 검증
    if (this.phoneList.length === 0) {
      toast.warn(this.locale.fm('store.smsSend.vali.emptyReceiverList'));
      return;
    }
    if (this.phoneList.some((p) => phonePattern.test(p.phone) === false)) {
      toast.warn(this.locale.fm('store.smsSend.vali.emptyReceiverNumber'));
      return;
    }

    //예약 전송일 경우 익일인지 채크함
    if (this.reserve) {
      if (this.reserveDatetime == null) {
        toast.warn(this.locale.fm('store.smsSend.vali.emptySchedule'));
        return;
      }
      if (moment(this.reserveDatetime) < moment().startOf('day').add(1, 'days')) {
        toast.warn(this.locale.fm('store.smsSend.vali.scheduleAtLeastTomorrow'));
        return;
      }
    }

    //body
    const body = {
      reportType: reportType,
      message: reportType === 'COVID' ? 'covid' : this.message,
      rsvDate: this.reserveDatetime,
      retest: this.retest
    };

    let url = '';

    if (body.reportType === 'Cervico') {
      body['phoneList'] = this.phoneList.map((w) => {
        return { idx: w.idx, phone: w.phone };
      });
      url = '/api/rq/sms';
    } else {
      body['phoneList'] = this.phoneList.map((w) => {
        return { reportId: w.reportId, phone: w.phone };
      });
      url = '/api/rq/smsOther';
    }

    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.put(url, body);
      if (response.data.success) {
        this.init();
        this.root.otherTest.triggerRefresh();
        toast.success(this.locale.fm('store.smsSend.sendSuccess'));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };

  @action
  addTemplate = () => {
    if (this.messageByte === 0) {
      toast.warn(this.locale.fm('store.smsSend.vali.emptyMsg'));
      return;
    }
    this.root.smsTemplate.add(this.message);
  };
}
