import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PictureListCardForAndroid from './PictureListCardForAndroid';
import CerviAiResultWindowWithoutPatInfoForAndroid from './CerviAiResultWindowWithoutPatInfoForAndroid';
import ZoomWindowForAndroid from './ZoomWindowForAndroid';

import './CervicoAndroid.scss';
import { CardMask } from 'components/common';
import { toast } from 'react-toastify';

@inject('locale')
@inject('cervicoRequest')
@inject('auth')
@inject('pictureList')
@inject('cerviBoxAdd')
@inject('cerviBox')
@observer
class CervicoAndroid extends Component {
  state = {
    reqAIAnaly: false,
    reqDetail: false
  };
  handleRequestClick = (e) => {
    this.setState({
      reqAIAnaly: false,
      reqDetail: false
    });
    e.preventDefault();
    const reqConfirmYN = this.props.auth.doctor.reqConfirmYN;
    if (reqConfirmYN) {
      this.props.cervicoRequest.request(true, false, false, true, true);
    } else {
      this.props.cervicoRequest.request(true, false, false, true);
    }
  };

  handleDeferRequestClick = (e) => {
    e.preventDefault();
    this.props.cervicoRequest.request(false);
  };

  handleRequestCerviAiClick = (e) => {
    this.setState({
      reqAIAnaly: true,
      reqDetail: false
    });
    // Todo: ai분석 클릭시 실행
    e.preventDefault();
    const reqConfirmYN = this.props.auth.doctor.reqConfirmYN;
    if (reqConfirmYN) {
      this.props.cervicoRequest.requestCerviAi(true, true);
    } else {
      this.props.cervicoRequest.requestCerviAi(true);
    }
  };

  handleDetailRequestClick = (e) => {
    this.setState({
      reqAIAnaly: false,
      reqDetail: true
    });

    e.preventDefault();
    const reqConfirmYN = this.props.auth.doctor.reqConfirmYN;
    if (reqConfirmYN) {
      this.props.cervicoRequest.request(true, false, false, true, true, true);
    } else {
      this.props.cervicoRequest.request(true, false, false, true, false, true);
    }
  };

  handleCerviboxAddClick = () => {
    const list = this.props.pictureList.group.reduce((a, c) => [...a, ...c.list], []).filter((e) => e.checked);
    if (list.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
      return;
    }
    this.props.cerviBoxAdd.show(list);
  };

  render() {
    const { cervicoRequest } = this.props;
    return (
      <div className="CervicoAndroid">
        <div className="h-flex">
          <PictureListCardForAndroid />
        </div>
        <CardMask show={cervicoRequest.mask} />
        <CerviAiResultWindowWithoutPatInfoForAndroid />
        <ZoomWindowForAndroid />
      </div>
    );
  }
}

export default CervicoAndroid;
