import { observable, action, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { toast } from 'react-toastify';
import { searchSerialize } from 'libs/Utils';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import cookies from 'browser-cookies';

const defaultSearch = {
  startDate: null,
  endDate: null,
  patName: '',
  chartNum: '',
  socialNum: '',
  scope: 'my'
};

const defaultPatInfoFormData = {
  patName: '',
  chartNum: '',
  socialNum1: '',
  socialNum2: '',
  age: '',
  g: '',
  p: '',
  a: '',
  comment: '',
  classResult: '',
  rqDspResult: ''
};

const defaultDataGrid = {
  rows: [],
  page: 1,
  totalPage: 1,
  total: 0
};

const defaultPreview = {
  mask: false,
  idx: null,
  patName: null,
  chartNum: null,
  age: null,
  birthDay: null,
  regDate: null,
  isPreg: null,
  g: null,
  p: null,
  a: null,
  comment: null,
  aiResultList: [],
  classResult: null
};

const defaultCsGuideData = {
  cs: 0,
  ai: '',
  aiWeight: 0,
  csaicnt: 0,
  a: 0,
  p2: 0,
  n: 0,
  p1pct: 0,
  npct: 0,
  p1: 0,
  apct: 0,
  p2pct: 0
};

const defaultSelectData = {
  ai: '',
  prob: 0
};

function convertModel(a) {
  return Object.assign(
    { ...a },
    {
      checked: false,
      url: `/api/rq/cerviai/${a.idx}/picture`
    }
  );
}

function buildGroupReducer(acc, row, i) {
  const p = Object.assign({ ...row }, { checked: false });
  acc.push(p);
  return acc;
}

export default class CerviAiStore {
  @observable dataGrid = { ...defaultDataGrid };
  @observable search = { ...defaultSearch };
  @observable mask = false;
  @observable selectAll = false;
  @observable preview = { ...defaultPreview };
  @observable currentPic = 0;
  @observable csGuideData = { ...defaultCsGuideData };
  @observable viewCsGuide = false;
  @observable selectData = { ...defaultSelectData };

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();

    // dialog용
    this.reqDialog = new RequestWindowStore(root, this);
  }

  //초기화
  @action
  init = () => {
    this.dataGrid = { ...defaultDataGrid };
    this.initSearch();
    this.initGroup();
    this.initPreview();
    this.setMask(false);
    this.csGuideData = { ...defaultCsGuideData };
    this.viewCsGuide = false;
    this.selectData = { ...defaultSelectData };
  };

  @action
  initSearch = () => {
    const scope = localStorage.getItem('aiResultSearchScope');
    const cookie = JSON.parse(cookies.get('aiResultSearch'));

    this.search = Object.assign(
      { ...defaultSearch },
      {
        startDate: cookie !== null ? moment(cookie.startDate).toDate() : moment().startOf('day').add(-1, 'month').toDate(),
        endDate: cookie !== null ? moment(cookie.endDate).toDate() : moment().startOf('day').toDate(),
        scope: scope !== null && scope === 'all' ? scope : 'my'
      }
    );
  };

  @action
  initGroup = () => {
    this.group = [];
  };

  @action
  initPreview = () => {
    this.preview = { ...defaultPreview };
  };

  @action
  setPreview = (idx) => {
    this.setMask(true);

    const p = this.dataGrid.rows.find((r) => r.idx === idx);
    this.preview = p;

    this.setMask(false);
  };

  @action
  setMask = (m) => {
    this.mask = m;
  };

  /*   @action
  getCurrentPic = () => {
    return this.currentPic;
  }; */

  @action
  setCurrentPic = (pIdx) => {
    this.currentPic = pIdx;
  };

  @action
  triggerSearch = async () => {
    await this.loadDataGrid();
  };

  @action
  triggerReset = async () => {
    this.init();
    await this.loadDataGrid();
  };

  @action
  triggerRefresh = async (selIdx = null) => {
    this.initPreview();
    await this.loadDataGrid();
    if (selIdx) this.setPreview(selIdx);
  };

  @action
  selectImg = (idx, checked) => {
    this.preview.aiResultList[idx].checked = checked;
  };

  @action
  selectRequest = () => {
    const wIdxs = this.selectedWorklistIdxList();
    const rIdxs = this.selectedRqUploadIdxList();

    if (wIdxs.length > 0) {
      toast.warn(this.locale.fm('store.cervicoRequest.fail.aleadyRequested'));
      return;
    }

    if (wIdxs.length + rIdxs.length === 0 || wIdxs.length + rIdxs.length > 4) {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.picture'));
      return;
    }

    if (this.preview.isRequest) {
      toast.warn(this.locale.fm('store.cervicoRequest.fail.aleadyRequested'));
      return;
    }

    // 환자정보 세팅
    this.root.cervicoRequest.init();
    this.root.cervicoRequest.setInfo(this.preview, 'AI');

    this.reqDialog.open();
  };

  @action
  setDataGrid = (data) => {
    this.dataGrid = data;
  };

  @action
  loadDataGrid = async (page = 1) => {
    this.mask = true;
    this.normalizeSearch();
    localStorage.setItem('aiResultSearchScope', this.search.scope === 'my' ? 'my' : 'all');
    cookies.set('aiResultSearch', JSON.stringify({ startDate: this.search.startDate, endDate: this.search.endDate }), { expires: 0 });

    try {
      const response = await axios.get('/api/rq/cerviai', {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('CerviAiStore : async loadDataGrid -> success', () => {
        this.setDataGrid(response.data);
        this.mask = false;
      });
    } catch (error) {
      runInAction('CerviAiStore : async loadDataGrid -> catch', () => {
        this.mask = false;
        ajaxErrorHandler(error);
      });
    }
  };

  @action
  normalizeSearch = () => {
    //param 검증
    const s = this.search;
    if (!moment(s.startDate).isValid()) s.startDate = null;
    if (!moment(s.endDate).isValid()) s.endDate = null;
    if (s.startDate !== null && s.endDate === null) s.endDate = s.startDate;
    if (s.startDate === null && s.endDate !== null) s.startDate = s.endDate;
    if (s.startDate > s.endDate) s.endDate = s.startDate;
  };

  //mode : 의뢰사진 req, 결과사진 res, 서비박스 box, 재분석 re
  @action
  analy = async (idxs, mode) => {
    const len = idxs.length;

    if (len && len > 0) {
      if (mode === 'req' && len > 20) {
        toast.warn(this.locale.fm('store.cerviai.noMore20'));
        return;
      }

      if (mode === 'res' && len > 5) {
        toast.warn(this.locale.fm('store.cerviai.noMore5'));
        return;
      }

      try {
        await axios.put(`/api/rq/cerviai/analy-${mode}`, idxs);
        toast.success(this.locale.fm('store.cerviai.success'));
      } catch (error) {
        ajaxErrorHandler(error);
      }
    } else {
      toast.warn(this.locale.fm('store.cerviai.noReSel'));
    }
  };

  @action
  rePreprocess = async (idxs) => {
    const len = idxs.length;

    if (len && len > 0) {
      if (len > 1000) {
        toast.warn(this.locale.fm('store.cerviai.noMore1000'));
        return;
      }

      try {
        const res = await axios.put(`/api/rq/cerviai/re-preprocess`, idxs);
        if (res.data.success) {
          toast.success(this.locale.fm('store.cerviai.preprocessing.success'));

          this.root.pictureList.setUploadCnt(len);
          this.root.pictureList.getPreprocessingCount();
        }
      } catch (error) {
        ajaxErrorHandler(error);
      }
    } else {
      toast.warn(this.locale.fm('store.preprocess.noReSel'));
    }
  };

  @action
  changeSearch = (key, value) => {
    this.search[key] = value;
  };

  @action
  setListAsGroup = (rows) => {
    this.preview.aiResultList.replace(rows.reduce(buildGroupReducer, []));
  };

  @action
  deSelectAll = () => {
    this.group.forEach((g) => this.selectGroup(g.idx, false));
  };

  @action
  selectedRqUploadIdxList = () => {
    return this.preview.aiResultList
      .filter((img) => img.checked)
      .map((el) => {
        return el.rqUploadIdx;
      });
  };

  @action
  selectedWorklistIdxList = () => {
    return this.preview.aiResultList.reduce((a, c) => {
      if (c.checked && c.worklistIdx !== null) {
        a.push(c.worklistIdx);
      }
      return a;
    }, []);
  };

  @action
  refreshOne = async (gIdx, idx) => {
    try {
      const response = await axios.get(`/api/rq/cerviai/status/${idx}`);
      if (response.data.success) {
        runInAction('CerviAiStore : async refreshOne -> success', () => {
          const index = this.getGroup(gIdx).list.findIndex((p) => p.idx === idx);
          if (response.data.data.isCompleted > 1) {
            this.getGroup(gIdx).list[index] = convertModel(response.data.data);
          }
        });
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
  };

  @action
  setPreviewBox = (idx) => {
    const p = this.dataGrid.rows.find((r) => r.idx === idx);

    this.preview = p;
    if (p.aiResultList) {
      this.setListAsGroup(p.aiResultList);
    } else {
      this.setListAsGroup([]);
    }
    this.currentPic = 0;
  };

  @action
  openScGuide = async (c, p) => {
    try {
      const response = await axios.get(`/api/rq/cerviai/getData`);
      if (response.data.success) {
        runInAction('CerviAiStore : async openScGuide -> success', () => {
          this.csGuideData = response.data.data;
          this.viewCsGuide = true;
          this.selectData = { ai: c, prob: p };
        });
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
  };

  @action
  closeCsGuide = () => {
    this.viewCsGuide = false;
  };

  @action
  loadCsGuideDataGrid = async (page = 1) => {
    this.mask = true;
    try {
      const response = await axios.get(`/api/rq/cerviai/getData`);
      if (response.data.success) {
        runInAction('CerviAiStore : async openScGuide -> success', () => {
          this.csGuideData = response.data.data;
          this.viewCsGuide = true;
          this.mask = false;
        });
      }
    } catch (error) {
      this.mask = false;
      ajaxErrorHandler(error);
    }
  };
}

class RequestWindowStore {
  @observable form = {
    open: false,
    data: { ...defaultPatInfoFormData }
  };

  constructor(root, container) {
    this.root = root;
    this.locale = root.locale;
    this.container = container;
  }

  @action
  open() {
    this.form.open = true;
  }

  @action
  close() {
    this.root.cervicoRequest.initPatInfo();
    this.form.open = false;
  }
}
