import { observable, action } from 'mobx';
import { toast } from 'react-toastify';

export default class SettingStore {
  @observable setting = {};

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init() {
    if (window.rq) {
      this.setting = { ...window.rq.loadConfig() };
    }
  }

  @action
  change(n, v) {
    this.setting[n] = v;
  }

  @action
  save() {
    //검증
    const s = this.setting;
    if (('' + s.listenPort).trim() === '') {
      toast.warn(this.locale.fm('store.setting.vali.portEmpty'));
      return;
    } else {
      s.listenPort = parseInt(s.listenPort);

      if (s.listenPort < 1024 || s.listenPort > 10000) {
        toast.warn(this.locale.fm('store.setting.vali.portNum'));
        return;
      }
    }

    if (s.usbDir.trim() === '') {
      toast.warn(this.locale.fm('store.setting.vali.folderEmpty'));
      return;
    }
    if (window.rq) {
      window.rq.saveConfig(this.setting);
    }
    //console.log(this.setting);
    toast.success(this.locale.fm('store.setting.saveSuccess'));
  }

  async clearCache() {
    const res = await window.rq.clearCache();
    if (res) toast.success(this.locale.fm('store.setting.cacheDelSuccess')); //사실 호출 안됨. 즉시종료..
    else toast.error(this.locale.fm('store.setting.cacheDelFailure'));
  }
}
