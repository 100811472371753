import React, { Component } from 'react';
import { Button, Form, Input, InputGroup, InputGroupAddon, Row, Col, FormGroup, Label } from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import Logo from 'resources/cervicareLogo.svg';
import { FaUserAlt, FaKey, FaSignInAlt } from 'react-icons/fa';
import { MdLanguage } from 'react-icons/md';
import './Login.scss';

import serialize from 'form-serialize';
import packageJson from '../../../package.json';
//눈 효과
import Snowflakes from 'magic-snowflakes';

@inject('auth')
@inject('locale')
@withRouter
@observer
class Login extends Component {
  form = React.createRef();

  afterLoginUrl = '';
  sf = null;

  handleLogin = async (e) => {
    e.preventDefault();
    const fd = serialize(this.form.current, { hash: true, empty: true });
    if (fd.userid === '' || fd.password === '') return false;

    const { auth, history } = this.props;
    const success = await auth.login(fd);
    if (success) {
      history.push(this.afterLoginUrl);
    } else {
    }
  };

  handleChangeLanguage = () => {};

  componentDidMount() {
    //뒤로가기 금지
    const { history, location } = this.props;

    //location.state 가 있는 경우에는 (private router 에서 보낸경우) 해당 경로 설정
    this.afterLoginUrl = location.state ? location.state.from : '/';

    history.push(location.pathname);
    window.onpopstate = () => {
      history.go(1);
    };

    //12월부터 1월까지에만 눈효과 실행
    const isDecemberOrJanuary = new Date().getMonth() >= 11 || new Date().getMonth() <= 0;
    this.sf = isDecemberOrJanuary ? new Snowflakes({ color: 'white', count: 30, maxOpacity: 0.6 }) : null;
  }

  componentWillUnmount() {
    //눈효과
    if (this.sf) {
      this.sf.destroy();
    }
  }

  render() {
    const { locale, auth } = this.props;

    return (
      <div className="Login d-flex align-items-center">
        <div className="version-box">
          <div>{locale.fm('field.Notation')}</div>
          <div>(01)8809909010019 (21)</div>
          <div>
            version : {packageJson.version} ({auth.checksum})
          </div>
          <div>{process.env.NODE_ENV === 'development' ? `debug : ${process.env.REACT_APP_SERVER_TYPE}, ${process.env.REACT_APP_FCM_PROJECT_ID}` : ''}</div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card p-4">
                <div className="card-body">
                  <div className="text-center p-3">
                    <img src={Logo} alt="NTL" className="logo-img" />
                  </div>
                  <h3 className="text-center">Cervical cancer diagnosis platform based on AI</h3>

                  <p className="text-muted">
                    <FormattedMessage id="comp.login.message" />
                  </p>
                  <Form onSubmit={this.handleLogin} innerRef={this.form}>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <FaUserAlt />
                        </span>
                      </InputGroupAddon>
                      <Input name="userid" placeholder={locale.fm('field.id')} tabIndex="1" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <FaKey />
                        </span>
                      </InputGroupAddon>
                      <input name="password" type="password" placeholder={locale.fm('field.password')} tabIndex="2" className="form-control" autoComplete="new-password" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <MdLanguage />
                        </span>
                      </InputGroupAddon>
                      <Input type="select" value={locale.value} onChange={(event) => locale.changeLocale(event.target.value)} tabIndex="3">
                        <option value="ko">한국어</option>
                        <option value="en">English</option>
                      </Input>
                    </InputGroup>
                    <div className="row">
                      <div className="col-12 text-center">
                        <Button color="primary" block tabIndex="4">
                          <FaSignInAlt /> <FormattedMessage id="button.login" />
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
