import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { ButtonGroup, Button, Card, CardHeader, CardBody, CustomInput, CardFooter, Alert } from 'reactstrap';
import { PictureBox, CardMask, CustomPopover } from 'components/common';
import debounce from 'lodash.debounce';
import moment from 'moment';
import cx from 'classnames';

import { FormattedMessage } from 'react-intl';
import { IoMdRefresh } from 'react-icons/io';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import { FaRegCheckSquare, FaRegSquare, FaTrash, FaCloudDownloadAlt, FaCloudUploadAlt, FaUserAstronaut, FaBox, FaPhotoVideo } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Eligible from 'resources/warning.png';
import Completed from 'resources/error.png';

import './PictureListCard.scss';

@inject('pictureList')
@inject('cerviBoxAdd')
@inject('cerviAi')
@inject('auth')
@inject('locale')
@observer
class PictureListCard extends Component {
  constructor(props) {
    super(props);

    this.intervalId = null;
    this.isRequestAI = false;

    this.uploadIntervalId = null;
  }

  handleUpload = (e) => {
    this.props.pictureList.upload(e.target.files);
    e.target.value = '';
  };

  handleCerviAiClick = debounce(async () => {
    const list = this.props.pictureList.group.reduce((a, c) => [...a, ...c.list], []).filter((e) => e.checked);

    if (list.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
      return;
    } else if (list.some((g) => g.aiStatus === 3)) {
      toast.warn(this.props.locale.fm('comp.cerviai.vali'));
      return;
    }
    const pl = list.map((el) => {
      return el.idx;
    });

    await this.props.cerviAi.analy(pl, 'req');
    this.props.pictureList.getList();

    this.isRequestAI = true;
  }, 500);

  handleRePreprocess = debounce(async () => {
    const list = this.props.pictureList.group.reduce((a, c) => [...a, ...c.list], []).filter((e) => e.checked);

    if (list.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
      return;
    }

    const pl = list
      .filter((el) => el.isCompleted === false)
      .map((el) => {
        return el.idx;
      });

    await this.props.cerviAi.rePreprocess(pl);
  }, 500);

  handlePreprocessing = () => {
    this.props.pictureList.getPreprocessingCount();
  };

  handleRefreshBtn = async () => {
    this.resetUploadInterval();

    await this.props.pictureList.getList();
    this.handlePreprocessing();
  };

  handleRefresh = () => {
    this.props.pictureList.getList();
  };

  resetInterval = () => {
    clearInterval(this.intervalId);
    this.intervalId = null;
  };

  resetUploadInterval = () => {
    clearInterval(this.uploadIntervalId);
    this.uploadIntervalId = null;
  };

  componentDidMount = () => {
    this.handleRefresh();
    this.handlePreprocessing();
  };

  componentDidUpdate = (prevProps) => {
    if (this.isRequestAI) {
      this.intervalId = setInterval(() => {
        this.handleRefresh();
      }, 3000);

      if (prevProps.pictureList.reFreshAiStatus) {
        this.isRequestAI = false;
      }
    }

    if (!prevProps.pictureList.reFreshAiStatus && this.intervalId) {
      this.resetInterval();
    }

    const isProcessing = this.props.pictureList.preprocessingTotal > 0 && this.props.pictureList.preprocessingTotal !== this.props.pictureList.preprocessingCompleted;
    if (isProcessing && this.uploadIntervalId === null) {
      // const intervalTime = this.props.pictureList.uploadCnt <= 20 ? 2000 : this.props.pictureList.uploadCnt * 100;
      const intervalTime = this.props.pictureList.uploadCnt <= 20 ? 2000 : 10000;

      this.uploadIntervalId = setInterval(() => {
        this.handlePreprocessing();
      }, intervalTime);
    }

    if (!prevProps.pictureList.reFreshPreprocessingStatus && this.uploadIntervalId) {
      this.resetUploadInterval();
      this.handleRefresh();
    }
  };

  componentWillUnmount = () => {
    this.resetInterval();
    this.resetUploadInterval();
  };

  render() {
    const { pictureList, auth, locale } = this.props;
    const { popoverTarget, popoverPicture, setPopover, preprocessingTotal, preprocessingCompleted } = pictureList;

    return (
      <>
        <Card className="PictureListCard basis-300">
          <CardHeader className="card-toolbar d-flex flex-wrap align-items-center">
            <Button color="warning" onClick={pictureList.selectChange} size="sm">
              {pictureList.selectAll ? <FaRegCheckSquare /> : <FaRegSquare />} <FormattedMessage id="button.select" />
            </Button>
            <Button size="sm" color="warning" style={{ color: 'white' }} className="mr-auto" onClick={this.handleRePreprocess}>
              <FaPhotoVideo /> <FormattedMessage id="button.rePreprocess" />
            </Button>
            {preprocessingTotal !== preprocessingCompleted && (
              <span>
                {preprocessingCompleted} / {preprocessingTotal}
              </span>
            )}
            <Button size="sm" color="primary" onClick={this.handleRefreshBtn}>
              <IoMdRefresh /> <FormattedMessage id="button.reload" />
            </Button>
            <Button size="sm" color="darkgray" onClick={pictureList.sortChange}>
              {pictureList.sortDesc ? <TiArrowSortedDown /> : <TiArrowSortedUp />} <FormattedMessage id="button.sortDate" />
            </Button>
            <Button size="sm" color="danger" onClick={pictureList.removeSelected}>
              <FaTrash /> <FormattedMessage id="button.removeSelected" />
            </Button>
            {auth.doctor.useAI && auth.doctor.useQuickAI ? (
              <Button size="sm" color="sunsetOrange" style={{ color: 'white' }} onClick={this.handleCerviAiClick}>
                <FaUserAstronaut /> <FormattedMessage id="button.quickAi" />
              </Button>
            ) : (
              ''
            )}

            <ButtonGroup size="sm">
              {/*
            <Button color="success" onClick={pictureList.downloadSelected}>
              <FaCloudDownloadAlt /> <FormattedMessage id="button.download" />
            </Button>
            */}
              <Button size="sm" color="success" tag="a" style={{ color: 'white' }}>
                <input type="file" className="button-file" onChange={this.handleUpload} accept=".jpg" multiple />
                <FaCloudUploadAlt /> <FormattedMessage id="button.uploadPicture" />
              </Button>
            </ButtonGroup>
          </CardHeader>
          <CardHeader className="info">
            <span>
              <FormattedMessage id="comp.req.grid.info" />
            </span>
          </CardHeader>
          <CardBody className="np h-flex">
            <PictureList />
          </CardBody>
          <CardFooter style={{ fontWeight: 'bold' }}>
            <CheckedCount className="mr-auto" />
          </CardFooter>
          <CardMask show={pictureList.mask} />
        </Card>
        {popoverTarget && (
          <CustomPopover
            placement="bottom"
            target={popoverTarget}
            title={locale.fm('popover.preprocessing.title')}
            contents={popoverPicture}
            setPopover={setPopover}
            fromPreprocessing
          />
        )}
      </>
    );
  }
}

@inject('pictureList')
@observer
class CheckedCount extends Component {
  render() {
    const pictureList = this.props.pictureList;
    return (
      <div>
        <FormattedMessage id="field.total" /> : {pictureList.totalCount} / <FormattedMessage id="field.selected" /> :{' '}
        {pictureList.selectedCount === 0 ? 0 : <span className="badge badge-primary">{pictureList.selectedCount}</span>}
      </div>
    );
  }
}

@inject('locale')
@inject('zoomWindow')
@inject('pictureList')
@inject('cerviAiResultWindowWithoutPatInfo')
@observer
class PictureList extends Component {
  handlePictureClick = (gIdx, cIdx, e) => {
    const { locale, zoomWindow, pictureList } = this.props;
    const pl = pictureList.getGroupPicture(gIdx).map((p) => {
      return {
        url: p.url,
        title: `${locale.fm('field.takedate')} : ${moment(p.takedate).format('YYYY-MM-DD HH:mm:SS')}`
      };
    });
    zoomWindow.setPictureList(pl, cIdx);
  };

  handlePictureAiClick = (gIdx, cIdx, e) => {
    e.stopPropagation();
    const { locale, cerviAiResultWindowWithoutPatInfo, pictureList } = this.props;
    const pl = pictureList.getGroupPicture(gIdx);
    cerviAiResultWindowWithoutPatInfo.setList(pl, pl[cIdx].idx);
  };

  handlePictureCheckChange = (gIdx, pIdx, e) => {
    const { pictureList } = this.props;
    pictureList.selectPicture(gIdx, pIdx, e.target.checked);
  };

  handleGroupCheckChange = (gIdx, e) => {
    const { pictureList } = this.props;
    pictureList.selectGroup(gIdx, e.target.checked);
  };

  handleClickReload = (gIdx, aiIdx, e) => {
    e.stopPropagation();
    const { pictureList } = this.props;
    pictureList.getAiStatus(gIdx, aiIdx);
  };

  componentDidMount() {
    this.props.pictureList.getList();
  }

  componentWillUnmount() {
    //store 정리
    //header 카운트 위해서 정리하지 않는다.
    //this.props.pictureList.init();
  }

  render() {
    const { group } = this.props.pictureList;
    return (
      <div className="picture-list">
        <ul>
          {group.map((g) => (
            <PictureGroup
              key={g.idx}
              pictureGroup={g}
              onPictureClick={this.handlePictureClick}
              onPictureAiClick={this.handlePictureAiClick}
              onGroupCheckChange={this.handleGroupCheckChange}
              onPictureCheckChange={this.handlePictureCheckChange}
              onClickReload={this.handleClickReload}
            />
          ))}
        </ul>
      </div>
    );
  }
}

@inject('locale')
@observer
class PictureGroup extends Component {
  render() {
    const { locale, pictureGroup: g, onPictureClick, onPictureAiClick, onGroupCheckChange, onPictureCheckChange, onClickReload } = this.props;
    return (
      <li>
        <div className="send-title">
          <CustomInput
            type="checkbox"
            id={`g-${g.idx}`}
            label={`${locale.fm('field.startdate')} : ${moment(g.startdate).format('YYYY-MM-DD HH:mm:ss')}`}
            onChange={(e) => onGroupCheckChange(g.idx, e)}
            checked={g.checked}
          />
        </div>
        <ul>
          {g.list.map((p, j) => (
            <Picture
              key={p.idx}
              gIdx={g.idx}
              cIdx={j}
              picture={p}
              onPictureClick={onPictureClick}
              onPictureAiClick={onPictureAiClick}
              onPictureCheckChange={onPictureCheckChange}
              onClickReload={onClickReload}
            />
          ))}
        </ul>
      </li>
    );
  }
}

@inject('pictureList')
@inject('auth')
@inject('locale')
@observer
class Picture extends Component {
  handleRefresh = (e) => {
    e.stopPropagation();
    this.props.pictureList.getList();
  };

  handlePopover = (target, picture, e) => {
    this.props.pictureList.setPopover(target, picture);
  };

  render() {
    const { gIdx, cIdx, picture: p, onPictureClick, onPictureAiClick, onPictureCheckChange, onClickReload, auth, locale } = this.props;

    return (
      <li key={p.fCode} className={cx({ checked: p.checked })}>
        <div className="picture" onClick={(e) => onPictureClick(gIdx, cIdx, e)}>
          <PictureBox small src={`${p.url}?thumb=true`} />
          {p.aiStatus === 0 || p.aiStatus === 1 ? (
            <>
              <div className="ai-result analyzing" disabled>
                Analyzing...
              </div>
              <Button className="refresh btn-success" onClick={(e) => this.handleRefresh(e)}>
                <IoMdRefresh />
              </Button>
            </>
          ) : p.aiStatus === 2 || p.aiStatus === 3 ? (
            <Button color="sunsetOrange" style={{ color: 'white' }} className="ai-result" onClick={(e) => onPictureAiClick(gIdx, cIdx, e)}>
              AI
            </Button>
          ) : null}
          {p.isCompleted !== null && p.isCompleted && !p.isEligible ? (
            <div
              id={`preprocessingPopover-${p.idx}`}
              className="preprocessing"
              onMouseEnter={(e) => this.handlePopover(`preprocessingPopover-${p.idx}`, p, e)}
              onMouseLeave={(e) => this.handlePopover(null, null, e)}
            >
              <img src={Eligible} alt="isEligible" width="31" height="31" />
            </div>
          ) : null}
          {p.isCompleted !== null && !p.isCompleted ? (
            <div
              id={`preprocessingPopover-${p.idx}`}
              className="preprocessing"
              onMouseEnter={(e) => this.handlePopover(`preprocessingPopover-${p.idx}`, p, e)}
              onMouseLeave={(e) => this.handlePopover(null, null, e)}
            >
              <img src={Completed} alt="isCompleted" width="31" height="31" />
            </div>
          ) : null}
        </div>
        <div className="take-date">
          <CustomInput
            type="checkbox"
            id={`p-${p.idx}`}
            label={moment(p.takedate).format('YYYY-MM-DD HH:mm:ss')}
            onChange={(e) => onPictureCheckChange(gIdx, p.idx, e)}
            checked={p.checked}
          />
        </div>
      </li>
    );
  }
}

export default PictureListCard;
