import { observable, action, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

export default class CerviAiDeleteWindowStore {
  @observable open = false;
  @observable mask = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.setMask(false);
    this.init();
  }

  @action
  setMask = (m) => {
    this.mask = m;
  };

  @action
  init = () => {
    this.open = false;
  };

  @action
  close = () => {
    this.open = false;
  };

  @action
  setOpen = (bool) => {
    this.open = bool;
  };
  @action
  delete = async () => {
    const idxs = this.root.cerviAi.preview.aiResultList
      .filter((img) => img.checked)
      .map((el) => {
        return el.idx;
      });

    if (idxs.length === 0) {
      toast.warn(this.locale.fm('store.cerviai.nosel'));
      return;
    }
    this.setMask(true);
    try {
      await axios.delete('/api/rq/cerviai/del', { data: idxs });

      runInAction('CerviAiStore : async delete -> success', () => {
        this.root.cerviAi.triggerRefresh(this.root.cerviAi.preview.idx);
        this.init();
        this.setMask(false);
        toast.success(this.locale.fm('store.cerviai.del'));
      });
    } catch (error) {
      runInAction('CerviAiStore : async delete -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };
}
