import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import { CardMask, CustomPagination } from 'components/common';
import { CervitemNavigation } from './components';
import './Cervitem.scss';
import '../common/PictureBox.scss';

@inject('cervitemCart')
@inject('cervitem')
@inject('locale')
@withRouter
@observer
class Cervitem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      prevQuery: '',
      categoryIndex: 0
    };
  }

  setPage = (page) => {
    this.props.cervitem.setPage(page, this.state.categoryIndex);
  };

  setCategoryIndex = (idx) => {
    this.setState({
      categoryIndex: idx
    });
  };

  componentDidMount() {
    const { page } = this.props.cervitem;
    if (page > 1) return;

    const defaultCategoryIndex = this.props.location.state === undefined ? 0 : this.props.location.state.categoryIndex;
    this.setCategoryIndex(defaultCategoryIndex);
    this.props.cervitem.setSelectCategory(defaultCategoryIndex);
  }

  componentWillUnmount = () => {
    this.props.cervitem.init();
  };

  render() {
    const { defaultList: list, page, totalPage, mask, categoryList } = this.props.cervitem;
    return (
      <Container fluid className="Cervitem h-flex">
        <CardMask show={mask} />
        <CervitemNavigation categoryIndex={this.state.categoryIndex} setCategoryIndex={this.setCategoryIndex} />
        <div className="d-flex flex-column justify-content-center align-items-center Item-Container" style={{ padding: '0px 20px 40px 20px' }}>
          <CervitemGroup list={list} />
          <CustomPagination page={page} totalPage={totalPage} setPage={this.setPage} />
        </div>
      </Container>
    );
  }
}

class CervitemGroup extends Component {
  render() {
    const { list } = this.props;
    return (
      <>
        {list ? (
          <CervitemProductList list={list} />
        ) : (
          <div className="CerviTemProductList none">
            <h3>준비된 상품이 없습니다.</h3>
          </div>
        )}
      </>
    );
  }
}

@inject('productWindow')
@inject('cervitemOrder')
@inject('cervitemProduct')
@inject('cervitem')
@withRouter
@observer
class CervitemProductList extends Component {
  componentDidMount = () => {
    this.props.cervitemOrder.history = this.props.history;
  };
  handleClick = (idx) => {
    // this.props.history.push(`/cervitem/${idx}`); // page version
    this.props.cervitemProduct.setProductIdx(idx); // modal version
    this.props.productWindow.setOpen(true); // modal version
  };

  render() {
    const { list } = this.props;

    return (
      <div className="CerviTemProductList">
        {list.map((el, idx) => (
          <Card className="CerviTemProduct" key={el.idx} onClick={() => this.handleClick(el.idx)}>
            <div className="card-img">
              <div className="PictureBox">
                <div className="inner-picture" style={{ backgroundImage: `url(${el.thumbImg})` }} alt={el.name} />
              </div>
            </div>
            <CardBody className="cervitem-info">
              <p className="cervitem-category">{el.categoryName}</p>
              <h4 className="cervitem-name">{el.name}</h4>
              <p className="cervitem-price">{el.price.toLocaleString() + '원'}</p>
            </CardBody>
          </Card>
        ))}
      </div>
    );
  }
}
export default Cervitem;
