import { observable, action, runInAction, computed } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler, searchSerialize } from 'libs/Utils';
import { toast } from 'react-toastify';
import { confirm } from 'libs/Utils';
import moment from 'moment';

function convertModel(a) {
  return Object.assign(
    { ...a },
    {
      url: `/api/rq/cervibox/image/${a.idx}`,
      memo: a.memo ? a.memo : '',

      // ai결과
      classResult: a.aiResult ? a.aiResult.classResult : null,
      classResultMap: a.aiResult ? a.aiResult.classResultMap : null
    }
  );
}

function buildGroupReducer(acc, row, i) {
  const p = Object.assign({ checked: false }, row);
  const pDate = moment(p['regDate'], 'YYYYMMDDHHmmss').format('YYYY-MM-DD');
  const g = acc.find((r) => {
    const rDate = moment(r['regDate'], 'YYYYMMDDHHmmss').format('YYYY-MM-DD');
    return rDate === pDate;
  });

  if (g === undefined) {
    acc.push({
      idx: i,
      checked: false,
      regDate: pDate,
      list: [convertModel(p)]
    });
  } else {
    g.list.push(convertModel(p));
  }
  return acc;
}

//채크 사진 합계 리듀서
function checkedCountReducer(acc, row) {
  return acc + row.list.filter((p) => p.checked).length;
}
function countReducer(acc, row) {
  return acc + row.list.length;
}

//채크 사진 idx [] 리듀서
function checkedListReducer(acc, row) {
  return acc.concat(row.list.filter((p) => p.checked).map((p) => p.idx));
}

const defaultSearch = {
  startDate: null,
  endDate: null,
  patName: '',
  chartNum: '',
  socialNum: '',
  searchWord: '',
  scope: 'my'
};

const defaultDataGrid = {
  rows: [],
  page: 1,
  totalPage: 1,
  total: 0
};

const defaultPreview = {
  box: null,
  group: [],
  mask: false,
  totalCount: 0,
  selectAll: false
};

export default class CerviBoxStore {
  @observable search = { ...defaultSearch };
  @observable dataGrid = { ...defaultDataGrid };
  @observable mask = false;

  //detail panel 용
  @observable preview = { ...defaultPreview };

  constructor(root) {
    this.root = root;
    this.locale = root.locale;

    //dialog 용
    this.reqDialog = new RequestWindowStore(root, this);

    this.init();
  }

  @action
  init = () => {
    this.dataGrid = { ...defaultDataGrid };
    this.initSearch();
    this.initPreview();
    this.mask = false;
  };

  @action
  initPreview = () => {
    this.preview = { ...defaultPreview };
  };

  @action
  initSearch = () => {
    const scope = localStorage.getItem('resultSearchScope');

    this.search = Object.assign(
      { ...defaultSearch },
      {
        startDate: moment().startOf('day').add(-1, 'month').toDate(),
        endDate: moment().startOf('day').toDate(),
        scope: scope !== null && scope === 'all' ? scope : 'my'
      }
    );
  };

  @action
  triggerSearch = async () => {
    await this.loadDataGrid();
  };

  @action
  triggerReset = async () => {
    this.init();
    await this.loadDataGrid();
  };

  @action
  triggerRefresh = async (boxIdx = null) => {
    this.initPreview();
    await this.loadDataGrid(this.dataGrid.page);
    if (boxIdx) this.setPreviewBox(boxIdx);
  };

  @action
  changeSearch = (key, value) => {
    this.search[key] = value;
  };

  @action
  changeImage = (gIdx, pIdx, key, value) => {
    const g = this.getGroup(gIdx);
    g.list.find((p) => p.idx === pIdx)[key] = value;
  };

  @action
  setListAsGroup = (rows) => {
    this.preview.group.replace(rows.reduce(buildGroupReducer, []));
  };

  @action
  selectGroup = (gIdx, checked) => {
    const g = this.getGroup(gIdx);
    g.checked = checked;
    g.list.forEach((p) => (p.checked = checked));
  };

  @action
  selectPicture = (gIdx, pIdx, checked) => {
    const g = this.getGroup(gIdx);
    g.list.find((p) => p.idx === pIdx).checked = checked;
    g.checked = g.list.every((p) => p.checked === true);
  };

  @computed
  get selectedCount() {
    return this.preview.group.reduce(checkedCountReducer, 0);
  }

  getGroup = (gIdx) => {
    return this.preview.group.find((g) => g.idx === gIdx);
  };

  getGroupPicture = (gIdx) => {
    const g = this.preview.group.find((g) => g.idx === gIdx);
    return g ? g.list : [];
  };

  selectedIdxList = () => {
    return this.preview.group.reduce(checkedListReducer, []);
  };

  @action
  loadDataGrid = async (page = 1) => {
    this.mask = true;
    this.normalizeSearch();
    localStorage.setItem('resultSearchScope', this.search.scope === 'my' ? 'my' : 'all');

    try {
      const response = await axios.get('/api/rq/cervibox', {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('CerviBoxStore : async loadDataGrid -> success', () => {
        this.dataGrid = response.data;
        this.mask = false;
      });
    } catch (error) {
      runInAction('CerviBoxStore : async loadDataGrid -> catch', () => {
        this.mask = false;
        ajaxErrorHandler(error);
      });
    }
  };

  @action
  normalizeSearch = () => {
    //param 검증
    const s = this.search;
    if (!moment(s.startDate).isValid()) s.startDate = null;
    if (!moment(s.endDate).isValid()) s.endDate = null;
    if (s.startDate !== null && s.endDate === null) s.endDate = s.startDate;
    if (s.startDate === null && s.endDate !== null) s.startDate = s.endDate;
    if (s.startDate > s.endDate) s.endDate = s.startDate;
  };

  @action
  setPreviewBox = (idx) => {
    this.setPreviewMask(true);

    const p = this.dataGrid.rows.find((r) => r.idx === idx);

    this.preview.box = p;
    if (p.imgList.length > 0) {
      this.setListAsGroup(p.imgList);
      this.preview.totalCount = this.preview.group.reduce(countReducer, 0);
    } else {
      this.setListAsGroup([]);
    }

    this.setPreviewMask(false);
  };

  @action
  removeImage = async () => {
    if (this.preview.box.docId !== this.root.auth.doctor.id) {
      toast.warn(this.locale.fm('store.cervibox.other.doctor.img'));
      return;
    }

    const idxs = this.selectedIdxList();

    if (idxs.length === 0) {
      toast.warn(this.locale.fm('store.cerviai.nosel'));
      return;
    }

    if (!(await confirm(this.locale.fm('store.cervibox.confirm.del')))) return;

    this.setPreviewMask(true);

    try {
      await axios.delete('/api/rq/cervibox/image', { data: idxs });

      runInAction('CerviBoxStore : async removeImage -> success', () => {
        toast.success(this.locale.fm('store.cervibox.success.del'));

        // 데이터갱신 후 선택화면으로
        const previewSnapShot = Object.assign({ ...this.preview.box }, {});
        this.triggerRefresh(previewSnapShot.idx);
      });
    } catch (error) {
      runInAction('CerviBoxStore : async removeImage -> catch', () => {
        ajaxErrorHandler(error);
      });
    }

    this.setPreviewMask(false);
  };

  @action
  setMask = (bool) => {
    this.mask = bool;
  };

  @action
  setPreviewMask = (bool) => {
    this.preview.mask = bool;
  };

  @action
  remove = async (idxs) => {
    const otherDoctor = this.dataGrid.rows.find((r) => idxs.includes(r.idx) && r.docId !== this.root.auth.doctor.id);
    if (otherDoctor) {
      toast.warn(this.locale.fm('store.cervibox.other.doctor.box'));
      return;
    }

    if (idxs == null || idxs.length === 0) {
      toast.warn(this.locale.fm('comp.cervibox.vali.selectMore1'));
      return;
    }

    if (!(await confirm(this.locale.fm('store.cervibox.confirm.del')))) return;

    this.setMask(true);

    try {
      const response = await axios.delete(`/api/rq/cervibox`, { data: idxs });

      if (response.data.success) {
        toast.success(this.locale.fm('store.cervibox.success.del'));
        this.triggerRefresh();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }

    this.setMask(false);
  };

  @action
  selectRequest = () => {
    const wIdxs = this.selectedWorklistIdxList();
    const rIdxs = this.selectedRqUploadIdxList();

    if (wIdxs.length > 0) {
      toast.warn(this.locale.fm('store.cervicoRequest.fail.aleadyRequested'));
      return;
    }

    if (wIdxs.length + rIdxs.length === 0 || wIdxs.length + rIdxs.length > 4) {
      toast.warn(this.locale.fm('store.cervicoRequest.vali.picture'));
      return;
    }

    // 환자정보 세팅
    this.root.cervicoRequest.init();
    this.root.cervicoRequest.setInfo(this.preview.box);

    this.reqDialog.setOpen(true);
  };

  @action
  selectedRqUploadIdxList = () => {
    return this.preview.group.reduce((a, c) => a.concat(c.list.filter((p) => p.checked && !p.fcode).map((p) => p.idxRqUpload)), []);
  };

  @action
  selectedWorklistIdxList = () => {
    return this.preview.group.reduce((a, c) => a.concat(c.list.filter((p) => p.checked && p.fcode).map((p) => p.fcode)), []);
  };

  @action
  selectChange = () => {
    this.preview.selectAll = !this.preview.selectAll;
    this.preview.group.forEach((g) => this.selectGroup(g.idx, this.preview.selectAll));
  };

  @action
  editImage = async (boxIdx, idx, memo) => {
    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.post(`/api/rq/cervibox/${boxIdx}/${idx}`, { memo: memo });
      if (response.data.success) {
        // 데이터갱신 후 선택화면으로
        const previewSnapShot = Object.assign({ ...this.preview.box }, {});
        this.triggerRefresh(previewSnapShot.idx);

        toast.success(this.locale.fm('store.cervibox.save'));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };
}

class RequestWindowStore {
  @observable open = false;

  constructor(root, container) {
    this.root = root;
    this.locale = root.locale;
    this.container = container;
  }

  @action
  setOpen = (bool) => {
    this.open = bool;
  };
}
