import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import { searchSerialize } from 'libs/Utils';

const defaultSearch = {
  startDate: null,
  endDate: null,
  productName: ''
};

const CART = 'cart';
const PRODUCT = 'product';

export default class CervitemOrderStore {
  history = null;

  @observable mask = false;
  @observable search = { ...defaultSearch };
  @observable orderList = [];
  @observable open = false;
  @observable page = 1;
  @observable totalPage = 1;
  @observable requirement = '';

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.cervitemCart = root.cervitemCart;
  }

  @action
  init = () => {
    this.initRequirement();
    this.initSearch();
    this.loadData();
    this.setMask(false);
  };

  @action
  setOpen() {
    this.root.cervitemAddr.loadData();
    this.open = true;
  }

  @action
  close() {
    this.open = false;
  }

  @action
  initRequirement = () => {
    this.requirement = '';
  };

  @action
  setMask = (bool) => {
    this.mask = bool;
  };

  @action
  setOrderList = (data) => {
    this.orderList = data;
  };
  @action
  setPage = (page) => {
    this.page = page;
    this.loadData(page);
  };

  @action
  setTotalPage = (page) => {
    this.totalPage = page;
  };

  @action
  initSearch = () => {
    this.search = Object.assign({ ...defaultSearch }, { startDate: moment().startOf('day').add(-1, 'month').toDate(), endDate: moment().startOf('day').toDate() });
  };

  @action
  changeSearch = (key, value) => {
    this.search[key] = value;
  };

  @action
  triggerRefresh = async () => {
    await this.loadData();
  };
  @action
  triggerSearch = async () => {
    await this.loadData();
  };

  @action
  triggerReset = async () => {
    this.initSearch();
    await this.loadData();
  };

  @action
  changeRequirement = (key, value) => {
    console.log(value);
    this.requirement = value;
  };

  @action
  loadData = async (page = 1) => {
    this.setMask(true);
    try {
      const response = await axios.get(`/api/rq/cervitem/order/list`, {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('CervitemOrderStore : async loadData -> success', () => {
        if (response.data.success) {
          this.setOrderList(response.data.data.orderList);
          this.setTotalPage(response.data.data.totalPage);
          this.setMask(false);
        }
      });
    } catch (error) {
      runInAction('CervitemOrderStore : async loadData -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  cancelOrder = async (idx, itemIdx) => {
    this.setMask(true);
    try {
      await axios.delete(`/api/rq/cervitem/order/${idx}/${itemIdx}/cancel`);
      runInAction('CervitemOrderStore : async cancelOrder -> success', () => {
        this.setMask(false);
        this.triggerRefresh();
        this.open = false;
      });
    } catch (error) {
      runInAction('CervitemOrderStore : async cancelOrder -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  addToOrder = async (from, item = {}) => {
    item.requirement = this.requirement;
    if (from === CART) {
      this.setMask(true);
      try {
        const response = await axios.put(`/api/rq/cervitem/order`, item);
        runInAction('CervitemOrderStore : async addToOrder.CART -> success', () => {
          if (response.data.success) {
            toast.success(this.locale.fm('store.cervitem.order.success'));
            this.root.cervitemCart.triggerRefresh();
            this.open = false;
            this.setMask(false);
            this.history.push('/order');
          } else {
            toast.error(response.data.message, {
              onClose: () => {
                this.setMask(false);
                window.location.reload();
              }
            });
          }
        });
      } catch (error) {
        runInAction('CervitemOrderStore : async addToOrder.CART -> catch', () => {
          ajaxErrorHandler(error);
          this.setMask(false);
        });
      }
    } else if (from === PRODUCT) {
      this.setMask(true);
      try {
        const response = await axios.put(`/api/rq/cervitem/order/add`, item);
        runInAction('CervitemOrderStore : async addToOrder.PRODUCT -> success', () => {
          if (response.data.success) {
            toast.success(this.locale.fm('store.cervitem.order.success'));
            this.open = false;
            this.setMask(false);
            this.history.push('/order');
          } else {
            toast.error(response.data.message, {
              onClose: () => {
                this.setMask(false);
                window.location.reload();
              }
            });
          }
        });
      } catch (error) {
        runInAction('CervitemOrderStore : async addToOrder.PRODUCT -> catch', () => {
          ajaxErrorHandler(error);
          this.setMask(false);
        });
      }
    }
  };
}
