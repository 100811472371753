import React, { Component } from 'react';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, InputGroup, InputGroupAddon } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './DoctorEditWindow.scss';
import { observer, inject } from 'mobx-react';

import { FaCheck, FaTimes, FaKey, FaUserEdit, FaInfo } from 'react-icons/fa';
import Table from 'reactstrap/lib/Table';
import RQ_Wifi from 'resources/RQ_Wifi.ico';
import RQ from 'resources/RQ.ico';
import transceiver from 'resources/transceiver.ico';

@inject('locale')
@inject('doctorEditWindow')
@inject('auth')
@observer
class DoctorEditWindow extends Component {
  handleEdit = () => {
    this.props.doctorEditWindow.edit();
  };

  handleClose = () => {
    this.props.doctorEditWindow.init();
  };

  handleChangeDeferredDate = () => {
    this.props.doctorEditWindow.changeDeferredDate();
  };

  handleFocus = (e) => {
    e.target.select();
  };

  handleChange = (e) => {
    const i = e.target;
    this.props.doctorEditWindow.changeDoctorEdit(i.name, i.value);
  };

  handleValidMsg = () => {
    this.props.doctorEditWindow.getValidMessage();
  };

  render() {
    const { locale, auth, doctorEditWindow } = this.props;
    const hc = this.handleChange;
    const { openPasswordChangeYN } = doctorEditWindow;

    return (
      <Modal isOpen={doctorEditWindow.open} toggle={this.handleClose} className="DoctorEditWindow" zIndex="940">
        <ModalHeader toggle={this.handleClose}>
          <FaUserEdit /> {locale.fm('comp.doctor.edit.title')}
        </ModalHeader>
        <ModalBody>
          <Form className="search">
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text">
                  <FaKey />
                </span>
              </InputGroupAddon>
              <input
                name="password1"
                type="password"
                placeholder={locale.fm('field.newPassword')}
                tabIndex="2"
                className="form-control"
                autoComplete="new-password"
                onChange={(e) => hc(e)}
                onKeyUp={this.handleValidMsg}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text">
                  <FaKey />
                </span>
              </InputGroupAddon>
              <input
                name="password2"
                type="password"
                placeholder={locale.fm('field.newPassword2')}
                tabIndex="2"
                className="form-control"
                autoComplete="new-password"
                onChange={(e) => hc(e)}
                onKeyUp={this.handleValidMsg}
              />
            </InputGroup>
          </Form>
          <Alert color="warning text-center">
            <h4 className="alert-heading">
              <FaInfo /> <FormattedMessage id="comp.doctor.edit.window.title" />
            </h4>
            <ul className="text-left">
              <li>
                <FormattedMessage id="store.doctorEditWindow.valid.pattern" />
              </li>
              <li>
                <FormattedMessage id="store.doctorEditWindow.valid.includeId" />
              </li>
              <li>
                <FormattedMessage id="store.doctorEditWindow.valid.length" />
              </li>
            </ul>
            <hr />
            <p className="doctor-edit-content">
              <FormattedMessage id="comp.doctor.edit.window.content1" />
            </p>
            <hr />
            <p className="doctor-edit-content mb-0 font-weight-bold">
              <FormattedMessage id="comp.doctor.edit.window.content2" />
            </p>
            <Table className="programs mb-0">
              <tbody>
                <tr>
                  <td>
                    <img src={transceiver} alt="Transceiver" />
                  </td>
                  <td>
                    <img src={RQ} alt="RQ" />
                  </td>
                  <td>
                    <img src={RQ_Wifi} alt="RQ Wifi" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="comp.doctor.edit.transceiver" />
                  </td>
                  <td>
                    <FormattedMessage id="comp.doctor.edit.rq" />
                  </td>
                  <td>
                    <FormattedMessage id="comp.doctor.edit.rqwifi" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Alert>
          {doctorEditWindow.doctorEdit.password1.length === 0 && doctorEditWindow.doctorEdit.password2.length === 0 ? (
            ''
          ) : (
            <Alert color={doctorEditWindow.validMsg.isValid ? 'success' : 'danger'}>
              <p className="doctor-edit-validmsg mb-0">
                {doctorEditWindow.validMsg.isValid ? (
                  <>
                    <FaCheck /> <FormattedMessage id="comp.doctor.edit.window.valid" />
                  </>
                ) : (
                  <>
                    <FaTimes /> {doctorEditWindow.validMsg.cause.join('\n')}
                  </>
                )}
              </p>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          {openPasswordChangeYN ? (
            <>
              <Button color="primary" onClick={this.handleEdit} size="sm">
                <FaCheck /> {locale.fm('button.modify')}
              </Button>
              <Button color="secondary" onClick={this.handleChangeDeferredDate} size="sm">
                <FaTimes /> {locale.fm('button.changeDeferredDate')}
              </Button>
            </>
          ) : (
            <>
              <Button color="primary" onClick={this.handleEdit} size="sm">
                <FaCheck /> {locale.fm('button.modify')}
              </Button>
              <Button color="secondary" onClick={this.handleClose} size="sm">
                <FaTimes /> {locale.fm('button.changeLater')}
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default DoctorEditWindow;
