import { observable, action, computed, set, runInAction } from 'mobx';
import { calcSocialNum } from 'libs/Utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

export default class CervicoResultSmsWindowStore {
  @observable open = false;
  @observable idx = null;
  @observable smsType = null;
  @observable resultList = [];

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.open = false;
    this.idx = null;
    this.smsType = null;
    this.resultList = [];
  };

  @action
  setRequestInfo = (idx, smsType) => {
    this.idx = idx;
    this.smsType = smsType;
    this.loadData();
    this.open = true;
  };

  @action
  loadData = async () => {
    if (this.idx && this.smsType) {
      try {
        const response = await axios.get(`/api/rq/sms/${this.smsType.code}/${this.idx}`);
        runInAction('CervicoResultSmsWindowStore : async loadData -> success', () => {
          this.resultList = response.data.data;
        });
      } catch (error) {
        ajaxErrorHandler(error);
      }
    }
  };
}
