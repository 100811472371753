import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { PictureBox } from 'components/common';
import CervicoResultTable from './CervicoResultTable';

import { toggleFullscreen } from 'libs/Screen';

import { FaArrowLeft, FaTimesCircle } from 'react-icons/fa';

import './CervicoResultCompareWindow.scss';

@inject('screen')
@inject('cervicoResultCompareWindow')
@withRouter
@observer
class CervicoResultCompareWindow extends Component {
  el = React.createRef();

  handleFullscreen = () => {
    toggleFullscreen(this.el.current);
  };

  handlePictureClick = (index) => {
    this.props.cervicoResultCompareWindow.zoom(index);
  };

  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.cervicoResultCompareWindow.init();
    }
  }

  componentDidMount() {
    //TODO keyboard 이벤트 bind
  }

  componentWillUnmount() {
    //TODO keyboard 이벤트 unbind
  }

  render() {
    const { cervicoResultCompareWindow } = this.props;

    if (cervicoResultCompareWindow.show) {
      const { resultList, result } = cervicoResultCompareWindow;

      return (
        <div className="CervicoResultCompareWindow layer-window" ref={this.el}>
          <div className="area-back-button">
            <Button size="sm" onClick={cervicoResultCompareWindow.init}>
              <FaArrowLeft />
            </Button>
          </div>
          <div className="area-button">
            {/* <Button size="sm" onClick={this.handleFullscreen}>{screen.fullscreen ? <FaCompress /> : <FaExpand />}</Button> */}
            <Button size="sm" onClick={cervicoResultCompareWindow.init}>
              <FaTimesCircle />
            </Button>
          </div>
          <div className="area-result">
            {resultList.map((_, i) => (
              <div key={i} className="area-worklist">
                {result[i].show ? (
                  <>
                    <div className="area-picture" onClick={() => this.handlePictureClick(i)}>
                      <PictureBox contain src={`/api/rq/cervico/worklist/${result[i].idx}/picture/${result[i].selectedImg}`} />
                    </div>
                    <div className="area-info">
                      <CervicoResultTable result={result[i]} />
                    </div>
                  </>
                ) : (
                  <div className="loading">
                    <div className="lds-roller">
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default CervicoResultCompareWindow;
