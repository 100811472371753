import { observable, action, computed, set, runInAction } from 'mobx';
import cookies from 'browser-cookies';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

const defaultDoctorEdit = {
  password1: '',
  password2: '',
  docId: '',
  pwdChgDate: ''
};

const defaultValidMsg = {
  isValid: false,
  cause: []
};

// 8~20자, 영문 및 숫자 및 특수문자 1가지 포함
const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?])[A-Za-z\d~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?]{8,20}$/;

export default class CervicoResultEditWindowStore {
  @observable doctorEdit = { ...defaultDoctorEdit };
  @observable validMsg = { ...defaultValidMsg };
  @observable open = false;

  openPasswordChangeYN = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.doctorEdit = { ...defaultDoctorEdit };
    this.validMsg = { ...defaultValidMsg };
    this.open = false;
  };

  @action
  setDoctorEdit = (openPasswordChangeYN) => {
    this.doctorEdit = {
      password1: '',
      password2: '',
      docId: this.root.auth.doctor.id,
      pwdChgDate: moment()
    };
    this.openPasswordChangeYN = openPasswordChangeYN;
    this.open = true;
  };

  @action
  changeDoctorEdit = (key, value) => {
    this.doctorEdit[key] = value;
  };

  // 비밀번호 검증 및 메시지 안내
  @action
  getValidMessage = () => {
    const p1 = this.doctorEdit.password1;
    const p2 = this.doctorEdit.password2;
    this.validMsg.isValid = true;
    this.validMsg.cause = [];

    if (p1 !== p2) {
      this.validMsg.isValid = false;
      this.validMsg.cause.push(this.locale.fm('store.doctorEditWindow.valid.notEqaul'));
    }

    if (!regex.test(p1) || !regex.test(p2)) {
      this.validMsg.isValid = false;
      this.validMsg.cause.push(this.locale.fm('store.doctorEditWindow.valid.pattern'));
    }

    if (p1.includes(this.root.auth.doctor.id)) {
      this.validMsg.isValid = false;
      this.validMsg.cause.push(this.locale.fm('store.doctorEditWindow.valid.includeId'));
    }

    if (p1.length < 8 || p1.length > 20) {
      this.validMsg.isValid = false;
      this.validMsg.cause.push(this.locale.fm('store.doctorEditWindow.valid.length'));
    }
  };

  @action
  changeRequired = (d) => {
    if (d.weakPassword) {
      this.setDoctorEdit(false);
    } else if (d.openPasswordChangeYN) {
      this.setDoctorEdit(true);
    }
  };

  @action
  edit = async () => {
    if (!this.validMsg.isValid) {
      toast.warn(this.locale.fm('store.doctorEditWindow.valid.not'));
      return;
    }

    const d = this.doctorEdit;

    this.root.screen.setProcessLayer(true);

    try {
      const response = await axios.post(`/api/rq/doctor/edit`, d);
      if (response.data.success) {
        this.init();
        this.root.auth.logout();
        toast.success(this.locale.fm('store.doctorEditWindow.edit.success'));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }

    this.root.screen.setProcessLayer(false);
  };

  @action
  changeDeferredDate = async () => {
    const d = this.doctorEdit;

    this.root.screen.setProcessLayer(true);

    try {
      const response = await axios.post(`/api/rq/doctor/changeNext`, d);
      if (response.data.success) {
        this.init();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }

    this.root.screen.setProcessLayer(false);
  };
}
