import React, { Component } from 'react';
import { Container, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, ButtonGroup, Button, Badge, Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { CustomPagination, DateTimePicker, PictureBox, CardMask } from 'components/common';
import { CervitemNavigation } from '../components';
import { inject, observer } from 'mobx-react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import cx from 'classnames';
import './CervitemOrder.scss';

const MIN_WIDTH = 850;

@inject('locale')
@inject('checkWindowWidth')
@inject('cervitemOrder')
@observer
class CervitemOrder extends Component {
  handleResize = () => {
    this.props.checkWindowWidth.setWindowWidth(window.innerWidth);
  };
  componentDidMount = () => {
    this.props.cervitemOrder.init();
    this.props.cervitemOrder.history = this.props.history;
    this.props.checkWindowWidth.init(MIN_WIDTH, window.innerWidth);
    window.addEventListener('resize', this.handleResize);
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setPage = (page) => {
    this.props.cervitemOrder.setPage(page);
  };

  render() {
    const { orderList, page, totalPage, mask } = this.props.cervitemOrder;
    return (
      <Container fluid className="CervitemOrder">
        <CervitemNavigation categoryIndex={null} fromCartOrder />
        <CervitemOrderSearchForm />
        <CardMask show={mask} />
        <Card>
          <CardHeader>
            <div>주문내역</div>
          </CardHeader>
          <CardBody className="d-flex flex-column align-items-center pt-3">
            <div style={{ width: '50%' }}>
              {orderList.length === 0 ? (
                <h4 className="text-center">주문한 내역이 없습니다.</h4>
              ) : (
                orderList.map((item, idx) => {
                  return <OrderItem {...item} key={idx} />;
                })
              )}
              {totalPage <= 1 ? null : (
                <div className="d-flex pb-3">
                  <CustomPagination page={page} totalPage={totalPage} setPage={this.setPage} />
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

@inject('cervitemOrder')
@observer
class CervitemOrderSearchForm extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.cervitemOrder.changeSearch(i.name, i.value);
  };

  handleResetClick = async (e) => {
    await this.props.cervitemOrder.triggerReset();
    // this.props.onAfterSearch();
  };

  handleSearchClick = async (e) => {
    await this.props.cervitemOrder.triggerSearch();
    // this.props.onAfterSearch();
  };

  handleKeyUpEnter = (e) => {
    if (e.key === 'Enter') this.handleSearchClick();
  };

  handleLast1wClick = () => {
    this.props.cervitemOrder.changeSearch('startDate', moment().startOf('day').add(-1, 'week').toDate());
    this.props.cervitemOrder.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1mClick = () => {
    this.props.cervitemOrder.changeSearch('startDate', moment().startOf('day').add(-1, 'month').toDate());
    this.props.cervitemOrder.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast3mClick = () => {
    this.props.cervitemOrder.changeSearch('startDate', moment().startOf('day').add(-3, 'month').toDate());
    this.props.cervitemOrder.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1yClick = () => {
    this.props.cervitemOrder.changeSearch('startDate', moment().startOf('day').add(-1, 'year').toDate());
    this.props.cervitemOrder.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  render() {
    const {
      cervitemOrder: { search: s }
    } = this.props;
    const hc = this.handleSearchChange;
    return (
      <Card>
        <CardHeader>
          <FormattedMessage id="comp.cervitem.title.searchcondition" />
        </CardHeader>
        <CardBody>
          <Form className="search">
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.cervitem.orderdate" />
                </Label>
                <DateTimePicker name="startDate" value={s.startDate} onChange={hc} />
                ~
                <DateTimePicker name="endDate" value={s.endDate} onChange={hc} />
              </FormGroup>
              <FormGroup>
                <ButtonGroup className="date-btns" size="sm">
                  <Button type="button" onClick={this.handleLast1wClick} outline>
                    <FormattedMessage id="button.last1weeks" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1mClick} outline>
                    <FormattedMessage id="button.last1months" />
                  </Button>
                  <Button type="button" onClick={this.handleLast3mClick} outline>
                    <FormattedMessage id="button.last3months" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1yClick} outline>
                    <FormattedMessage id="button.last1years" />
                  </Button>
                </ButtonGroup>
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.cervitem.productName" />
                </Label>
                <Input type="text" name="productName" size="25" value={s.productName} onChange={hc} />
              </FormGroup>
            </div>
            <div className="form-row flex-row-reverse">
              <FormGroup>
                <Button size="sm" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch /> <FormattedMessage id="button.search" />
                </Button>
                <span>&nbsp;</span>
                <Button size="sm" color="light" type="reset" style={{ width: '100px' }} onClick={this.handleResetClick}>
                  <FaTimes /> <FormattedMessage id="button.reset" />
                </Button>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

@inject('report')
@inject('cervitemCart')
@inject('cervitemOrder')
@inject('cervitemProduct')
@observer
class OrderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      cancelItemIdx: 0
    };
  }

  handleOpenToggle = (isOpen, itemIdx) => {
    this.setState({
      open: !isOpen,
      cancelItemIdx: itemIdx
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      cancelItemIdx: 0
    });
  };

  handleMovePage = async (page) => {
    await this.props.cervitemOrder.loadData(page);
  };

  handleReport = (idx) => {
    this.props.report.print(idx, 'cervitem');
  };

  handleCancelOrder = (idx) => {
    this.props.cervitemOrder.cancelOrder(idx, this.state.cancelItemIdx);
    this.handleClose();
  };

  handleAddCart = (idx) => {
    this.props.cervitemProduct.addToCart(idx);
  };

  render() {
    const { totalPrice, regDate, idx, itemList } = this.props;
    const chkReportPrint = itemList.filter((item) => item.status >= 300 && item.status !== 500).length > 0 ? false : true;

    const state = {
      100: '주문완료',
      200: '출고대기',
      300: '출고완료',
      400: '환불',
      500: '주문취소',
      600: '배송완료'
    };

    const getStatusString = (status) => {
      switch (status) {
        case 100:
          return (
            <Badge size="sm" color="success">
              {state[status]}
            </Badge>
          );
        case 200:
          return (
            <Badge size="sm" color="success">
              {state[status]}
            </Badge>
          );
        case 300:
          return (
            <Badge size="sm" color="info">
              {state[status]}
            </Badge>
          );
        case 400:
          return (
            <Badge size="sm" color="warning">
              {state[status]}
            </Badge>
          );
        case 500:
          return (
            <Badge size="sm" color="danger">
              {state[status]}
            </Badge>
          );
        case 600:
          return (
            <Badge size="sm" color="primary">
              {state[status]}
            </Badge>
          );
        default:
          return (
            <Badge size="sm" color="secondary">
              N/A
            </Badge>
          );
      }
    };
    return (
      <li className="d-flex flex-column mb-0 p-1 OrderItem">
        <CervitemOrderCancelModal isOpen={this.state.open} close={this.handleClose} cancel={this.handleCancelOrder} idx={idx} />
        <ul className="rounded border shadow-sm p-0">
          <li className="d-flex flex-row p-4 justify-content-between">
            <div>
              <h4 className="mb-0">{moment(regDate, 'YYYYMMDD').format('YYYY. MM. DD')}</h4>
            </div>
            <div hidden={chkReportPrint}>
              <Button color="primary" onClick={() => this.handleReport(idx)}>
                거래명세서
              </Button>
            </div>
          </li>
          {itemList
            ? itemList.map((item, index) => {
                // item.optionName 이 있을 경우에만 option 명을 보여준다.
                const optionName = item.optionName && item.option ? '(' + item.option.name + ')' : '';
                const { fileDir, name, status, price, quantity } = item;

                return (
                  <li className="d-flex flex-row p-4 justify-content-between border-bottom" key={index}>
                    <div className="d-flex flex-row">
                      <div className="item-picture mr-3">
                        <PictureBox small src={fileDir} />
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <h4 className="mb-1 mt-0">{getStatusString(status)}</h4>
                          <div className="item-info d-flex flex-column justify-content-between">
                            <h3 className="item-name mb-0">{name + ' ' + optionName}</h3>
                          </div>
                        </div>
                        <div className="d-flex align-items-end">
                          <p className="item-price mb-0">{price.toLocaleString() + '원'}</p>
                          <p className="ml-1 mr-1 mb-0">·</p>
                          <p className="item-quantity mb-0">{quantity + ' 개'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      {itemList && (
                        <div
                          className={cx('d-flex justify-content-center align-items-center pr-3', { 'text-decoration-line-through': status === 500 })}
                          style={{ color: '#8C8C8C' }}
                        >
                          <h4>{(price * quantity).toLocaleString()}</h4>
                          <span className="pl-1">원</span>
                        </div>
                      )}
                      <div className="d-flex flex-column justify-content-center">
                        {itemList.findIndex((item) => item.status === 100) !== -1 ? (
                          <Button outline color="primary" onClick={() => this.handleOpenToggle(this.state.open, idx)}>
                            <FormattedMessage id="button.cervitem.ordercancel" />
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </li>
                );
              })
            : null}
          <div className="pr-4 pt-3 pb-3 d-flex justify-content-end align-items-center">
            <span className="pr-3">총 결제금액</span>
            <h4>{totalPrice ? totalPrice.toLocaleString() : 0}</h4>
            <span className="pl-1">원</span>
          </div>
        </ul>
      </li>
    );
  }
}

class CervitemOrderCancelModal extends Component {
  render() {
    const { idx, cancel } = this.props;
    const open = this.props.isOpen;
    return (
      <Modal isOpen={open} toggle={this.props.close}>
        <ModalBody>
          <h3 className="text-center p-3">
            <FormattedMessage id="field.cervitem.ordercancel" />
          </h3>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="d-flex">
            <Button
              className="mr-2"
              size="sm"
              color="primary"
              onClick={() => {
                cancel(idx);
              }}
            >
              <FormattedMessage id="value.yes" />
            </Button>
            <Button size="sm" color="primary" outline onClick={this.props.close}>
              <FormattedMessage id="value.no" />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CervitemOrder;
