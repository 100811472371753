import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Alert, Button } from 'reactstrap';
import { openLink } from 'libs/Utils';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

import './Support.scss';

const remoteSupportUrl = 'https://www.377.co.kr';

class Support extends Component {
  handleRSDownload = async (idx) => {
    if (window.rq) {
      window.rq.remoteSupport(idx);
    } else {
      window.location.href = `/api/remote-support/${idx}`;
    }
  };

  handleGoRemoteSupportSite = (e) => {
    e.preventDefault();
    openLink(remoteSupportUrl);
  };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="container-fluid h-flex">
        <Card className="Support">
          <CardHeader>
            <FormattedMessage id="comp.support.title.remoteAssistance" />
          </CardHeader>
          <CardBody>
            <p className="desc">
              <FormattedMessage id="comp.support.content1" />
            </p>
            {/* <Alert color="info" fade={false}>
              <FormattedMessage id="comp.support.content2"/>
            </Alert>
            <p>
              <Button size="lg" color="primary" outline={true} onClick={() => this.handleRSDownload(1)}>1</Button>{' '}
              <Button size="lg" color="primary" outline={true} onClick={() => this.handleRSDownload(2)}>2</Button>{' '}
              <Button size="lg" color="primary" outline={true} onClick={() => this.handleRSDownload(3)}>3</Button>{' '}
            </p> */}
            <p>
              <Button size="lg" outline={true} color="primary" onClick={this.handleGoRemoteSupportSite}>
                <FormattedMessage id="button.support.romoteAssistanceSite" />
              </Button>
            </p>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Support;
