import { observable, action, runInAction, toJS } from 'mobx';
import { toast } from 'react-toastify';

import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

const MAX_QUANTITY = 999;
const MIN_QUANTITY = 1;
export default class CervitemProductStore {
  @observable product = {};
  @observable thumbImgList = [];
  @observable mask = false;
  @observable productIdx = null;
  @observable selectedOptions = {};

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
  }

  @action
  init = () => {
    this.mask = false;
    this.product = {};
    this.thumbImgList = [];
    this.productIdx = null;
    this.selectedOptions = {};
  };

  @action
  setProductIdx = (idx) => {
    this.productIdx = idx;
  };

  @action
  getProductIdx = () => {
    return this.productIdx;
  };

  @action
  setMask = (bool) => {
    this.mask = bool;
  };

  @action
  setData = (data) => {
    this.thumbImgList = toJS(data.thumbImgList);
    this.product = data;

    if (!this.product.optionName) {
      const option = this.product.options.filter((item) => item.name === 'default');
      this.setSelectedOptions(option.length > 0 ? option[0].idx : -1, 1);
    }
  };

  @action
  quantityIncrement = (optionKey) => {
    if (this.selectedOptions[optionKey] < MAX_QUANTITY) this.setSelectedOptions(optionKey, this.selectedOptions[optionKey] + 1);
  };

  @action
  quantityDecrement = (optionKey) => {
    if (this.selectedOptions[optionKey] > MIN_QUANTITY) this.setSelectedOptions(optionKey, this.selectedOptions[optionKey] - 1);
  };

  @action
  setQuantity = (quantity, optionKey) => {
    quantity = parseInt(quantity);
    if (isNaN(quantity)) {
      toast.warn(this.locale.fm('store.cervitem.checkNum'));
      return;
    }

    if (quantity > MAX_QUANTITY) this.setSelectedOptions(optionKey, MAX_QUANTITY);
    else if (quantity < MIN_QUANTITY) this.setSelectedOptions(optionKey, MIN_QUANTITY);
    else this.setSelectedOptions(optionKey, quantity);
  };

  @action
  setOption = (idx) => {
    if (idx in this.selectedOptions) {
      this.setSelectedOptions(idx, this.selectedOptions[idx] + 1);
    } else {
      this.setSelectedOptions(idx, 1);
    }
  };

  @action
  setSelectedOptions = (optionKey, quantity) => {
    this.selectedOptions = {
      ...this.selectedOptions,
      [optionKey]: quantity
    };
  };

  @action
  deleteOption = (optionKey) => {
    delete this.selectedOptions[optionKey];
    this.selectedOptions = { ...this.selectedOptions };
  };

  @action
  loadData = async (idx) => {
    this.setMask(true);
    try {
      const response = await axios.get(`/api/rq/cervitem/${idx}`);
      runInAction('CervitemStore : async loadData -> success', () => {
        this.setData(response.data.data);
        this.setMask(false);
      });
    } catch (error) {
      runInAction('CervitemStore : async loadData -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  addToCart = async (idx) => {
    if (this.product.optionName && Object.keys(this.selectedOptions).length === 0) {
      toast.warn(this.locale.fm('store.cervitem.selectOption'));
      return;
    }

    let isRightQuantity = true;
    Object.keys(this.selectedOptions).forEach((key) => {
      if (this.selectedOptions[key] < MIN_QUANTITY || this.selectedOptions[key] > MAX_QUANTITY) {
        isRightQuantity = false;
      }
    });
    if (!isRightQuantity) {
      toast.warn(this.locale.fm('store.cervitem.cart.limitquantity'));
      return;
    }

    const cartItem = {
      itemIdx: idx,
      itemOptionMap: this.selectedOptions
    };
    try {
      const response = await axios.put(`/api/rq/cervitem/cart/add`, cartItem);
      if (response.data.success) {
        toast.success(this.locale.fm('store.cervitem.cart.success'));
        this.root.cervicoRequest.init();
        this.root.cervitemCart.loadData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      ajaxErrorHandler(error);
    }
  };
}
