import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import './ProcessLayer.scss';

@inject('screen')
@observer
class ProcessLayer extends Component {
  render() {
    const { processLayer } = this.props.screen;

    return processLayer ? (
      <div className="ProcessLayer">
        <div className="lds-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    ) : (
      <></>
    );
  }
}

export default ProcessLayer;
