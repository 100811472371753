import { observable, action, runInAction } from 'mobx';
import { searchSerialize } from 'libs/Utils';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

const defaultAddrInfo = {
  contact: '',
  zonecode: '',
  address: '',
  addressDetail: ''
};

export default class CervitemAddrStore {
  @observable open = false;
  @observable shippingType = false;
  @observable shippingAddrType = 'default';
  @observable shippingAddrInfo = null;
  @observable addressInfo = { ...defaultAddrInfo };
  @observable defaultAddr = false;

  @action
  init = () => {
    this.open = false;
    this.shippingType = false;
    this.shippingAddrType = 'default';
    this.addressInfo = { ...defaultAddrInfo };
    this.defaultAddr = false;
  };

  @action
  setOpen = () => {
    this.open = true;
  };

  @action
  close = () => {
    this.open = false;
  };

  @action
  setAddressInfo = (data) => {
    this.addressInfo = {
      ...this.addressInfo,
      contact: data.contact,
      zonecode: data.zonecode,
      address: data.address,
      addressDetail: data.addressDetail
    };
  };

  @action
  changeShippingType = () => {
    this.shippingType = !this.shippingType;
  };

  @action
  changeShippingAddrType = (addrType) => {
    this.shippingAddrType = addrType;
    if (addrType !== 'new') this.setAddressInfo(this.shippingAddrInfo[addrType]);
    else this.setAddressInfo(defaultAddrInfo);
  };

  @action
  changeDefaultAddr = () => {
    this.defaultAddr = !this.defaultAddr;
  };

  @action
  changeAddr = (key, value) => {
    if (key === 'contact') {
      if (value.length > 11 || isNaN(value)) return;
    }

    this.addressInfo[key] = value;
  };

  @action
  onCompletePost = (data) => {
    this.addressInfo = {
      ...this.addressInfo,
      zonecode: data.zonecode,
      address: data.address
    };
    this.open = false;
  };

  @action
  loadData = async () => {
    try {
      const response = await axios.get('/api/rq/cervitem/list/address');
      runInAction('CervitemAddrStore : async loadData -> success', () => {
        this.shippingAddrInfo = response.data.data;
        this.setAddressInfo(this.shippingAddrInfo[this.shippingAddrType]);
      });
    } catch (error) {
      runInAction('CervitemAddrStore : async loadData -> catch', () => {
        ajaxErrorHandler(error);
      });
    }
  };
}
