import { observable, action, computed, runInAction } from 'mobx';
import axios from 'axios';
import { addCheckedKey, ajaxErrorHandler } from 'libs/Utils';

export default class CerviAiResultWindowWithoutPatInfoStore {
  //resultList 는 {idx, statusCode} 의 배열임!!
  @observable resultList = [];
  @observable currentIndex = 0;
  @observable result = null;
  @observable currentPic = 0;
  @observable preview = null;
  @observable pictureIndex = 0;
  @observable roiList = [];

  constructor(root) {
    this.root = root;
    this.init();
  }

  //TODO init 로 변경
  @action
  init = () => {
    this.resultList.replace([]);
    this.currentIndex = 0;
    this.result = null;
    this.pictureIndex = 0;
    this.currentPic = 0;
    this.preview = null;
    this.roiList = [];
  };

  @action
  setRoiList = (pIdx) => {
    this.roiList = addCheckedKey(this.result[pIdx].roiList);
  };

  @action
  setCurrentPic = (pIdx) => {
    this.setRoiList(pIdx);
    this.currentPic = pIdx;
  };

  @action
  toggleRoiCheck = (idx) => {
    this.roiList.forEach((el) => {
      if (el.idx === idx) {
        el.checked = !el.checked;
      }
    });
  };

  @action
  changeRoiName = (idx, value) => {
    this.roiList.forEach((el) => {
      if (el.idx === idx) {
        el.roiName = value;
      }
    });
  };

  @action
  setList = (data, idx) => {
    const filterList = data.filter((img) => img.aiIdx && img.rqDspResult);
    const cIdx = filterList.findIndex((item) => item.idx === idx);
    this.roiList = addCheckedKey(filterList[this.currentPic].roiList);
    this.result = filterList;
    this.setCurrentPic(cIdx);
  };

  @action
  setResult = async (idx) => {
    this.root.screen.setProcessLayer(true);

    try {
      const res = await axios.get(`/api/rq/cerviai/${idx}`);
      runInAction('CerviAiResultWindowStore : async setResult -> success', () => {
        this.result = res.data;
      });
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };

  @action
  nextResult = () => {
    this.currentIndex++;
    this.setResult();
  };

  @action
  prevResult = () => {
    this.currentIndex--;
    this.setResult();
  };

  @action
  setPictureIndex = (pictureIndex) => {
    this.pictureIndex = pictureIndex;
  };
  @action
  setPreviewBox = (idx) => {
    const p = this.dataGrid.rows.find((r) => r.idx === idx);

    this.preview = p;
    this.currentPic = 0;
  };
  //zoom 화면
  @action
  zoom = () => {
    const r = this.result;
    if (r !== null) {
      const pictureList = [...Array(parseInt(r.imgCount))].map((_, i) => {
        return {
          url: `/api/rq/cervico/worklist/${r.idx}/picture/${i + 1}`,
          title: `환자명 : ${r.patName}`
        };
      });
      this.root.zoomWindow.setPictureList(pictureList, this.pictureIndex);
    }
  };

  @action
  zoomForAndroid = (pIdx) => {
    const r = this.result;
    if (r !== null) {
      const pictureList = r.map((img) => {
        return {
          url: `/api/rq/cerviai/${img.aiIdx}/image`,
          title: `Result : ${img.rqDspResult}`
        };
      });
      this.root.zoomWindow.setPictureList(pictureList, pIdx);
    }
  };

  @computed
  get show() {
    return this.result !== null;
  }

  @computed
  get isLastResult() {
    return this.resultList.length === this.currentIndex + 1;
  }

  @computed
  get isFirstResult() {
    return this.currentIndex === 0;
  }

  @computed
  get position() {
    return `${this.currentIndex + 1} / ${this.resultList.length}`;
  }

  @computed
  get completeCount() {
    return this.resultList.filter((r) => r.statusCode > 6).length;
  }
}
