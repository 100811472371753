import moment from 'moment';
import ec from 'escape-html';
import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { toast } from 'react-toastify';
import { ConfirmModal } from 'components/common';
import browser from 'browser-detect';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import cx from 'classnames';

const currentBrowser = browser();

//*global ajax error handler*/
export const ajaxErrorHandler = (error, callback) => {
  const _locale = window._locale;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    if (error.response.status === 401) {
      toast.error(_locale.fm('value.err.authorization'), {
        onClose: () => {
          window.location = '/';
        }
      });
    } else {
      toast.error(`${_locale.fm('value.err.service')} : ${error.response.status}`);
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request);
    toast.error(`${_locale.fm('value.err.service')} : NORES`);
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log("Error", error.message);
    toast.error(`${_locale.fm('value.err.service')} : ${error.message}`);
  }

  if (callback) {
    callback();
  }
};

export const openLink = (url) => {
  if (window.rq) {
    window.rq.openLink(url);
  } else {
    window.open(url);
  }
};

/*그리드 렌더러*/
export const testTypeCellRenderer = ({ data: worklist }) => {
  const _locale = window._locale;
  const res = [];
  if (worklist.isER) res.push(badgeHtml(_locale.fm('value.isER'), 'danger'));
  if (worklist.refTestCase) res.push(badgeHtml(_locale.fm('value.testtype.2'), 'warning'));

  return res.join(' ');
};

export const statusCodeCellRenderer = ({ value }) => {
  const _locale = window._locale;
  switch (value) {
    case 0:
      return _locale.fm('value.status.0');
    case 1:
      return _locale.fm('value.status.a');
    case 7:
      return _locale.fm('value.status.7');
    case 8:
      return _locale.fm('value.status.8');
    case 9:
      return _locale.fm('value.status.9');
    default:
      return _locale.fm('value.status.b');
  }
};

export const smsReqTypeMap = {
  써비코_재검진: { code: 1, field: 'smsRetestDate', title: '써비코 재검진 SMS' },
  써비코_검사결과: { code: 2, field: 'smsRsltDate', title: '써비코 검사결과 SMS' },
  LBC_재검진: { code: 5, field: 'smsRetestDate', title: 'LBC 재검진 SMS' },
  LBC_검사결과: { code: 6, field: 'smsRsltDate', title: 'LBC 검사결과 SMS' },
  STD_재검진: { code: 7, field: 'smsRetestDate', title: 'STD 재검진 SMS' },
  STD_검사결과: { code: 8, field: 'smsRsltDate', title: 'STD 검사결과 SMS' },
  HPV_재검진: { code: 9, field: 'smsRetestDate', title: 'HPV 재검진 SMS' },
  HPV_검사결과: { code: 10, field: 'smsRsltDate', title: 'HPV 검사결과 SMS' },
  STDRT_재검진: { code: 11, field: 'smsRetestDate', title: 'STD-RT 재검진 SMS' },
  STDRT_검사결과: { code: 12, field: 'smsRsltDate', title: 'STD-RT 검사결과 SMS' },
  COVID_검사결과: { code: 20, field: 'smsRsltDate', title: 'Covid-19 검사결과 SMS' }
};

export const statusCodeCellClassRules = {
  'status-0': 'x === 0',
  'status-1': 'x === 1',
  'status-2-6': 'x > 1 && x < 7',
  'status-7': 'x === 7',
  'status-8': 'x === 8',
  'status-9': 'x === 9'
};

export const ClassResultMap = {
  N: { rqDspResultKo: 'Normal', rqDspResultEn: 'Normal', color: 'primary' },
  A: { rqDspResultKo: 'Normal', rqDspResultEn: 'Normal', color: 'success' },
  P1: { rqDspResultKo: 'Abnormal', rqDspResultEn: 'Abnormal', color: 'coral' },
  P2: { rqDspResultKo: 'Abnormal', rqDspResultEn: 'Abnormal', color: 'crimsonRed' },
  TD: { rqDspResultKo: 'Inadequate', rqDspResultEn: 'Inadequate', color: 'secondary' }
};

export const resultCellRenderer = ({ value, data }) => {
  const _locale = window._locale;
  //txtResult 셀..
  if (data.statusCode < 7) return _locale.fm('value.status.b');
  return value;
};

export const otherTestResultCellRenderer = ({ value, data }) => {
  const _locale = window._locale;
  //cervico 와 other 가 섞여 들어온다...
  if (data.statusCode > 6) return value;
  if (data.reportId && data.completed) return data.result;
  return _locale.fm('value.status.b');
};

export const dateCellRenderer = ({ value }) => {
  if (value === null) return '';
  return moment(value).format('YYYY-MM-DD HH:mm:ss');
};

export const dateCellHmRenderer = ({ value }) => {
  if (value === null) return '';
  return moment(value).format('YYYY-MM-DD HH:mm');
};

export const receivedDateCellRenderer = ({ value, data }) => {
  if (data.statusCode === 0) return '';
  return dateCellRenderer({ value });
};

export const shortDateCellRenderer = ({ value }) => {
  return moment(value).format('YYYY-MM-DD');
};
export const isRequestCellRenderer = ({ value }) => {
  return value ? '✅' : null;
};

export const socialNumRenderer = ({ value }) => {
  const v = ec(value);
  return v ? (v.length > 6 ? `${v.substring(0, 6)}-${v.substring(6, 7)}` : v) : '';
};

export const rqDspResultCellRenderer = ({ value }) => {
  const _locale = window._locale;
  if (value === null) return '';

  if (ClassResultMap[value]) {
    if (_locale._locale === 'ko') return badgeHtml(ClassResultMap[value].rqDspResultKo, ClassResultMap[value].color);
    else if (_locale._locale === 'en') return badgeHtml(ClassResultMap[value].rqDspResultEn, ClassResultMap[value].color);
  }
};
/*그리드 intl */
export const localeTextFunc = (key, defaultValue) => {
  const _locale = window._locale,
    gridKey = 'grid.' + key,
    value = _locale.fm(gridKey);
  return value === gridKey ? defaultValue : value;
};

/*휴대폰 패턴*/
export const phonePattern = /^01[016789]\d{7,8}$/;

/*주민번호 나이계산기*/
export const calcSocialNum = (index, s1, s2, age) => {
  const currYY = moment().year() % 100;
  const ret = [s1, s2, age];

  if (index === 1) {
    //1번째 칸
    if (ret[0].length === 1) ret[0] += '00101';
    if (ret[0].length === 2) ret[0] += '0101';

    if (ret[0].length > 2 && ret[0].length < 6) {
      ret[0] = ret[0].padEnd(6, '0');
    }
    if (ret[0].length === 6 && ret[1].length === 0) {
      const birthYY = parseInt(ret[0].substring(0, 2));
      //ret[1] = `${birthYY > currYY ? "2" : "4"}000000`;
      ret[1] = `${birthYY > currYY ? '2' : '4'}`;
    }
  } else {
    //2번째 칸
    if (ret[1].length === 0) {
      ret[0] = '';
    } else if (ret[1].length > 0 && ret[1].length < 7 && ret[0].length === 6) {
      //ret[1] = ret[1].padEnd(7, "0");
    } else if (ret[0].length < 6) {
      ret[1] = '';
    }
  }

  //나이구하기
  if (ret[0].length === 6) {
    const yy = parseInt(ret[0].substring(0, 2));
    const mm = parseInt(ret[0].substring(2, 4));
    const dd = parseInt(ret[0].substring(4, 6));

    const byyyy = yy > currYY ? 1900 + yy : 2000 + yy;
    const bmm = mm > 0 && mm < 13 ? mm : 1;

    let birthday = moment([byyyy, bmm - 1, dd]);
    //유효하지 않으면 1일로 처리
    if (!birthday.isValid()) birthday = moment([byyyy, bmm, 1]);

    ret[2] = moment().startOf('day').diff(birthday, 'years');
  }
  return ret;
};

//confirm 메서드는 반드시 async function 안에서 수행되어야 한다!!!
export const confirm = (msg) => {
  const instance = new Confirm(msg);
  return instance.open();
};

//confirm 생성
class Confirm {
  constructor(msg) {
    this.message = msg;
    this.el = document.createElement('div');
  }

  open() {
    let confirmPromise = new Promise((resolve) => {
      render(
        <ConfirmModal
          message={this.message}
          onClose={(result) => {
            resolve(result);
          }}
        />,
        this.el
      );
    });

    return confirmPromise;
  }
}

/* search param serialize */
export const searchSerialize = (search = {}, def = {}) => {
  //search.key=value 형식으로 만들기 위한 함수
  return Object.entries(search).reduce(paramReducer, def);
};
function paramReducer(search, param) {
  search[`search.${param[0]}`] = param[1];
  return search;
}

export const isWindows = () => {
  return currentBrowser.os.indexOf('Windows') > -1;
};

/* html generator */
const badgeHtml = (text, color, cls = '') => {
  return `<span class="badge badge-${color} ${cls}">${text}</span>`;
};

export const numberToString = (data) => {
  if (data === null) return null;
  else return `${data}`;
};

export const nullableNumber = (data) => {
  if (data === null) return null;
  else return data;
};

export const dateToString = (data) => {
  if (data === null) return '';
  else return moment(data).format('YYYY-MM-DD');
};

export const AI_Alert = {
  Normal: {
    color: 'primary',
    message1: (
      <div className="mt-2" style={{ fontSize: '15px' }}>
        <FormattedMessage id="field.result.normal" />
      </div>
    )
  },
  Suspicious: {
    color: 'success',
    message1: (
      <div className="mt-2" style={{ fontSize: '15px' }}>
        <FormattedMessage id="field.result.Atypical" />
      </div>
    ),
    message2: (
      <div>
        <p>
          <FormattedMessage id="field.result.Atypical2" />
        </p>
      </div>
    )
  },
  Abnormal: {
    color: 'danger',
    message1: (
      <div className="mt-2" style={{ fontSize: '15px' }}>
        <p>
          <FormattedMessage
            id="field.result.abnormal"
            values={{
              abnormalKR: '비정상(Abnormal)',
              abnormalEN: 'Abnormal'
            }}
          />
        </p>
      </div>
    ),
    message2: (
      <div>
        <p>
          <FormattedMessage
            id="field.result.abnormal2"
            values={{
              hpvKR: 'HPV 검사'
            }}
          />
        </p>
      </div>
    )
  },
  Inadequate: {
    color: 'warning',
    message1: (
      <div className="mt-2" style={{ fontSize: '15px' }}>
        <p>
          <FormattedMessage id="field.result.TD" />
        </p>
      </div>
    )
  }
};

export const aiAlertMessage2 = (rslt, prob) => {
  const message =
    rslt === 'N' ? (
      <Fragment>
        <p className="mt-4">음성(Negative)으로 예측됩니다. (신뢰점수 : {prob})</p>
      </Fragment>
    ) : rslt === 'A' ? (
      <Fragment>
        <p className="mt-4">정상과 비정상의 경계인 비정형(Atypical)이 의심됩니다. (신뢰점수 : {prob})</p>
      </Fragment>
    ) : rslt === 'P1' ? (
      <Fragment>
        <p className="mt-4">저등급 병변(Low grade lesion)이 의심됩니다. (신뢰점수 : {prob})</p>
      </Fragment>
    ) : rslt === 'P2' ? (
      <Fragment>
        <p className="mt-4">고등급 병변(High grade lesion)이 의심됩니다. (신뢰점수 : {prob})</p>
      </Fragment>
    ) : (
      ''
    );

  return message;
};
export const resultGraphic = (rqDspResult, classResult) => {
  return (
    <div className="graph-container">
      {rqDspResult === 'Inadequate' ? (
        <Container className="classResult-TD">{/* <h1>Technically Defective</h1> */}</Container>
      ) : (
        <Container className="classResult-grid">
          <Row className="text-center">
            <Col xs={12}>
              <div className="text-dark d-flex justify-content-around">
                <h5>Normal</h5>
                <h5>Abnormal</h5>
              </div>
              <div
                className={cx('border border-3 rounded-lg resultbox', {
                  'border-blue bg-normal': classResult === 'N',
                  'border-green bg-atypical': classResult === 'A',
                  'border-danger bg-positive1': classResult === 'P1',
                  'border-danger bg-positive2': classResult === 'P2'
                })}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export const csCellRenderer = ({ value }) => {
  if (value === null) return '';
  return `${value} ~ ${value + 10}`;
};

export const addCheckedKey = (roiList) => {
  return roiList.map((roi) => {
    return { ...roi, checked: true };
  });
};
