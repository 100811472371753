import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, CustomInput, Card, CardBody, FormGroup } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { FaCheck, FaTimes, FaBox } from 'react-icons/fa';
import { PictureBox } from 'components/common';
import cx from 'classnames';
import PatForm from '../cervico/request/PatForm';
import moment from 'moment';
import { CerviBoxResultGridCardForSearch } from '.';

import './CerviBoxCopyWindow.scss';

@inject('locale')
@inject('cerviBoxCopy')
@inject('cerviBoxForSearch')
@observer
class CerviBoxCopyWindow extends Component {
  cerviBoxGridApi = null;

  handleResultGridReady = (params) => {
    this.cerviBoxGridApi = params.api;
  };

  handleResetGridScrollbar = () => {
    this.cerviBoxGridApi.ensureIndexVisible(0, 'top');
  };

  handleResultGridReady = (params) => {
    this.cerviBoxGridApi = params.api;
  };

  handleResetGridScrollbar = () => {
    this.cerviBoxGridApi.ensureIndexVisible(0, 'top');
  };

  handleSave = () => {
    const checkedList = this.props.cerviBoxCopy.list.filter((e) => e.checked).map((p) => p.idx);
    const targetCerviboxList = this.cerviBoxGridApi.getSelectedRows().map((d) => d.idx);

    this.props.cerviBoxCopy.save(checkedList, targetCerviboxList);
  };

  handleClose = () => {
    this.props.cerviBoxCopy.close();
  };

  handlePictureCheckChange = (idx, e) => {
    this.props.cerviBoxCopy.checkImg(idx, e.target.checked);
  };

  handleDelete = (idx) => {
    this.props.cerviBoxCopy.remove(idx);

    if (this.props.cerviBoxCopy.list.length === 0) this.handleClose();
  };

  handleCerviBoxSearchClick = (patName, chartNum, socialNum1, socialNum2) => {
    this.props.cerviBoxForSearch.changeSearch('startDate', null);
    this.props.cerviBoxForSearch.changeSearch('endDate', null);
    this.props.cerviBoxForSearch.changeSearch('patName', patName);
    this.props.cerviBoxForSearch.changeSearch('chartNum', chartNum);
    this.props.cerviBoxForSearch.changeSearch('socialNum', socialNum1);
    this.props.cerviBoxForSearch.triggerSearch();
  };
  handleRemoveOriginalCheck = (e) => {
    this.props.cerviBoxCopy.setRmOriginalCheck(e.target.checked);
  };
  render() {
    const {
      locale,
      cerviBoxCopy: { list }
    } = this.props;
    return (
      <Modal className="CerviBoxCopyWindow" isOpen={this.props.cerviBoxCopy.open} toggle={this.handleClose} size="lg" zIndex="940">
        <ModalHeader toggle={this.handleClose}>
          <FaBox /> {locale.fm('comp.cervibox.title')}
        </ModalHeader>
        <ModalBody>
          <Card style={{ marginBottom: '10px' }}>
            <CardBody className="thumb-list">
              <ul>
                {list.map((p) => (
                  <li key={p.idx} className={cx({ checked: p.checked })}>
                    <div className="take-date d-flex">
                      <CustomInput
                        type="checkbox"
                        id={`box-p-${p.idx}`}
                        label={moment(p.takeDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}
                        onChange={(e) => this.handlePictureCheckChange(p.idx, e)}
                        checked={p.checked}
                      />
                    </div>
                    <div className="picture">
                      <PictureBox small src={`${p.url}?thumb=true`} />
                    </div>
                  </li>
                ))}
              </ul>
            </CardBody>
          </Card>
          <FormGroup>
            <PatForm useCerviBoxMemoYn useCerviBoxSearch useCerviBoxCopy onSearchClick={this.handleCerviBoxSearchClick} />
          </FormGroup>
          <FormGroup>
            <CerviBoxResultGridCardForSearch onGridReady={this.handleResultGridReady} onAfterMove={this.handleResetGridScrollbar} />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex flex-wrap align-items-center">
          <CustomInput type="checkbox" id="RemoveOriginalCheck" label={locale.fm('store.cervibox.copy')} onChange={(e) => this.handleRemoveOriginalCheck(e)} className="mr-auto" />
          <Button color="primary" onClick={this.handleSave} size="sm" style={{ width: '100px' }}>
            <FaCheck /> {locale.fm('button.copy')}
          </Button>
          <Button color="secondary" onClick={this.handleClose} size="sm" style={{ width: '100px' }}>
            <FaTimes /> {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CerviBoxCopyWindow;
