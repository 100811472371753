import React, { Component } from 'react';
import { Input, Card, CardHeader, CardBody, Button, FormGroup, Form, Label, ButtonGroup, Badge } from 'reactstrap';
import { DateTimePicker } from 'components/common';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { FaSearch, FaTimes } from 'react-icons/fa';
import './CerviBox.scss';
import moment from 'moment';

@inject('locale')
@inject('cerviBox')
@observer
class CerviBoxSearchFormCard extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.cerviBox.changeSearch(i.name, i.value);
  };

  handleSearchClick = () => {
    this.props.cerviBox.triggerSearch();
  };

  handleResetClick = () => {
    this.props.cerviBox.triggerReset();
  };

  handleLast1wClick = () => {
    this.props.cerviBox.changeSearch('startDate', moment().startOf('day').add(-1, 'week').toDate());
    this.props.cerviBox.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1mClick = () => {
    this.props.cerviBox.changeSearch('startDate', moment().startOf('day').add(-1, 'month').toDate());
    this.props.cerviBox.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast3mClick = () => {
    this.props.cerviBox.changeSearch('startDate', moment().startOf('day').add(-3, 'month').toDate());
    this.props.cerviBox.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1yClick = () => {
    this.props.cerviBox.changeSearch('startDate', moment().startOf('day').add(-1, 'year').toDate());
    this.props.cerviBox.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast3yClick = () => {
    this.props.cerviBox.changeSearch('startDate', moment().startOf('day').add(-3, 'year').toDate());
    this.props.cerviBox.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  render() {
    const { locale } = this.props;
    const { search: s } = this.props.cerviBox;
    const hc = this.handleSearchChange;

    return (
      <Card className="searchFormCard">
        <CardHeader>
          <FormattedMessage id="comp.cervibox.title.searchcondition" />{' '}
          <Badge color="info">
            <FormattedMessage id="field.beta" />
          </Badge>
        </CardHeader>
        <CardBody>
          <Form className="search">
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.regDate" />
                </Label>
                <DateTimePicker name="startDate" value={s.startDate} onChange={hc} />
                ~
                <DateTimePicker name="endDate" value={s.endDate} onChange={hc} />
              </FormGroup>
              <FormGroup>
                <ButtonGroup className="date-btns" size="sm">
                  <Button type="button" onClick={this.handleLast1wClick} outline>
                    <FormattedMessage id="button.last1weeks" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1mClick} outline>
                    <FormattedMessage id="button.last1months" />
                  </Button>
                  <Button type="button" onClick={this.handleLast3mClick} outline>
                    <FormattedMessage id="button.last3months" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1yClick} outline>
                    <FormattedMessage id="button.last1years" />
                  </Button>
                  <Button type="button" onClick={this.handleLast3yClick} outline>
                    <FormattedMessage id="button.last3years" />
                  </Button>
                </ButtonGroup>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.patname" />
                </Label>
                <Input className="ime" bsSize="sm" name="patName" value={s.patName} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.chartnum" />
                </Label>
                <Input bsSize="sm" name="chartNum" value={s.chartNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.birthday" />
                </Label>
                <Input bsSize="sm" name="socialNum" size="8" value={s.socialNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.target" />
                </Label>
                <Input type="select" bsSize="sm" name="scope" value={s.scope} onChange={hc}>
                  <option value="my">{locale.fm('value.mypat')}</option>
                  <option value="">{locale.fm('value.all')}</option>
                </Input>
              </FormGroup>
            </div>
            {/* <div className="form-row">
              <FormGroup>
                <Label><FormattedMessage id="field.searchword" /></Label>
                <Input className="ime" bsSize="sm" name="searchWord" value={s.searchWord} onChange={hc} onKeyUp={this.handleKeyUpEnter}/>
              </FormGroup>
            </div> */}
            <div className="form-row flex-row-reverse">
              <FormGroup>
                <Button size="sm" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch /> <FormattedMessage id="button.search" />
                </Button>
                <span>&nbsp;</span>
                <Button size="sm" color="light" type="reset" style={{ width: '100px' }} onClick={this.handleResetClick}>
                  <FaTimes /> <FormattedMessage id="button.reset" />
                </Button>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default CerviBoxSearchFormCard;
