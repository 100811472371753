import React, { Component } from 'react';
import { DataGrid, CardMask } from 'components/common';
import { Card, CardBody } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { socialNumRenderer, shortDateCellRenderer } from 'libs/Utils';
import './CervicoRequest.scss';

@inject('locale')
@inject('cervicoRequest')
@observer
class CervicoPatinfoGridCardForSearch extends Component {
  gridApi = null;

  handleGridReady = (params) => {
    this.props.cervicoRequest.init();
    this.gridApi = params.api;
    this.props.onGridReady(params);
  };

  handleMovePage = async (page) => {
    await this.props.cervicoRequest.loadDataGridByEMR(page);
    this.props.onAfterMove();
  };

  handleCellClicked = ({ data }) => {
    this.props.cervicoRequest.changePatinfo('idxHpAiReqInfo', data.idx);
    this.props.cervicoRequest.changePatinfo('patName', data.patName);
    this.props.cervicoRequest.changePatinfo('chartNum', data.chartNum);
    this.props.cervicoRequest.changeRefPatinfo('patName', data.patName);
    this.props.cervicoRequest.changeRefPatinfo('visitDate', data.visitDate);
    if (data.birthDay.length > 0) {
      this.props.cervicoRequest.changePatinfo('socialNum1', data.birthDay.trim().substring(0, 6));
      this.props.cervicoRequest.changePatinfo('socialNum2', data.birthDay.trim().substring(6, 7));
      this.props.cervicoRequest.changeSocialNum(1);
    } else {
      this.props.cervicoRequest.changePatinfo('socialNum1', '');
      this.props.cervicoRequest.changePatinfo('socialNum2', '');
      this.props.cervicoRequest.changePatinfo('age', '');
    }
  };

  render() {
    const { locale } = this.props;

    return (
      <div className="ResultGridCard">
        <Card className="basis-300">
          <CardBody className="np h-flex">
            <DataGrid
              columnDefs={[
                { width: 30, checkboxSelection: true, headerCheckboxSelection: false },
                { headerName: locale.fm('field.visitDate'), field: 'visitDate', width: 130, cellRenderer: shortDateCellRenderer, sortable: true },
                { headerName: locale.fm('field.patname'), field: 'patName', width: 130, sortable: true },
                { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 130, sortable: true },
                { headerName: locale.fm('field.birthday'), field: 'birthDay', width: 80, cellRenderer: socialNumRenderer, sortable: true },
                { headerName: locale.fm('field.age'), field: 'age', width: 70, sortable: true }
              ]}
              rowSelection={'single'}
              suppressRowClickSelection={false}
              showSelectCount={false}
              onGridReady={this.handleGridReady}
              onCellClicked={this.handleCellClicked}
              onMovePage={this.handleMovePage}
              dataGrid={this.props.cervicoRequest.dataGrid}
              context={{
                componentParent: this
              }}
            />
          </CardBody>
          <CardMask show={this.props.cervicoRequest.mask} />
        </Card>
      </div>
    );
  }
}

export default CervicoPatinfoGridCardForSearch;
