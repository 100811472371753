import React, { Component } from 'react';
import { DataGrid } from 'components/common';
import { Card, CardBody, Badge, Button, CardHeader, Progress } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { dateCellRenderer, isRequestCellRenderer, ClassResultMap } from 'libs/Utils';
import { FormattedMessage } from 'react-intl';
import { FcSynchronize } from 'react-icons/fc';
import CerviAiResultEditWindow from './CerviAiResultEditWindow';
import { IoMdRefresh } from 'react-icons/io';

@inject('locale')
@inject('cerviAi')
@inject('cerviAiResultWindow')
@inject('patientAIInfoEditWindow')
@observer
class CerviAiResultGridCard extends Component {
  gridApi = null;
  selected = [];

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
    this.props.cerviAi.loadDataGrid();
  };

  handleGridSelectionChanged = (l) => {
    this.selected = l;
  };

  handleMovePage = async (page) => {
    await this.props.cerviAi.loadDataGrid(page);
    this.props.onAfterMove();
  };

  handleCellClicked = ({ data }) => {
    this.props.cerviAi.setPreviewBox(data.idx);
  };

  handleSelectAllClicked = () => {
    this.gridApi.selectAll();
  };

  handleEditBtnClick = (idx) => {
    this.props.patientAIInfoEditWindow.setPatinfo(this.gridApi.getRowNode(idx).data, 'ai');
  };

  handleDeselectClicked = () => {
    this.gridApi.deselectAll();
  };

  handleDeleteClicked = () => {
    this.props.cerviAi.remove(this.selected.map((s) => s.idx));
  };

  handleGridRowClick = (data) => {
    this.props.cerviAiResultWindow.setList(data);
  };

  handleDetailBtnClick = (data) => {
    this.props.cerviAiResultWindow.setList(data);
  };

  handleAIRequestBtnClick = async (picList) => {
    await this.props.cerviAi.analy(picList, 'req');
  };

  handleReloadClick = async () => {
    await this.props.cerviAi.triggerSearch();
  };

  refHeaderName = () => {
    const progress = (
      <Progress multi style={{ border: '1px solid gray' }}>
        <Progress bar color={'primary'} value={25} />
        <Progress bar color={'success'} value={25} />
        <Progress bar color={'coral'} value={25} />
        <Progress bar color={'crimsonRed'} value={25} />
      </Progress>
    );

    return this.props.locale.fm('field.ref') + progress;
  };

  render() {
    const { locale } = this.props;

    return (
      <div className="ResultGridCard flex-grow-1 h-flex">
        <Card className="basis-300">
          <CardBody className="np h-flex">
            <DataGrid
              columnDefs={[
                { width: 30, checkboxSelection: true, headerCheckboxSelection: true },
                { headerName: locale.fm('field.reqdate'), field: 'regDate', width: 130, sortable: true, cellRenderer: dateCellRenderer },
                { headerName: locale.fm('field.patname'), field: 'patName', width: 80, sortable: true },
                { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 100, sortable: true },
                { headerName: locale.fm('field.birthday'), field: 'birthDay', width: 80, sortable: true },
                { headerName: locale.fm('field.age'), field: 'age', width: 50, sortable: true },
                {
                  headerName: locale.fm('field.AIResult'),
                  field: 'classResult',

                  cellRenderer: 'aiResultCellRenderer',
                  cellStyle: () => ({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }),
                  width: 90,
                  sortable: true
                },
                {
                  headerName: locale.fm('field.ref'),
                  field: 'classResult',
                  cellRenderer: 'aiResultRefCellRenderer',
                  cellStyle: () => ({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }),
                  width: 130,
                  sortable: true
                },
                {
                  headerName: locale.fm('field.requestYN'),
                  field: 'isRequest',
                  cellRenderer: isRequestCellRenderer,
                  cellStyle: () => ({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }),
                  width: 40,
                  sortable: true
                },
                {
                  headerName: locale.fm('field.detail'),
                  width: 60,
                  cellRenderer: 'detailClickRenderer',
                  cellClass: 'button'
                },
                { headerName: locale.fm('field.patinfo'), width: 90, cellRenderer: 'editClickRenderer', cellClass: 'button' }
              ]}
              rowSelection={'multiple'}
              suppressRowClickSelection={false}
              onGridReady={this.handleGridReady}
              onRowDoubleClicked={this.handleGridRowClick}
              onSelectionChanged={this.handleGridSelectionChanged}
              onCellClicked={this.handleCellClicked}
              onMovePage={this.handleMovePage}
              dataGrid={this.props.cerviAi.dataGrid}
              redraw={this.props.cerviAi.t}
              context={{
                componentParent: this
              }}
              frameworkComponents={{
                editClickRenderer: EditClickRenderer,
                detailClickRenderer: DetailClickRenderer,
                aiResultCellRenderer: AIResultCellRenderer,
                aiResultRefCellRenderer: AIResultRefCellRenderer
              }}
            />
          </CardBody>
        </Card>
        <CerviAiResultEditWindow />
      </div>
    );
  }
}

class DetailClickRenderer extends Component {
  invokeParentMethod = (d) => {
    //this.props.data 는 render 시에 결정되어 수정된 값 반영 안됨.
    this.props.context.componentParent.handleDetailBtnClick(this.props.data);
  };

  render() {
    return (
      <span onClick={this.invokeParentMethod} aria-label="search" role="img" style={{ cursor: 'pointer' }}>
        🔎
      </span>
    );
  }
}
class AIResultCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReAnaly: this.props ? (this.props.value ? true : false) : false
    };
  }

  invokeParentMethod = async (mode) => {
    if (mode === 're-analy') {
      const pl = this.props.data.aiResultList.map((el) => {
        return el.rqUploadIdx;
      });
      this.props.context.componentParent.handleAIRequestBtnClick(pl);
      this.setState({ isReAnaly: true });
    } else if (mode === 'refresh') {
      this.props.context.componentParent.handleReloadClick();
    }
  };

  render() {
    const { classResult } = this.props.data;
    const mode = {
      RE_ANALY: 're-analy',
      REFRESH: 'refresh'
    };
    if (classResult) {
      return (
        <Badge className="ai-result-badge" color={ClassResultMap[classResult].color}>
          {ClassResultMap[classResult].rqDspResultKo}
        </Badge>
      );
    } else {
      return (
        <>
          {!this.state.isReAnaly ? (
            <Button outline color="warning" onClick={() => this.invokeParentMethod(mode.RE_ANALY)} style={{ cursor: 'pointer', height: '20px', padding: '0 10px 0 10px' }}>
              <FcSynchronize />
            </Button>
          ) : (
            <Button outline color="primary" onClick={() => this.invokeParentMethod(mode.REFRESH)} style={{ cursor: 'pointer', height: '20px', padding: '0 10px 0 10px' }}>
              <IoMdRefresh />
            </Button>
          )}
        </>
      );
    }
  }
}

class AIResultRefCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classResult: this.props.data.classResult
    };
  }

  render() {
    const resultColor = {
      N: 'primary',
      A: 'success',
      P1: 'coral',
      P2: 'crimsonRed'
    };
    const { classResult } = this.props.data;
    let color = '';
    return (
      <Progress multi style={{ border: '1px solid lightgray' }}>
        {Object.keys(resultColor).map((key) => {
          key === classResult ? (color = resultColor[key]) : (color = 'gray600');
          return <Progress bar color={color} value={25} key={key} />;
        })}
      </Progress>
    );
  }
}

class EditClickRenderer extends Component {
  invokeParentMethod = (d) => {
    //this.props.data 는 render 시에 결정되어 수정된 값 반영 안됨.
    this.props.context.componentParent.handleEditBtnClick(this.props.data.idx);
  };

  render() {
    return (
      <button onClick={this.invokeParentMethod} className="btn btn-outline-primary">
        <FormattedMessage id="button.modify" />
      </button>
    );
  }
}

export default CerviAiResultGridCard;
