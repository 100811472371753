import React, { PureComponent } from 'react';
import moment from 'moment';
import './CerviAiResultTable.scss';

import { FormattedMessage } from 'react-intl';
import { inject } from 'mobx-react';
import { ClassResultMap } from 'libs/Utils';

@inject('locale')
class CerviAiResultTable extends PureComponent {
  render() {
    const { locale, result, currentPic } = this.props;
    const { regDate, patName, age, isPreg, g, p, a, comment, classResult } = result;

    const airesult = ClassResultMap[classResult]
      ? locale._locale === 'ko'
        ? ClassResultMap[classResult].rqDspResultKo
        : locale._locale === 'en'
        ? ClassResultMap[classResult].rqDspResultEn
        : ''
      : '';

    return (
      <div className="CervicoResultTable">
        <table>
          <tbody>
            <tr>
              <th>
                <FormattedMessage id="field.reqdate" />
              </th>
              <td>{moment(regDate).format('YYYY-MM-DD HH:mm:ss')}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.patname" />
              </th>
              <td>{patName}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.age" />
              </th>
              <td>{age}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.pregnancy" />
              </th>
              <td>
                {isPreg === null ? (
                  <span className="badge badge-light">
                    <FormattedMessage id="value.unknown" />
                  </span>
                ) : isPreg ? (
                  <span className="badge badge-primary">
                    <FormattedMessage id="value.yes" />
                  </span>
                ) : (
                  <span className="badge badge-secondary">
                    <FormattedMessage id="value.no" />
                  </span>
                )}{' '}
                G:{g === null ? '- ' : g + ' '}
                P:{p === null ? '- ' : p + ' '}
                A:{a === null ? '- ' : a + ' '}
              </td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.doctorcomment" />
              </th>
              <td className="ws-pre-line" dangerouslySetInnerHTML={{ __html: comment }}></td>
            </tr>
            <tr>
              <th>
                <FormattedMessage id="field.AIResult" />
              </th>
              <td>{airesult}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CerviAiResultTable;
