import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { NavLink } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { MdCameraEnhance, MdDesktopMac, MdMessage, MdSettings, MdHeadsetMic, MdInsertChart, MdErrorOutline, MdMoveToInbox } from 'react-icons/md';
import { FaVials, FaVial, FaYoutube, FaBox, FaStore } from 'react-icons/fa';

import { openLink, isWindows } from 'libs/Utils';
import axios from 'axios';

import './Menu.scss';
import { Badge } from 'reactstrap';
@inject('locale')
@inject('auth')
@inject('screen')
class Menu extends Component {
  handleMenuClick = () => {
    if (getComputedStyle(document.querySelector('#content')).marginLeft === '0px') {
      this.props.onToggleMenu();
    }
  };

  handleClientLink = (e) => {
    e.preventDefault();

    openLink(e.currentTarget.href);
  };

  handleOtpLink = async (e) => {
    e.preventDefault();
    const result = await axios.get(e.currentTarget.href);
    const url = `https://report.ntllab.com/webclient/sso.aspx?compcode=${this.props.auth.doctor.compCode}&otp=${result.data.message}`;
    openLink(url);
  };

  render() {
    const { auth, screen } = this.props;

    return (
      <nav id="menu">
        <ul onClick={this.handleMenuClick}>
          {auth.doctor.useCervicoYN ? (
            <li>
              <NavLink exact to="/request" activeClassName="active">
                <MdCameraEnhance />{' '}
                <span>
                  <FormattedMessage id="menu.request" />
                </span>
              </NavLink>
            </li>
          ) : (
            ''
          )}
          {auth.doctor.useCervicoYN && auth.doctor.useAI ? (
            <li>
              <NavLink exact to="/cerviai" activeClassName="active">
                <MdMoveToInbox />{' '}
                <span>
                  <FormattedMessage id="menu.cerviai" />
                </span>
              </NavLink>
            </li>
          ) : (
            ''
          )}
          {auth.doctor.useCervicoYN && auth.doctor.showResult ? (
            <li>
              <NavLink exact to="/result" activeClassName="active">
                <MdDesktopMac />{' '}
                <span>
                  <FormattedMessage id="menu.response" />
                </span>
              </NavLink>
            </li>
          ) : (
            ''
          )}
          {auth.doctor.otherTestYN ? (
            process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
              this.props.locale._locale === 'ko' ? (
                <li>
                  <a href="/api/rq/other-test/otp" onClick={this.handleOtpLink}>
                    <FaVials />{' '}
                    <span>
                      <FormattedMessage id="menu.other" />
                    </span>
                  </a>
                </li>
              ) : (
                ''
              )
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
            this.props.locale._locale === 'ko' ? (
              <li>
                <NavLink exact to="/other-test" activeClassName="active">
                  <FaVial />{' '}
                  <span>
                    <FormattedMessage id="menu.other-test" />
                  </span>
                </NavLink>
              </li>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
            this.props.locale._locale === 'ko' ? (
              <li>
                <NavLink exact to="/sms-result" activeClassName="active">
                  <MdMessage />{' '}
                  <span>
                    <FormattedMessage id="menu.sms" />
                  </span>
                </NavLink>
              </li>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
            <li>
              <NavLink exact to="/report" activeClassName="active">
                <MdInsertChart />{' '}
                <span>
                  <FormattedMessage id="menu.report" />
                </span>
              </NavLink>
            </li>
          ) : (
            ''
          )}
          {auth.doctor.useCervicoYN && process.env.REACT_APP_SERVER_TYPE !== 'vm' && isWindows() ? (
            this.props.locale._locale === 'ko' ? (
              <li>
                <NavLink exact to="/support" activeClassName="active">
                  <MdHeadsetMic />{' '}
                  <span>
                    <FormattedMessage id="menu.remoteAssist" />
                  </span>
                </NavLink>
              </li>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {window.rq ? (
            <li>
              <NavLink exact to="/setting" activeClassName="active">
                <MdSettings />{' '}
                <span>
                  <FormattedMessage id="menu.setting" />
                </span>
              </NavLink>
            </li>
          ) : (
            ''
          )}
          {auth.doctor.useCervicoYN && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
            this.props.locale._locale === 'ko' ? (
              <li>
                <NavLink exact to="/notice" activeClassName="active">
                  <MdErrorOutline />{' '}
                  <span>
                    <FormattedMessage id="menu.notice" />
                  </span>
                  {screen.unreadNoticeCount > 0 ? <span className="badge badge-pill badge-pink">{screen.unreadNoticeCount}</span> : ''}
                </NavLink>
              </li>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {auth.doctor.useCervicoYN && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
            this.props.locale._locale === 'ko' ? (
              <li>
                <NavLink exact to="/video" activeClassName="active">
                  <FaYoutube />{' '}
                  <span>
                    <FormattedMessage id="menu.video" />
                  </span>
                  {screen.unreadVideoCount > 0 ? <span className="badge badge-pill badge-pink">{screen.unreadVideoCount}</span> : ''}
                </NavLink>
              </li>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {auth.doctor.useCervicoYN && auth.doctor.useCerviboxYN && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
            this.props.locale._locale === 'ko' ? (
              <li>
                <NavLink exact to="/cervibox" activeClassName="active">
                  <FaBox />{' '}
                  <span>
                    <FormattedMessage id="menu.cervibox" />{' '}
                    <Badge color="info">
                      <FormattedMessage id="field.beta" />
                    </Badge>
                  </span>
                </NavLink>
              </li>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {auth.doctor.useCervicoYN && auth.doctor.useCervitemYn && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
            this.props.locale._locale === 'ko' ? (
              <li>
                <NavLink exact to="/cervitem-main" activeClassName="active">
                  <FaStore />{' '}
                  <span>
                    <FormattedMessage id="menu.cervitem" />{' '}
                    <Badge color="info">
                      <FormattedMessage id="field.beta" />
                    </Badge>
                  </span>
                </NavLink>
              </li>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </ul>
      </nav>
    );
  }
}

export default Menu;
