import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { PictureBox, CardMask, DropdownList } from 'components/common';
import { Alert, Container, Row, Col, Card, CardBody, Carousel, CarouselItem, CarouselControl, CarouselIndicators, Button, CardFooter, Table } from 'reactstrap';
import { FaAngleDown, FaAngleUp, FaArrowLeft, FaArrowUp, FaWindowClose } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { CervitemNavigation, CervitemOrderConfirmModal } from './components';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import './CervitemProduct.scss';

@inject('cervitemProduct')
@observer
class CervitemProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // idx: this.props.match.params.idx // page version
      idx: this.props.cervitemProduct.getProductIdx() // modal version
    };
  }

  componentDidMount() {
    this.props.cervitemProduct.loadData(this.state.idx);
  }

  componentWillUnmount() {
    this.props.cervitemProduct.init();
  }

  render() {
    const tl = this.props.cervitemProduct.thumbImgList.slice();
    const { detailsImgDir } = this.props.cervitemProduct.product;
    return (
      <Container className="CervitemProduct">
        {/* <CervitemMoveIcon /> */}
        <CervitemNavigation categoryIndex={null} />
        <ScrollToTop />
        <CervitemOrderConfirmModal fromComponent={'product'} />
        <CardMask show={this.props.cervitemProduct.mask} />
        <Row>
          <ThumbImgContainer tl={tl} />
          <ProductInfo itemIdx={this.state.idx} />
        </Row>
        <Row className="mt-3">
          <Col xs="12">
            <Card className="prod-detail-img-container">
              <CardBody>
                <img className="prod-detail-img" src={detailsImgDir} alt="detailimg" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

@inject('cervitemProduct')
@observer
class ThumbImgContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      animating: false
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
  }

  next() {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === this.props.cervitemProduct.thumbImgList.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.state.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.cervitemProduct.thumbImgList.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.state.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { tl } = this.props;
    return (
      <Col xs="12" sm="6">
        <Card className="Cervitem-Info">
          <Carousel className="prod-item-img-carousel" activeIndex={activeIndex} next={this.next} previous={this.previous} interval={null} dark={true}>
            <CarouselIndicators className="prod-item-img-indicator" items={tl} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
            {tl.map((image, idx) => {
              return (
                <CarouselItem className="prod-item" onExiting={() => this.setState({ animating: true })} onExited={() => this.setState({ animating: false })} key={image.idx}>
                  <PictureBox src={image.fileDir} alt={`Slide ${idx + 1}`} contain />
                </CarouselItem>
              );
            })}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
          </Carousel>
        </Card>
      </Col>
    );
  }
}

@withRouter
@inject('cervitemProduct')
@inject('cervitemOrder')
@inject('locale')
@observer
class ProductInfo extends Component {
  addToCartItem = (idx) => {
    const { product, selectedOptions } = this.props.cervitemProduct;

    if (product.optionName && Object.keys(selectedOptions).length <= 0) {
      toast.warn(this.props.locale.fm('store.cervitem.selectOption'));
      return;
    }
    this.props.cervitemProduct.addToCart(idx);
  };

  handleOpenModal = () => {
    const { product, selectedOptions } = this.props.cervitemProduct;

    if (product.optionName && Object.keys(selectedOptions).length <= 0) {
      toast.warn(this.props.locale.fm('store.cervitem.selectOption'));
      return;
    }
    this.props.cervitemOrder.setOpen();
  };

  render() {
    const { itemIdx, cervitemProduct } = this.props;
    const { product, selectedOptions } = cervitemProduct;
    const { price } = product;

    const totalPrice = Object.keys(selectedOptions)
      .reduce((acc, cur) => {
        return acc + selectedOptions[cur] * price;
      }, 0)
      .toLocaleString();

    return (
      <Col xs="12" sm="6">
        <Card className="Cervitem-Info">
          <CardBody className="d-flex flex-column justify-content-between overflow-auto">
            <ProductDetailInfo />
            <OptionComponent />
          </CardBody>
          <CardFooter>
            <div className="prod-by-btn-container">
              <div className="d-flex align-items-center">
                <span style={{ fontSize: 15, fontWeight: 800, paddingRight: 5 }}>주문금액 : </span>
                <div className="prod-totalprice">{totalPrice + ' 원'}</div>
              </div>
              <Button color="primary" outline size="lg" onClick={() => this.addToCartItem(itemIdx)}>
                <FormattedMessage id="button.cervitem.addcart" />
              </Button>
              <Button color="primary" size="lg" onClick={this.handleOpenModal}>
                <FormattedMessage id="button.cervitem.order" />
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Col>
    );
  }
}

@inject('cervitemProduct')
class ProductDetailInfo extends Component {
  handleOption = (idx) => {
    this.props.cervitemProduct.setOption(idx);
  };

  render() {
    const { product } = this.props.cervitemProduct;
    const { name, price, standard, unit, manufacturer, insuranceCode, optionName, options } = product;

    return (
      <div>
        <div className="prod-buy-header p-2 ml-4">
          <h1 className="prod-buy-header-title">{name}</h1>
        </div>
        <Table>
          <tbody>
            <tr>
              <th>규격</th>
              <td>{standard}</td>
            </tr>
            <tr>
              <th>단위</th>
              <td>{unit}</td>
            </tr>
            <tr>
              <th>제조사/수입사</th>
              <td>{manufacturer}</td>
            </tr>
            <tr>
              <th>판매 가격</th>
              <td>{price ? price.toLocaleString() + ' 원' : ''}</td>
            </tr>
            {insuranceCode ? (
              <tr>
                <th>보험코드</th>
                <td>{insuranceCode}</td>
              </tr>
            ) : null}
            {optionName ? (
              <tr>
                <th>옵션</th>
                <td>
                  <DropdownList defaultToggleText={optionName} items={options} setSelect={this.handleOption} />
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </div>
    );
  }
}

@inject('cervitemProduct')
class OptionComponent extends Component {
  render() {
    const { product, selectedOptions } = this.props.cervitemProduct;
    const { optionName } = product;

    return optionName ? (
      selectedOptions && Object.keys(selectedOptions).length > 0 ? (
        <div className="p-2 mb-3">
          <SelectOptionComponent />
        </div>
      ) : null
    ) : (
      <SelectOptionComponent />
    );
  }
}

@inject('cervitemProduct')
class SelectOptionComponent extends Component {
  handleDecrement = (optionKey) => {
    this.props.cervitemProduct.quantityDecrement(optionKey);
  };

  handleIncrement = (optionKey) => {
    this.props.cervitemProduct.quantityIncrement(optionKey);
  };

  handleQuantity = (e, optionKey) => {
    this.props.cervitemProduct.setQuantity(e.target.value, optionKey);
  };

  handleDeleteOption = (idx) => {
    this.props.cervitemProduct.deleteOption(idx);
  };

  render() {
    const { product, selectedOptions } = this.props.cervitemProduct;
    const { price, optionName, options } = product;

    return (
      <Table>
        {Object.keys(selectedOptions).map((key, idx) => {
          const checkOptionExist = optionName && options.find((o) => o.idx === parseInt(key));

          return (
            <tbody key={key}>
              <tr>
                <th className="pt-3" style={{ width: '100%', fontSize: 15 }}>
                  {checkOptionExist ? options.find((o) => o.idx === parseInt(key)).name + ' - ' : ''} {(selectedOptions[key] * price).toLocaleString() + ' 원'}
                </th>
                <td className="prod-by-btn-table-container d-flex align-items-center justify-content-center">
                  <div className="prod-quantity-container">
                    <button className="prod-quantity-minus" onClick={() => this.handleDecrement(key)}>
                      <FaAngleDown />
                    </button>
                    <input type="text" value={selectedOptions[key]} onChange={(e) => this.handleQuantity(e, key)} />
                    <button className="prod-quantity-plus" onClick={() => this.handleIncrement(key)}>
                      <FaAngleUp />
                    </button>
                  </div>
                  {checkOptionExist && (
                    <div className="pl-2" style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteOption(key)}>
                      <FaWindowClose />
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          );
        })}
      </Table>
    );
  }
}

class ScrollToTop extends Component {
  handleScrollToTop = () => {
    const section = document.querySelector('.area');
    section.scrollTop = 0;
  };

  render() {
    return (
      <button style={{ position: 'fixed', bottom: '40px', right: '40px', zIndex: 100 }} onClick={this.handleScrollToTop}>
        <FaArrowUp />
      </button>
    );
  }
}

export default CervitemProduct;
