import { observable, action, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import moment from 'moment';
import { numberToString, nullableNumber } from 'libs/Utils';
import { Listener } from 'ag-grid-community';

export default class CerviBoxAddStore {
  @observable open = false;
  @observable showGridArea = false;
  @observable list = [];
  @observable from = '';

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
  }

  @action
  show = (list, from = '') => {
    this.list = JSON.parse(JSON.stringify(list));

    const p = this.root.cervicoRequest.patinfo;
    p.memo = p.comment; //닥터코맨트 복사
    this.from = from;
    this.open = true;
  };

  @action
  close = () => {
    this.root.cerviBoxForSearch.init();

    this.open = false;
    this.list = [];
    this.showGridArea = false;
    this.from = '';
  };

  @action
  setShowGridArea = () => {
    this.showGridArea = true;
  };

  @action
  saveForReq = async (pictureList, targetCerviboxList) => {
    let cervibox = null;

    if (pictureList.length === 0) {
      toast.warn(this.locale.fm('comp.cervibox.vali.sel1pic'));
      return;
    }
    const p = this.root.cervicoRequest.patinfo;

    if (targetCerviboxList.length === 0) {
      //patinfo 검증
      if (p.patName.trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.patname'));
        return;
      }
      if (p.chartNum.trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.chartnum'));
        return;
      }
      if ((p.socialNum1 + p.socialNum2).trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate'));
        return;
      }
      //생년월일 검사
      if (!moment(p.socialNum1.trim(), 'YYMMDD').isValid()) {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate2'));
        return;
      }

      //주민번호 padEnd
      const socialNum = (p.socialNum1 + p.socialNum2).trim().length > 0 ? p.socialNum1.trim().padEnd(6, '0') + p.socialNum2.trim().padEnd(7, '0') : '';

      //cervibox 생성
      cervibox = {
        patName: p.patName.trim(),
        chartNum: p.chartNum.trim(),
        socialNum: socialNum,
        age: numberToString(p.age),
        isPreg: p.isPreg === '' ? null : p.isPreg === 'true',
        g: nullableNumber(p.g),
        p: nullableNumber(p.p),
        a: nullableNumber(p.a),
        memo: p.memo.trim(),
        plist: pictureList,
        from: this.from
      };

      this.root.screen.setProcessLayer(true);
      try {
        const response = await axios.put(`/api/rq/cervibox/add-req`, cervibox);
        if (response.data.success) {
          toast.success(this.locale.fm('store.cervibox.save'));
          this.close();
          this.root.cervicoRequest.init();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        ajaxErrorHandler(error);
      }
      this.root.screen.setProcessLayer(false);
    }
    // 선택한 박스가 여러개일때
    else if (targetCerviboxList.length > 1) {
      toast.warn(this.locale.fm('comp.cervibox.vali.sel1box'));
      return;
    }
    // 1개의 박스를 선택했을 때
    else if (targetCerviboxList.length === 1) {
      const boxIdx = targetCerviboxList[0];
      const pList = {
        plist: pictureList,
        from: this.from
      };
      this.root.screen.setProcessLayer(true);
      try {
        const response = await axios.post(`/api/rq/cervibox/${boxIdx}/append-req-img`, pList);
        const totalCnt = pictureList.length;
        const dupCnt = response.data.data;
        const successCnt = totalCnt - dupCnt;
        if (response.data.success) {
          if (response.data.data === 0) {
            toast.success(this.locale.fm('store.cervibox.save'));
            this.close();
            this.root.cervicoRequest.init();
          } else if (response.data.data > 0 && pictureList.length !== response.data.data) {
            toast.warn(this.locale.fm('comp.cervibox.vali.equalpic2', { cnt1: dupCnt, cnt2: successCnt }));
          } else if (pictureList.length === response.data.data) {
            toast.error(this.locale.fm('comp.cervibox.vali.equalpic'));
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        ajaxErrorHandler(error);
      }
      this.root.screen.setProcessLayer(false);
    }
  };

  @action
  checkImg = (idx, checked) => {
    this.list.find((p) => p.idx === idx).checked = checked;
  };
}
