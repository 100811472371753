import React, { Component } from 'react';
import { Alert, Card, CardHeader, CardFooter, CardBody, Form, FormGroup, Label, Input, Button, CustomInput, Container, Row, Col } from 'reactstrap';
import cx from 'classnames';
import ec from 'escape-html';

import { observer, inject } from 'mobx-react';

import { FormattedMessage } from 'react-intl';
import { IoIosChatboxes } from 'react-icons/io';
import { FaUserTimes, FaRegPlusSquare, FaPaperPlane, FaEdit, FaTrash, FaCheck, FaUndo } from 'react-icons/fa';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { DateTimePicker, CardMask } from 'components/common';
import { shortDateCellRenderer, resultCellRenderer, phonePattern, localeTextFunc } from 'libs/Utils';

import './SmsArea.scss';
import { toast } from 'react-toastify';
@inject('smsSend')
@inject('smsTemplate')
@observer
class SmsArea extends Component {
  phoneBookGridApi = null;

  handlePhoneBookGridReady = (params) => {
    this.phoneBookGridApi = params.api;
  };

  handleShowClick = () => {
    this.props.smsSend.toggleShow();
  };

  handleSendClick = () => {
    this.phoneBookGridApi.stopEditing(false);
    this.props.smsSend.send();
  };

  render() {
    const { smsSend } = this.props;
    return (
      <div className={cx('SmsArea', smsSend.show ? '' : 'hide')}>
        <div className="tip" onClick={this.handleShowClick}>
          <IoIosChatboxes />
          <span className="badge badge-pill badge-light">{smsSend.phoneCount}</span>
        </div>
        <Container fluid className="h-flex">
          <Alert className="mr-2 ml-2 mb-0" color="warning">
            <div>
              <FormattedMessage id="comp.res.sms.contents1" />
            </div>
            <div>
              <FormattedMessage
                id="comp.res.sms.contents2"
                values={{
                  hpName: (
                    <strong className="text-danger">
                      <FormattedMessage id="field.sms.template.hpName" />
                    </strong>
                  ),
                  hpTel: (
                    <strong className="text-danger">
                      <FormattedMessage id="field.sms.template.hpTel" />
                    </strong>
                  ),
                  patName: (
                    <strong className="text-danger">
                      <FormattedMessage id="field.sms.template.patName" />
                    </strong>
                  ),
                  createDate: (
                    <strong className="text-danger">
                      <FormattedMessage id="field.sms.template.createDate" />
                    </strong>
                  )
                }}
              />
            </div>
            <div>
              <FormattedMessage id="comp.res.sms.contents3" />
            </div>
          </Alert>
          <Row style={{ flex: 1 }} className="flex-column-reverse flex-lg-row">
            <Col lg="5" className="left-pane">
              <div className="container-fluid h-flex">
                <SendPane onSendClick={this.handleSendClick} />
              </div>
            </Col>
            <Col lg="7" className="right-pane">
              <div className="container-fluid h-flex">
                <PhoneBookGridCard onGridReady={this.handlePhoneBookGridReady} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

@inject('locale')
@inject('smsSend')
@observer
class SendPane extends Component {
  messageGridApi = null;

  handleTemplateAddClick = () => {
    this.props.smsSend.addTemplate();
  };

  handleSendClick = () => {
    this.props.onSendClick();
  };

  handleMessageGridReady = (params) => {
    this.messageGridApi = params.api;
  };

  handleSelectMessage = (message) => {
    this.props.smsSend.setMessage(message);
  };

  handleCheckboxChange = (checked) => {
    this.props.smsSend.setRetest(checked);
  };

  render() {
    const { smsSend } = this.props;
    const hcc = this.handleCheckboxChange;
    return (
      <>
        <Card>
          <CardHeader>
            <FormattedMessage id="field.sms.msg" /> : {smsSend.messageByte} <FormattedMessage id="field.sms.msg.msgChrCnt" />
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <Input type="textarea" style={{ height: '100px' }} value={smsSend.message} onChange={(e) => smsSend.setMessage(e.target.value)} />
              </FormGroup>
              <FormGroup className="text-center">
                <Button size="sm" color="success" type="button" onClick={this.handleTemplateAddClick}>
                  <FaRegPlusSquare /> <FormattedMessage id="button.sms.saveMsg" />
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <MessagePane onSelectMessage={this.handleSelectMessage} onGridReady={this.handleMessageGridReady} />
        <Card>
          <CardBody>
            <Form>
              <div className="form-row">
                <FormGroup>
                  <Label>
                    <Input type="radio" name="smsType" checked={!smsSend.retest} onChange={({ target }) => hcc(!target.checked)} />
                    <FormattedMessage id="field.txtresultSms" />
                  </Label>
                  <Label>
                    <Input type="radio" name="smsType" onChange={({ target }) => hcc(target.checked)} />
                    <FormattedMessage id="field.txtretestSms" />
                  </Label>
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup>
                  <Label>
                    <Input
                      type="checkbox"
                      id="reServeSend"
                      checked={smsSend.reserve}
                      onChange={(e) => {
                        smsSend.setReserve(e.target.checked);
                      }}
                    />
                    <FormattedMessage id="field.sms.receiverList.scheduledSms" />
                  </Label>
                  <DateTimePicker value={smsSend.reserveDatetime} onChange={(e) => smsSend.setReserveDatetime(e.target.value)} dropUp showTime />
                </FormGroup>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Button size="lg" color="primary" block onClick={this.handleSendClick}>
          <FaPaperPlane /> <FormattedMessage id="button.sms.send" />
        </Button>
      </>
    );
  }
}

const defaultColDef = {
  sortable: true,
  singleClickEdit: true
};

@inject('locale')
@inject('smsSend')
@observer
class PhoneBookGridCard extends Component {
  state = {
    selectedCount: 0
  };

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
  };

  handleRemoveClick = () => {
    this.props.smsSend.removePhoneList(this.gridApi.getSelectedRows());
  };

  handleCellEditingStarted = (e) => {
    //아 모르겠다.
    const input = document.querySelector('.ag-cell-edit-input');
    if (input.value === '') input.value = '010';
  };

  handleSelectionChanged = () => {
    this.setState({
      selectedCount: this.gridApi.getSelectedRows().length
    });
  };

  render() {
    const { locale } = this.props;

    return (
      <Card className="basis-300">
        <CardHeader> {locale.fm('comp.sms.title.receiverList')} </CardHeader>
        <CardBody className="np">
          <div className="DataGrid">
            <div className="DataGrid ag-theme-balham" style={{ flex: 1, overflow: 'hidden' }}>
              <AgGridReact
                reactNext={true}
                localeTextFunc={localeTextFunc}
                suppressRowClickSelection={true}
                suppressNoRowsOverlay={true}
                deltaRowDataMode={true}
                columnDefs={[
                  { width: 30, checkboxSelection: true, headerCheckboxSelection: true },
                  { headerName: locale.fm('field.sms.receiverList.requestDate'), field: 'receivedDate', width: 90, cellRenderer: shortDateCellRenderer },
                  { headerName: locale.fm('field.patname'), field: 'patName', width: 110 },
                  {
                    headerName: locale.fm('field.sms.receiverList.mobile'),
                    field: 'phone',
                    width: 110,
                    editable: true,
                    cellClassRules: {
                      invalid: ({ value }) => !phonePattern.test(value)
                    }
                  },
                  { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 110 },
                  {
                    headerName: locale.fm('field.txtresult'),
                    field: 'txtResult',
                    width: 70,
                    cellRenderer: resultCellRenderer,
                    cellClassRules: {
                      'text-black-50': ({ data }) => data.statusCode < 6
                    }
                  },
                  { headerName: locale.fm('field.fcode'), field: 'idx', width: 80 }
                ]}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
                getRowNodeId={(data) => data.idx}
                animateRows={true}
                enterMovesDownAfterEdit={true}
                onGridReady={this.handleGridReady}
                onSelectionChanged={this.handleSelectionChanged}
                rowData={this.props.smsSend.phoneList}
                onCellEditingStarted={this.handleCellEditingStarted}
              />
            </div>
            <div className="area-paging">
              <div>
                <span>
                  <FormattedMessage id="field.total" /> : {this.props.smsSend.phoneCount}
                </span>{' '}
                /{' '}
                <span>
                  <FormattedMessage id="field.selected" /> :{' '}
                  {this.state.selectedCount === 0 ? 0 : <span className="badge badge-primary">{this.state.selectedCount.toLocaleString()}</span>}
                </span>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter className="card-toolbar text-center">
          <Button size="sm" color="warning" onClick={this.handleRemoveClick}>
            <FaUserTimes /> {locale.fm('button.sms.delReceiver')}
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

@inject('locale')
@inject('smsSend')
@inject('smsTemplate')
@observer
class MessagePane extends Component {
  gridApi = null;

  state = {
    selectedCount: 0
  };

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.props.onGridReady(params);
  };

  handleFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };

  handleRemoveClick = () => {
    if (this.state.selectedCount === 0) {
      toast.warn(this.props.locale.fm('comp.sms.vali.emptyMessageList'));
      return;
    }
    this.props.smsTemplate.remove(this.gridApi.getSelectedRows().map((d) => d.idx));
  };

  handleRowDoubleClicked = ({ data }) => {
    this.props.onSelectMessage(data.msgTxt);
  };

  handleSelectionChanged = () => {
    this.setState({
      selectedCount: this.gridApi.getSelectedRows().length
    });
  };

  onRowEditingStarted = (params) => {
    params.api.refreshCells({
      columns: ['action'],
      rowNodes: [params.node],
      force: true
    });
  };

  onRowEditingStopped = (params) => {
    params.api.refreshCells({
      columns: ['action'],
      rowNodes: [params.node],
      force: true
    });
    params.api.resetRowHeights();

    // apply 버튼 안누르면 리스트 초기화시키기
    this.gridApi.setRowData(this.props.smsTemplate.getTemplateList());
  };

  onRowDragEnd = () => {
    let rowData = [];
    this.gridApi.forEachNode((r, i) => {
      r.data.orderBy = i;
      rowData.push({ idx: r.data.idx, orderBy: r.data.orderBy });
    });

    this.props.smsTemplate.saveList(rowData);
  };

  render() {
    const { locale, smsTemplate } = this.props;

    return (
      <Card className="basis-300">
        <CardHeader>
          <FormattedMessage id="comp.sms.title.storedMsg" />
        </CardHeader>
        <CardBody className="np">
          <div className="DataGrid">
            <div className="ag-theme-balham" style={{ flex: 1, overflow: 'hidden' }}>
              <AgGridReact
                onRowEditingStopped={this.onRowEditingStopped}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowDragEnd={this.onRowDragEnd}
                reactNext={true}
                localeTextFunc={localeTextFunc}
                suppressClickEdit={true}
                suppressRowClickSelection={true}
                suppressHorizontalScroll={true}
                suppressNoRowsOverlay={true}
                deltaRowDataMode={true}
                rowDragManaged={true}
                suppressMoveWhenRowDragging={true}
                columnDefs={[
                  // { maxWidth: 30, checkboxSelection: true, headerCheckboxSelection: true, suppressSizeToFit: false },
                  {
                    headerName: locale.fm('field.sms.msg'),
                    field: 'msgTxt',
                    cellRenderer: ({ value }) => {
                      return ec(value)
                        .replace(/\n/g, '<br/>')
                        .replace(/(#병원이름#)/g, '<span class="text-danger">$1</span>')
                        .replace(/(#병원전화번호#)/g, '<span class="text-danger">$1</span>')
                        .replace(/(#환자이름#)/g, '<span class="text-danger">$1</span>')
                        .replace(/(#검사날짜#)/g, '<span class="text-danger">$1</span>');
                    },
                    cellClass: 'message',
                    autoHeight: true,
                    editable: true,
                    cellEditor: 'customLargeCellEditor',
                    suppressKeyboardEvent() {
                      // 엔터키 줄넘김해야함
                      return true;
                    },
                    rowDrag: true
                  },
                  {
                    headerName: locale.fm('field.sms.action'),
                    cellRenderer: 'actionCellRenderer',
                    colId: 'action',
                    maxWidth: 70,
                    cellClass: 'actionBtns',
                    suppressSizeToFit: false
                  }
                ]}
                defaultColDef={{
                  resizable: false
                  // suppressAutoSize: true
                }}
                rowSelection="multiple"
                getRowNodeId={(data) => data.idx}
                animateRows={true}
                onGridReady={this.handleGridReady}
                onSelectionChanged={this.handleSelectionChanged}
                onRowDoubleClicked={this.handleRowDoubleClicked}
                onFirstDataRendered={this.handleFirstDataRendered}
                editType="fullRow"
                rowData={smsTemplate.templateList}
                context={{
                  componentParent: this
                }}
                frameworkComponents={{
                  actionCellRenderer: ActionCellRenderer,
                  customLargeCellEditor: CustomLargeCellEditor
                }}
              />
            </div>
            <div className="area-paging">
              <div>
                <span>
                  <FormattedMessage id="field.total" /> : {this.props.smsTemplate.templateList.length}
                </span>{' '}
                /{' '}
                <span>
                  <FormattedMessage id="field.selected" /> :{' '}
                  {this.state.selectedCount === 0 ? 0 : <span className="badge badge-primary">{this.state.selectedCount.toLocaleString()}</span>}
                </span>
              </div>
            </div>
          </div>
        </CardBody>
        <CardMask show={this.props.smsTemplate.mask} />
      </Card>
    );
  }
}

class CustomLargeCellEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value ? this.props.value : '' };
  }

  handleChanged = (e) => {
    this.setState({ value: e.target.value });
  };

  getValue = () => {
    return this.state.value;
  };

  render() {
    return <Input type="textarea" style={{ height: '100%' }} defaultValue={this.state.value} onChange={this.handleChanged} />;
  }
}

@inject('smsTemplate')
class ActionCellRenderer extends Component {
  handleEditClicked = () => {
    this.props.context.componentParent.gridApi.startEditingCell({
      rowIndex: this.props.node.rowIndex,
      colKey: this.props.columnApi.getDisplayedCenterColumns()[0].colId
    });
  };

  handleDelClicked = () => {
    this.props.smsTemplate.remove([this.props.data.idx]);
  };

  handleApplyClicked = () => {
    this.props.context.componentParent.gridApi.stopEditing(false);

    // 추가, 수정 분기
    if (this.props.data.action != null && this.props.data.action === 'add') {
      this.props.smsTemplate.add(this.props.data.msgTxt);
    } else {
      this.props.smsTemplate.edit(this.props.data);
    }
  };

  handleCancelClicked = () => {
    this.props.context.componentParent.gridApi.stopEditing(true);
  };

  render() {
    const gridApi = this.props.context.componentParent.gridApi;
    const isEditing = gridApi.getEditingCells().some((cell) => {
      return cell.rowIndex === this.props.node.rowIndex;
    });

    return (
      <div className="text-center align-middle">
        {isEditing ? (
          <>
            <Button size="sm" onClick={this.handleApplyClicked} className="mr-1">
              <FaCheck />
            </Button>
            <Button size="sm" onClick={this.handleCancelClicked}>
              <FaUndo />
            </Button>
          </>
        ) : (
          <>
            <Button size="sm" onClick={this.handleEditClicked} className="mr-1">
              <FaEdit />
            </Button>
            <Button size="sm" onClick={this.handleDelClicked}>
              <FaTrash />
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default SmsArea;
