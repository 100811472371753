import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Label, Col, CustomInput, Input, Button } from 'reactstrap';

import { DateTimePicker } from 'components/common';
import { DataGrid, CardMask } from 'components/common';
import { FormattedMessage } from 'react-intl';
import { FaCheck, FaSearch } from 'react-icons/fa';

import { receivedDateCellRenderer, shortDateCellRenderer } from 'libs/Utils';
import { observer, inject } from 'mobx-react';

@inject('locale')
@inject('report')
@observer
class Report extends Component {
  versionInfo = window.rq ? window.rq.getVersionInfo() : {};
  resultGridApi = null;

  handleResultGridReady = (params) => {
    this.resultGridApi = params.api;
  };

  handleChange = (e) => {
    this.props.setting.change(e.target.name, e.target.value);
  };

  handleResetGridScrollbar = () => {
    //스크롤바
    this.resultGridApi.ensureIndexVisible(0, 'top');
  };

  render() {
    return (
      <div className="container-fluid h-flex">
        <SearchFormCard />
        {this.props.locale._locale === 'ko' ? <ResultGridCard onGridReady={this.handleResultGridReady} onAfterMove={this.handleResetGridScrollbar} /> : null}
      </div>
    );
  }
}

@inject('locale')
@inject('auth')
@inject('report')
@observer
class SearchFormCard extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.report.changeSearch(i.name, i.value);
  };

  handleSearchClick = () => {
    //await this.props.report.triggerSearch();
    //this.props.onAfterSearch();
    this.props.report.print();
  };

  render() {
    const {
      locale,
      auth,
      report: { search: s }
    } = this.props;
    const hc = this.handleSearchChange;

    return (
      <Card>
        <CardHeader>
          {' '}
          <FormattedMessage id="comp.report.title" />{' '}
        </CardHeader>
        <CardBody>
          <Form className="search">
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.report.type" />
                </Label>
                <Input type="select" bsSize="sm" name="reportType" value={s.reportType} onChange={hc}>
                  <option value="cervico">{locale.fm('value.report.type.cervico')}</option>
                  {locale._locale === 'ko' ? <option value="sms">{locale.fm('value.report.type.sms')}</option> : ''}
                  {/* {locale._locale === 'ko' ? <option value="order">{locale.fm('value.report.type.cervitem')}</option> : ''}
                  {auth.doctor.useAI ? <option value="ai">{locale.fm('value.report.type.AI')}</option> : ''} */}
                </Input>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  {' '}
                  <FormattedMessage id="field.report.term" />{' '}
                </Label>
                <DateTimePicker name="startDate" value={s.startDate} onChange={hc} />
                ~
                <DateTimePicker name="endDate" value={s.endDate} onChange={hc} />
              </FormGroup>
            </div>
            <div className="form-row flex-row-reverse">
              <FormGroup>
                <Button size="sm" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch /> <FormattedMessage id="button.search" />
                </Button>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

@inject('locale')
@inject('report')
@observer
class ResultGridCard extends Component {
  gridApi = null;
  selected = [];

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
    this.props.report.loadDataGrid();
  };

  handleGridSelectionChanged = (l) => {
    this.selected = l;
  };

  handleMovePage = async (page) => {
    await this.props.report.loadDataGrid(page);
    this.props.onAfterMove();
  };

  handleGridRowClick = (data) => {
    this.props.report.print(data.idx);
  };

  render() {
    const { locale } = this.props;

    return (
      <Card className="basis-300">
        <CardHeader>
          {' '}
          <FormattedMessage id="comp.settlement.title" />{' '}
        </CardHeader>
        <CardBody className="np h-flex">
          <DataGrid
            columnDefs={[
              { headerName: locale.fm('field.settlementName'), field: 'name', width: 300 },
              { headerName: locale.fm('field.fromDate'), field: 'fromDate', width: 130, cellRenderer: shortDateCellRenderer },
              { headerName: locale.fm('field.toDate'), field: 'toDate', width: 130, cellRenderer: shortDateCellRenderer },
              { headerName: locale.fm('field.regDate'), field: 'regDate', width: 130, cellRenderer: receivedDateCellRenderer }
            ]}
            rowSelection="single"
            onGridReady={this.handleGridReady}
            onRowDoubleClicked={this.handleGridRowClick}
            dataGrid={this.props.report.dataGrid}
            context={{
              componentParent: this
            }}
          />
        </CardBody>
        <CardMask show={this.props.report.mask} />
      </Card>
    );
  }
}

export default Report;
