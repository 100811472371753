import { observable, action, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import moment from 'moment';
import { numberToString, nullableNumber } from 'libs/Utils';

export default class CerviBoxAddForAIAnalysisStore {
  @observable open = false;
  @observable list = [];

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
  }

  @action
  show = async (data) => {
    const { a, age, aiResultList, birthDay, chartNum, comment, g, idx, isPreg, p, patName } = data;

    this.root.cervicoRequest.changePatinfo('patName', patName);
    this.root.cervicoRequest.changePatinfo('chartNum', chartNum);
    this.root.cervicoRequest.changePatinfo('socialNum1', birthDay);
    this.root.cervicoRequest.changePatinfo('socialNum2', '');
    this.root.cervicoRequest.changePatinfo('age', age);
    this.root.cervicoRequest.changePatinfo('isPreg', isPreg ? 'true' : 'false');
    this.root.cervicoRequest.changePatinfo('p', p);
    this.root.cervicoRequest.changePatinfo('g', g);
    this.root.cervicoRequest.changePatinfo('a', a);
    this.root.cervicoRequest.changePatinfo('memo', comment);

    for (let i = 0; i < aiResultList.length; i++) {
      this.list.push({
        idx: aiResultList[i].idx,
        checked: true,
        // takeDate: takeDate,
        url: `/api/rq/cerviai/${aiResultList[i].idx}/image?width=200`
      });
    }

    this.idx = idx;
    this.open = true;
  };

  @action
  close = () => {
    this.root.cerviBoxForSearch.init();

    this.open = false;
    this.idx = null;
    this.list = [];
  };

  @action
  save = async (pictureList, targetCerviboxList) => {
    let cervibox = null;
    if (pictureList.length === 0) {
      toast.warn(this.locale.fm('comp.cervibox.vali.sel1pic'));
      return;
    }
    const p = this.root.cervicoRequest.patinfo;
    const boxIdx = targetCerviboxList;
    const list = {
      checkedList: this.list
        .filter((el) => {
          return el.checked === true;
        })
        .map((el) => {
          return el.idx;
        })
    };

    if (targetCerviboxList.length === 0) {
      //patinfo 검증
      if (p.patName.trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.patname'));
        return;
      }
      if (p.chartNum.trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.chartnum'));
        return;
      }
      if ((p.socialNum1 + p.socialNum2).trim() === '') {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate'));
        return;
      }
      //생년월일 검사
      if (!moment(p.socialNum1.trim(), 'YYMMDD').isValid()) {
        toast.warn(this.locale.fm('store.cervicoRequest.vali.birthdate2'));
        return;
      }

      //주민번호 padEnd
      const socialNum = (p.socialNum1 + p.socialNum2).trim().length > 0 ? p.socialNum1.trim().padEnd(6, '0') + p.socialNum2.trim().padEnd(7, '0') : '';

      //cervibox 생성
      cervibox = {
        patName: p.patName.trim(),
        chartNum: p.chartNum.trim(),
        socialNum: socialNum,
        age: numberToString(p.age),
        isPreg: p.isPreg === '' ? null : p.isPreg === 'true',
        g: nullableNumber(p.g),
        p: nullableNumber(p.p),
        a: nullableNumber(p.a),
        memo: p.memo.trim(),
        ...list
      };
      this.root.screen.setProcessLayer(true);
      try {
        const response = await axios.put(`/api/rq/cervibox/add-ai`, cervibox);
        if (response.data.success) {
          toast.success(this.locale.fm('store.cervibox.save'));
          this.close();
          this.root.cervicoRequest.init();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        ajaxErrorHandler(error);
      }
      this.root.screen.setProcessLayer(false);
    }
    // 선택한 박스가 여러개일때
    else if (targetCerviboxList.length > 1) {
      toast.warn(this.locale.fm('comp.cervibox.vali.sel1box'));
      return;
    }
    // 1개의 박스를 선택했을 때
    else if (targetCerviboxList.length === 1) {
      this.root.screen.setProcessLayer(true);
      try {
        const response = await axios.post(`/api/rq/cervibox/${boxIdx}/ai-img`, list);
        if (response.data.success) {
          toast.success(this.locale.fm('store.cervibox.save'));
          this.close();
          this.root.cervicoRequest.init();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        ajaxErrorHandler(error);
      }
      this.root.screen.setProcessLayer(false);
    }
  };

  @action
  checkImg = (idx, checked) => {
    this.list.find((p) => p.idx === idx).checked = checked;
  };
}
