import React, { Component } from 'react';
import { CardMask, PictureBox } from 'components/common';
import { Card, CardHeader, CardBody, Button, CustomInput, Input, Row, Col } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { FaFileUpload, FaRegCheckSquare, FaRegSquare, FaTrash } from 'react-icons/fa';
import cx from 'classnames';
import './CerviBox.scss';
import { Fragment } from 'react';
import Table from 'reactstrap/lib/Table';
import { CerviBoxSearchFormCard, CerviBoxResultGridCard, CerviBoxCopyWindow } from '.';
import { MdDesktopMac } from 'react-icons/md';
import CerviBoxEditWindow from './CerviBoxEditWindow';
import moment from 'moment';
import CardFooter from 'reactstrap/lib/CardFooter';
import CerviBoxRequestWindow from './CerviBoxRequestWindow';
import * as Sqrl from 'squirrelly';
import { toast } from 'react-toastify';
import { ClassResultMap } from 'libs/Utils';

@inject('cerviBox')
@observer
class CerviBox extends Component {
  cerviBoxGridApi = null;

  handleResultGridReady = (params) => {
    this.cerviBoxGridApi = params.api;
  };

  handleResetGridScrollbar = () => {
    //스크롤바
    this.cerviBoxGridApi.ensureIndexVisible(0, 'top');
  };

  componentWillUnmount() {
    this.props.cerviBox.init();
  }

  render() {
    const {
      cerviBox: { reqDialog }
    } = this.props;

    return (
      <Fragment>
        <div className="CerviBoxArea">
          <div className="container-fluid h-flex">
            <CerviBoxSearchFormCard />
            <Row className="flex-grow-1">
              <Col md="5" className="left-area">
                <CerviBoxResultGridCard onGridReady={this.handleResultGridReady} onAfterMove={this.handleResetGridScrollbar} />
              </Col>
              <Col md="7">
                <ResultPreviewCard />
              </Col>
            </Row>
          </div>
          <CerviBoxEditWindow />
          <CerviBoxRequestWindow store={reqDialog} />
        </div>
      </Fragment>
    );
  }
}

@inject('auth')
@inject('locale')
@inject('cerviBox')
@inject('cerviAi')
@inject('cerviBoxCopy')
@observer
class ResultPreviewCard extends Component {
  handleRequestClick = () => {
    this.props.cerviBox.selectRequest();
  };

  handleCerviAiClick = () => {
    this.props.cerviAi2.analy(this.props.cerviBox.selectedIdxList(), 'box');
  };

  handleDeleteClick = () => {
    this.props.cerviBox.removeImage();
  };

  handleCopyOpenClick = () => {
    const list = this.props.cerviBox.preview.group.reduce((a, c) => [...a, ...c.list], []).filter((el) => el.checked === true);
    if (list.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
      return;
    }
    this.props.cerviBoxCopy.show(list[0].idxCervibox, list);
  };

  handleExcelClick = () => {
    let excel = `
    <html xmlns:x="urn:schemas-microsoft-com:office:excel">
      <head>
        <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
          <xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
            <x:Name>Sheet</x:Name>
            <x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>
          </x:ExcelWorksheets></x:ExcelWorkbook></xml>
      </head>
      <body>
        <table border='1px'>
          <tr>
            <td>결과</td><td>%</td><td>메모</td>
          </tr>
  {{@each(it) => e}}
          <tr>
            <td>{{e.result}}</td>
            <td>{{e.percent}}%</td>
            <td>{{e.memo}}</td>
          </tr>
  {{/each}}
        </table>
      </body>
    </html>
    `;
    const {
      cerviBox: {
        preview: { group }
      }
    } = this.props;
    const exList = group.reduce((acc, c) => {
      c.list.forEach((e) => {
        acc.push({
          result: e.classResult,
          percent: e.classResultMap.find((m) => m.label === e.classResult).probality || null,
          memo: e.memo
        });
      });
      return acc;
    }, []);
    const blob = new Blob([Sqrl.render(excel, exList)], {
      type: 'application/csv;charset=utf-8;'
    });
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = 'ai-result.xls';
    document.body.appendChild(anchor);
    anchor.click();

    // 클릭(다운) 후 요소 제거
    document.body.removeChild(anchor);
  };

  render() {
    const {
      auth,
      cerviBox,
      cerviBox: {
        preview: { box: p }
      }
    } = this.props;

    return (
      <div className="ResultPreviewCard h-flex">
        <Card className="flex-grow-1">
          <CardHeader className="card-toolbar d-flex flex-wrap align-items-center">
            <Button size="sm" color="warning" disabled={p ? false : true} onClick={cerviBox.selectChange} className="mr-auto">
              {cerviBox.selectAll ? <FaRegCheckSquare /> : <FaRegSquare />} <FormattedMessage id="button.select" />
            </Button>
            <Button size="sm" color="warning" disabled={p ? false : true} onClick={this.handleCopyOpenClick}>
              <FaFileUpload /> <FormattedMessage id="button.copy" />
            </Button>
            {auth.doctor.useRequest ? (
              <Button size="sm" color="primary" disabled={p ? false : true} onClick={this.handleRequestClick}>
                <FaFileUpload /> <FormattedMessage id="button.request" />
              </Button>
            ) : null}
            {/* <Button size="sm" color="primary" disabled={p ? false : true} onClick={this.handleCerviAiClick}>
              <FaFileUpload /> AI 분석
            </Button> */}
            <Button size="sm" color="danger" disabled={p ? false : true} onClick={this.handleDeleteClick}>
              <FaTrash /> <FormattedMessage id="button.remove" />
            </Button>
          </CardHeader>
          <CardBody className="result-preview-body-area">
            <div className="ofy-auto h-flex">
              {p ? (
                <Fragment>
                  <ResultInfoPanel />
                  <ThumbListPanel />
                </Fragment>
              ) : (
                <div className="text-center">
                  <FormattedMessage id="comp.cervibox.nobox" />
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

@inject('cerviBox')
@inject('locale')
@observer
class ThumbListPanel extends Component {
  handleGroupCheckChange = (gIdx, e) => {
    const { cerviBox } = this.props;
    cerviBox.selectGroup(gIdx, e.target.checked);
  };

  handlePictureCheckChange = (gIdx, pIdx, e) => {
    const { cerviBox } = this.props;
    cerviBox.selectPicture(gIdx, pIdx, e.target.checked);
  };

  render() {
    const {
      cerviBox: {
        preview: { group }
      }
    } = this.props;
    return (
      <div className="ThumbListPanel">
        <Card className="basis-300">
          <CardBody className="thumb-list">
            <ul>
              {group.length > 0 ? (
                group.map((g) => (
                  <PictureGroup
                    key={g.idx}
                    pictureGroup={g}
                    onCardClick={this.handleCardClick}
                    onPictureClick={this.handlePictureClick}
                    onGroupCheckChange={this.handleGroupCheckChange}
                    onPictureCheckChange={this.handlePictureCheckChange}
                  />
                ))
              ) : (
                <div className="text-center">
                  <FormattedMessage id="comp.cervibox.vali.noimg" />
                </div>
              )}
            </ul>
          </CardBody>
          <CardFooter style={{ fontWeight: 'bold' }}>
            <CheckedCount className="mr-auto" />
          </CardFooter>
          <CardMask show={this.props.cerviBox.preview.mask} />
          <CerviBoxCopyWindow />
        </Card>
      </div>
    );
  }
}

@inject('cerviBox')
@observer
class CheckedCount extends Component {
  render() {
    const cerviBox = this.props.cerviBox;
    return (
      <div>
        <FormattedMessage id="field.total" /> : {cerviBox.preview.totalCount} / <FormattedMessage id="field.selected" /> :{' '}
        {cerviBox.selectedCount === 0 ? 0 : <span className="badge badge-primary">{cerviBox.selectedCount}</span>}
      </div>
    );
  }
}

@inject('cerviBox')
@observer
class ResultInfoPanel extends Component {
  render() {
    const {
      cerviBox: {
        preview: { box: p }
      }
    } = this.props;

    return (
      <div className="ResultInfoPanel">
        <div className="patInfo">
          <Table bordered hover size="sm">
            <tbody>
              <tr>
                <th>
                  <FormattedMessage id="field.patname" />
                </th>
                <td>{p.patName}</td>
                <th>
                  <FormattedMessage id="field.chartnum" />
                </th>
                <td>{p.chartNum}</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage id="field.birthday" />
                </th>
                <td>{p.socialNum ? (p.socialNum.length > 6 ? `${p.socialNum.substring(0, 6)}-${p.socialNum.substring(6, 7)}` : p.socialNum) : p.socialNum}</td>
                <th>
                  <FormattedMessage id="field.age" />
                </th>
                <td>{`${p.age}세`}</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage id="field.pregnancy" />
                </th>
                <td>
                  {p.isPreg === null ? (
                    <span className="badge badge-light">
                      <FormattedMessage id="value.unknown" />
                    </span>
                  ) : p.isPreg ? (
                    <span className="badge badge-primary">
                      <FormattedMessage id="value.yes" />
                    </span>
                  ) : (
                    <span className="badge badge-secondary">
                      <FormattedMessage id="value.no" />
                    </span>
                  )}
                </td>
                <th>
                  <FormattedMessage id="field.preginfo" />
                </th>
                <td>
                  G:{p.g === null ? '- ' : p.g + ' '}
                  P:{p.p === null ? '- ' : p.p + ' '}
                  A:{p.a === null ? '- ' : p.a + ' '}
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage id="field.memo" />
                </th>
                <td colSpan="3">{p.memo}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

@inject('locale')
@observer
class PictureGroup extends Component {
  render() {
    const { locale, pictureGroup: g, onGroupCheckChange, onPictureCheckChange } = this.props;
    return (
      <li>
        <div className="reg-date">
          <CustomInput
            type="checkbox"
            id={`g-${g.idx}`}
            label={`${locale.fm('field.regdate')} : ${g.regDate}`}
            onChange={(e) => onGroupCheckChange(g.idx, e)}
            checked={g.checked}
          />
        </div>
        <ul>
          {g.list.map((p, j) => (
            <Picture key={p.idx} picture={p} gIdx={g.idx} cIdx={j} onPictureCheckChange={onPictureCheckChange} small src={`${p.url}?width=180&marked=true`} />
          ))}
        </ul>
      </li>
    );
  }
}

@inject('auth')
@inject('locale')
@inject('cerviBox')
@inject('cervicoResultWindow')
@inject('cerviAiResultWindowWithoutPatInfo')
@inject('zoomWindow')
@observer
class Picture extends Component {
  handlePictureClick = (gIdx, cIdx) => {
    const { locale, zoomWindow, cerviBox } = this.props;
    const pl = cerviBox.getGroupPicture(gIdx).map((p) => {
      return {
        url: p.url,
        title: `${locale.fm('field.takedate')} : ${moment(p.takeDate).format('YYYY-MM-DD HH:mm:SS')}`
      };
    });
    zoomWindow.setPictureList(pl, cIdx);
  };

  handleViewResultButtonClick = (gIdx, idx) => {
    const { cerviBox, cervicoResultWindow } = this.props;
    cervicoResultWindow.setList(
      [cerviBox.getGroupPicture(gIdx).find((p) => p.idx === idx)].map((p) => {
        return { idx: p.fcode, statusCode: p.statusCode };
      })
    );
  };

  handleViewAIResultButtonClick = (gIdx, idx) => {
    const { cerviBox, cerviAiResultWindowWithoutPatInfo } = this.props;

    // 출처에 따라 다른 결과 표기
    // rq_upload면 cerviAiResultWindowWithoutPatInfo로, cerviAi면 cerviAiResultWindow로 보낸다.
    const { idxRqUpload, aiResult } = cerviBox.getGroupPicture(gIdx).find((p) => p.idx === idx);

    if (aiResult) {
      cerviAiResultWindowWithoutPatInfo.setList(this.convertAiResult(aiResult, idxRqUpload), idxRqUpload);
    }
  };

  handleEditMemoButtonClick = () => {
    const { cerviBox, picture: p } = this.props;
    cerviBox.editImage(p.idxCervibox, p.idx, p.memo);
  };

  handleImageChange = (gIdx, pIdx, e) => {
    const i = e.target;
    this.props.cerviBox.changeImage(gIdx, pIdx, i.name, i.value);
  };

  convertAiResult = (r, rIdx) => {
    return [
      {
        idx: rIdx,
        aiIdx: r.idx,
        classResult: r.classResult,
        probability: r.probability,
        rqDspResult: r.rqDspResult,
        roiList: r.roiList,
        url: `/api/rq/cerviai/picture/${rIdx}`
      }
    ];
  };
  render() {
    const { auth, gIdx, cIdx, picture: p, locale, onPictureCheckChange } = this.props;
    return (
      <li key={p.idx} className={cx({ checked: p.checked })}>
        <div className="h-flex picture-box">
          <div className="d-flex">
            <div className="align-items-center">
              <div className="picture" onClick={(e) => this.handlePictureClick(gIdx, cIdx, e)}>
                <PictureBox small src={`${p.url}?${p.aiResult ? `ts=${moment(p.aiResult.infDate).valueOf()}&` : ''}width=180&marked=true`} />
                {/* {p.classResult ? (
                  <Button color="warning" disabled>
                    AI
                  </Button>
                ) : (
                  ''
                )} */}
              </div>
              <div className="take-date">
                <CustomInput
                  type="checkbox"
                  id={`p-${p.idx}`}
                  label={p.takeDate ? moment(p.takeDate).format('YYYY-MM-DD HH:mm:ss') : ''}
                  onChange={(e) => onPictureCheckChange(gIdx, p.idx, e)}
                  checked={p.checked}
                />
              </div>
            </div>
            <div className="thumb-info h-flex">
              <Table bordered hover size="sm">
                <tbody>
                  <tr>
                    <th>
                      <FormattedMessage id="field.ev.result" />
                    </th>
                    <td>{p.fcode ? p.statusCode >= 7 ? p.txtResult : <FormattedMessage id="value.status.b" /> : ''}</td>
                    <td className="text-center">
                      {p.txtResult ? (
                        <Button size="sm" onClick={() => this.handleViewResultButtonClick(gIdx, p.idx)}>
                          <MdDesktopMac /> <FormattedMessage id="button.res.show" />
                        </Button>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <FormattedMessage id="field.ai.result" />
                    </th>
                    <td>{p.aiResult ? ClassResultMap[p.aiResult.classResult].rqDspResultKo : ''}</td>
                    <td className="text-center">
                      {p.aiResult ? (
                        <Button size="sm" onClick={() => this.handleViewAIResultButtonClick(gIdx, p.idx)}>
                          <MdDesktopMac /> <FormattedMessage id="button.res.show" />
                        </Button>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="d-flex memo-area">
            <Input type="textarea" name="memo" value={p.memo} onChange={(e) => this.handleImageChange(gIdx, p.idx, e)} />
            <Button size="sm" onClick={() => this.handleEditMemoButtonClick(p.idx)}>
              <FormattedMessage id="button.save.memo" />
            </Button>
          </div>
        </div>
      </li>
    );
  }
}

export default CerviBox;
