import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './DropdownList.scss';

export default class DropdownList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      toggleText: props.defaultToggleText,
      selectedIndex: -1
    };
    this.toggle = this.toggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleSelect(e, idx) {
    this.setState({
      toggleText: e.target.innerText,
      selectedIndex: idx
    });
    this.props.setSelect(idx);
  }

  render() {
    return (
      <Dropdown className="custom-dropdown" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle color="secondary" caret>
          {this.state.toggleText}
        </DropdownToggle>
        <DropdownMenu>
          {this.props.items.map((item, idx) => (
            <DropdownItem key={item.idx} onClick={(e) => this.handleSelect(e, item.idx)}>
              {item.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
