import { observable, action } from 'mobx';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

export default class CerviBoxCopyStore {
  @observable open = false;
  @observable rmOriginalCheck = false;
  @observable list = [];

  cerviBoxIdx = null;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
  }
  @action
  show = (cerviBoxIdx, list) => {
    this.cerviBoxIdx = cerviBoxIdx;
    this.list = JSON.parse(JSON.stringify(list));
    this.open = true;
  };

  @action
  setRmOriginalCheck = (bool) => {
    this.rmOriginalCheck = bool;
  };

  @action
  checkImg = (idx, checked) => {
    this.list.find((p) => p.idx === idx).checked = checked;
  };

  @action
  close = () => {
    this.open = false;
    this.list = [];
    this.rmOriginalCheck = false;
    this.cerviBoxIdx = null;
  };

  @action
  save = async (list, targetCerviboxList) => {
    const rmOriginalCheck = this.rmOriginalCheck;

    if (list.length === 0) {
      toast.warn(this.locale.fm('comp.cervibox.vali.sel1pic'));
      return;
    }

    if (targetCerviboxList.length === 0) {
      toast.warn(this.locale.fm('comp.cervibox.vali.selBox'));
      return;
    }

    const fromBoxIdx = this.cerviBoxIdx;
    const req = {
      toBoxIdx: targetCerviboxList,
      plist: list,
      rmOriginalCheck: rmOriginalCheck
    };

    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.put(`/api/rq/cervibox/${fromBoxIdx}/copy-box`, req);
      if (response.data.success) {
        toast.success(this.locale.fm('store.cervibox.save'));
        this.close();
        this.root.cervicoRequest.init();
        this.root.cerviBox.triggerReset();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };
}
