import React, { Component } from 'react';
import {
  FaObjectGroup,
  FaSearchPlus,
  FaSearchMinus,
  FaHandPaper,
  FaDrawPolygon,
  FaPen,
  FaEraser,
  FaUndoAlt,
  FaRedoAlt,
  FaTrashAlt,
  FaChevronLeft,
  FaChevronRight,
  FaQuestion,
  FaRegCircle,
  FaRegSquare,
  FaCompress,
  FaExpand
} from 'react-icons/fa';
import './DrawRoiNaviBar.scss';

class DrawRoiNaviBar extends Component {
  handlerNaviBtn = (e) => {
    e.stopPropagation();
  };

  render() {
    const { mode, cantUndo, cantRedo, handlePictureClick, currentPic, handleFullscreen, expand } = this.props;
    return (
      <div className="navi-wrap">
        <div className="navi" onClick={(e) => this.handlerNaviBtn(e)}>
          <div className={mode === 'SEL' ? 'active' : ''}>
            <FaObjectGroup />
          </div>
          <div>
            <FaSearchPlus />
          </div>
          <div>
            <FaSearchMinus />
          </div>
          <div className={mode === 'MOV' ? 'active' : ''}>
            <FaHandPaper />
          </div>
          <div className={mode === 'REC' ? 'active' : ''}>
            <FaRegSquare />
          </div>
          {/* <div className={mode === 'CIR' ? 'active' : ''}>
            <FaRegCircle />
          </div>
          <div className={mode === 'LIN' ? 'active' : ''}>
            <FaDrawPolygon />
          </div>
          <div className={mode === 'PEN' ? 'active' : ''}>
            <FaPen />
          </div> */}
          <div className={mode === 'CLR' ? 'active' : ''}>
            <FaEraser />
          </div>
          <div className={cantUndo ? 'disabled-ur' : ''}>
            <FaUndoAlt />
          </div>
          <div className={cantRedo ? 'disabled-ur' : ''}>
            <FaRedoAlt />
          </div>
          <div>
            <FaTrashAlt />
          </div>

          <span className="flex-grow-1"></span>

          <div onClick={handleFullscreen}>{expand ? <FaCompress /> : <FaExpand />}</div>
          <div className="d-flex navi-move">
            <div onClick={(e) => handlePictureClick(currentPic - 1, e)}>
              <FaChevronLeft />
            </div>
            <div onClick={(e) => handlePictureClick(currentPic + 1, e)}>
              <FaChevronRight />
            </div>
          </div>
          <span className="nav-spacer"></span>
          <div>
            <FaQuestion />
          </div>
        </div>
      </div>
    );
  }
}

export default DrawRoiNaviBar;
