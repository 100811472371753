import preval from 'preval.macro';
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Row, Col, CustomInput, Input, Button, Spinner } from 'reactstrap';
import { MdSignalWifiOff } from 'react-icons/md';

import { FormattedMessage } from 'react-intl';
import { FaCheck, FaTrash } from 'react-icons/fa';

import { observer, inject } from 'mobx-react';

import './Setting.scss';

function Ping({ test, alive, ping }) {
  if (test) return <Spinner type="grow" color="secondary" />;
  if (alive) {
    if (ping < 30) return <span className="text-success">●●●</span>;
    else if (ping < 50)
      return (
        <>
          <span className="text-warning">●●</span>
          <span className="text-light">●</span>
        </>
      );
    else
      return (
        <>
          <span className="text-danger">●</span>
          <span className="text-light">●●</span>
        </>
      );
  } else {
    return (
      <span className="text-secondary">
        <MdSignalWifiOff />
      </span>
    );
  }
}

@inject('locale')
@inject('setting')
@observer
class Setting extends Component {
  versionInfo = window.rq ? window.rq.getVersionInfo() : {};

  state = {
    router: { test: false, alive: false, ping: 0 },
    camera: { test: false, alive: false, ping: 0 },
    display: { test: false, alive: false, ping: 0 }
  };

  handleChange = (e) => {
    this.props.setting.change(e.target.name, e.target.value);
  };

  handlePingTest = () => {
    const { natIp, cameraIp, displayIp } = this.props.setting.setting;

    if (cameraIp === '') {
    } else {
      this.setState({
        router: { test: true, alive: false, ping: 0 },
        camera: { test: true, alive: false, ping: 0 },
        display: { test: true, alive: false, ping: 0 }
      });
      window.rq.cameraPingTest(natIp).then((res) => {
        this.setState({
          router: { test: false, alive: res.alive, ping: res.avg }
        });
      });
      window.rq.cameraPingTest(cameraIp).then((res) => {
        this.setState({
          camera: { test: false, alive: res.alive, ping: res.avg }
        });
      });
      window.rq.cameraPingTest(displayIp).then((res) => {
        this.setState({
          display: { test: false, alive: res.alive, ping: res.avg }
        });
      });
    }
  };

  handleSave = () => {
    this.props.setting.save();
  };

  handleClearCache = (e) => {
    this.props.setting.clearCache();
  };

  componentDidMount() {
    this.props.setting.init();
    this.handlePingTest();
  }

  componentWillUnmount() {}

  render() {
    const {
      locale,
      setting: { setting: s }
    } = this.props;
    const drive = 'CDEFGHIJKLMNOPQRSTUVWXYZ';
    return (
      <div className="container-fluid h-flex">
        <Card className="Setting">
          <CardHeader>
            <FormattedMessage id="comp.setting.cam.title" />
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup row>
                <Col sm={2}>
                  <CustomInput
                    type="radio"
                    id="cameraType1"
                    name="cameraType"
                    value="wifi"
                    label={locale.fm('field.setting.camera.wireless')}
                    checked={s.cameraType === 'wifi'}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col sm={5}>
                  <Input type="select" bsSize="sm" name="serverType" value={s.serverType} onChange={this.handleChange}>
                    <option>https</option>
                    <option>http</option>
                  </Input>
                </Col>
                <Col sm={5}>
                  <Input
                    type="number"
                    bsSize="sm"
                    placeholder={locale.fm('value.setting.camera.wireless.port')}
                    name="listenPort"
                    value={s.listenPort}
                    onChange={this.handleChange}
                    min={1025}
                    max={10000}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2} />
                <Col sm={8} className="ip-group">
                  <FormGroup row>
                    <Col sm={2}>
                      <FormattedMessage id="value.setting.router" />
                    </Col>
                    <Col sm={9}>
                      <Input type="text" bsSize="sm" placeholder={locale.fm('value.setting.ip')} name="natIp" value={s.natIp} onChange={this.handleChange} />
                    </Col>
                    <Col sm={1}>
                      <Ping {...this.state.router} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={2}>
                      <FormattedMessage id="value.setting.camera" />
                    </Col>
                    <Col sm={9}>
                      <Input type="text" bsSize="sm" placeholder={locale.fm('value.setting.ip')} name="cameraIp" value={s.cameraIp} onChange={this.handleChange} />
                    </Col>
                    <Col sm={1}>
                      <Ping {...this.state.camera} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={2}>
                      <FormattedMessage id="value.setting.receiver" />
                    </Col>
                    <Col sm={9}>
                      <Input type="text" bsSize="sm" placeholder={locale.fm('value.setting.ip')} name="displayIp" value={s.displayIp} onChange={this.handleChange} />
                    </Col>
                    <Col sm={1}>
                      <Ping {...this.state.display} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <Button type="button" outline size="sm" block onClick={() => this.handlePingTest()}>
                    <FormattedMessage id="button.test" />
                  </Button>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2}>
                  <CustomInput
                    type="radio"
                    id="cameraType2"
                    name="cameraType"
                    value="usb"
                    label={locale.fm('field.setting.camera.wired')}
                    checked={s.cameraType === 'usb'}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col sm={10}>
                  <Input placeholder={locale.fm('value.setting.camera.wired')} bsSize="sm" name="usbDir" value={s.usbDir} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2}>
                  <CustomInput
                    type="radio"
                    id="cameraType3"
                    name="cameraType"
                    value="ext"
                    label={locale.fm('field.setting.camera.external')}
                    checked={s.cameraType === 'ext'}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col sm={10}>
                  <Input type="select" bsSize="sm" name="extDrive" value={s.extDrive} onChange={this.handleChange}>
                    {drive.split('').map((d, i) => (
                      <option key={i}>{d}</option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2} />
                <Col sm={10}>
                  <CustomInput
                    type="checkbox"
                    id="notification"
                    name="notification"
                    label={locale.fm('field.setting.notification')}
                    checked={s.notification === true}
                    onChange={(e) => {
                      this.handleChange({ target: { name: e.target.name, value: e.target.checked } });
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="text-right" row>
                <Col sm={10} />
                <Col sm={2}>
                  <Button type="button" color="primary" size="sm" block onClick={this.handleSave}>
                    <FaCheck /> <FormattedMessage id="button.save" />
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <Card className="Setting">
          <CardHeader>
            <FormattedMessage id="comp.setting.cache.title" />
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <Button type="button" color="danger" size="sm" onClick={this.handleClearCache}>
                  <FaTrash /> <FormattedMessage id="button.setting.cache" />
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <Card className="Setting">
          <CardHeader>
            <FormattedMessage id="comp.setting.version.title" />
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={2}>
                <FormattedMessage id="field.setting.version.title" />
              </Col>
              <Col sm={10}>{this.versionInfo.app}</Col>
            </Row>
            <Row>
              <Col sm={2}>
                <FormattedMessage id="field.setting.version.components" />
              </Col>
              <Col sm={10}>
                <div>U-{preval`const moment = require('moment'); module.exports = moment().format('YYYYMMDDHHmm');`}</div>
                <div>E-{this.versionInfo.electron}</div>
                <div>N-{this.versionInfo.node}</div>
                <div>C-{this.versionInfo.chrome}</div>
                <div>R-{React.version}</div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Setting;
