import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { PrivateRoute, PopupModal } from 'components/common';
import { Header, Menu, ProcessLayer, ProgressLayer } from 'components/layout';
import { Login } from 'components/login';
import { CervicoRequest, CervicoResult, CervicoResultWindow, CervicoResultCompareWindow } from 'components/cervico';
import { SmsResult } from 'components/sms';
import { Report } from 'components/report';
import { OtherTest } from 'components/otherTest';
import { Support } from 'components/support';
import { Setting } from 'components/setting';
import { Notice } from 'components/notice';
import { Video } from 'components/video';
import { ZoomWindow, PdfWindow, ProductWindow } from 'components/window';
import { CerviBox } from 'components/cervibox';
import { Cervitem, CervitemMain, CervitemProduct, CervitemCart } from 'components/cervitem';
import { CervitemOrder } from 'components/cervitem/order';
import { CerviAi, CerviAiResultWindow, CerviAiResultWindowWithoutPatInfo } from 'components/cerviai';
import { UpdateToast } from 'components/notice';
import { CervicoAndroid } from 'components/android';
import ZoomPopup from 'components/popup/ZoomPopup';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';

import cx from 'classnames';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ko from 'react-intl/locale-data/ko';

addLocaleData([...en, ...ko]);

@inject('screen')
@inject('auth')
@inject('locale')
@observer
class App extends Component {
  //state 가 아닌 이유... render() 방지
  hideMenu = false;
  el = React.createRef();

  handleToggleMenu = (e) => {
    //render 방지하기 위해 직접 dom 조작
    const classList = this.el.current.classList;
    if (this.hideMenu) {
      classList.remove('hide');
      classList.add('show');
      this.hideMenu = false;
    } else {
      classList.remove('show');
      classList.add('hide');
      this.hideMenu = true;
    }
  };

  componentDidMount() {
    const { auth, screen } = this.props;

    //오른쪽 클릭 막기
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    //App 최초 마운트시 인증채크
    //picture 제외
    if (window.location.pathname === '/picture') {
      //picture 팝업일 경우 인증채크 생략
      this.props.screen.setAuthChecked(true);
    } else {
      auth.touch();
    }

    const handleFullscreenChange = () => {
      screen.setFullscreen(!(!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement));
    };
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange, false);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange, false);
    document.addEventListener('fullscreenchange', handleFullscreenChange, false);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange, false);

    if (window.innerWidth < 576) {
      this.hideMenu = true;
      //this.handleToggleMenu();
    }

    //electron 종료컴펌 활성화
    if (window.rq && window.rq.enableCloseConfirm) {
      window.rq.enableCloseConfirm();
    }
  }

  render() {
    const { screen, locale, auth } = this.props;

    return screen.authChecked ? (
      <IntlProvider locale={locale.value} messages={locale.messages}>
        <BrowserRouter>
          <div className={cx('App', this.hideMenu ? 'hide' : 'show')} ref={this.el}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/picture" component={ZoomPopup} />
              <PrivateRoute>
                <Switch>
                  <Redirect exact from="/" to={auth.doctor && !auth.doctor.useCervicoYN ? '/other-test' : '/request'} />
                  {locale && locale._locale !== 'ko' && <Redirect exact from="/sms-result" to="/" />}
                  {locale && locale._locale !== 'ko' && <Redirect exact from="/notice" to="/" />}
                  {locale && locale._locale !== 'ko' && <Redirect exact from="/video" to="/" />}
                  {auth.doctor && !auth.doctor.showResult && <Redirect exact from="/result" to="/" />}
                  {auth.doctor && !auth.doctor.useCerviboxYN && <Redirect exact from="/cervibox" to="/" />}
                  {auth.doctor && !auth.doctor.useAI && <Redirect exact from="/cerviai" to="/" />}
                  {auth.doctor && !auth.doctor.useCervitemYn && <Redirect exact from="/cervitem" to="/" />}
                  {auth.doctor && !auth.doctor.useCervitemYn && <Redirect exact from="/cervitem-main" to="/" />}
                  {auth.doctor && !auth.doctor.useCervitemYn && <Redirect exact from="/order" to="/" />}
                  {auth.doctor && !auth.doctor.useCervitemYn && <Redirect exact from="/cart" to="/" />}
                  <Route exact path="/android" component={CervicoAndroid} />
                  <>
                    <Header onToggleMenu={this.handleToggleMenu} />
                    <div className="content-wrap">
                      <Menu onToggleMenu={this.handleToggleMenu} />
                      <div id="content">
                        <Switch>
                          <Route exact path="/cervitem-main" component={CervitemMain} />
                          <Route exact path="/cervitem" component={Cervitem} />
                          <Route exact path="/cervitem/:idx" component={CervitemProduct} />
                          <Route exact path="/order" component={CervitemOrder} />
                          <Route exact path="/cart" component={CervitemCart} />
                          <Fragment>
                            <section className="with-padding">
                              <Switch>
                                <Route exact path="/result" component={CervicoResult} />
                                <Route exact path="/request" component={CervicoRequest} />
                                <Route exact path="/sms-result" component={SmsResult} />
                                <Route exact path="/report" component={Report} />
                                <Route exact path="/support" component={Support} />
                                <Route exact path="/other-test" component={OtherTest} />
                                <Route exact path="/setting" component={Setting} />
                                <Route exact path="/notice/:category?" component={Notice} />
                                <Route exact path="/video" component={Video} />
                                <Route exact path="/cervibox" component={CerviBox} />
                                <Route exact path="/cerviai" component={CerviAi} />
                              </Switch>
                            </section>
                          </Fragment>
                        </Switch>
                      </div>
                    </div>
                    <CervicoResultCompareWindow />
                    <CervicoResultWindow />
                    <CerviAiResultWindow />
                    <CerviAiResultWindowWithoutPatInfo />
                    <PdfWindow />
                    <ZoomWindow />
                    <ProductWindow />
                    {screen.popup.map((v, i) => (
                      <PopupModal key={i} popup={v} />
                    ))}
                    {screen.unreadUpdateRelease.length > 0 && <>{toast.info(<UpdateToast />, { position: 'top-right', autoClose: 4000 })}</>}
                  </>
                </Switch>
              </PrivateRoute>
            </Switch>
            <ProcessLayer />
            <ProgressLayer />
            <ToastContainer position="top-center" autoClose={2000} pauseOnFocusLoss={false} />
            <iframe id="download-frame" title="download frame" className="d-none"></iframe>
            {/* 캡쳐용 임시 주석 */}
            {/* {process.env.REACT_APP_SERVER_TYPE === 'staging' ? <div className="test-server">TEST SERVER</div> : ''} */}
            {/* {process.env.REACT_APP_SERVER_TYPE === 'staging-next' ? <div className="test-server next">NEXT DEV SERVER</div> : ''} */}
          </div>
        </BrowserRouter>
      </IntlProvider>
    ) : (
      <div id="premount-progress">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default App;
