import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ajaxErrorHandler } from 'libs/Utils';
import { getMessagingToken, deleteMessagingToken } from 'libs/Fcm';
export default class Auth {
  //checksum
  @observable checksum = '';

  //로그인 유저
  @observable doctor = null;

  //최초 접근 로그인 채크
  @observable authChecked = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
  }

  //최초 app load 시에 호출
  @action
  touch = async () => {
    try {
      const response = await axios.post('/api/auth/rq/touch', {
        pushToken: await getMessagingToken()
      });
      if (response.data.success) {
        this.afterLogin(response.data.data);
      } else {
        this.checksum = response.data.data;
      }
    } catch (error) {
      //TO nothing
    }
    this.root.screen.setAuthChecked(true);
  };

  @action
  login = async (fd) => {
    let ret = false;
    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.post('/api/auth/rq/login', {
        id: fd.userid,
        pw: fd.password,
        pushToken: await getMessagingToken()
      });
      if (response.data.success) {
        this.afterLogin(response.data.data);
        ret = true;
      } else {
        toast.error(this.locale.fm('store.auth.fail'));
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
    return ret;
  };

  @action
  logout = async () => {
    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.post('/api/auth/rq/logout', {
        pushToken: await getMessagingToken()
      });
      this.checksum = response.data.data;
      await deleteMessagingToken();
    } catch (error) {
      //여러가지 이유로 error 발생 가능
      //1. 이미 logout 된 경우
      //2. fcm 토큰 등록안되어 있는경우..
      console.error(error);
    }

    this.afterLogout(); //무조건 호출
    this.root.screen.setProcessLayer(false);
  };

  @action
  afterLogin(data) {
    this.doctor = data.user;
    this.root.screen.checkPopup();

    if (window.rq) window.rq.login();
  }

  @action
  afterLogout() {
    this.doctor = null;
    if (window.rq) window.rq.logout();
  }

  @action
  reqConfirmYNChange = async (reqConfirmYN) => {
    try {
      const response = await axios.post(`/api/rq/doctor/reqConfirmYN?reqConfirmYN=${reqConfirmYN ? 'true' : 'false'}`);
      runInAction('AuthStore : async reqConfirmYNChange -> success', () => {
        if (response.data.success) {
          this.doctor.reqConfirmYN = reqConfirmYN;
        }
      });
    } catch (error) {
      ajaxErrorHandler(error);
    }
  };
}
