import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { CervitemProduct } from 'components/cervitem';

import './ProductWindow.scss';

@inject('screen')
@inject('productWindow')
@withRouter
@observer
class ProductWindow extends Component {
  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.productWindow.init();
    }
  }

  render() {
    const { productWindow } = this.props;

    if (productWindow.show) {
      return (
        <div className="ProductWindow layer-window" ref={this.el} onKeyDown={this.handleKeyDown}>
          <div className="area">
            <CervitemProduct />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default ProductWindow;
