export default {
  en: {
    'menu.request': 'Request',
    'menu.response': 'Expert Reading',
    'menu.sms': 'Message history',
    'menu.report': 'Statistics',
    'menu.other-test': 'Send Message',
    'menu.other': 'Pathology·Diagnosis',
    'menu.remoteAssist': 'Remote Help',
    'menu.setting': 'Settings',
    'menu.notice': 'Notice',
    'menu.video': 'Seminar',
    'menu.cervibox': 'CerviBox',
    'menu.cervitem': 'CerviTem',
    'menu.order': 'Order',
    'menu.cerviai': 'AI Analysis',

    'field.Notation': 'This product is a medical device.',
    'field.unreadUpdateRelease': 'There is an update history. \n Click here to check.',
    'field.beta': 'Beta',
    'field.optional': 'Optional',
    'field.id': 'ID',
    'field.password': 'Password',
    'field.newPassword': 'New Password',
    'field.newPassword2': 'Confirm New Password',
    'field.startdate': 'Uploaded',
    'field.regdate': 'Registration Date',
    'field.visitDate': 'Visit Date',
    'field.fcode': 'Case No.',
    'field.patinfo': 'Patient Info.',
    'field.docname': 'Doctor',
    'field.patname': 'Name',
    'field.chartnum': 'MRN',
    'field.birthday': 'Date of Birth',
    'field.age': 'Age',
    'field.comment': 'Comment',
    'field.deleteCause': 'Reason for cancellation',
    'field.pregnancy': 'Pregnancy',
    'field.preginfo': 'Pregnancy info',
    'field.gestatio': 'No. of Gravidity',
    'field.parturition': 'No. of Parity',
    'field.abortion': 'No. of Abortion',
    'field.therapy': 'Medical History',
    'field.therapy.short': 'Medical History',
    'field.morphology': 'Morphology',
    'field.doctorcomment': 'Comments',
    'field.takedate': 'Capture Date',
    'field.requestdate': 'Request Date',
    'field.analydate': 'Analysis date',
    'field.lastrequestdate': 'Last Request Date',
    'field.equalresultmsg': 'There is a history of the same patient within the last 2 weeks.',
    'field.receiveddate': 'Request Date',
    'field.teststatus': 'Status',
    'field.txtresult': 'Result',
    'field.evaResult': 'Expert Result',
    'field.resultSum': 'CT',
    'field.txtresultSms': 'Result Message',
    'field.txtretestSms': 'Retest Message',
    'field.testtype': 'Type',
    'field.target': 'Patients',
    'field.lastsmsdate': 'Last sent date',
    'field.evaname': 'Expert',
    'field.diagnosisdate': 'Evaluation Date',
    'field.pickdate': 'Collection Date',
    'field.testdate': 'Test Date',
    'field.typecomments': 'Comments',
    'field.settlementName': 'Title',
    'field.memo': 'Comment',
    'field.fromDate': 'Start Date',
    'field.toDate': 'End Date',
    'field.regDate': 'Creation Date',
    'field.camtype': 'Camera',
    'field.rsltdate': 'Result Message',
    'field.retestdate': 'Retest Message',
    'field.searchword': 'Search word',
    'field.cnt': 'Count',
    'field.sms.receiverList.requestDate': 'Request Date',
    'field.sms.receiverList.mobile': 'Mobile',
    'field.sms.receiverList.smsType': 'Type',
    'field.sms.receiverList.result': 'Result',
    'field.sms.receiverList.scheduledSms': 'Scheduled Message',
    'field.sms.list.sendDate': 'Date',
    'field.total': 'Total',
    'field.selected': 'Selected',
    'field.sms.msg': 'Message',
    'field.sms.msg.msgChrCnt': 'character(s)',
    'field.sms.status': 'Status',
    'field.sms.contents': 'Contents',
    'field.sms.action': 'Action',
    'field.sms.template.hpName': '#병원이름#',
    'field.sms.template.hpTel': '#병원전화번호#',
    'field.sms.template.patName': '#PatName#',
    'field.sms.template.createDate': '#CreateDate#',
    'field.report.type': 'Type',
    'field.report.term': 'Date',
    'field.setting.camera.wireless': 'Wireless camera',
    'field.setting.camera.wired': 'Wired camera',
    'field.setting.camera.external': 'External device',
    'field.setting.notification': 'Use OS notification',
    'field.setting.version.title': 'CERVICARE',
    'field.setting.version.components': 'Components',
    'field.requestConfirm': 'Do you want to request with the information you entered?',
    'field.requestConfirm.AI': 'Do you want to analyze with the information you entered?',
    'field.ev.result': 'Expert Evaluation Results',
    'field.ai.result': 'AI Analysis Results',
    'field.ai.process': 'Analyzing',
    'field.ai.process-error': 'Fail',
    'field.ai.analysis': 'AI',
    'field.ai.roi': 'ROI',
    'field.ai.notes': 'Notes',
    'field.ai.n': 'Normal',
    'field.ai.a': 'Atypical',
    'field.ai.p': 'Abnormal',
    'field.ai.p1': 'Abnormal(Low)',
    'field.ai.p2': 'Abnormal(High)',
    'field.ai.td': 'Inadequate',
    'field.ai.n.probality': 'Probability of Normal',
    'field.ai.p.probality': 'Probability of Abnormal',
    'field.ai.msg1': 'The results were analyzed by AI and represent 90% confidence score.',
    'field.ai.msg2': 'If you would like additional evaluation by a Cervicography specialist, please request additional examination.',
    'field.ai.confidenceScore':
      '* Confidence Score is a numerical value from 0 to 100 that represents the degree of confidence in the prediction result. The higher the value, the higher the confidence in the prediction result.',

    'field.ai.csguide.version': '* Ref. CerviCARE® AI 1.10 from NTL Lab',
    'field.ai.csguide.result': 'AI가 예측한 결과는 {ai}이며, 신뢰점수 구간은 {cs}입니다.',
    'field.ai.csguide.result2': '신뢰점수 구간 별 예측 등급과 참조표준*을 비교해 \n시험데이터에 대한 AI 분석 결과의 정확도를 산출결과는 다음과 같습니다.',
    'field.ai.csguide.end': '아래 표는 예측값 {ai}에 대한 신뢰점수 구간별 정확도입니다. AI 결과 해석에 참고해주시길 바랍니다.',
    'field.ai.csguide.viewPct': 'View percentage',
    'field.ai.csguide.guideNA': 'AI가 예측한 결과가 {ai}일 때 참조표준이 Normal일 확률은 {npct}이고, 참조표준이 Atypical일 확률은 {apct}입니다.',
    'field.ai.csguide.guideP1P2': 'AI가 예측한 결과가 {ai}일 때 참조표준이 Abnormal(Low)일 확률은 {p1pct}이고, 참조표준이 Abnormal(High)일 확률은 {p2pct}입니다.',

    'field.tab.update': 'Update',
    'field.tab.notice': 'Notice',
    'field.cervitem.orderdate': 'Order Date',
    'field.cervitem.productName': 'Product',
    'field.cervitem.ordercancel': 'Do you want to cancel your order?',
    'field.reqdate': 'Analysis Date',
    'field.AIResult': 'AI Result',
    'field.ref': 'Reference',
    'field.ai': 'AI',
    'field.viewAiROI': 'View AI ROI',
    'field.viewEvROI': 'View Expert ROI',
    'field.ConfidenceLevel': 'Confidence Score',
    'field.requestYN': 'Request',
    'field.detail': 'Detail',

    'field.notice.none': 'There is no notices.',
    'field.other-test.sms': 'Send Message',
    'field.other-test.type': 'Type',
    'field.video.none': 'No videos.',

    // TODO: 영문 문구 수정
    'field.result.normal': 'As a result of AI analysis, Normal is predicted.',
    'field.result.Atypical': 'As a result of AI analysis, it is suspected to be Atypical stage and predicted in a normal to abnormal boundary.',
    'field.result.Atypical2': 'Consultation with a specialist is recommended for further examination.',
    'field.result.abnormal': 'As a result of AI analysis, {abnormalEN} is suspected.',
    'field.result.abnormal2': 'For a more accurate examination, recommended to refer to a specialist. ',
    'field.result.TD': 'It is an inadequate images for AI analysis. We recommended you to rephotograph it.',
    'field.AI.remove': 'Deleted images will be recovered in the Request menu. Also, the analysis will be reanalyzed after excluding deleted images. ',

    'field.ai.alert': `The AI analysis is an auxiliary tool for medical decision making for the primary screening of cervical cancer. This may differ from the evaluation result of the specialist. Please make a final decision by referrring to patient's condition and other test results comprehensively.`,
    'field.ai.alert2': ' CerviCARE® AI is a Class 3 AI software medical device approved by the Ministry of Food and Drug Safety of Korea.',
    'field.ai.noresult': 'No AI results.',
    'field.ai.noresult.request': 'No AI results, please run the AI again for a reading.',
    'field.ai.noresult.detail': 'No AI results, please run the AI again for more information.',

    'field.ai.checkbox': 'Image verification status',

    'value.unknown': 'Unknown',
    'value.err.authorization': 'Authorization has expired. Please sign in again.',
    'value.err.service': 'Service error codes',
    'value.yes': 'Yes',
    'value.no': 'No',
    'value.all': 'All',
    'value.mypat': 'My patient only',
    'value.status.a': 'Awaiting',
    'value.status.b': 'Processing',
    'value.status.0': 'Pending',
    'value.status.7': 'Completed',
    'value.status.8': 'Read',
    'value.status.9': 'Printed',
    'value.result.negative': 'Negative',
    'value.result.positive': 'Positive',
    'value.result.atypical': 'Atypical',
    'value.isER': 'ER',
    'value.testtype.1': 'Normal',
    'value.testtype.2': 'Compared',
    'value.testtype.3': 'Re-evaluation',
    'value.report.type.cervico': 'TeleCervico',
    'value.report.type.sms': 'Messages',
    'value.report.type.AI': 'AI',
    'value.report.type.cervitem': 'Cervitem',
    'value.printtype.standard': 'Report(Standard)',
    'value.printtype.bigPicLocal': 'Report(Advanced) Kor ver.', // TODO: 영문 문구 수정
    'value.printtype.bigPicEng': 'Report(Advanced) Eng ver.', // TODO: 영문 문구 수정
    'value.success': 'Succeed',
    'value.failure': 'Failed',
    'value.sms.status.sending': 'Sending',
    'value.sms.status.scheduling': 'Scheduled',
    'value.sms.status.success': 'Succeed',
    'value.sms.status.failed': 'Failed',
    'value.setting.camera.wired': "Input folder names. (use delimiter ',')",
    'value.setting.camera.wireless.port': 'Port number',
    'value.setting.router': 'Router',
    'value.setting.camera': 'Camera',
    'value.setting.receiver': 'Receiver',
    'value.setting.ip': 'IP address',

    'comp.login.message': 'Welcome',
    'comp.doctor.edit.title': 'Change Password',
    'comp.doctor.edit.window.title': 'Notification',
    'comp.doctor.edit.window.content1': 'For safe use, please change your password every 6 months.',
    'comp.doctor.edit.window.content2': 'Please change the password directly in the setting menu of the program.',
    'comp.doctor.edit.transceiver': 'NTL Transceiver',
    'comp.doctor.edit.rq': 'TeleCervico RQ',
    'comp.doctor.edit.rqwifi': 'TeleCervico RQ WIFI',
    'comp.req.grid.info': 'All images which have not been requested for 30 days will be automatically deleted.',
    'comp.req.title.pat': "Patient's Info.",
    'comp.req.title.detail': "Patient's Medical Info.",
    'comp.req.defer.title': '※Comparative request',
    'comp.req.defer.contents':
      "If you request after selecting the case data from the past case history, the expert will be requested for comparative request by attaching the patient's past image ",
    'comp.res.title.searchcondition': 'Telecervico',
    'comp.res.vali.show.more1': 'Select 1 or more case.',
    'comp.res.vali.compare.more2': 'Select 2 or more completed cases.',
    'comp.res.vali.compare.less5': 'Up to 5 results can be compared.',
    'comp.res.vali.print.more1': 'Select 1 or more completed case.',
    'comp.res.vali.print.only1': 'Select only 1 completed case.',
    'comp.res.vali.req.more1': 'Select 1 or more awaiting or pending cases.',
    'comp.res.title.modifyPatInfo': "Modify patient's info.",
    'comp.res.title.deleteCause': 'Enter reason for cancellation',
    'comp.res.sms.contents1': 'After adding a phone number while checking the recipient list, click the Send Text Message button to send the message you wrote.',
    'comp.res.sms.contents2':
      "If you write {hpName}, {hpTel}, {patName}, {createDate} in the body of the message, the hospital information, each patient's name, and the date of the last examination will be automatically changed and sent.",
    'comp.res.sms.contents3': 'Messages cannot not sent from midnight to 4 am due to system maintenance. Please keep this in mind when making a reservation.',
    'comp.top.unsendPic': 'Pending images',
    'comp.sms.title.searchcondition': 'Search Message history',
    'comp.sms.title.writeMsg': 'New Message',
    'comp.sms.title.storedMsg': 'Loading contents',
    'comp.sms.title.receiverList': "Recipient's List",
    'comp.sms.vali.emptyMessageList': 'Select messages.',
    'comp.confirmModal.title': 'Confirmed',
    'comp.smsResult.vali.selectMore1': 'Select 1 or more message.',
    'comp.report.title': 'Statistics',
    'comp.settlement.title': 'Settlement List',
    'comp.support.title.remoteAssistance': 'Remote Help',
    'comp.support.content1': 'This service provides remote help by allowing a agent to view your PC screen via internet.',
    'comp.support.content2': 'Click the number called by the agent and run the downloaded program.',
    'comp.setting.cam.title': 'Camera',
    'comp.setting.cache.title': 'Cache',
    'comp.setting.version.title': 'Version',
    'comp.cervibox.title': 'Cervibox',
    'comp.cervibox.title.searchcondition': 'Search CerviBox',
    'comp.cervibox.title.new': 'Save Cervibox',
    'comp.cervibox.title.selectedImg': 'Selected Image',
    'comp.cervibox.title.regDate': 'Registration Date',
    'comp.cervibox.nobox': 'Select a CerviBox.',
    'comp.cervibox.vali.selBox': 'Select a Box.',
    'comp.cervibox.vali.sel1box': 'Select only 1 Box.',
    'comp.cervibox.vali.sel1pic': 'Select a image.',
    'comp.cervibox.vali.equalpic': 'There is the same image in CerviBox.',
    'comp.cervibox.vali.equalpic2': '{cnt2} images saved, excluding {cnt1} images already saved.',
    'comp.cervibox.vali.noimg': 'There is no image.',
    'comp.cervibox.vali.more1img': 'Select 1 or more image.',
    'comp.cerviai.title.searchcondition': 'CerviCARE® AI',
    'comp.cerviai.title.csguide': 'Confidence Score Guide',
    'comp.cerviai.title.remove': 'Delete AI results ',
    'comp.cerviai.vali': 'It includes images that have already been analyzed.',
    'comp.cerviai.title.request': 'Request',
    'comp.dialog.closeInfo': 'Right-click on the image to close the (image) page.',
    'comp.doctor.edit.window.valid': 'This is a secure password.',

    'store.auth.fail': 'The ID or password you entered does not match.',
    'store.auth.logout': 'Sign out',
    'store.doctorEditWindow.valid.not': 'This is an invalid password.',
    'store.doctorEditWindow.valid.notEqaul': 'This is not the same password.',
    'store.doctorEditWindow.valid.pattern': 'Please make sure to include upper and lowercase letters, numbers, and special characters.',
    'store.doctorEditWindow.valid.includeId': "Passwords can't contain the user ID or the parts of the user ID.",
    'store.doctorEditWindow.valid.length': 'Please enter at least 8 to 20 characters.',
    'store.doctorEditWindow.edit.success': 'Has been changed. Please sign in again.',
    'store.cervicoRequest.vali.patname': "Type the patient' name.",
    'store.cervicoRequest.vali.chartnum': 'Type the medical record number.',
    'store.cervicoRequest.vali.birthdate': 'Type the date of birth.',
    'store.cervicoRequest.vali.birthdate2': 'The date of birth is invalid.',
    'store.cervicoRequest.vali.more1': "Enter either patient's name, medical record number, or date of birth.",
    'store.cervicoRequest.vali.picture': 'Select images. (1~4)',
    'store.cervicoRequest.vali.deleteCause': 'Type the reason for cancellation.',
    'store.cervicoRequest.confirm.title': 'Patient information',
    'store.cervicoRequest.confirm.save': 'All pending cases which have not been requested for 30 days will be automatically deleted.',
    'store.cervicoRequest.confirm.refReq': 'Do you want to request a test compared to the previous result?',
    'store.cervicoRequest.confirm.otherPat': 'Check if including images of other patients.',
    'store.cervicoRequest.success': 'Successfully Requested',
    'store.cervicoRequest.aisuccess': 'Successfully Analyzed',
    'store.cervicoRequest.delete': 'Successfully Deleted',
    'store.cervicoRequest.fail.aleadyRequested': 'It has already been requested.',
    'store.cervicoRequest.fail.print': 'Only completed cases can be printed.',
    'store.pictureList.pre.remove': 'Are you sure to delete {cnt} images?',
    'store.res.confirm.remove': 'Are you sure you want to delete?',
    'store.res.modify.PatInfo': "Patient's information has been successfully modified.",
    'store.smsSend.vali.delReceiver.selectMore1': 'Select recipients to remove from the list.',
    'store.smsSend.vali.emptyMsg': 'Type a message.',
    'store.smsSend.vali.wrongCallingNumber': 'Type the outgoing number correctly.',
    'store.smsSend.vali.emptyReceiverList': 'Add the recipient.',
    'store.smsSend.vali.emptyReceiverNumber': 'Check all phone numbers.',
    'store.smsSend.vali.emptySchedule': 'Set the reserved time.',
    'store.smsSend.vali.scheduleAtLeastTomorrow': 'Scheduled message is available from the next day.',
    'store.smsSend.sendSuccess': 'Messages have been sent successfully.',
    'store.smsTemplate.saveSuccess': 'Saved.',
    'store.smsTemplate.confirm.delete': 'Are you sure to delete?',
    'store.smsTemplate.deleteSuccess': 'Deleted.',
    'store.smsTemplate.editSuccess': 'Edited.',
    'store.smsTemplate.msgEmpty': 'There is no message content.',
    'store.smsResult.confirm.delete': ' message(s) will be deleted.',
    'store.smsResult.deleteSuccess': 'Deleted.',
    'store.pictureList.vali.cantSendOver10': 'Up to 10 images can be sent at a time.',
    'store.pictureList.vali.onlyJpg': 'Only jpg files can be sent.',
    'store.pictureList.vali.wrongFile': 'Wrong file.',
    'store.pictureList.msg.transmiting': 'Transmiting images...',
    'store.pictureList.msg.transmitingFail': 'Transmission Failed',
    'store.pictureList.msg.deleteSuccess': 'Images are deleted.',
    'store.pictureList.msg.deleteFail': 'Failed to delete images',
    'store.pictureList.vali.selectMore1': 'Select at least 1 image.',
    'store.pictureList.vali.downloadLess20': 'Up to 20 images can be downloaded at a time.',
    'store.setting.vali.portEmpty': 'Type the port number.',
    'store.setting.vali.portNum': 'The port number must be in between 1025 and 10000.',
    'store.setting.vali.folderEmpty': 'Type folder name of wired camera.',
    'store.setting.vali.cameraIp': 'Type the wireless camera IP address',
    'store.setting.saveSuccess': 'The setting is saved.',
    'store.setting.cacheDelSuccess': 'Cached images are deleted.',
    'store.setting.cacheDelFailure': 'Failed to delete cached images.',
    'stroe.cervibox.nosel': 'There is no image selected.',
    'store.cervibox.confirm.del': 'Are you sure you want to delete?',
    'store.cervibox.other.doctor.img': 'Includes a photo of another doctor.',
    'store.cervibox.other.doctor.box': 'Includes a Cervibox of another doctor.',
    'store.cervibox.success.del': 'Deleted.',
    'store.cervibox.save': 'Saved.',
    'store.cervibox.worklist.disable': '{cnt} cases that have already been saved are excluded.',
    'store.cervibox.worklist.otherpic': `Other users' images cannot be saved to CerviBox.`,
    'store.cervibox.copy': `Delete images in this box.`,
    'store.screen.quit': 'When you exit the program, you cannot receive images from the camera.\nAre you sure you want to quit?',
    'store.cerviai.success': 'AI Analysis is completed.',
    'store.cerviai.preprocessing.success': 'The verification request is complete.',
    'store.cerviai.nosel': 'No images are selected.',
    'store.cerviai.requestPic': "You can't delete images that have been requested to expert.",
    'store.cerviai.noMore20': 'Up to 20 are possible.',
    'store.cerviai.noMore5': 'Up to 5 are possible.',
    'store.cerviai.noMore1000': 'Please select up to 1000 images.',
    'store.cerviai.noReSel': 'None of the selected images are being analyzed.',
    'store.cerviai.least1': 'At least 1 image must be saved.',
    'store.cerviai.del': 'Deleted.',
    'store.otherTest.3month': 'You can search within a timeframe of 3 months.',
    'store.preprocess.noReSel': 'Images are already verified.',

    'button.reqConfirmYN': 'Recheck info',
    'button.login': 'Sign in',
    'button.logout': 'Sign out',
    'button.editDoctor': 'Change Password',
    'button.medicalDevice': 'Medical device information',
    'button.ok': 'OK',
    'button.cancel': 'Cancel',
    'button.changeLater': 'Change Later',
    'button.changeDeferredDate': 'Check after 1 month',
    'button.search': 'Search',
    'button.reset': 'Reset',
    'button.add': 'Add',
    'button.save': 'Save',
    'button.modify': 'Modify',
    'button.report': 'Report',
    'button.remove': 'Delete',
    'button.copy': 'Copy',
    'button.test': 'Test',
    'button.removeSelected': 'Delete selected images',
    'button.quickAi': 'Quick AI',
    'button.select': 'Select all images',
    'button.reload': 'Refresh',
    'button.cervibox': 'Save Cervibox',
    'button.sortDate': 'Sort',
    'button.download': 'Download',
    'button.uploadPicture': 'Upload images',
    'button.clientDownload': 'Download CerviCARE® program',
    'button.search.testhistory': 'Search History',
    'button.request': 'Expert Request',
    'button.request.short': 'Request',
    'button.deferRequest': 'Pending',
    'button.detailRequest': 'Expert Combined', // TODO check
    'button.nosms': 'Not sent',
    'button.today': 'Today',
    'button.last3days': '3 days',
    'button.last1weeks': '1 week',
    'button.last1months': '1 month',
    'button.last3months': '3 months',
    'button.last1years': '1 years',
    'button.last3years': '3 years',
    'button.reTest': 'For re-test',
    'button.res.show': 'View',
    'button.res.compare': 'Compare',
    'button.res.print': 'Print reports',
    'button.res.sms': 'Send Message',
    'button.res.req': 'Request draft',
    'button.res.del': 'Cancel request',
    'button.res.dup': 'Check duplicate',
    'button.res.downloadRptImg': 'Download Report Image',
    'button.res.AI.print': 'Print AI reports',
    'button.res.AI.downloadRptImg': 'Download AI Report Image',
    'button.sms.saveMsg': 'Save the draft',
    'button.sms.delReceiver': 'Remove recipients',
    'button.sms.send': 'Send a message',
    'button.sms.deleteMsg': 'Delete messages',
    'button.sms.unsend': 'Not Sent',
    'button.support.romoteAssistanceSite': '(Click) Remote Help web page',
    'button.setting.cache': 'Delete cached images and restart',
    'button.ai.analy': 'AI',
    'button.video': 'Play Video',
    'button.box.selectAll': 'Select all',
    'button.box.deselect': 'Unselect',
    'button.box.new': 'Create new Box',
    'button.save.memo': 'Save Comment',
    'button.more': 'View more',
    'button.rePreprocess': 'Image Verfication',

    'grid.page': 'Page',
    'grid.to': '~',
    'grid.of': '/',
    'grid.next': 'Next',
    'grid.last': 'Last',
    'grid.first': 'First',
    'grid.previous': 'Privious',
    'grid.loadingOoo': 'Londing...',
    'grid.noRowsToShow': 'No results',

    'popover.common.title': 'Description',
    'popover.res.dup': "Search the number of duplicate of patients' names and medical record numbers or dates of birth.",
    'popover.reTest': 'Re-examination prior to 6~7 months.',

    'popover.preprocessing.title': 'Image Quality Result',
    'popover.preprocessing.imageCrash': 'Some corruption in the image has been detected.',
    'popover.preprocessing.cervixClassification': 'Cervix in sight',
    'popover.preprocessing.blurryClassification': 'Cervix focused',
    'popover.preprocessing.brightnessCheck': 'Cervix brightness',
    'popover.preprocessing.true': 'Qualified',
    'popover.preprocessing.false': 'Unqualified',
    'popover.preprocessing.unknown': 'Indeterminable',
    'popover.preprocessing.notCompleted': 'Error. Image verification incomplete. Please check server connection and try again.',
    'popover.preprocessing.notice': 'Image quality verification may affect the results of AI analysis. For more accurate results, we recommend retaking the image.',

    temp: '__'
  },
  ko: {
    'menu.request': '검사의뢰',
    'menu.response': '결과조회',
    'menu.report': '통계조회',
    'menu.other-test': 'SMS전송',
    'menu.sms': 'SMS내역',
    'menu.other': '병리·진단',
    'menu.remoteAssist': '원격지원',
    'menu.setting': '설정',
    'menu.notice': '공지사항',
    'menu.video': '세미나',
    'menu.cervibox': 'CerviBox',
    'menu.cervitem': 'CerviTem',
    'menu.order': '주문내역',
    'menu.cerviai': 'AI 결과',

    'field.Notation': '본 제품은 의료기기임.',
    'field.unreadUpdateRelease': '업데이트 내역이 있습니다. \n 여기를 눌러서 확인하세요.',
    'field.beta': 'Beta',
    'field.optional': '선택입력',
    'field.id': '아이디',
    'field.password': '패스워드',
    'field.newPassword': '새 패스워드',
    'field.newPassword2': '새 패스워드 재입력',
    'field.startdate': '전송일시',
    'field.regdate': '생성일시',
    'field.visitDate': '방문일',
    'field.fcode': '검사번호',
    'field.patinfo': '환자정보',
    'field.docname': '의사',
    'field.patname': '이름',
    'field.chartnum': '차트번호',
    'field.birthday': '생년월일',
    'field.age': '나이',
    'field.comment': 'Comment',
    'field.deleteCause': '취소사유',
    'field.pregnancy': '임신여부',
    'field.preginfo': '임신정보',
    'field.gestatio': '임신회수',
    'field.parturition': '출산회수',
    'field.abortion': '유산회수',
    'field.therapy': '병력/치료이력',
    'field.therapy.short': '병력/치료이력',
    'field.morphology': 'Morphology',
    'field.doctorcomment': '코멘트/메모',
    'field.takedate': '촬영일',
    'field.requestdate': '검사의뢰일',
    'field.analydate': '분석실행일',
    'field.lastrequestdate': '최근의뢰일',
    'field.equalresultmsg': '최근 2주 내에 같은 환자의 검사이력이 있습니다.',
    'field.receiveddate': '검사요청일',
    'field.teststatus': '검사상태',
    'field.txtresult': '결과',
    'field.evaResult': '전문가 판독 결과',
    'field.resultSum': 'CT',
    'field.txtresultSms': '검사결과 안내',
    'field.txtretestSms': '재검진 안내',
    'field.testtype': '종류',
    'field.target': '검색대상',
    'field.lastsmsdate': '최근 문자 전송일',
    'field.evaname': '판독자',
    'field.diagnosisdate': '판독일',
    'field.pickdate': '채취일',
    'field.testdate': '검사일',
    'field.typecomments': '판독자 코멘트',
    'field.settlementName': '이름',
    'field.memo': '메모',
    'field.fromDate': '시작일',
    'field.toDate': '종료일',
    'field.regDate': '생성일',
    'field.camtype': '카메라',
    'field.rsltdate': '검사결과 SMS',
    'field.retestdate': '재검진 SMS',
    'field.searchword': '검색어',
    'field.cnt': '개수',
    'field.sms.receiverList.requestDate': '검사요청일',
    'field.sms.receiverList.mobile': '전화번호',
    'field.sms.receiverList.smsType': '검사',
    'field.sms.receiverList.result': '판독결과',
    'field.sms.receiverList.scheduledSms': '예약전송',
    'field.sms.list.sendDate': '전송(예약일)',
    'field.total': '총',
    'field.selected': '선택',
    'field.sms.msg': '메시지',
    'field.sms.msg.msgChrCnt': '자',
    'field.sms.status': '전송상태',
    'field.sms.contents': '내용',
    'field.sms.action': '편집',
    'field.sms.template.hpName': '#병원이름#',
    'field.sms.template.hpTel': '#병원전화번호#',
    'field.sms.template.patName': '#환자이름#',
    'field.sms.template.createDate': '#검사날짜#',
    'field.report.type': '통계 종류',
    'field.report.term': '기간',
    'field.setting.camera.wireless': '무선카메라',
    'field.setting.camera.wired': '유선카메라',
    'field.setting.camera.external': '외부장비',
    'field.setting.notification': '운영체제 알림 사용',
    'field.setting.version.title': 'CERVICARE',
    'field.setting.version.components': '구성요소',
    'field.requestConfirm': '입력하신 정보로 의뢰하시겠습니까?',
    'field.requestConfirm.AI': '입력하신 정보로 분석하시겠습니까?',
    'field.ev.result': '전문가판독결과',
    'field.ai.result': 'AI 분석 결과',
    'field.ai.process': '분석중',
    'field.ai.process-error': '분석실패',
    'field.ai.analysis': 'AI 예측',
    'field.ai.roi': '영역',
    'field.ai.notes': '참고사항',
    'field.ai.n': 'Normal',
    'field.ai.a': 'Atypical',
    'field.ai.p': 'Abnormal',
    'field.ai.p1': 'Abnormal(Low)',
    'field.ai.p2': 'Abnormal(High)',
    'field.ai.td': 'Inadequate',
    'field.ai.n.probality': 'N확률',
    'field.ai.p.probality': 'P확률',
    'field.ai.msg1': '검사 결과는 AI가 판독한 결과로, 90% 신뢰도를 가집니다.',
    'field.ai.msg2': '추가적으로 Cervicography 전문가의 판독을 원하시면, 추가적인 검사를 의뢰하세요.',

    'field.ai.confidenceScore': '* 신뢰점수란? 인공지능이 예측 결과를 확신하는 정도를 0~100점의 수치로 나타낸 것으로, 높을수록 예측 결과에 대한 신뢰도가 높음을 의미합니다.',
    'field.ai.csguide.version': '* Ref. CerviCARE® AI 1.10 from NTL Lab',
    'field.ai.csguide.result': 'AI가 예측한 결과는 {ai}이며, 신뢰점수 구간은 {cs}입니다.',
    'field.ai.csguide.result2': '신뢰점수 구간 별 예측 등급과 참조표준*을 비교해 \n시험데이터에 대한 AI 분석 결과의 정확도를 산출결과는 다음과 같습니다.',
    'field.ai.csguide.end': '아래 표는 예측값 {ai}에 대한 신뢰점수 구간별 정확도입니다. AI 결과 해석에 참고해주시길 바랍니다.',
    'field.ai.csguide.viewPct': 'View percentage',
    'field.ai.csguide.guideNA': 'AI가 예측한 결과가 {ai}일 때 참조표준이 Normal일 확률은 {npct}이고, 참조표준이 Atypical일 확률은 {apct}입니다.',
    'field.ai.csguide.guideP1P2': 'AI가 예측한 결과가 {ai}일 때 참조표준이 Abnormal(Low)일 확률은 {p1pct}이고, 참조표준이 Abnormal(High)일 확률은 {p2pct}입니다.',
    'field.tab.update': '업데이트',
    'field.tab.notice': '공지사항',
    'field.cervitem.orderdate': '주문일',
    'field.cervitem.productName': '상품명',
    'field.cervitem.ordercancel': '주문을 취소하시겠습니까?',
    'field.reqdate': '요청일',
    'field.AIResult': 'AI 예측',
    'field.ref': '참조',
    'field.ai': 'AI',
    'field.viewAiROI': 'AI ROI 보기',
    'field.viewEvROI': '전문가 ROI 보기',
    'field.ConfidenceLevel': '신뢰도(Confidence Score)',
    'field.requestYN': '판독',
    'field.detail': '상세보기',

    'field.notice.none': '공지사항이 없습니다.',
    'field.other-test.sms': 'SMS전송',
    'field.other-test.type': '검사종류',
    'field.video.none': '동영상이 없습니다.',

    'field.result.normal': 'AI 분석 결과 정상(Normal)으로 예측됩니다.',
    'field.result.Atypical': 'AI 분석 결과 정상(Normal)으로 예측됩니다.',
    'field.result.Atypical2': 'HPV 검사가 요구될 수 있으며, 보다 정확한 검사를 위하여 부인종양전문 판독의에게 의뢰하시기 바랍니다.',
    'field.result.abnormal': 'AI 분석 결과 {abnormalKR}으로 예측됩니다',
    'field.result.abnormal2': '{hpvKR}가 요구될 수 있으며, 보다 정확한 검사를 위하여 부인종양전문 판독의에게 의뢰하시기 바랍니다.',
    'field.result.TD': 'AI 분석을 하기에는 적합한 이미지가 아니므로 재촬영이 요구됩니다.',
    'field.AI.remove': '삭제 된 사진은 검사의뢰 메뉴에 복구됩니다. 또한 삭제된 사진을 제외 후 재분석이 진행됩니다.',

    'field.ai.alert':
      'AI분석결과는 자궁경부암 1차 스크리닝을 위해 의료진의 진료에 도움을 주기 위한 의사결정 보조수단입니다. 이는 부인종양전문의의 판독결과와도 차이가 있을 수 있습니다. 환자상태와 다른 검사결과들을 종합적으로 참고하셔서 최종 판단하시기 바랍니다',
    'field.ai.alert2': 'CerviCARE® AI는 식약처로부터 승인받은 3등급 인공지능 소프트웨어 의료기기입니다.',
    'field.ai.noresult': 'AI 실행 결과가 없습니다.',
    'field.ai.noresult.request': 'AI 실행 결과가 없습니다. 판독을 위해 AI를 다시 실행해주세요.',
    'field.ai.noresult.detail': 'AI 실행 결과가 없습니다. 자세한 정보 확인을 위해 AI를 다시 실행해주세요.',

    'field.ai.checkbox': '이미지 검증',

    'value.unknown': '모름',
    'value.err.authorization': '인증이 만료되었습니다. 다시 로그인해 주세요.',
    'value.err.service': '서비스 에러 코드',
    'value.yes': '예',
    'value.no': '아니오',
    'value.all': '전체',
    'value.mypat': '내환자만',
    'value.status.a': '검사대기',
    'value.status.b': '판독중',
    'value.status.0': '검사보류',
    'value.status.7': '판독완료',
    'value.status.8': '결과읽음',
    'value.status.9': '출력완료',
    'value.result.negative': 'Negative',
    'value.result.positive': 'Positive',
    'value.result.atypical': 'Atypical',
    'value.isER': '응급',
    'value.testtype.1': '일반',
    'value.testtype.2': '비교',
    'value.testtype.3': '재판독',
    'value.report.type.cervico': '텔레써비코',
    'value.report.type.sms': 'SMS',
    'value.report.type.AI': 'AI',
    'value.report.type.cervitem': 'Cervitem',
    'value.printtype.standard': '표준형',
    'value.printtype.bigPicLocal': '개량형 한글',
    'value.printtype.bigPicEng': '개량형 영문',
    'value.success': '성공',
    'value.failure': '실패',
    'value.sms.status.sending': '발송요청',
    'value.sms.status.scheduling': '발송대기',
    'value.sms.status.success': '발송성공',
    'value.sms.status.failed': '발송실패',
    'value.setting.camera.wired': '폴더명 (,로 구분하여 여러폴더 입력가능)',
    'value.setting.camera.wireless.port': '포트',
    'value.setting.router': '공유기',
    'value.setting.camera': '카메라',
    'value.setting.receiver': '수신기',
    'value.setting.ip': 'IP 주소',

    'comp.login.message': '환영합니다.',
    'comp.doctor.edit.title': '비밀번호 변경',
    'comp.doctor.edit.window.title': '안내사항',
    'comp.doctor.edit.window.content1': '안전한 사용을 위하여 6개월마다 비밀번호를 변경해주세요.',
    'comp.doctor.edit.window.content2': '해당 프로그램을 설정 메뉴에서 비밀번호를 직접 변경해주세요.',
    'comp.doctor.edit.transceiver': 'NTL Transceiver',
    'comp.doctor.edit.rq': 'TeleCervico RQ',
    'comp.doctor.edit.rqwifi': 'TeleCervico RQ WIFI',
    'comp.req.grid.info': '의뢰하지 않은 사진은 30일 이후 자동 삭제 됩니다.',
    'comp.req.title.pat': '환자 기본정보',
    'comp.req.title.detail': '환자 세부정보',
    'comp.req.defer.title': '※비교판독',
    'comp.req.defer.contents': '과거 검사이력에서 검사자료 선택 후, 의뢰하시면 판독자에게 수진자의 과거 사진을 첨부하여 비교판독으로 의뢰됩니다.',
    'comp.res.title.searchcondition': '텔레써비코 결과조회',
    'comp.res.vali.show.more1': '1개 이상 선택해주세요.',
    'comp.res.vali.compare.more2': '판독 완료된 검사를 2개 이상 선택해주세요.',
    'comp.res.vali.compare.less5': '5건까지 비교조회 가능합니다.',
    'comp.res.vali.print.more1': '판독 완료된 검사를 1개 이상 선택해주세요.',
    'comp.res.vali.print.only1': '판독 완료된 검사를 1개만 선택해주세요.',
    'comp.res.vali.req.more1': '검사보류나 검사대기 상태를 1개 이상 선택해주세요.',
    'comp.res.title.modifyPatInfo': '환자정보 수정',
    'comp.res.title.deleteCause': '취소사유 입력',
    'comp.res.sms.contents1': '수신자목록을 확인하면서 전화번호를 추가해 주신후 문자메시지 전송 버튼을 누르면 작성하신 메시지가 전송됩니다.',
    'comp.res.sms.contents2':
      '메시지 본문중에 {hpName}, {hpTel}, {patName}, {createDate} 란 문구를 써 주시면 병원정보와 각 환자의 이름과 최종 검사일로 자동으로 바뀌어서 전송됩니다.',
    'comp.res.sms.contents3': '자정 ~ 오전4시까지는 시스템 점검으로 인해 메시지 전송이 되지 않습니다. 예약전송시 참고하세요.',
    'comp.top.unsendPic': '대기중인 사진',
    'comp.sms.title.searchcondition': 'SMS내역 조회',
    'comp.sms.title.writeMsg': '문자메시지 작성',
    'comp.sms.title.storedMsg': '내용 불러오기',
    'comp.sms.title.receiverList': '수신자 목록',
    'comp.sms.vali.emptyMessageList': '문자메시지를 선택해 주세요.',
    'comp.confirmModal.title': '확인',
    'comp.smsResult.vali.selectMore1': '1개 이상의 문자메시지를 선택해주세요.',
    'comp.report.title': '텔레써비코 및 문자 통계',
    'comp.settlement.title': '거래내역서 목록',
    'comp.support.title.remoteAssistance': '원격지원',
    'comp.support.content1': '원격지원 상담원이 인터넷을 통해 고객님의 PC화면을 함께 보면서 원격으로 고객지원하는 서비스 입니다.',
    'comp.support.content2': '상담원이 불러주는 번호를 클릭한 후, 다운로드 한 프로그램을 실행해 주세요.',
    'comp.setting.cam.title': '카메라 설정',
    'comp.setting.cache.title': '캐시 삭제',
    'comp.setting.version.title': '버전 정보',
    'comp.cervibox.title': 'Cervibox',
    'comp.cervibox.title.searchcondition': 'CerviBox 검색',
    'comp.cervibox.title.new': 'CerviBox저장',
    'comp.cervibox.title.selectedImg': '선택사진',
    'comp.cervibox.title.regDate': '사진등록일',
    'comp.cervibox.nobox': 'CerviBox를 선택해주세요.',
    'comp.cervibox.vali.selBox': 'Box를 선택해주세요.',
    'comp.cervibox.vali.sel1box': 'Box를 1개만 선택해주세요.',
    'comp.cervibox.vali.sel1pic': '사진을 선택해주세요.',
    'comp.cervibox.vali.equalpic': 'CerviBox에 동일한 사진이 있습니다.',
    'comp.cervibox.vali.equalpic2': '이미 저장된 사진 {cnt1}개를 제외한 {cnt2}개가 저장되었습니다.',
    'comp.cervibox.vali.noimg': '사진이 없습니다.',
    'comp.cervibox.vali.more1img': '1장 이상 이미지를 선택해주세요.',
    'comp.cerviai.title.searchcondition': 'CerviCARE® AI',
    'comp.cerviai.title.csguide': 'Confidence Score Guide',
    'comp.cerviai.title.remove': 'AI 결과 삭제',
    'comp.cerviai.vali': '이미 분석 완료된 사진이 포함되어 있습니다.',
    'comp.cerviai.title.request': '검사 의뢰하기',
    'comp.dialog.closeInfo': '사진에 오른쪽 클릭을 하면 창이 닫힙니다.',
    'comp.doctor.edit.window.valid': '안전한 비밀번호입니다.',
    // cervitem
    'comp.cervitem.title': 'CerviTem',
    'comp.cervitem.title.searchcondition': '주문내역 검색',

    'store.auth.fail': '아이디 혹은 비밀번호가 일치하지 않습니다.',
    'store.auth.logout': '로그아웃 완료',
    'store.doctorEditWindow.valid.not': '유효하지 않은 비밀번호입니다.',
    'store.doctorEditWindow.valid.notEqaul': '동일한 비밀번호가 아닙니다.',
    'store.doctorEditWindow.valid.pattern': '영문, 숫자, 특수문자를 반드시 포함하여 입력해주세요.',
    'store.doctorEditWindow.valid.includeId': '비밀번호에 아이디가 포함되면 안됩니다.',
    'store.doctorEditWindow.valid.length': '최소 8자리 이상, 20자리 이내로 입력해주세요.',
    'store.doctorEditWindow.edit.success': '변경되었습니다. 다시 로그인 해주세요.',
    'store.cervicoRequest.vali.patname': '환자 이름을 적어주세요.',
    'store.cervicoRequest.vali.chartnum': '차트번호를 적어주세요.',
    'store.cervicoRequest.vali.birthdate': '생년월일을 적어주세요.',
    'store.cervicoRequest.vali.birthdate2': '생년월일이 유효하지 않습니다.',
    'store.cervicoRequest.vali.more1': '이름, 차트번호, 생년월일 중 하나를 입력해주세요.',
    'store.cervicoRequest.vali.picture': '사진을 선택해 주세요. (1~4장)',
    'store.cervicoRequest.vali.deleteCause': '취소 사유를 적어주세요.',
    'store.cervicoRequest.confirm.title': '환자정보 확인',
    'store.cervicoRequest.confirm.save': '보류건은 30일 이후 케이스가 자동 삭제됩니다.',
    'store.cervicoRequest.confirm.refReq': '이전 검사와 비교하여 검사 의뢰하시겠습니까?',
    'store.cervicoRequest.confirm.otherPat': '다른 환자의 사진이 포함되어 있는지 확인해주세요.',
    'store.cervicoRequest.success': '검사의뢰 완료',
    'store.cervicoRequest.aisuccess': 'AI 분석 완료',
    'store.cervicoRequest.delete': '의뢰취소 완료',
    'store.cervicoRequest.fail.aleadyRequested': '이미 의뢰된 사진입니다.',
    'store.cervicoRequest.fail.print': '판독 완료된 검사만 인쇄할 수 있습니다.',
    'store.pictureList.pre.remove': '{cnt} 건을 삭제 하시겠습니까?',
    'store.res.confirm.remove': '정말 삭제 하시겠습니까?',
    'store.res.modify.PatInfo': '환자 정보가 수정되었습니다.',
    'store.smsSend.vali.delReceiver.selectMore1': '발송목록에서 삭제할 수신자를 선택해 주세요.',
    'store.smsSend.vali.emptyMsg': '메시지를 작성해 주세요.',
    'store.smsSend.vali.wrongCallingNumber': '발신번호를 올바르게 작성해 주세요.',
    'store.smsSend.vali.emptyReceiverList': '수신자를 추가해 주세요',
    'store.smsSend.vali.emptyReceiverNumber': '전화번호를 모두 작성해 주세요.',
    'store.smsSend.vali.emptySchedule': '예약시간을 작성해 주세요.',
    'store.smsSend.vali.scheduleAtLeastTomorrow': '예약전송은 다음날 부터 가능합니다.',
    'store.smsSend.sendSuccess': '문자메시지 발송요청 완료',
    'store.smsTemplate.saveSuccess': '저장 완료',
    'store.smsTemplate.confirm.delete': '삭제 하시겠습니까?',
    'store.smsTemplate.deleteSuccess': '삭제 완료',
    'store.smsTemplate.editSuccess': '수정 완료',
    'store.smsTemplate.msgEmpty': '메시지 내용이 없습니다.',
    'store.smsResult.confirm.delete': '개의 메시지를 삭제할까요?',
    'store.smsResult.deleteSuccess': '삭제 완료',
    'store.pictureList.vali.cantSendOver10': '한번에 10개 까지만 전송 가능합니다.',
    'store.pictureList.vali.onlyJpg': 'jpg 만 전송 가능합니다.',
    'store.pictureList.vali.wrongFile': '잘못된 파일입니다.',
    'store.pictureList.msg.transmiting': '사진 전송중...',
    'store.pictureList.msg.transmitingFail': '사진 전송 실패',
    'store.pictureList.msg.deleteSuccess': '사진 삭제 완료',
    'store.pictureList.msg.deleteFail': '사진 삭제 실패',
    'store.pictureList.vali.selectMore1': '1개 이상 선택해 주세요.',
    'store.pictureList.vali.downloadLess20': '한번에 20장 까지 다운로드 가능합니다.',
    'store.setting.vali.portEmpty': '포트번호를 적어주세요.',
    'store.setting.vali.portNum': '포트번호는 1025 ~ 10000 까지 가능합니다.',
    'store.setting.vali.folderEmpty': '유선카메라 폴더명을 적어주세요.',
    'store.setting.vali.cameraIp': '무선카메라 IP를 적어주세요',
    'store.setting.saveSuccess': '저장 완료',
    'store.setting.cacheDelSuccess': '캐시 삭제 완료',
    'store.setting.cacheDelFailure': '캐시 삭제 실패',
    'stroe.cervibox.nosel': '선택된 이미지가 없습니다.',
    'store.cervibox.confirm.del': '정말 삭제하시겠습니까?',
    'store.cervibox.other.doctor.img': '다른 의사의 사진이 포함되어 있습니다.',
    'store.cervibox.other.doctor.box': '다른 의사의 CerviBox가 포함되어 있습니다.',
    'store.cervibox.success.del': '삭제되었습니다.',
    'store.cervibox.save': '저장되었습니다.',
    'store.cervibox.worklist.disable': '이미 저장한 {cnt} 건이 제외됩니다.',
    'store.cervibox.worklist.otherpic': '다른 사용자의 사진은 CerviBox에 저장할 수 없습니다.',
    'store.cervibox.copy': '복사한 이미지 삭제',
    'store.screen.quit': '프로그램을 종료하시면, 카메라로부터 사진을 받을 수 없습니다.\n종료 하시겠습니까?',
    'store.cerviai.success': '분석을 요청하였습니다.',
    'store.cerviai.preprocessing.success': '검증 요청을 하였습니다.',
    'store.cerviai.nosel': '선택된 사진이 없습니다.',
    'store.cerviai.requestPic': '판독 의뢰된 사진은 삭제할 수 없습니다.',
    'store.cerviai.noMore20': '20개까지 가능합니다.',
    'store.cerviai.noMore5': '5개까지 가능합니다.',
    'store.cerviai.noMore1000': '1000개까지 가능합니다.',
    'store.cerviai.noReSel': '선택된 사진 중 분석중인 것이 없습니다.',
    'store.cerviai.least1': '최소 한 장의 사진은 보존되어야 합니다.',
    'store.cerviai.del': '삭제되었습니다.',
    'store.otherTest.3month': '3달 이내의 기간으로 검색하실 수 있습니다.',
    'store.cervitem.selectOption': '옵션을 선택해주세요.',
    'store.cervitem.checkNum': '숫자만 입력이 가능합니다.',
    'store.cervitem.cart.success': '상품이 장바구니에 담겼습니다.',
    'store.cervitem.cart.limitquantity': '수량 선택은 1~999까지 가능합니다. 대량 구매는 02-401-7799로 연락주세요.',
    'store.cervitem.cart.del': '장바구니에서 삭제되었습니다.',
    'store.cervitem.order.success': '주문이 완료되었습니다.',
    'store.preprocess.noReSel': '이미 검증된 사진입니다.',

    'button.reqConfirmYN': '환자정보확인',
    'button.login': '로그인',
    'button.logout': '로그아웃',
    'button.editDoctor': '비밀번호 변경',
    'button.medicalDevice': '의료기기 표시 기재 사항',
    'button.ok': '확인',
    'button.cancel': '취소',
    'button.changeLater': '다음에 변경하기',
    'button.changeDeferredDate': '한 달 후 변경하기',
    'button.search': '검색',
    'button.reset': '초기화',
    'button.add': '추가',
    'button.save': '저장',
    'button.modify': '수정',
    'button.report': '결과지',
    'button.remove': '삭제',
    'button.copy': '복사',
    'button.test': '테스트',
    'button.removeSelected': '선택삭제',
    'button.quickAi': 'Quick AI',
    'button.select': '전체선택',
    'button.reload': '새로고침',
    'button.cervibox': 'CerviBox저장',
    'button.sortDate': '전송일시',
    'button.download': '다운로드',
    'button.uploadPicture': '사진업로드',
    'button.clientDownload': 'CerviCARE® 다운로드',
    'button.search.testhistory': '검사이력조회',
    'button.request': '검사 의뢰하기',
    'button.request.short': '의뢰하기',
    'button.deferRequest': '검사보류',
    'button.detailRequest': '정밀분석',
    'button.nosms': '미발송',
    'button.today': '오늘',
    'button.last3days': '3일',
    'button.last1weeks': '1주',
    'button.last1months': '1달',
    'button.last3months': '3달',
    'button.last1years': '1년',
    'button.last3years': '3년',
    'button.reTest': '재검대상',
    'button.res.show': '결과보기',
    'button.res.compare': '결과비교',
    'button.res.print': '결과지',
    'button.res.sms': '메시지전송',
    'button.res.req': '보류건의뢰',
    'button.res.del': '의뢰취소',
    'button.res.dup': '중복건검색',
    'button.res.downloadRptImg': '결과지 이미지 다운로드',
    'button.res.AI.print': 'AI 결과지',
    'button.res.AI.downloadRptImg': 'AI 결과지 이미지 다운로드',
    'button.sms.saveMsg': '내용 저장하기',
    'button.sms.delReceiver': '수신자 삭제',
    'button.sms.send': '문자메시지 전송',
    'button.sms.deleteMsg': '문자메시지 삭제',
    'button.sms.unsend': '미발송',
    'button.support.romoteAssistanceSite': '원격지원사이트',
    'button.setting.cache': '캐시 삭제 후 다시시작',
    'button.ai.analy': 'AI 분석',
    'button.video': '영상시청',
    'button.box.selectAll': '모두선택',
    'button.box.deselect': '선택해제',
    'button.box.new': '새 Box 생성',
    'button.save.memo': '메모저장',
    'button.more': '더보기',
    'button.cervitem.ordercancel': '주문 취소',
    'button.cervitem.addcart': '장바구니 담기',
    'button.cervitem.directorder': '바로 주문하기',
    'button.cervitem.order': '바로 주문하기',
    'button.rePreprocess': '이미지 검증',

    'grid.page': '페이지',
    'grid.of': '/',
    'grid.to': '~',
    'grid.next': '다음',
    'grid.last': '마지막',
    'grid.first': '처음',
    'grid.previous': '이전',
    'grid.loadingOoo': '로딩중...',
    'grid.noRowsToShow': '결과가 없습니다.',

    'popover.common.title': '설명',
    'popover.res.dup': '환자명과 차트번호 혹은 생년월일이 중복된 건수를 조회합니다.',
    'popover.reTest': '6~7개월 전 재검대상을 조회합니다.',

    'popover.preprocessing.title': '이미지 품질 결과',
    'popover.preprocessing.imageCrash': '이미지의 일부 손상이 감지되었습니다.',
    'popover.preprocessing.cervixClassification': '자궁 경부 이미지 여부',
    'popover.preprocessing.blurryClassification': '자궁 경부 포커싱 여부',
    'popover.preprocessing.brightnessCheck': '자궁 경부 밝기 이상 여부',
    'popover.preprocessing.true': '적격',
    'popover.preprocessing.false': '부적격',
    'popover.preprocessing.unknown': '판정불가',
    'popover.preprocessing.notCompleted': '현재 일시적인 문제로 작업이 중단되었습니다.\n잠시 후에 다시 시도해 주시기 바랍니다.',
    'popover.preprocessing.notice': '이미지 품질 결과 이상 시 검사결과에 영향을 미칠 수 있습니다. 보다 정확한 결과를 위해서 재촬영을 권장합니다.',

    temp: '__'
  }
};
