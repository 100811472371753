import React from 'react';
import { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody, Row, Col } from 'reactstrap';
import { inject } from 'mobx-react';
import cx from 'classnames';

import './CustomPopover.scss';
import { FormattedMessage } from 'react-intl';

const delayOpts = {
  hide: 0
};

export default class CustomPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    };
  }

  toggle = () => {
    if (!this.props.fromPreprocessing) {
      this.setState({
        tooltipOpen: !this.state.tooltipOpen
      });
    } else if (this.props.fromPreprocessing && !this.state.tooltipOpen) {
      // 전처리AI 팝업으로 동작할 때, 팝업이 열리지 않았을 때만 동작 (닫힐 때는 동작하지 않음)
      this.setState({
        tooltipOpen: !this.state.tooltipOpen
      });
    }
  };

  render() {
    const { placement, target, title, contents, boundariesElement, fromPreprocessing } = this.props;

    return (
      <Popover
        // default
        trigger={'hover'}
        isOpen={this.state.tooltipOpen}
        toggle={this.toggle}
        delay={delayOpts}
        // custom
        boundariesElement={boundariesElement}
        placement={placement}
        target={target}
        className={fromPreprocessing ? 'custom-popover' : ''}
      >
        <PopoverHeader className={fromPreprocessing ? 'text-center' : ''}>{title}</PopoverHeader>
        {fromPreprocessing ? <PreprocessingResultTest info={contents} /> : <PopoverBody>{contents}</PopoverBody>}
      </Popover>
    );
  }
}

@inject('locale')
class PreprocessingResultTest extends Component {
  render() {
    const { info, locale } = this.props;
    const { isCervixImage, isBlurryImage, isBrightnessOK, isCompleted, imageCrash, aiModelIdx } = info;

    const status = {
      true: 'true',
      false: 'false'
    };

    const checkCervixClassification = status[isCervixImage];
    const checkBlurryClassification = isCervixImage ? status[isBlurryImage] : 'unknown';
    const checkBrightnessCheck = isCervixImage && isBlurryImage ? status[isBrightnessOK] : 'unknown';

    const rowDict = {
      cervixClassificationRslt: {
        label: locale.fm('popover.preprocessing.cervixClassification'),
        value: aiModelIdx === 1 ? checkCervixClassification : isCervixImage ? 'true' : 'false'
      },
      blurryClassificationRslt: {
        label: locale.fm('popover.preprocessing.blurryClassification'),
        value: aiModelIdx === 1 ? checkBlurryClassification : isBlurryImage ? 'false' : 'true'
      },
      brightnessCheckRslt: {
        label: locale.fm('popover.preprocessing.brightnessCheck'),
        value: aiModelIdx === 1 ? checkBrightnessCheck : isBrightnessOK ? 'true' : 'false'
      }
    };

    return (
      <PopoverBody>
        {imageCrash && (
          <div>
            <FormattedMessage id="popover.preprocessing.imageCrash" />
          </div>
        )}
        {isCompleted
          ? Object.keys(rowDict).map((key, idx) => (
              <div key={idx}>
                {idx === 0 && <hr />}
                <Row key={key} className="mt-2 mb-2">
                  <Col
                    md="6"
                    className={cx({ 'text-green': rowDict[key].value === 'true', 'text-red': rowDict[key].value === 'false', 'text-unknown': rowDict[key].value === 'unknown' })}
                  >
                    {rowDict[key].label}
                  </Col>
                  {rowDict[key].value !== 'unknown' ? (
                    <Col>
                      <Row className="result-box">
                        <Col className={cx({ false: rowDict[key].value === 'false' })}>{locale.fm('popover.preprocessing.false')}</Col>
                        <Col className={cx({ true: rowDict[key].value === 'true' })}>{locale.fm('popover.preprocessing.true')}</Col>
                      </Row>
                    </Col>
                  ) : (
                    <Col className="result-box unknown">{locale.fm('popover.preprocessing.unknown')}</Col>
                  )}
                </Row>
              </div>
            ))
          : locale.fm('popover.preprocessing.notCompleted')}
        <hr />
        <FormattedMessage id="popover.preprocessing.notice" />
      </PopoverBody>
    );
  }
}
