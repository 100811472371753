const importPromises = [];

const config = {
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  appId: process.env.REACT_APP_FCM_APP_ID,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID
};

if (!navigator.userAgent.includes('CervicareAndroid')) {
  importPromises.push(import('@firebase/app'));
  importPromises.push(import('@firebase/messaging'));
  importPromises.push(import('@firebase/analytics'));
}

if ('serviceWorker' in navigator) {
  Promise.all(importPromises).then(([modules]) => {
    if (!modules) return;
    const firebase = modules.firebase;
    firebase.initializeApp(config);
    firebase.messaging().usePublicVapidKey('BFW13FAlvILE6v5WgTM9EtaHUz6YRmSDkf7YxFiqZo0Rx20flE65q1Bj9StCIKn6syllyQ0_YZQEgUBeByFlkP8');
  });
}

export function analytics() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    if (process.env.REACT_APP_SERVER_TYPE === 'normal') {
      console.log('firebase analytics start');
      Promise.all(importPromises).then(([modules]) => {
        if (!modules) return;
        const firebase = modules.firebase;
        firebase.analytics();
      });
    }
  }
}

let lastToken = null;

export async function getMessagingToken() {
  if (!window.rq && 'serviceWorker' in navigator) {
    //웹에서만 동작
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        Promise.all(importPromises).then(async ([modules]) => {
          if (!modules) return;
          const firebase = modules.firebase;
          lastToken = await firebase.messaging().getToken();
        });
        return lastToken;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
}

export async function deleteMessagingToken() {
  if (!window.rq && 'serviceWorker' in navigator) {
    Promise.all(importPromises).then(async ([modules]) => {
      if (!modules) return;
      const firebase = modules.firebase;
      await firebase.messaging().deleteToken(lastToken);
    });
  }
}

export function setMessageListener(root) {
  if (!window.rq && 'serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (event) => {
      root.screen.href(event.data.url);
    });
  }
}
