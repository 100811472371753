import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { openLink } from 'libs/Utils';

import './PopupModal.scss';

class PopupModal extends Component {
  img = React.createRef();
  popup = React.createRef();

  state = {
    modalOpen: true
  };

  handleClick = (e) => {
    e.preventDefault();
    openLink(e.currentTarget.href);
  };

  handleClose = () => {
    this.setState({
      modalOpen: false
    });
  };

  componentDidMount = () => {
    //const p = this.popup.current;
    const i = this.img.current;
    i.onload = () => {
      const el = document.getElementById(`popup-${this.props.popup.idx}`);
      if (el === null) return;

      el.style.maxWidth = i.width + 2 + 'px';
      el.classList.add('loaded');
    };
  };

  render() {
    const popup = this.props.popup;
    return (
      <Modal isOpen={this.state.modalOpen} toggle={this.handleClose} className="PopupModal" id={`popup-${popup.idx}`} zIndex="940">
        <ModalHeader toggle={this.handleClose}>{popup.title}</ModalHeader>
        <ModalBody>
          {popup.link === '' ? (
            <img src={popup.image} alt={popup.title} ref={this.img} />
          ) : (
            <a href={popup.link} onClick={this.handleClick}>
              <img src={popup.image} alt={popup.title} ref={this.img} />
            </a>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default PopupModal;
