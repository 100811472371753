import { observable, action } from 'mobx';

const PC_MIN_WIDTH = 1080;

export default class CheckWindowWidth {
  @observable minWindowWidth = 0;
  @observable windowWidth = 0;
  @observable isNarrow = true;
  @observable isPC = true;

  @action
  init = (minWidth, width) => {
    this.minWindowWidth = minWidth;
    this.setWindowWidth(width);
  };

  @action
  setWindowWidth = (width) => {
    this.windowWidth = width;

    if (width >= this.minWindowWidth) this.isNarrow = true;
    else this.isNarrow = false;

    if (width >= PC_MIN_WIDTH) this.isPC = true;
    else this.isPC = false;

    // console.log('windowWidth:', this.windowWidth, ', isNarrow:', this.isNarrow);
  };
}
