import '@babel/polyfill';
/* import 'react-app-polyfill/ie11';
import 'es6-string-polyfills';
import 'ie-array-find-polyfill'; */

import RootStore from './stores';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import App from './components/root/App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import qs from 'qs';

import { analytics } from 'libs/Fcm';

if (process.env.NODE_ENV === 'development') {
  console.log(process.env);
}

//request 기본 파라미터 직렬화
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { indices: false });
};

const root = new RootStore();

function refresh() {
  if (window.rq) {
    //cache 클리어 시도..
    window.rq.clearCache();
  } else {
    window.location.reload();
  }
}

//css check
if (window.getComputedStyle(document.getElementById('checker')).display !== 'none') {
  //script 가 성공적으로 로딩되지 않았다면. index.html 의 div#checker display 가 none 이 아니게 됨.
  ReactDOM.render(
    <div style={{ color: 'white', padding: '10px' }}>
      <p>프로그램이 업데이트 되었습니다. Update 버튼을 눌러주세요.</p>
      <button
        onClick={() => {
          refresh();
        }}
      >
        Update
      </button>
    </div>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <Provider {...root}>
      <App />
    </Provider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  if (window.rq) {
    serviceWorker.unregister();
  } else {
    //client 아닌 경우에만 등록
    serviceWorker.register();
  }

  analytics();
}
