import { observable, action, computed, runInAction } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';

export default class CervicoResultWindowStore {
  //resultList 는 {idx, statusCode} 의 배열임!!
  @observable resultList = [];
  @observable currentIndex = 0;
  @observable result = null;

  @observable pictureIndex = 0;

  constructor(root) {
    this.root = root;
    this.init();
  }

  //TODO init 로 변경
  @action
  init = () => {
    this.resultList.replace([]);
    this.currentIndex = 0;
    this.result = null;
    this.pictureIndex = 0;
  };

  @action
  setList = (resultList) => {
    this.resultList.replace(resultList);
    this.setCurrentIndex = 0;

    this.setResult();
  };

  @action
  setResult = async () => {
    this.root.screen.setProcessLayer(true);

    try {
      const res = await axios.get(`/api/rq/cervico/worklist/${this.resultList[this.currentIndex].idx}`);
      runInAction('CervicoResultWindowStore : async setResult -> success', () => {
        this.result = res.data;
        this.pictureIndex = this.result.selectedImg - 1;

        //grid statusCode 업데이트
        this.root.cervicoResult.changeStatusCodeByIdx(res.data.idx, res.data.statusCode);
      });
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };

  @action
  nextResult = () => {
    this.currentIndex++;
    this.setResult();
  };

  @action
  prevResult = () => {
    this.currentIndex--;
    this.setResult();
  };

  @action
  setPictureIndex = (pictureIndex) => {
    this.pictureIndex = pictureIndex;
  };

  //zoom 화면
  @action
  zoom = () => {
    const r = this.result;
    if (r !== null) {
      const pictureList = [...Array(parseInt(r.imgCount))].map((_, i) => {
        return {
          url: `/api/rq/cervico/worklist/${r.idx}/picture/${i + 1}`,
          title: `환자명 : ${r.patName}`
        };
      });
      this.root.zoomWindow.setPictureList(pictureList, this.pictureIndex);
    }
  };

  @computed
  get show() {
    return this.result !== null;
  }

  @computed
  get isLastResult() {
    return this.resultList.length === this.currentIndex + 1;
  }

  @computed
  get isFirstResult() {
    return this.currentIndex === 0;
  }

  @computed
  get position() {
    return `${this.currentIndex + 1} / ${this.resultList.length}`;
  }

  @computed
  get completeCount() {
    return this.resultList.filter((r) => r.statusCode > 6).length;
  }

  download = (type) => {
    /* const link = document.createElement('a');
    link.href = `/api/rq/cervico/report/img?type=${type}&idx=${idx}`;
    link.download = 'report.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); */
    const url = `/api/rq/cervico/report/img?type=${type}&idx=${this.result.idx}`;
    // console.log(url);
    window.location.href = url;
  };
}
