import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, InputGroup, InputGroupAddon, Button, Container } from 'reactstrap';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { IoIosCart, IoIosListBox, IoMdMenu } from 'react-icons/io';
import CheckWindowWidth from './CheckWindowWidth';
import { CustomPopover } from 'components/common';
import cx from 'classnames';
import './CervitemNavigation.scss';

const MIN_WIDTH = 620;

@inject('checkWindowWidth')
@inject('productWindow')
@inject('cervitemCart')
@inject('cervitem')
@inject('locale')
@withRouter
@observer
class CervitemNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      prevQuery: ''
    };
  }

  setSelectCategory = (idx) => {
    this.props.setCategoryIndex(idx);
    this.props.cervitem.setSelectCategory(idx);
    this.setState({
      prevQuery: ''
    });
  };

  handleInputQuery = (e) => {
    this.setState({
      query: e.target.value
    });
  };

  handleSearch = () => {
    if (this.state.query !== this.state.prevQuery) {
      this.props.cervitem.loadData(this.props.cervitem.page, null, this.state.query);
      this.setState({
        prevQuery: this.state.query
      });
      this.props.setCategoryIndex(0);
    }
  };

  handleKeyPressEnter = (e) => {
    if (e.key === 'Enter') this.handleSearch();
  };

  handleBackToMainBtn = () => {
    if ('fromCartOrder' in this.props) {
      this.props.history.push({
        pathname: '/cervitem',
        state: { categoryIndex: 0 }
      });
    } else {
      if (this.props.categoryIndex === null) {
        this.props.productWindow.setOpen(false);
      } else {
        this.props.history.push('/cervitem-main');
      }
    }
  };

  handleMoveCart = () => {
    this.props.history.push('/cart');
  };
  handleMoveOrder = () => {
    this.props.history.push('/order');
  };

  handleMenuComponent = (categoryIndex, isNarrow, type) => {
    if (categoryIndex !== null) {
      if (isNarrow) {
        if (type === 'PC') return <PC categoryIndex={this.props.categoryIndex} setSelectCategory={this.setSelectCategory} />;
      } else {
        if (type === 'Mobile') return <Mobile categoryIndex={this.props.categoryIndex} setSelectCategory={this.setSelectCategory} />;
      }
    }
  };

  handleResize = () => {
    this.props.checkWindowWidth.setWindowWidth(window.innerWidth);
  };
  componentDidMount() {
    this.props.checkWindowWidth.init(MIN_WIDTH, window.innerWidth);
    this.props.cervitemCart.loadData();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    this.props.cervitemCart.loadData();
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { isNarrow, isPC } = this.props.checkWindowWidth;
    const { cartList } = this.props.cervitemCart;
    return (
      <Container fluid>
        <CheckWindowWidth isNarrow={isNarrow} />
        <CustomPopover key="cart" placement="top" target="cartPopover" contents={'장바구니'} />
        <CustomPopover key="order" placement="top" target="orderPopover" contents={'주문내역'} />
        <div className="Nav-Container">
          <ul className={`category-container ${this.props.categoryIndex === null && 'category-container-without-menu'}`}>
            <div className="w-25 d-flex">
              <div className="back-btn" onClick={this.handleBackToMainBtn}>
                <FaArrowLeft />
              </div>
              {this.handleMenuComponent(this.props.categoryIndex, isPC, 'Mobile')}
            </div>
            {this.handleMenuComponent(this.props.categoryIndex, isPC, 'PC')}
            <div className="w-25 d-flex justify-content-end">
              {this.props.categoryIndex !== null && (
                <div className="search-container pr-5">
                  {/* <DropdownList defaultToggleText={defaultCategoryName} items={categoryList} setSelect={this.setSelectCategory} /> */}
                  <InputGroup>
                    <Input
                      style={{ border: 0 }}
                      placeholder="상품을 입력하세요"
                      value={this.state.query}
                      onChange={(e) => this.handleInputQuery(e)}
                      onKeyPress={this.handleKeyPressEnter}
                    />
                    <InputGroupAddon addonType="append">
                      <Button className="search-icon" onClick={this.handleSearch}>
                        <FaSearch />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              )}
              <div className="d-flex">
                <div id="cartPopover" className={cx('icon-with-text', cartList.length > 0 ? 'mr-4' : 'mr-5')} onClick={this.handleMoveCart}>
                  <IoIosCart />
                  {cartList.length > 0 && (
                    <h5>
                      <span className="badge badge-danger badge-pill">{cartList.length}</span>
                    </h5>
                  )}
                </div>
                <div id="orderPopover" className="icon-with-text" onClick={this.handleMoveOrder}>
                  <IoIosListBox />
                </div>
              </div>
            </div>
          </ul>
        </div>
      </Container>
    );
  }
}

class PC extends Component {
  render() {
    const { categoryIndex, setSelectCategory } = this.props;

    return (
      <ul className="w-50 d-flex justify-content-around">
        <li className={`${categoryIndex === 0 ? 'checked' : ''}`} onClick={() => setSelectCategory(0)}>
          ALL
        </li>
        <li className={`${categoryIndex === 1 ? 'checked' : ''}`} onClick={() => setSelectCategory(1)}>
          치료재료
        </li>
        <li className={`${categoryIndex === 2 ? 'checked' : ''}`} onClick={() => setSelectCategory(2)}>
          의료기기
        </li>
        <li className={`${categoryIndex === 3 ? 'checked' : ''}`} onClick={() => setSelectCategory(3)}>
          화장품
        </li>
      </ul>
    );
  }
}

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.dropdownRef = React.createRef();
  }

  toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleClickOutside(event) {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target) && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  render() {
    const { categoryIndex, setSelectCategory } = this.props;
    const menuClass = `dropdown-menu${this.state.isOpen ? ' show' : ''}`;

    return (
      <div
        className="dropdown pl-3 d-flex align-items-center"
        ref={(ref) => {
          if (ref !== null) {
            this.dropdownRef = ref;
          }
        }}
      >
        <div className="icon-btn" onClick={this.toggleMenu}>
          <IoMdMenu />
        </div>
        <ul className={menuClass}>
          <li className={`${categoryIndex === 0 ? 'checked' : ''}`} onClick={() => setSelectCategory(0)}>
            ALL
          </li>
          <li className={`${categoryIndex === 1 ? 'checked' : ''}`} onClick={() => setSelectCategory(1)}>
            치료재료
          </li>
          <li className={`${categoryIndex === 2 ? 'checked' : ''}`} onClick={() => setSelectCategory(2)}>
            의료기기
          </li>
          <li className={`${categoryIndex === 3 ? 'checked' : ''}`} onClick={() => setSelectCategory(3)}>
            화장품
          </li>
        </ul>
      </div>
    );
  }
}

export default CervitemNavigation;
