import { observable, action, runInAction } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import { toast } from 'react-toastify';

const MAX_QUANTITY = 999;
const MIN_QUANTITY = 1;
class CervitemCartStore {
  @observable mask = false;
  @observable cartList = [];
  @observable selectAll = false;
  @observable selectList = [];

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.cervitemOrder = root.cervitemOrder;
  }

  @action
  init = () => {
    this.cartList = [];
    this.selectAll = false;
    this.selectList = [];
    this.setMask(false);
  };

  @action
  setMask = (bool) => {
    this.mask = bool;
  };

  @action
  setCartList = (data) => {
    this.cartList = data;
  };

  @action
  triggerRefresh = async () => {
    await this.loadData();
  };

  @action
  quantityIncrement = (idx) => {
    this.cartList.forEach((item) => {
      if (item.idx === idx) {
        if (item.quantity > MAX_QUANTITY) item.quantity = MAX_QUANTITY;
        else if (item.quantity < MIN_QUANTITY) item.quantity = MIN_QUANTITY;
        else item.quantity++;
      }
    });
  };

  @action
  quantityDecrement = (idx) => {
    this.cartList.forEach((item) => {
      if (item.idx === idx && item.quantity > 1) {
        if (item.quantity > MAX_QUANTITY) item.quantity = MAX_QUANTITY;
        else if (item.quantity < MIN_QUANTITY) item.quantity = MIN_QUANTITY;
        else item.quantity--;
      }
    });
  };

  @action
  setQuantity = async (idx, quantity) => {
    if (quantity > MAX_QUANTITY) quantity = MAX_QUANTITY;
    else if (quantity < MIN_QUANTITY) quantity = MIN_QUANTITY;

    const item = {
      cartIdx: idx,
      quantity
    };
    this.setMask(true);
    try {
      const response = await axios.post(`/api/rq/cervitem/cart/${idx}/quantity`, item);
      if (response.data.success) {
        runInAction('CervitemCartStore : async setQuantity -> success', () => {
          this.cartList.forEach((item) => {
            if (item.idx === idx) item.quantity = quantity;
          });
          this.setMask(false);
        });
      }
    } catch (error) {
      runInAction('CervitemCartStore : async setQuantity -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  setSelectList = () => {
    this.selectList = this.cartList.filter((item) => item.selectYn === true);
  };

  @action
  setSelectAll = () => {
    const selectedCnt = this.cartList.filter((el) => el.selectYn === true).length;
    const allChecked = this.cartList.length === selectedCnt;
    this.selectAll = allChecked;
  };

  @action
  selectedItem = async (idx, checked) => {
    this.setMask(true);
    try {
      await axios.post(`/api/rq/cervitem/cart/${idx}/check`);
      runInAction('CervitemCartStore : async selectedItem -> success', () => {
        this.setMask(false);
        this.cartList.forEach((item) => {
          if (item.idx === idx) item.selectYn = checked;
        });
        this.selectList = this.cartList.filter((item) => item.selectYn === true);
        this.setSelectAll();
        this.setSelectList();
      });
    } catch (error) {
      runInAction('CervitemCartStore : async selectedItem -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  selectedAllItems = async (checked) => {
    this.setMask(true);
    try {
      await axios.post('/api/rq/cervitem/cart/allcheck');
      runInAction('CervitemCartStore : async setSelectAll -> success', () => {
        this.setMask(false);

        this.selectAll = checked;
        this.cartList.forEach((item) => {
          item.selectYn = checked;
        });
        this.setSelectList();
      });
    } catch (error) {
      runInAction('CervitemCartStore : async setSelectAll -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  deleteCartItem = async (idx) => {
    this.setMask(true);
    try {
      await axios.delete(`/api/rq/cervitem/cart/${idx}/remove`);
      runInAction('CervitemCartStore : async deleteCartItem -> success', () => {
        this.setMask(false);
        toast.success(this.locale.fm('store.cervitem.cart.del'));
        this.triggerRefresh();
      });
    } catch (error) {
      runInAction('CervitemCartStore : async deleteCartItem -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };

  @action
  loadData = async () => {
    this.setMask(true);
    try {
      const response = await axios.get(`/api/rq/cervitem/cart`);
      runInAction('CervitemCartStore : async loadData -> success', () => {
        this.setCartList(response.data.data);
        this.selectList = this.cartList.filter((item) => item.selectYn === true);
        this.setSelectAll();
        this.setMask(false);
      });
    } catch (error) {
      runInAction('CervitemCartStore : async loadData -> catch', () => {
        ajaxErrorHandler(error);
        this.setMask(false);
      });
    }
  };
}

export default CervitemCartStore;
