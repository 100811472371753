import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { FaTimes, FaFileUpload } from 'react-icons/fa';
import { PatForm } from 'components/cervico';

import './CerviAiRequestWindow.scss';

@inject('auth')
@inject('locale')
@inject('cervicoRequest')
@observer
class CerviAiRequestWindow extends Component {
  handleClose = () => {
    this.props.store.close();
  };

  handleRequestClick = (e) => {
    e.preventDefault();

    const reqConfirmYN = this.props.auth.doctor.reqConfirmYN;
    if (reqConfirmYN) {
      this.props.cervicoRequest.request(true, false, true, false, true);
    } else {
      this.props.cervicoRequest.request(true, false, true);
    }
  };

  render() {
    const { locale, store, auth } = this.props;

    return (
      <Modal className="cerviAiRequestWindow" isOpen={store.form.open} toggle={this.handleClose} size="lg" zIndex="940">
        <ModalHeader toggle={this.handleClose}>
          <FaFileUpload /> {locale.fm('comp.cerviai.title.request')}
        </ModalHeader>
        <ModalBody>
          <PatForm useDetailHistoryYn useAiRequestYn />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleRequestClick} size="sm" style={{ width: '140px' }}>
            <FaFileUpload /> {locale.fm('button.request')}
          </Button>
          <Button color="secondary" onClick={this.handleClose} size="sm" style={{ width: '100px' }}>
            <FaTimes /> {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CerviAiRequestWindow;
