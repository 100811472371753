import React from 'react';

export default ({ show }) => {
  return show ? (
    <div className="card-mask">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  ) : (
    <></>
  );
};
