import React, { Component } from 'react';

import DateTimePickerWidget from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import './DateTimePicker.scss';

const dateFormat = 'YYYY-MM-DD';
const parseDateFormat = ['YYYY-MM-DD', 'YYYYMMDD'];
const dateTimeFormat = 'YYYY-MM-DD HH:mm';
const parseDateTimeFormat = ['YYYY-MM-DD HH:mm', 'YYYYMMDDHHmm'];

class DateTimePicker extends Component {
  state = {
    value: this.props.value
  };

  handleChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: value
        }
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return {
        value: nextProps.value
      };
    } else {
      return null;
    }
  }
  /*
  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevState.value) {
      this.setState({value: this.props.value});
    }
  } */

  render() {
    //console.log(this.props.showTime);
    return !this.props.showTime ? (
      <DateTimePickerWidget
        className="DateTimePicker"
        name={this.props.name}
        parse={parseDateFormat}
        format={dateFormat}
        value={this.state.value}
        dropUp={this.props.dropUp}
        time={false}
        onChange={this.handleChange}
      />
    ) : (
      <DateTimePickerWidget
        className="DateTimePicker field-datetime"
        name={this.props.name}
        parse={parseDateTimeFormat}
        format={dateTimeFormat}
        //timeFormat="HH:mm"
        value={this.state.value}
        dropUp={this.props.dropUp}
        time={true}
        onChange={this.handleChange}
      />
    );
  }
}

export default DateTimePicker;
