import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { FaTimes, FaFileUpload } from 'react-icons/fa';
import { MdFolderSpecial } from 'react-icons/md';
import { PatForm } from 'components/cervico';

import './CerviBoxRequestWindow.scss';

@inject('locale')
@inject('auth')
@inject('cervicoRequest')
@observer
class CerviBoxRequestWindow extends Component {
  handleClose = () => {
    this.props.cervicoRequest.init();
    this.props.store.setOpen(false);
  };

  handleRequestClick = () => {
    this.props.cervicoRequest.request(true, true, false);
  };

  render() {
    const { locale, store, auth } = this.props;

    return (
      <Modal className="cerviBoxRequestWindow" isOpen={store.open} toggle={this.handleClose} size="lg" zIndex="940">
        <ModalHeader toggle={this.handleClose}>
          <MdFolderSpecial /> {locale.fm('comp.cervibox.title')}
        </ModalHeader>
        <ModalBody>
          <PatForm useDetailHistoryYn />
        </ModalBody>
        <ModalFooter>
          {auth.doctor.useRequest ? (
            <Button color="primary" onClick={this.handleRequestClick} size="sm" style={{ width: '140px' }}>
              <FaFileUpload /> {locale.fm('button.request')}
            </Button>
          ) : null}
          <Button color="secondary" onClick={this.handleClose} size="sm" style={{ width: '100px' }}>
            <FaTimes /> {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CerviBoxRequestWindow;
