import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Button, Badge } from 'reactstrap';
import { MdFiberNew, MdMarkunreadMailbox } from 'react-icons/md';
import { CardMask } from 'components/common';
import axios from 'axios';
import moment from 'moment';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { inject } from 'mobx-react';

import './Notice.scss';

const PAGE_PER_ARTICLE = 10;

@inject('locale')
class Notice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notice: {
        1: { list: [], more: false, page: 1 },
        2: { list: [], more: false, page: 1 }
      },
      mask: false,
      load: false,
      open: null,
      category: parseInt(this.props.match.params.category || 1)
    };
  }
  async componentDidMount() {
    await this.loadNotice(true);
  }

  async loadNotice(init = false) {
    this.setState({ mask: true });
    const category = this.state.category;
    const page = init ? 1 : this.state.notice[category].page;
    const res = await axios.get(`/api/rq/notice`, { params: { category, page } });
    const list = res.data;
    let more = false;
    if (list.length > PAGE_PER_ARTICLE) {
      more = true;
      list.splice(PAGE_PER_ARTICLE);
    }
    this.setState({
      mask: false,
      load: true,
      notice: {
        ...this.state.notice,
        [category]: {
          list: (init ? [] : this.state.notice[category].list).concat(list),
          more,
          page: page + 1
        }
      }
    });
  }

  componentWillUnmount() {}

  handleOpen = async (idx, category = 1) => {
    const notice = { ...this.state.notice };
    notice[category].list = this.state.notice[category].list.map((n) => (n.idx === idx ? { ...n, readDate: true } : n));
    this.setState({ mask: true });
    const res = await axios.get(`/api/rq/notice/${idx}`);
    this.setState({ mask: false, open: res.data, notice });
  };

  handleClose = () => {
    this.setState({ open: null });
  };

  toggle = (category) => {
    this.setState({ category }, () => {
      this.loadNotice(true);
    });
  };

  render() {
    const { locale } = this.props;
    const category = this.state.category;
    return (
      <>
        <Nav tabs style={{ cursor: 'pointer', margin: '0 5px 0 5px' }}>
          <NavItem>
            <NavLink className={cx({ active: category === 1 })} onClick={() => this.toggle(1)}>
              <FormattedMessage id={'field.tab.notice'} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={cx({ active: category === 2 })} onClick={() => this.toggle(2)}>
              <FormattedMessage id={'field.tab.update'} />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={String(category)}>
          <TabPane tabId="1">
            <div className="container-fluid h-flex">
              <Card className="Notice">
                <CardBody>
                  <ul>
                    {this.state.notice[1].list.map((n, i) => (
                      <Article n={n} key={i} handleOpen={this.handleOpen} />
                    ))}
                  </ul>
                  {this.state.load && this.state.notice[1].list.length === 0 ? (
                    <div className="none">
                      <FormattedMessage id={'field.notice.none'} />
                    </div>
                  ) : (
                    ''
                  )}
                </CardBody>
                <CardMask show={this.state.mask} />
              </Card>
              {this.state.notice[1].more && (
                <Button onClick={() => this.loadNotice()}>
                  {' '}
                  <FormattedMessage id={'button.more'} />
                </Button>
              )}
              <PopupArticle n={this.state.open} handleClose={this.handleClose} />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="container-fluid h-flex">
              <Card className="Notice">
                <CardBody>
                  <ul>
                    {this.state.notice[2].list.map((n, i) => (
                      <Article n={n} key={i} handleOpen={this.handleOpen} />
                    ))}
                  </ul>
                </CardBody>
              </Card>
              {this.state.notice[2].more && (
                <Button onClick={() => this.loadNotice()}>
                  {' '}
                  <FormattedMessage id={'button.more'} />
                </Button>
              )}
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}

const Article = ({ n, handleOpen }) => {
  const now = moment();
  const writeDate = moment(n.writeDate);
  return (
    <li>
      <dl onClick={() => handleOpen(n.idx, n.category)}>
        {n.thumbImg && (
          <dt className="photo">
            <img src={n.thumbImg} alt={n.title} />
          </dt>
        )}
        <dt className={cx('title', { read: n.readDate !== null })}>
          <MdMarkunreadMailbox className="text-primary unread" style={{ marginRight: '3px' }} />
          {writeDate.isAfter(now.add(-7, 'd')) ? <MdFiberNew className="text-danger" style={{ marginRight: '3px' }} /> : ''}
          {n.title}
        </dt>
        <dd>
          {n.summary.split('\n').map((line, key) => {
            return (
              <span className="summary" key={key}>
                {line}
              </span>
            );
          })}
          <span>{writeDate.format('YYYY년 M월 D일 HH:mm')}</span>
        </dd>
        <dd className="clr"></dd>
      </dl>
    </li>
  );
};

const PopupArticle = ({ n, handleClose }) => {
  return (
    <Modal isOpen={n !== null} toggle={handleClose} className="PopupModal notice" zIndex="940">
      {n !== null ? (
        <>
          <ModalHeader toggle={handleClose}>{n.title}</ModalHeader>
          <ModalBody>
            {n.fileName !== null && n.fileName !== '' && (
              <div className="file">
                File :{' '}
                <a href={n.file} download>
                  {n.fileName}
                </a>
              </div>
            )}
            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: n.content }} />
          </ModalBody>
        </>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default Notice;
