import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Alert, Button, CustomInput } from 'reactstrap';
import cx from 'classnames';
import CerviAiResultTable from './CerviAiResultTable';
import { FormattedMessage } from 'react-intl';
import { AI_Alert, aiAlertMessage2, resultGraphic } from 'libs/Utils';
import { toggleFullscreen } from 'libs/Screen';
import DrawRoiNaviBar from './DrawRoiNaviBar';
import DrawRoiPanel from './DrawRoiPanel';

import { FaTimesCircle, FaArrowLeft, FaQuestionCircle, FaPrint, FaCloudDownloadAlt } from 'react-icons/fa';

import './CerviAiResultWindow.scss';

@inject('auth')
@inject('locale')
@inject('screen')
@inject('cerviAi')
@inject('cerviAiResultWindow')
@withRouter
@observer
class CerviAiResultWindow extends Component {
  state = {
    value: false,
    expand: false,
    image: { url: '', width: '100%', height: '100%', ratio: '', fitRate: '' }
  };

  el = React.createRef();

  handleFullscreen = () => {
    this.setState({ expand: !this.state.expand });
    toggleFullscreen(this.el.current);
  };

  handlePictureContextMenu = (e) => {
    e.preventDefault();
    this.props.cerviAiResultWindow.init();
  };

  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.cerviAiResultWindow.init();
    }
  }

  handleCheckBoxClick = (idx) => {
    this.props.cerviAiResultWindow.toggleRoiCheck(idx);
  };

  handlePictureClick = (pIdx, e) => {
    e.stopPropagation();

    if (pIdx < 0 || pIdx >= this.props.cerviAiResultWindow.result.aiResultList.length) return;
    this.props.cerviAiResultWindow.setCurrentPic(pIdx);
  };

  handlePrintClick = (pIdx) => {
    const type = this.props.locale._locale === 'ko' ? 'reKo' : 'reEn';
    this.props.cerviAiResultWindow.print(pIdx, type);
  };

  handleDownloadClick = (pIdx) => {
    const type = this.props.locale._locale === 'ko' ? 'reKo' : 'reEn';
    this.props.cerviAiResultWindow.download(pIdx, type);
  };

  handleRoiNameSaveClick = (roiIdx) => {
    console.log('handleRoiNameSaveClick', roiIdx);
    // this.props.cerviAiResultWindow.saveRoiName(roiIdx);
  };

  handleRoiName = (e, idx) => {
    this.props.cerviAiResultWindow.changeRoiName(idx, e.target.value);
  };

  render() {
    const { locale, auth, cerviAiResultWindow } = this.props;
    const { show, result, currentPic, roiList } = cerviAiResultWindow;

    if (!show) return null;

    const { aiResultList, rqDspResult } = result;
    const currentAiResult = aiResultList[currentPic];

    const url = `/api/rq/cerviai/${currentAiResult.idx}/image`;

    return (
      <div className="CerviAiResultWindow layer-window" ref={this.el}>
        <div className="area-back-button">
          <Button size="sm" onClick={cerviAiResultWindow.init}>
            <FaArrowLeft />
          </Button>
        </div>
        <div className="picture-container">
          <div className="area-picture" onContextMenu={this.handlePictureContextMenu}>
            <DrawRoiPanel url={url} roiList={roiList} classResult={currentAiResult.classResult} />
          </div>
          <ul className="area-picture-list">
            {aiResultList.map(
              (g, j) => (
                g.roiList.map((roi) => (roi.checked = true)),
                (
                  <div className={cx('picture', { active: currentPic === j })} key={g.idx} onClick={(e) => this.handlePictureClick(j, e)}>
                    <DrawRoiPanel url={`/api/rq/cerviai/${g.idx}/image`} roiList={g.roiList} classResult={g.classResult} active={currentPic === j} thumb />
                  </div>
                )
              )
            )}
          </ul>
        </div>
        {auth.doctor.useDrawTool && (
          <DrawRoiNaviBar currentPic={currentPic} handlePictureClick={this.handlePictureClick} handleFullscreen={this.handleFullscreen} expand={this.state.expand} />
        )}
        <div className="area-info h-flex">
          <div className="button-box justify-content-end">
            <span className="d-none d-md-block">
              <FaQuestionCircle /> <FormattedMessage id="comp.dialog.closeInfo" />
            </span>
            <Button size="sm" onClick={cerviAiResultWindow.init}>
              <FaTimesCircle />
            </Button>
          </div>

          <CerviAiResultTable result={result} currentPic={currentPic} />

          <div className="descr-container">
            {Object.keys(AI_Alert).map((key) => {
              const alert = AI_Alert[key];
              const showAlert = currentAiResult.rqDspResult === key;

              return (
                showAlert && (
                  <Fragment key={key}>
                    <Alert color="secondary" className="mt-5 pt-4">
                      <div className="airesultBox">
                        <h3 className="rounded-lg">{locale.fm('field.AIResult')}</h3>
                      </div>
                      {alert.message1}
                    </Alert>

                    <Alert color="success">
                      <p>
                        <FormattedMessage id="field.ai.alert" />
                      </p>
                      <p>
                        <FormattedMessage id="field.ai.alert2" />
                      </p>
                    </Alert>

                    {currentAiResult.rqDspResult !== 'Inadequate' && (
                      <Alert color="secondary" className="mt-5 pt-4">
                        <h4 style={{ textDecorationLine: 'underline' }}>
                          <strong>{locale.fm('field.ref')}</strong>
                        </h4>
                        <hr />
                        {resultGraphic(currentAiResult.rqDspResult, currentAiResult.classResult)}
                        {aiAlertMessage2(currentAiResult.classResult, currentAiResult.probability)}
                        {alert.message2}
                        {locale.fm('field.ai.confidenceScore')}
                      </Alert>
                    )}
                  </Fragment>
                )
              );
            })}

            {auth.doctor.useDrawTool && (
              <Alert color="info">
                <h4 style={{ textDecorationLine: 'underline' }}>
                  <strong>{locale.fm('field.ai.roi')}</strong>
                </h4>
                <hr />
                {roiList.map((roi, i) => {
                  if (roi.coordinate === '0,0,0,0') {
                    return null;
                  }
                  return (
                    <div key={roi.idx} className="d-flex align-items-center">
                      <div>
                        <CustomInput type="checkbox" id={`checkbox-${roi.idx}`} checked={roi.checked} onChange={() => this.handleCheckBoxClick(roi.idx)} />
                      </div>
                      <div>
                        <CustomInput type="text" placeholder="Label Name" id={`roi-name-${roi.idx}`} value={roi.roiName} onChange={(e) => this.handleRoiName(e, roi.idx)} />{' '}
                      </div>
                      <Button size="sm" color="primary" className="roi-button ml-1" onClick={() => this.handleRoiNameSaveClick(roi.idx)}>
                        <FormattedMessage id="button.save" />
                      </Button>
                    </div>
                  );
                })}
              </Alert>
            )}
          </div>
          {auth.doctor.useAIReport ? (
            <div className="btn-container mt-auto">
              <Button
                size="lg"
                block
                color="primary"
                onClick={() => this.handlePrintClick(aiResultList[currentPic].idx, aiResultList[currentPic].idx)}
                disabled={rqDspResult === 'Inadequate'}
              >
                <FaPrint /> <FormattedMessage id="button.res.AI.print" />
              </Button>
              <Button
                block
                color="secondary"
                onClick={() => this.handleDownloadClick(aiResultList[currentPic].idx, aiResultList[currentPic].idx)}
                disabled={rqDspResult === 'Inadequate'}
              >
                <FaCloudDownloadAlt /> <FormattedMessage id="button.res.AI.downloadRptImg" />
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default CerviAiResultWindow;
