import { observable, action, computed } from 'mobx';

export default class PdfWindowStore {
  @observable pdf = '';

  constructor(root) {
    this.root = root;
    this.init();
  }

  @action
  init = () => {
    this.pdf = '';
  };

  @computed
  get show() {
    return this.pdf !== '';
  }

  @action
  print = (url) => {
    this.pdf = url;
    return;
  };
}
