import React, { Component } from 'react';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import { Input, PaginationItem, PaginationLink } from 'reactstrap';

import './CustomPagination.scss';

export default class CustomPagination extends Component {
  handleMovePage = (page) => {
    this.props.setPage(page);
  };
  render() {
    const { page, totalPage } = this.props;
    return (
      <div className="CustomPagination ml-auto">
        <PaginationLink type="button" onClick={() => this.handleMovePage(1)} disabled={page === 1}>
          <FaAngleDoubleLeft />
        </PaginationLink>
        <PaginationLink type="button" onClick={() => this.handleMovePage(page - 1)} disabled={page === 1}>
          <FaAngleLeft />
        </PaginationLink>
        {page - 2 >= 1 ? <PaginationLink onClick={() => this.handleMovePage(page - 2)}>{page - 2}</PaginationLink> : null}
        {page - 1 >= 1 ? <PaginationLink onClick={() => this.handleMovePage(page - 1)}>{page - 1}</PaginationLink> : null}
        <PaginationLink style={{ backgroundColor: '#C7C7C7', color: 'white' }}>{page}</PaginationLink>
        {totalPage >= page + 1 ? <PaginationLink onClick={() => this.handleMovePage(page + 1)}>{page + 1}</PaginationLink> : null}
        {totalPage >= page + 2 ? <PaginationLink onClick={() => this.handleMovePage(page + 2)}>{page + 2}</PaginationLink> : null}
        <PaginationLink type="button" onClick={() => this.handleMovePage(page + 1)} disabled={totalPage === page}>
          <FaAngleRight />
        </PaginationLink>
        <PaginationLink type="button" onClick={() => this.handleMovePage(totalPage)} disabled={totalPage === page}>
          <FaAngleDoubleRight />
        </PaginationLink>
      </div>
    );
  }
}
