import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';

import './CervicoResultDeleteWindow.scss';
import { observer, inject } from 'mobx-react';

import { FaCheck, FaTimes } from 'react-icons/fa';

@inject('locale')
@inject('cervicoResultDeleteWindow')
@observer
class CervicoResultDeleteWindow extends Component {
  handleDelete = () => {
    this.props.cervicoResultDeleteWindow.delete();
  };

  handleClose = () => {
    this.props.cervicoResultDeleteWindow.init();
  };

  handleChange = (e) => {
    const i = e.target;
    this.props.cervicoResultDeleteWindow.changeDeleteCauseInfo(i.name, i.value);
  };

  handleFocus = (e) => {
    e.target.select();
  };

  render() {
    const { locale, cervicoResultDeleteWindow } = this.props;
    const hc = this.handleChange;

    return (
      <Modal isOpen={this.props.cervicoResultDeleteWindow.open} toggle={this.handleClose} zIndex="940">
        <ModalHeader toggle={this.handleClose}>{locale.fm('comp.res.title.deleteCause')}</ModalHeader>
        <ModalBody>
          <Form className="deleteCause">
            <div className="form-row">
              <FormGroup className="w-100">
                <Label>{locale.fm('field.deleteCause')}</Label>
                <Input type="textarea" name="deleteCause" value={cervicoResultDeleteWindow.deleteCauseInfo.deleteCause} onChange={hc} />
              </FormGroup>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleDelete} size="sm">
            <FaCheck /> {locale.fm('button.save')}
          </Button>{' '}
          <Button color="secondary" onClick={this.handleClose} size="sm">
            <FaTimes /> {locale.fm('button.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CervicoResultDeleteWindow;
