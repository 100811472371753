import React, { Component } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import cx from 'classnames';
import './PictureBox.scss';
import LdsEllipsis from 'resources/lds-ellipsis.svg';
import LdsEllipsisGif from 'resources/lds-ellipsis.gif';

class PictureBox extends Component {
  render() {
    const cm = this.props.onContextMenu || function () {};
    const isIe = navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true;
    return (
      <div className="PictureBox" onContextMenu={cm}>
        <img src={isIe ? LdsEllipsisGif : LdsEllipsis} alt="hello" className={cx('inner-progress', { small: this.props.small })} />
        <div className={cx('inner-picture', { contain: this.props.contain })} style={{ backgroundImage: `url(${this.props.src})` }}></div>
      </div>
    );
  }
}

class LazyLoadPictureBox extends Component {
  render() {
    const cm = this.props.onContextMenu || function () {};
    return (
      <LazyLoadComponent>
        <PictureBox src={this.props.src} small={this.props.small} contain={this.props.contain} onContextMenu={cm} />
      </LazyLoadComponent>
    );
  }
}

export default LazyLoadPictureBox;
