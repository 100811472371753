import React, { Component } from 'react';
import { DataGrid, CardMask } from 'components/common';
import { Card, CardBody, CardFooter, Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { socialNumRenderer, dateCellRenderer } from 'libs/Utils';
import { FaRegCheckSquare, FaRegSquare, FaBox, FaTrash, FaEdit } from 'react-icons/fa';
import './CerviBox.scss';

@inject('locale')
@inject('cerviBox')
@inject('cerviBoxEdit')
@observer
class CerviBoxResultGridCard extends Component {
  gridApi = null;
  selected = [];

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
    this.props.cerviBox.loadDataGrid();
  };

  handleGridSelectionChanged = (l) => {
    this.selected = l;
  };

  handleMovePage = async (page) => {
    await this.props.cerviBox.loadDataGrid(page);
    this.props.onAfterMove();
  };

  handleCellClicked = ({ data }) => {
    this.props.cerviBox.setPreviewBox(data.idx);
  };

  handleSelectAllClicked = () => {
    this.gridApi.selectAll();
  };

  handleDeselectClicked = () => {
    this.gridApi.deselectAll();
  };

  handleEditClicked = () => {
    this.props.cerviBoxEdit.show(this.selected);
  };

  handleDeleteClicked = () => {
    this.props.cerviBox.remove(this.selected.map((s) => s.idx));
  };

  render() {
    const { locale } = this.props;

    return (
      <div className="ResultGridCard flex-grow-1 h-flex">
        <Card className="basis-300">
          <CardBody className="np h-flex">
            <DataGrid
              columnDefs={[
                { width: 30, checkboxSelection: true, headerCheckboxSelection: true },
                { headerName: locale.fm('field.regdate'), field: 'regDate', width: 130, cellRenderer: dateCellRenderer },
                { headerName: locale.fm('field.patname'), field: 'patName', width: 130 },
                { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 130 },
                { headerName: locale.fm('field.birthday'), field: 'socialNum', width: 80, cellRenderer: socialNumRenderer },
                { headerName: locale.fm('field.age'), field: 'age', width: 70 },
                { headerName: locale.fm('field.cnt'), field: 'cnt', width: 70 },
                {
                  headerName: locale.fm('field.docname'),
                  field: 'docName',
                  width: 70,
                  cellRenderer: (p) => {
                    return `${p.data.docName}(${p.data.docId})`;
                  }
                }
              ]}
              rowSelection={'multiple'}
              suppressRowClickSelection={false}
              onGridReady={this.handleGridReady}
              onRowDoubleClicked={this.handleGridRowClick}
              onSelectionChanged={this.handleGridSelectionChanged}
              onCellClicked={this.handleCellClicked}
              onMovePage={this.handleMovePage}
              dataGrid={this.props.cerviBox.dataGrid}
              redraw={this.props.cerviBox.t}
              context={{
                componentParent: this
              }}
            />
          </CardBody>
          <CardFooter className="text-center">
            <Button type="button" size="sm" color="warning" onClick={this.handleSelectAllClicked}>
              <FaRegCheckSquare /> <FormattedMessage id="button.box.selectAll" />
            </Button>
            <Button type="button" size="sm" color="warning" onClick={this.handleDeselectClicked}>
              <FaRegSquare /> <FormattedMessage id="button.box.deselect" />
            </Button>
            <Button type="button" size="sm" color="primary" onClick={this.handleEditClicked}>
              <FaEdit /> <FormattedMessage id="button.modify" />
            </Button>
            <Button type="button" size="sm" color="danger" onClick={this.handleDeleteClicked}>
              <FaTrash /> <FormattedMessage id="button.remove" />
            </Button>
          </CardFooter>
          <CardMask show={this.props.cerviBox.mask} />
        </Card>
      </div>
    );
  }
}

export default CerviBoxResultGridCard;
